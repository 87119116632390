export const apiWrapperobjects = (url, method, body = {}, headers = {}) => {
  //const URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
  const site = sessionStorage.getItem("site");

  let URL_BACKEND;

  if (window.location.hostname === "www.valdoapp.com" && site === "tlaxcala") {
    URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
  } else if (
    window.location.hostname === "www.valdoapp.com" &&
    site === "cdmx"
  ) {
    URL_BACKEND = "https://bkmg-production.up.railway.app/";
  } else if (
    window.location.hostname === "https://test-tlaxcala.vercel.app/" &&
    site === "tlaxcala"
  ) {
    URL_BACKEND = "https://m14sxgd6-3001.uks1.devtunnels.ms/";
    //   TEST
  } else {
    URL_BACKEND =
      site === "cdmx" ? "http://localhost:3002/" : "http://localhost:3001/";
  }

  //console.log('Site:', site);
  //console.log('URL_BACKEND:', URL_BACKEND);
  {
    /* 
    const URL_BACKEND =
    window.location.hostname === "www.valdoapp.com"
    ? "https://tlaxcala-production.up.railway.app/"
    : "http://localhost:3001/";
    
    
        export      let URL_BACKEND
        
        if (window.location.hostname === "www.valdoapp.com") {
          URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
        } else {
          // Assume site is accessible here
          URL_BACKEND = site === "cdmx" ? "http://localhost:3002/" : "http://localhost:3001/";
        }
      */
  }

  //render host
  //const URL_BACKEND = window.location.hostname === 'www.valdoapp.com' ? "https://tlaxcala.onrender.com/" : 'http://localhost:3001/';

  //angelo server
  //const URL_BACKEND = window.location.hostname === 'www.valdoapp.com' ? "https://217-133-34-187.static.clienti.tiscali.it:8080/"  : 'http://localhost:3001/';
  const options = {
    method,
    mode: "cors",
    headers: {
      // "Content-Type": "application/json",
      Accept: "application.json",
      ...headers,
    },
  };
  // Get the token from local storage
  const token = window.localStorage.getItem("user-session");
  // console.log(token)
  // Include the authorization header if a token is provided
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  if (body) {
    if (body instanceof FormData) {
      // If the body is FormData, don't stringify it
      options.body = body;
    } else if (Object.keys(body).length > 0) {
      // If the body is an object, stringify it and set the Content-Type to application/json
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(body);
    }
  }

  return fetch(URL_BACKEND + url, options).then((response) => {
    if (response.ok) {
      // If the response was successful, return the response body
      return response.json();
    } else {
      // If the response was not successful, throw an error with the response body
      return response.json().then((error) => {
        throw error;
      });
    }
  });
};
