import React, { useState, useRef } from "react";
//import QrReader from "react-qr-scanner";
//import { QrReader } from 'react-qr-reader';
import { apiWrapper } from '../../utils/apiwrapper';
//import  { Html5QrcodeScanner } from  "html5-qrcode"
import Html5QrcodePlugin from "./VideoProductNewQrReader";
import { tokenDecoder } from '../../utils/tokenDecoder';
import { getUserSession } from '../../utils/localStorage.utils';
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {date, options, mexicanTime} from "../../utils/variousConst"
//import { fetch } from "@cloudinary/url-gen/qualifiers/source";
import { successSound, errorSound } from "../../utils/variousConst";

const QRScanNewTest = ({ id, article }) => {

  const session =tokenDecoder(getUserSession())
  const user = session.username
 
 
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("No result");
  const [scanning, setScanning] = useState(false);
  const qrRef = useRef(null);

  const [articles, setArticles] = useState([]);

  


  const handleScan = async (data) => {
    const dataerr = JSON.stringify(data, null, 2)
    console.log("here is",data)
    //setMessage(`${dataerr}`);
    
   // const jsonData = JSON.parse(data); // Parse the JSON-formatted string
   // const { code, provider, value, kg, masterkg, id, country } = JSON.parse(data);
   // console.log('Code:', code);
   // const code = jsonData.code; // Access the 'code' property
    //const all = jsonData
  //  console.log(all)
    //console.log('Code:', code);
    //const extractedData = extractDataFromQRCode(data.text);
   // console.log('Extracted QR code data:', extractedData);
    setResult(data);
    if (data ) {
      setMessage(`Reading....`);
      setScanning(false); // Stop scanning when an article is scanned
      const qrCodeText = data
     // console.log(qrCodeText)
      try {
        
    
        // Extract the data from the QR code
        const { code, provider, value, kg, masterkg, id, country } = extractDataFromQRCode(data);
     //console.log('extracting the QR code data:', data); // Log the QR code data
     // console.log('Article ID:', { id }); // Log the extracted ID
  
        // Calculate the updated value
        const updatedValue = Number(value) + 1;
        const updatedKg = Number(kg) + Number(masterkg);
  
        //console.log('Article ID:',id );

        // Call the API to patch the updated value to the database
        const response =   await apiWrapper(`almacen/${id}/increment`, 'PATCH', { value: updatedValue, kg: updatedKg, employee: {user, mexicanTime} });
  
        //console.log('QR code data submitted to the database:', data.text);
        const idscanned = id;
       // console.log(response)
      if (response.message) {
        if (response.message === "Article not Found. Not Updated") {
          //console.error(error);
          errorSound.play();
          setMessage(
            <Alert className="flex justify-center" severity="error">
              An error occurred during scanning. Is this an Article QR?
              <div className="flex flex-col items-center">
                <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                  <pre className="whitespace-pre-wrap">
                    {JSON.stringify(data, null, 2)}
                  </pre>
                </div>
              </div>
              Please try again.
            </Alert>
          );
          setTimeout(() => {
            setMessage("No result");
          }, 9000);
          return;
        }
      }
      else  {
        const fetchArticles = async () => {
          try {
            const response = await apiWrapper("almacen", "GET");
            const filteredArticles = response.filter(
              (article) => article._id === idscanned
            );
           setArticles(filteredArticles);
        // console.log(filteredArticles)
            //setArticles(response)

            const itemsupdated = filteredArticles[0]?.value;
            const kglastupdated = filteredArticles[0]?.kg;
         //   console.log(kglastupdated)
        //   console.log(itemsupdated)
        
           return {
            itemsupdated: Number.isInteger(itemsupdated) ? itemsupdated : Math.round(itemsupdated * 100) / 100,
            kglastupdated: Number.isInteger(kglastupdated) ? kglastupdated : Math.round(kglastupdated * 100) / 100 
          };
            
          } catch (error) {
            console.error("Error fetching articles:", error); 
            return { itemsupdated: null, kglastupdated: null };
          }
        };
           // Play the success sound
           successSound.play();
           const { itemsupdated, kglastupdated } = await fetchArticles();
   //console.log(itemsupdated, kglastupdated);
   
           setMessage(
             <Alert className="flex justify-center" severity="success">
               <AlertTitle>Scan completed!</AlertTitle>
               <div className="flex flex-col items-center">
                 <div>
                   <strong>Code:</strong> {code}
                 </div>
                 <div>
                   <strong>Provider:</strong> {provider}
                 </div>
                 <div>
                   <strong>Country:</strong> {country}
                 </div>
                 <div>
                   <strong>kg:</strong> {updatedKg}
                 </div>
                 <div>
                   <strong>Sacchi:</strong> {updatedValue}
                 </div>
                 <div className="text-2xl">
                   <strong>Sacchi Totali:</strong> {itemsupdated}
                 </div>
                 <div className="text-2xl">
                   <strong>Kg Totali:</strong> {kglastupdated}
                 </div>
               </div>
             </Alert>
           );
   
           // Clear the message after 1 second
           setTimeout(() => {
             setMessage("No result");
           }, 9000);
         }} 
        catch (error) {
        console.error(error,data);
        errorSound.play();
        setMessage(
          <Alert className="flex justify-center" severity="error">
            An error occurred during scanning. Is this an Article QR?
            <div className="flex flex-col items-center">
              <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                <pre className="whitespace-pre-wrap">
                {dataerr}
                </pre>
              </div>
            </div>
            Please try again.
          </Alert>
        );
        setTimeout(() => {
          setMessage("No result");
        }, 9000);
        return;
      }
    } else {
      setMessage("No result");
    }
  };

       
  



  const extractDataFromQRCode = (data) => {
  //  console.log(data)
    // Parse the QR code data and extract the code, provider, value, kg, masterkg , and ID
 
    const { code, provider, value, kg, masterkg, id, country  } = JSON.parse(data);

    return { code, provider, value, kg, masterkg, id, country   };
 
 
  }

const handleError = (err,data) => {
    //console.error(err);
   // setMessage(`${dataerr}`);
  };


  const startScanning = () => {
    setScanning(true);
    {/* 
    <div className="App">
    <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        //qrCodeSuccessCallback={onNewScanResult}
    />
</div>
  */}
  //  qrRef.current?.openImageDialog(); // Open the device camera
 //close camera if no scan done in 6 seconds
 setTimeout(() => {
  setScanning(false);
}, 9000);

  };

  const stopScanning = () => {
    setScanning(false);
  };

  return (
    <div >
      {scanning ? (
       <Html5QrcodePlugin
       fps={10}
       qrbox={250}
       disableFlip={false}
       qrCodeSuccessCallback={handleScan}
      // qrCodeErrorCallback={handleError}
   />
      ) : (
       
       <p>Ready</p>
      )}
      {message !== "No result" && (
        <div className="flex justify-center items-center h-16 bg-gray-600 text-white">
          {message}
        </div>
      )}
      {scanning ? (
        <>
          <div className="flex flex-col justify-center text-white">
            <h1 className="flex  justify-center">
              {" "}
              Camera will turn off if no scan done in 6 seconds{" "}
            </h1>

            <button
              className="flex justify-center  text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-red-700 focus:ring-teal-800 mt-4"
              onClick={stopScanning}
            >
              Stop Scanning
            </button>
          </div>{" "}
        </>
      ) : (
        <QrCodeScannerOutlinedIcon
          sx={{ fontSize: 60 }}
          onClick={startScanning}
        />
      )}
    </div>
  );
};

export default QRScanNewTest;