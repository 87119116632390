import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const GuiaCrearPedidoPage = () => {
  const navigate = useNavigate();
  const navigateToGuia = () => {
    navigate("/guia");
  }; 
   

  

  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
      <span><button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button></span>         <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                  Instrucciones para crear Pedidos
                  </h1> 
                  </div>
                  <div id="description" className="text-white ml-3 mr-3">

                  <p class="mb-4 text-sky-700">El administrador puede crear una orden a través de este formulario autocompletado.</p>

<p class="mb-4">Existen 3 tipos posibles de órdenes.</p>

<p >Seleccione el tipo de Materia Prima:</p>
<p class="mb-4">Resina para Preforma y Modelo Final:</p>
<ul class="list-disc ml-6">
  <li> Se pueden seleccionar el tipo de resina, el modelo de preforma y el modelo final (por ejemplo, garrafa).</li>
  <li>Tendremos un control del color de la preforma y del modelo final seleccionados, destacando en verde en caso de coincidencia y en rojo en caso de diferencia.</li>
  <li>Justo debajo, se mostrará automáticamente el peso de un solo artículo, y al ingresar el número total de artículos a producir, obtendremos los kg necesarios de "materia prima" para completar la orden.</li>
  <li>Al agregar el número de preformas contenidas en un paquete y el número de modelos finales por paquete, aparecerán los "paquetes" totales de preformas y modelos finales necesarios, así como el número total de códigos QR para imprimir y aplicar a los paquetes de productos.</li>
</ul>

<p class="mb-4">Una vez que se guarda el procedimiento, aparecerá un código QR para adjuntar a la ficha de la orden generada a través del botón "Editar" y la opción "agregar código QR" en el menú "pedido de preforma".</p>

<p class="mb-4">Seleccionando Materia Prima: Resina para Preforma, tendremos acceso a los datos de la resina y los tipos de preformas para agregar, el resto del procedimiento permanecerá igual.</p>

<p class="mb-4">Seleccionando Materia Prima: Resina para Modelo Final, tendremos acceso a los datos de la resina y los tipos de modelos finales, el resto del procedimiento permanecerá igual, excepto por el código QR que debe adjuntarse al final del procedimiento, que se encontrará en el menú "pedido".</p>
                  </div>
                  <div>
          

</div>

<div>
<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696840529/guia/crearpedido_preformaymodelo.mp4")
                        }
                        >Crear Pedido Preforma y Modelo Final </button>


<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696839760/guia/crearpedido_solopreforma.mp4")
                        }
                        >Crear Pedido solo Preforma </button>
                        
                        <button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696841812/guia/crearpedido_solomodelofinal.mp4"
                        )
                      }
           
          >
           Crear Pedido solo Modelo Final
          </button>   
  
                        </div>
                        
                        
                       
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaCrearPedidoPage