import React, { useState, useRef, useEffect } from "react";
//import QrReader from "react-qr-scanner";
//import { QrReader } from 'react-qr-reader';
import { apiWrapper } from "../../utils/apiwrapper";
import Html5QrcodePlugin from "../temp/VideoProductNewQrReader";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//import { SwitchAccessShortcutAddOutlined } from "@mui/icons-material";
import {date, options, mexicanTime} from "../../utils/variousConst"
import { successSound, errorSound } from "../../utils/variousConst";

const QRScanOrder = ({ id, order }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  // console.log("ciccio", user)
 
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("No result");
  const [scanning, setScanning] = useState(false);
  const qrRef = useRef(null);

  const [orders, setOrders] = useState([]);

  

  const handleScan = async (data) => {
    const dataerr = JSON.stringify(data, null, 2)
    setResult(data);

    if (data ) {
      setMessage(`Reading....`);
     // setScanning(false); // Stop scanning when an article is scanned
      onScanDetected()
      try {
        // Extract the data from the QR code
        const {
          ordernr,
          customer,
        //  finalproduct,
        //  itemstodo,
         // itemsscanned,
         // pack,
          id,
        } = extractDataFromQRCode(data);
        //  console.log('extracting the QR code data:', data.text); // Log the QR code data
        // console.log('Article ID:', { id }); // Log the extracted ID
        const response2 = await apiWrapper(`order/${id}`, "GET");
        const pack = response2.pack;
        const itemsscanned = "";
        const itemstodo = response2.itemstodo;
        const finalproduct = response2.finalproduct;
        // Calculate the updated value
        const updatedValue = Number(itemsscanned) + Number(pack);

        // const updatedKg = Number(kg) + Number(masterkg);

        //  console.log('Article ID:', id);

        // Call the API to patch the updated value to the database
       const response = await apiWrapper(`order/${id}/increment`, "PATCH", {
          itemsscanned: updatedValue,
          employeestory: { user,
            mexicanTime: new Date().toLocaleString("en-US", options),
            },
        });

        //console.log('QR code data submitted to the database:', data.text);
        const idscanned = id;

        if (response.message) {
          if (response.message === "Order not Found. Not Updated") {
            errorSound.play();
            setMessage(
              <Snackbar
              open={true}
              autoHideDuration={4000}
              onClose={() => setMessage('No result')}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <Alert className="flex justify-center" severity="error">
                Ocurrió un error durante el escaneo. ¿Es este un pedido QR?
                <div className="flex flex-col items-center">
                  <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                    <pre className="whitespace-pre-wrap">
                      {JSON.stringify(data, null, 2)}
                    </pre>
                  </div>
                </div>
                Please try again.
              </Alert>
              </Snackbar>
            );
           // setTimeout(() => {
           //   setMessage("No result");
          //  }, 5000);
          }
          else {
            if (response.message === "Updated value exceeds itemtodo. Patch denied.") {
              errorSound.play()
              setMessage(
                <Snackbar
                open={true}
                autoHideDuration={4000}
                onClose={() => setMessage('No result')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                Llegaste al tope de la orden de producción. No se permiten más escaneos en esta fase
                  <div className="flex flex-col items-center">
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
                  <div> Art Para hacer: {itemstodo} </div>
                  
                    </div>
                  </div>
                  
                </Alert>
                </Snackbar>
              );
            }
            if (response.message === "order.kgneeded < order.kgloadedfromalmacen. Patch denied.") {
              errorSound.play()
              setMessage(
                <Snackbar
                open={true}
                autoHideDuration={4000}
                onClose={() => setMessage('No result')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                No has cargado suficientes kg de resina para la producción
                  <div className="flex flex-col items-center">
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
                  <div> Kg necesarios: {response2.kgneeded} </div>
                  
                    </div>
                  </div>
                  
                </Alert>
                </Snackbar>
              );
            }
            if (response.message === "updatedValue > order.preformaaddedtoprod. Patch denied.") {
              errorSound.play()
              setMessage(
                <Snackbar
                open={true}
                autoHideDuration={4000}
                onClose={() => setMessage('No result')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                Estás intentando producir más Artículos de las Preformas en Existencia
                  <div className="flex flex-col items-center">
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
                  <div> Preformas en producción: {response2.preformaaddedtoprod} </div>
                  
                    </div>
                  </div>
                  
                </Alert>
                </Snackbar>
              );
            }
            if (response.message === "la fase de produccion no ha sido confirmada") {
              errorSound.play()
              setMessage(
                <Snackbar
                open={true}
                autoHideDuration={4000}
                onClose={() => setMessage('No result')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                La fase de producción no ha sido confirmada
                  <div className="flex flex-col items-center">
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
                  <div> Revisa Pedido en producción </div>
                  
                    </div>
                  </div>
                  
                </Alert>
                </Snackbar>
              );
            }
          }
        }

else { 
  
        const fetchOrders = async () => {
          try {
            const response = await apiWrapper("order", "GET");
            const filteredOrders = response.filter(
              (order) => order._id === idscanned
            );
            setOrders(filteredOrders);
            //setOrders(response)

            const itemsupdated = filteredOrders[0]?.itemsscanned;
            const packupdated = filteredOrders[0]?.packscanned;
            //console.log(itemsupdated)
            return { itemsupdated, packupdated };
          } catch (error) {
            console.error("Error fetching orders:", error);
          }
        };
        // Play the success sound
        successSound.play();
        const { itemsupdated, packupdated } = await fetchOrders(); 
        //console.log(itemsupdated)

        setMessage(
          <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={() => setMessage('No result')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="success">
            <AlertTitle>¡Escaneo completado!</AlertTitle>
            <div className="flex flex-col items-center">
              <div>
                <strong>Pedido:</strong> {ordernr}
              </div>
              <div>
                <strong>Cliente:</strong> {customer}
              </div>
              <div>
                <strong>Artículos para hacer:</strong> {itemstodo}
              </div>
              <div >
                <strong>Añadidos:</strong><span className="text-2xl">{updatedValue}</span> 
                </div>
                <div>
                <strong>Total:</strong> <span className="text-2xl">{itemsupdated}</span>
              </div>
                <div>
                <strong>Paquetes:</strong><span className="text-2xl"> {packupdated}</span> 
              </div>
          
            </div>
          </Alert>
          </Snackbar>
        );

        // Clear the message after 1 second
       // setTimeout(() => {
      //    setMessage("No result");
      //  }, 5000);
    }} catch (error) {
        console.error(error);
        errorSound.play();
        setMessage(
          <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={() => setMessage('No result')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="error">
            Ocurrió un error durante el escaneo. ¿Es este un pedido QR?
            <div className="flex flex-col items-center">
              <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                <pre className="whitespace-pre-wrap">
                  {dataerr}
                </pre>
              </div>
            </div>
            Inténtalo de nuevo
          </Alert>
          </Snackbar>,
        );
        //setTimeout(() => {
      //    setMessage("No result");
      //  }, 5000);
      }
    } else {
      setMessage("No result");
    }
    // console.log(data);
  };

  const extractDataFromQRCode = (qrCodeData) => {
    // Parse the QR code data and extract the code, provider, value, kg, masterkg , and ID
    const {
      ordernr,
      customer,
      finalproduct,
      itemstodo,
      itemsscanned,
      pack,
      id,
    } = JSON.parse(qrCodeData);

    return {
      ordernr,
      customer,
      finalproduct,
      itemstodo,
      itemsscanned,
      pack,
      id,
    };
  };

  const handleError = (err) => {
    console.error(err);
  };

  let scanTimeout;

  const startScanning = () => {
    // Open the device camera
    setScanning(true);

    //qrRef.current?.openImageDialog();

    //close camera if no scan done in 6 seconds
   // setTimeout(() => {
    //  setScanning(false);
 //   }, 5000);
  };

  const stopScanning = () => {
    setScanning(false);
    clearTimeout(scanTimeout);
  };

  const onScanDetected = () => {
    setScanning(false);
    clearTimeout(scanTimeout);
     // Set the scanning state back to true after 1 second
    
     scanTimeout = setTimeout(() => {
     setScanning(true);
   //  console.log("passo1riattivo")
     }, 3000);
     //scanTimeOutTwo = setTimeout(() => {
     //    console.log("passo2laspengodefi")
       //  setScanning(false);
        // }, 90000);
     };


  return (
    <div>
          {scanning ? (
       <Html5QrcodePlugin
       fps={10}
       qrbox={250}
       disableFlip={false}
       showTorchButtonIfSupported={true}
       qrCodeSuccessCallback={handleScan}
      // qrCodeErrorCallback={handleError}
   />
      ) : (
        <p className="text-center">Producto</p>
      )}
      {message !== "No result" && (
        <div className="flex justify-center items-center h-16 bg-gray-600 text-white">
          {message}
        </div>
      )}
      {scanning ? (
        <>
          <div className="flex flex-col justify-center text-white">
            <h1 className="flex  justify-center">
              {" "}
              ...Trabajando...{" "}
            </h1>

            <button
              className="flex justify-center  text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-red-700 focus:ring-teal-800 mt-4"
              onClick={stopScanning}
            >
              Apagar la cámara
            </button>
          </div>{" "}
        </>
      ) : (
        <QrCodeScannerOutlinedIcon
          sx={{ fontSize: 60 }}
          onClick={startScanning}
        />
      )}
    </div>
  );
};

export default QRScanOrder;
