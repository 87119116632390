import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const GuiaRevisoPage = () => {
    const navigate = useNavigate();
  
 
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };   

      const toggleDropdown2 = () => {
      setIsOpen2(!isOpen2);
    };  

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
      };  

      const toggleDropdown4 = () => {
        setIsOpen4(!isOpen4);
      };  

      const toggleDropdown5 = () => {
        setIsOpen5(!isOpen5);
      }; 
  
      const navigateToGuia = () => {
        navigate("/guia");
      };
  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
      <button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button> 
        
                  
                  </div>
                  <div id="description" className="text-white ml-3 mr-3">

                  

<p className="text-sky-700"><strong>Revisión de Pedidos</strong></p>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
      onClick={toggleDropdown1}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen1}
      aria-controls="options-menu"
    >    {isOpen1 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
    {isOpen1 && ( 
      <>



<p class="mb-4">Esta ficha permite visualizar todos los pedidos en curso y los finalizados con diversos detalles.</p>

  <p class="mb-4">Se ofrece un resumen del pedido de preformas y el pedido de modelos, así como el uso de la materia prima.</p>

  <p class="mb-4">Se proporciona un resumen de las actividades realizadas por los diferentes actores en la producción, con detalles por acción, fecha y hora, para llevar un control de las actividades realizadas y la productividad.</p>

  <p class="mb-4">Para esta última búsqueda, se recomienda utilizar el modo de escritorio, ya que los detalles para cada actor pueden resultar confusos en una vista desde un teléfono móvil.</p>

  <p class="mb-4">También es posible realizar búsquedas por fecha, pedido y actor, donde aparecerán resultados si alguno de estos parámetros está presente.</p>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
      onClick={toggleDropdown1}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen1}
      aria-controls="options-menu"
    >    {isOpen1 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>

</>  )}

                  

<p className="text-sky-700"><strong>Pedidos Completados</strong></p>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
      onClick={toggleDropdown2}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen2}
      aria-controls="options-menu"
    >    {isOpen2 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
    {isOpen2 && ( 
      <>
 <p class="mb-4">En esta ficha, encontrarás todos los pedidos de preformas y modelos completados con todos los detalles de la fase de producción. También es posible realizar modificaciones si es necesario.</p>

<p class="mb-4">También encontrarás la opción de descargar el pedido (para Excel) por artículo y por preforma, donde se detallarán todas las acciones de los actores, incluyendo fecha y hora.</p>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
      onClick={toggleDropdown2}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen2}
      aria-controls="options-menu"
    >    {isOpen2 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>

</>  )}

  

  <p class=" text-sky-700"><strong>Exportar a Excel</strong></p>
  <button
        type="button"
        className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
        onClick={toggleDropdown}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="options-menu"
      >    {isOpen ? (
        <>
          
          Ocultar
        </>
      ) : (
        <>
          <AddCircleIcon className="mr-3" />
         Mostrar
        </>
      )}
      </button>
      {isOpen && ( 
        <>
  <p class="mb-4">En todas las fichas de producción y de almacén, encontrarás un botón para exportar a Excel el pedido o el artículo deseado.</p>

<p class="mb-4">Debes presionar el botón una primera vez para activar la posibilidad de descarga. El ícono cambiará de estado y permitirá la operación durante cinco segundos.</p>

<p class="mb-4">Durante el primer clic, el programa preparará una descarga con los datos actualizados al segundo y cerrará la ventana después de unos momentos para evitar la descarga de datos desactualizados.</p>
  
  <button
        type="button"
        className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
        onClick={toggleDropdown}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="options-menu"
      >    {isOpen ? (
        <>
          
          Ocultar
        </>
      ) : (
        <>
          <AddCircleIcon className="mr-3" />
         Mostrar
        </>
      )}
      </button>
  </>  )}

  


                  </div>
                  <div>

</div>

<div>

                        </div>
                     
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaRevisoPage