import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
//import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const NewPreformaModel = () => {
  
 

  useEffect(() => {
  const fetchRoscas = async () => {
    try {
      const response = await apiWrapper("roscas", "GET");
      setListaRoscas(response);
 //     console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching roscas:", error);
    }
  };

  const fetchColores = async () => {
    try {
      const response = await apiWrapper("colores", "GET");
      setColor(response);
  //    console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };

  const fetchPrefListaCode = async () => {
    try {
      const response = await apiWrapper("preflistasubfamilia", "GET");
      setListaPrefSubFam(response);
      //   console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching preflistasubfamilia:", error);
    }
  };


  fetchRoscas();
  fetchColores();
  fetchPrefListaCode();
 
}, []);

  const [color, setColor] = useState([]);
  const [listaRoscas, setListaRoscas] = useState([]);

  const [listaPrefSubFam, setListaPrefSubFam] = useState([]);
  const [selectedPrefListaCode, setSelectedPrefListaCode] = useState("");
  const [selectedPrefListaCodeDescription, setSelectedPrefListaCodeDescription] =
    useState("");
  
  const [selectedListaColores, setSelectedListaColores] = useState("");
  const [selectedListaColoresDescription, setSelectedListaColoresDescription] =
    useState("");
  const [selectedListaRoscas, setSelectedListaRoscas] = useState("");

  const [grams, setGrams] = useState('');
const [pModels, setPModels] = useState([]);

  const [codeError, setCodeError] = useState("")
  const[message, setMessage]=useState("")
  const [formData, setFormData] = useState({
    code: "",
    modelname: "",
    color:"",
    value: 1,

    familia: "",
    familiadescription: "",
    subfamilia: "",
    subfamiliadescription: "",
    cuello:"",
    cuellodescription:"",
    cuellotipo:"",
    cuellotipodescription:"",
    colordescription:"",
    rosca:"",
    roscadescription:"",

    grams: "",
    masterkg:"",
    //qr:""
  });

  const [familiaKey, setFamiliaKey] = useState("SEL");
  const [cuelloTipoKey, setCuelloTipoKey] = useState("SEL");
  const [cuelloKey, setCuelloKey] = useState("SEL");

  //const subfamiliaMapping = [
 //   { code: "I1", description: "I1" },
  //  { code: "E", description: "Extrusion" },
  //  { code: "I", description: "Inyeccion" },
 // ];

  const familiaMapping = [
    { code: "PRG", description: "PREFORMA-GARRAFON" },
    { code: "PRB", description: "PREFORMA-BOTELLA" },
  //  { code: "GLP", description: "Garrafon Liso Presion" },
  //  { code: "GAP", description: "Garrafon Asa presion" },
  ];

  const cuelloMapping = [
    { code: "COS", description: "COSMETIQUERO" },
    { code: "REF", description: "REFRESQUERO" },
    { code: "LAV", description: "LAVATRASTES" },
    { code: "NA", description: "NO APLICA" },
  ];

  const cuelloTipoMapping = [
    { code: "CL", description: "CUELLO LARGO" },
    { code: "CT", description: "CUELLO CORTO" },
    { code: "NA", description: "NO APLICA" },
  ];

  const [model, setModel] = useState("");
  //const qrCodeRef = useRef(null);

  //const [qrData, setQrData] = useState("");
  const resetAllStates = () => {
    //  selectedAsacolorCode("");
    
    setSelectedListaColores("");
    setSelectedListaColoresDescription("");
    setSelectedPrefListaCode("");
    setSelectedPrefListaCodeDescription("");
    setSelectedListaRoscas("");
    setFamiliaKey("SEL");
    setCuelloTipoKey("SEL");
    setCuelloKey("SEL");
    setGrams('')
      setFormData({
        code: "",
        modelname: "",
        color: "",
        colordescription: "",
        value: 1,
        masterkg: "",
        //qr,
        familia: "",
        familiadescription: "",
        subfamilia: "",
        subfamiliadescription: "",
        rosca: "",
        roscadescription: "",
        cuello: "",
        cuellodescription: "",
        cuellotipo:"",
        cuellotipodescription:"",
        masterkg:"",
      });
    };

  
      const fetchPModels = async () => {
        try {
          const response = await apiWrapper("preforma", "GET");
          setPModels(response);
        } catch (error) {
          // Handle error
          console.error("Error fetching models:", error);
        }
      };
  
   

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
  
    if (name === "value" || name === "masterkg") {
      parsedValue = Number(value);
    }
    if (name === "grams") {
      setGrams(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        grams: value, 
        masterkg: value / 1000, // Convert grams to kg
      }));
    }
  
    if (name === "familia") {
      setFamiliaKey(value);
      const selectedFamilia = familiaMapping.find(
        (familia) => familia.code === value
      );
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        familia: value === "SEL" ? "" : value,
        familiadescription: selectedFamilia ? selectedFamilia.description : "",
      }));
    } 
   else if (name === "cuellotipo") {
      setCuelloTipoKey(value);
      const selectedTipoCuello = cuelloTipoMapping.find(
        (cuellotipo) => cuellotipo.code === value
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        cuellotipo: value === "SEL" ? "" : value,
        cuellotipodescription: selectedTipoCuello
          ? selectedTipoCuello.description
          : "",
      }));
    }
      else if (name === "cuello") {
      setCuelloKey(value);
      const selectedCuello = cuelloMapping.find(
        (cuello) => cuello.code === value
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        cuello: value === "SEL" ? "" : value,
        cuellodescription: selectedCuello
          ? selectedCuello.description
          : "",
      }));
    }else if (name === "subfamilia") {
      const selectedSubfamilia = listaPrefSubFam.find(
        (subfamilia) => subfamilia.preflistacode === value
      );
      setSelectedPrefListaCode(
        selectedSubfamilia ? selectedSubfamilia.preflistacode : ""
      );
      setSelectedPrefListaCodeDescription(
        selectedSubfamilia ? selectedSubfamilia.preflistadescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        subfamiliadescription: value === "SEL" ? "" : selectedSubfamilia ? selectedSubfamilia.preflistadescription : "",
        subfamilia: value === "SEL" ? "" : selectedSubfamilia ? selectedSubfamilia.preflistacode : "", // Use preflistacode here
      }));
    } else if (name === "color") {
      const selectedListaColores = color.find(
        (color) => color.colorcode === value
      );
      setSelectedListaColores(
        selectedListaColores ? selectedListaColores.colorcode : ""
      );
      setSelectedListaColoresDescription(
        selectedListaColores ? selectedListaColores.colordescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        colordescription: value === "SEL" ? "" : selectedListaColores.colordescription,
        color: value === "SEL" ? "" : value,
      }));
    } else if (name === "rosca") {
      const selectedListaRosca = listaRoscas.find(
        (rosca) => rosca.rosca === value
      );
      setSelectedListaRoscas(
        selectedListaRosca ? selectedListaRosca.rosca : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        roscadescription: selectedListaRosca
          ? selectedListaRosca.rosca[0] === "R"
            ? "rosca"
            : selectedListaRosca.rosca[0] === "P"
            ? "presion"
            : selectedListaRosca.rosca[0] === "L"
            ? "liso"
            : selectedListaRosca.rosca
          : "",
        rosca: value === "SEL" ? "" : value,
      }));
    } 
    
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue,
        
      }));
      
    }
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value === "SEL" ? "" : value,
      };

      // console.log(newFormData.tipomodelo, value);

      // Create codeConcatenation using the newFormData
    let codeConcatenation = `${newFormData.familia || ''}${newFormData.subfamiliadescription || ''}${newFormData.grams ? newFormData.grams + "G" : ''}${newFormData.rosca || ''}${newFormData.cuello || ''}${newFormData.cuellotipo || ''}${newFormData.color|| ''}` ;
      
      // Update "code" field
    newFormData.code = codeConcatenation;

    //Garrafon Asa rosca 19 Lts. R-49 Morado Tepa
      let descriptionConcatenation = `${newFormData.familiadescription || ''} ${newFormData.subfamiliadescription || ''} ${newFormData.rosca || ''} ${newFormData.cuello || ''} ${newFormData.cuellotipo || ''} ${newFormData.grams ? newFormData.grams + "G" : ''} ${newFormData.colordescription || ''}`
     newFormData.modelname = descriptionConcatenation;
      return newFormData;
    });

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
      };
  

      const response = await apiWrapper("preforma", "POST", formDataWithNumberValue);
      setFormData({ code: "", modelname: "",
     value: 1,
      familia: "",
      familiadescription: "",
      subfamilia: "",
      subfamiliadescription: "",
      cuello:"",
      cuellodescription:"",
      colordescription:"",
      rosca:"",
      roscadescription:"",
      grams: "",
      color:"", masterkg:"" });
      setModel(response._id);
      fetchPModels();
      resetAllStates()

   
      
  // to get all the data in the message
      const updatedFormData = { ...formDataWithNumberValue };
    
  //   console.log("Response:", response);
  if (response.msg) {
    if (response.msg === "Code already exists") {
      setCodeError(response.msg);
      setMessage(
        <Alert className="flex justify-center" severity="error">
          El código {formDataWithNumberValue.code}  ya existe.
        Por favor asigne uno nuevo.
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
      }, 6000);
      setTimeout(() => {
        setCodeError(false);
        
      }, 6000);
   }
  
   }
   else{ 

  
    setMessage(
      <Alert className="flex justify-center" severity="success">
        <AlertTitle>¡Nueva preforma añadida!</AlertTitle>
        <div className="flex flex-col items-center">
          <div>
            <strong>Codigo:</strong> {updatedFormData.code}
          </div>
          <div>
            <strong>Nombre Modelo:</strong> {updatedFormData.modelname}
          </div>
       
        </div>
      </Alert>
    );

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }} catch (error) {
    console.error(error);
    
    setMessage(
      <Alert className="flex justify-center" severity="error">
        
        Ocurrió un error,
        
        Inténtalo de nuevo.
      </Alert>
    );
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }
} 



{/* 
  useEffect(() => {
    setFormData({ code: "", modelname: "", 
    value: 1,  
    color:"", masterkg:"", rosca:"", });
  }, []);
*/ }
    const navigate = useNavigate();

    const navigateToDashboardPreforma = () => {
      navigate("/dashboardpreforma");
    };

  return (

    <section className=" bg-gray-900  ">
     <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 
           bg-gray-800 ">
             <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
               <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                    Añadir Modelo PREFORMA
                </h1>
 
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                        <label htmlFor="code"
                         className="block mb-2 text-sm font-medium  text-white">Codigo</label>
                        <input type="text" name="code" id="code" 
                        value={formData.code}
                        onChange={() => {}} 
                        // onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " 
                            placeholder="codigo" required/>
                             {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                   
                    <div>
                        <label htmlFor="modelname"
                         className="block mb-2 text-sm font-medium  text-white">Nombre Modelo</label>
                        <input type="text" name="modelname" id="modelname" 
                        value={formData.modelname}
                        onChange={() => {}}
                        //  onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " 
                            placeholder="Nombre Modelo" required/>
                    </div>
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
              <div>

                <label
                  htmlFor="familia"
                  className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Familia
                </label>

                <select
                  className={`rounded-lg  text-white p-2  w-full ${familiaKey === 'SEL' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="familia"
                  value={familiaKey}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {familiaMapping.map((familia) => (
                    <option key={familia.code} value={familia.code}>
                      {familia.description}
                    </option>
                  ))}
                </select>
              </div>

              <div>

                  <label
                    htmlFor="subfamilia"
                    className="block mb-2 mt-2 text-sm font-medium  text-white xl:text-xl uppercase"
                  >
                    SubFamilia
                  </label>
                  <select
                   className={`rounded-lg  text-white p-2 w-full  ${formData.subfamilia === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                    name="subfamilia"
                    value={formData.subfamilia}
                    onChange={handleInputChange}
                    required
                  >
                  <option value="SEL">SEL</option>
                  {listaPrefSubFam.map((subfam) => (
                    <option key={subfam._id} 
                    
                    // to have code and description different uncomment it
                    value={subfam.preflistacode}
                  //  value={subfam.preflistadescription}
                    >
                      {subfam.preflistadescription}
                    </option>
                  ))}


               
                  </select>
              </div>

                </div>

                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid xl:gap-20 md:gap-10 sm:gap-2 md:grid-cols-2 ">
                <div className="flex flex-col gap-2">
                  <input
                    type="text"
                    name="familia"
                    placeholder="familia código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white md:w-full   "
                    value={formData.familia}
                    onChange={() => {}}
                 //  readOnly
                   required
                  />

                  <input
                    type="text"
                    name="familiadescription"
                    placeholder="familia descripción ..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white mb-1 md:w-full  "
                    value={formData.familiadescription}
                    onChange={() => {}}
                    //readOnly
                    required
                  />
                 </div>
             
            
                <div className="flex flex-col flex-grow">
                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamiliatipo código..."
                    className="sm:text-sm rounded-lg mt-2  w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white mb-2"
                    value={formData.subfamilia}
                    onChange={() => {}}
                //    readOnly
                    required
                  />

                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamilia descripción..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={formData.subfamiliadescription}
                    onChange={() => {}}
                 //   readOnly
                    required
                  />
                </div>
              </div>

              <div className="w-full rounded-lg shadow  mb-4 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-3 ">
              <div >
                <label
                  htmlFor="rosca"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Rosca
                </label>
                <select
                 className={`rounded-lg  w-full p-2 text-white ${formData.rosca === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="rosca"
                  value={formData.rosca}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {listaRoscas.map((rosca) => (
                    <option key={rosca._id} value={rosca.rosca}>
                      {rosca.rosca}
                    </option>
                  ))}
                </select>
                </div>
                <div>
                {formData.familia !=="PRG" && ( 
                    <>
<label
  htmlFor="cuello"
  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
>
  CUELLO
</label>
<select
                   className={`rounded-lg  text-white p-2 w-full  ${cuelloKey === 'SEL' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                    name="cuello"
                    value={formData.cuello}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="SEL">SEL</option>
                    {cuelloMapping.map((cuello) => (
                      <option key={cuello.code} value={cuello.code}>
                        {cuello.description}
                      </option>
                    ))}
                  </select>
                  </>)}
                  </div>
                  <div>
                  {formData.familia !=="PRG" && ( 
                    <>
                  <label
  htmlFor="cuellotipo"
  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
>
CUELLO TIPO
</label>
<select
                   className={`rounded-lg  text-white p-2 w-full  ${cuelloTipoKey === 'SEL' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                    name="cuellotipo"
                    value={formData.cuellotipo}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="SEL">SEL</option>
                    {cuelloTipoMapping.map((cuellotipo) => (
                      <option key={cuellotipo.code} value={cuellotipo.code}>
                        {cuellotipo.description}
                      </option>
                    ))}
                  </select>
                  </>   )}
</div>
</div>
<div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-6 ">
               <input
                  type="text"
                  name="rosca"
                  id="rosca"
                  placeholder="rosca..."
                  value={selectedListaRoscas}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
              // readOnly
                  required
                />
                 <input
                  type="text"
                  name="roscadescription"
                  id="roscadescription"
                  placeholder="rosca descripción..."
                  value={formData.roscadescription} 
              //    onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
               //   readOnly
                  required
                />
                    {formData.familia !=="PRG" && ( 
                    <>
                <input
                  type="text"
                  name="cuello"
                  id="cuello"
                  placeholder="cuello código..."
                  value={formData.cuello}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
              //   readOnly
              required={formData.familia === "PRB"}
                />
                <input
                  type="text"
                  name="cuellodescription"
                  id="cuellodescription"
                  placeholder="cosm,ref,lav..."
                  value={ formData.cuellodescription}
                //  onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
                //  readOnly
                required={formData.familia === "PRB"}
                />
                  <input
                  type="text"
                  name="cuellotipo"
                  id="cuellotipo"
                  placeholder="cuello tipo código..."
                  value={formData.cuellotipo}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
              //   readOnly
              required={formData.familia === "PRB"}
                />
                <input
                  type="text"
                  name="cuellotipodescription"
                  id="cuellotipodescription"
                  placeholder="largo,corto,na..."
                  value={ formData.cuellotipodescription}
                //  onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
                //  readOnly
                required={formData.familia === "PRB"}
                />
                </>)}
               </div>
                    <div>
                    <div className="w-full rounded-lg shadow  mb-4 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-1 ">
         
                <div>

<label
  htmlFor="color"
  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
>
  Color
</label>
<select
 className={`rounded-lg w-full p-2 text-white ${formData.color === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
  name="color"
  value={formData.color}
  onChange={handleInputChange}
  required
>
  <option value="SEL">SEL</option>
  {color.map((color) => (
    <option key={color._id} value={color.colorcode}>
      {color.colordescription}
    </option>
  ))}
</select>
</div>
</div>
<div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
           
                <input
                  type="text"
                  name="color"
                  id="color"
                  placeholder="color código..."
                  value={selectedListaColores}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
              //   readOnly
                  required
                />
                <input
                  type="text"
                  name="colordescription"
                  id="colordescription"
                  placeholder="blue, rojo, transparente..."
                  value={ selectedListaColoresDescription}
                //  onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
                //  readOnly
                  required
                />
               </div>
                      
                    </div>

             

                   
                    <div className="w-full rounded-lg shadow  mb-4 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-2 ">
         
         <div>

         <label htmlFor="masterkg" className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase">Peso (GR)</label>
</div>
<div>

<label htmlFor="masterkg" className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase">Peso (KG)</label>
</div>

</div>
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
                       
                        <input type="number" name="grams" id="grams" placeholder="añadir peso en gramos" 
                        value={grams}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white
                          " required/>
                           
                        <input type="number" name="masterkg" id="masterkg" placeholder="peso en kg" 
                        value={formData.masterkg}
                        onChange={() => {}}
                        readOnly
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white
                          " />
                    </div>

                    
                    
                    <span className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p0 bg-gray-800  grid gap-2 md:grid-cols-2 md:justify-between xl:justify-between">
                    <button type="submit"
                      className="sm:w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800"
                      >Guardar</button>
  <button type="dashboardmodels"
                       className=" sm:w-full text-white mt-1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center   bg-sky-900 hover:bg-sky-700 focus:sky-800  xl:ml-auto md:ml-auto sm:ml-0"
                        onClick={navigateToDashboardPreforma}
                        >Panel de Preforma</button>

                    </span>
                   
                    </form>
                                     {/* Display the message */}
                                     {message && (
        <div className="message-container">
          {message}
        </div>
      )}
                   
  
                


            </div>
        </div>
    </div>
      
  </section>

    
   
  );
};

export default NewPreformaModel;