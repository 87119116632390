import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const EditDefectedModalPreforma = ({ defectedEditablePreforma, onSave, onClose }) => {
  const { order, index } = defectedEditablePreforma;
  const [editedOrderNr, setEditedOrderNr] = useState(order.ordernr || "");
  const [editedPreforma, setEditedPreforma] = useState(
    order.preforma || ""
  );
  //model missing
  const [editedPreformaKg, setEditedPreformaKg] = useState(
    order.preformakg || ""
  );
  const [editedItemsDefected, setEditedItemsDefected] = useState(
     ""
  );
  const [editedKgItemsDefected, setEditedKgItemsDefected] = useState(
     ""
  );
  //mat prima missing

  const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
  const [personalComment, setPersonalComment] = useState(""); // State to store the personal comment

  const [editedDesperdicioPreformaTf, setEditedDesperdicioPreformaTf] = useState(false)
  const handleToggle = () => {
    setEditedDesperdicioPreformaTf(!editedDesperdicioPreformaTf);
  };


   // Event handler for when the select input changes
const handleSelectChange = (e) => {
  const selectedValue = e.target.value;
  const isOptionSelected = ["arana", "polvo", "quemada", "barredura", "purgas"].includes(selectedValue);
  setSelectedOption(selectedValue);
  setEditedDesperdicioPreformaTf(isOptionSelected);
};

// Event handler for when the personal comment input changes
const handleCommentChange = (e) => {
  const comment = e.target.value;
  setPersonalComment(comment);
}; 


  const isSaveEnabled = editedItemsDefected > 0

  const handleKgItemsDefectedChange = (e) => {
    const inputValue = e.target.value;
    const calculatedValue =
      Math.round(editedPreformaKg * editedItemsDefected * 100) / 100;
    setEditedKgItemsDefected(calculatedValue);
  };

  const handleItemsDefectedChange = (f) => {
    const inputValue = f.target.value;
    const calculatedValue = Math.round(editedPreformaKg * inputValue*100) /100;
    setEditedItemsDefected(inputValue);
    setEditedKgItemsDefected(calculatedValue);
  };

  const handleSave = () => {
    //console.log("editedDesperdicioModelTf:", editedDesperdicioPreformaTf);
  //  console.log("selectedOption:", selectedOption);
 //  console.log("personalComment:", personalComment);
    onSave(order.index, {
      ...order,

      preformaitemdefected: editedItemsDefected,
      preformaitemdefectedkg: editedKgItemsDefected,
      
      desperdiciopreforma: {  
        desperdiciopreformatf: editedDesperdicioPreformaTf,
      defectedtypepreforma: selectedOption,
      defectedcommentpreforma: personalComment
    }

    });
    onClose();
  };

  return (
    <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }} >
      {/* Modal content */}
      <div
        className="sm:mt-10 rounded-lg shadow bg-gray-800  "
      >
        <div className="block mb-2   text-sm font-medium  text-white uppercase text-center">Añadir preformas defectuosas</div>
        <label className="block mb-2 mt-10 text-sm font-medium  text-gray-400 uppercase">
          Pedido NR
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="text"
          value={editedOrderNr}
          readOnly
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Preforma Modelo
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="text"
          value={editedPreforma}
          readOnly
        />

        <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">
        Artículos defectuosos
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          onChange={handleItemsDefectedChange}
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Peso por unidad (Kg)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          value={editedPreformaKg}
          readOnly
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Resina necesaria (Kg)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          value={editedKgItemsDefected}
          onChange={handleKgItemsDefectedChange}
        />
      </div>

      <div className="flex justify-between">
  <label className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase">
    {selectedOption === "merma" || selectedOption === 'burbuja' ?  (
     
        "se puede recuperar" 
    ) : selectedOption === "arana" || selectedOption === "polvo" || selectedOption === "quemada"
    || selectedOption === "barredura" || selectedOption === "purgas"
    ? (
      "es desperdicio"
    ) : selectedOption === "otro" ? (
      "marcar si es desperdicio"
    ) : (
      "elijes una opcion "
    )}
  </label>
  
  {/* Conditionally render the checkbox */}
  {selectedOption !== "merma" && selectedOption !== "burbuja" && (
    <button onClick={handleToggle}>
      {editedDesperdicioPreformaTf === true ? (
        <CheckBoxIcon className="text-green-500" />
      ) : (
        <CheckBoxOutlineBlankIcon />
      )}
    </button>
  )}
      </div>
      <div>
      
        <select
        className="text-white bg-gray-700 rounded-lg mt-4 p-2"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="start">Tipo de Defecto</option>
        <option value="arana">araña</option>
        <option value="merma">merma</option>
        <option value="burbuja">burbuja</option>
        <option value="polvo">polvo</option>
        <option value="quemada">pref quemada</option>
        <option value="barredura">barredura</option>
        <option value="purgas">purgas</option>
        <option value="otro">Otro</option>
      </select>

       
        <div>
          <label
            htmlFor="comment"
            className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase"
          >
            Comentario:
          </label>
          <input
            type="text"
            id="comment"
            name="comment"
            className="text-white bg-gray-700 rounded-lg block w-full p-2.5 "
            value={personalComment}
            onChange={handleCommentChange}
          />
        </div>
      
      
      </div>


      
      <div className="mb-6">
      <button
          className={`w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center 
          ${isSaveEnabled ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800'
        : 'bg-gray-400 cursor-not-allowed' } mt-8`}
                        onClick={isSaveEnabled ? handleSave : null}
                        disabled={!isSaveEnabled}
        >
           {isSaveEnabled ? 'Guardar' : 'Guardar'}
        </button>
        <button
          className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditDefectedModalPreforma;
