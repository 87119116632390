import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const GuiaConsultaPage = () => {
    const navigate = useNavigate();
  
 
      const navigateToGuia = () => {
        navigate("/guia");
      };
  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
      <button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button> 
        
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
              Modo Consulta
                  </h1> 
                  </div>
                  <div id="description" className="text-white ml-3 mr-3">
<div className="text-center"> <HelpIcon /></div>
                 


<p class="text-lg font-bold mb-4 text-center">Este icono sirve para consultar el código QR en caso de duda.</p>

<p class="mb-4">Si, por alguna razón, no se entiende si el código QR forma parte del almacén o no se sabe a qué pedido está vinculado, desde el icono ubicado en el menú "Almacén" y en los menús "Pedido", podemos obtener información sin realizar cambios en las etapas de producción.</p>

<p class="mb-4 text-amber-500">¡Es mejor realizar una verificación adicional y eliminar cualquier duda con un simple click!</p>
                  </div>
                    
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaConsultaPage