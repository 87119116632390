import React, { useState } from "react";
import { useEffect } from "react";
//import { useDispatch } from "react-redux";
//import { getUser } from "../../api/UserRequests";
import { apiWrapper } from '../../utils/apiwrapper';



const Conversation = ({ data, currentUser, online, user, openConversationId }) => {
const [messages, setMessages] = useState([]);




  useEffect(() => {
    const getMessages = async () => {
      console.log("members", data) 
      const chatId = data._id
   //  const userId = data.members.find((m) => m === currentUser);
     console.log("chatId last conversation", chatId,openConversationId )
     console.log("data",data)
    // const userId = data.senderId
   // const userId = data.senderId.find((m) => m === currentUser);
   try {
    const response = await apiWrapper(`message/${chatId}`, "GET");
    const messagesFromCurrentUser = response.filter((message) => message.senderId === currentUser);
    setMessages(messagesFromCurrentUser);
  } catch (error) {
    console.error("Error fetching chats:", error);
  }
};
getMessages();
}, []);
//console.log("cyser",user, currentUser, data)
  const [userData, setUserData] = useState(null)
 

  useEffect(() => {
    const userId = data.members.find((id) => id !== currentUser);
   // console.log("conversation and this should be the other userid",userId)
    const getUserData = async () => {
      try {
        const response = await apiWrapper(`user/${userId}`, "GET");
        setUserData(response);
    //    console.log("from conversation user data", response)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserData();
  }, []);

  return (
    <>
      <div className="follower conversation">
        <div>
          {online && <div className="online-dot"></div>}
       
          <div className="name flex flex-col" style={{fontSize: '0.8rem'}}>
            <span>{userData?.username}</span>
            <span>{userData?.site}</span>
            <span style={{color: online?"#51e200":""}}>{online? "Online" : "Offline"}</span>
          </div>
        </div>
      </div>
      <hr style={{ width: "85%", border: "0.1px solid #ececec" }} />
    </>
  );
};

export default Conversation;