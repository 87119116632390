import { useState } from "react";

const EditCustomerModal = ({ editableItem, onSave, onClose }) => {
    const { customers, index } = editableItem;
    const [editedRazonSocial, setEditedRazonSocial] = useState(customers.razonsocial);
    const [editedNombreComprador, setEditedNombreComprador] = useState(customers.nombrecomprador);
    
    const [editedEstado, setEditedEstado] = useState(customers.estado);
    const [editedDireccion, setEditedDireccion] = useState(customers.direccion);
    const [editedTelefono, setEditedTelefono] = useState(customers.telefono);
  
  
    const handleRazonSocialChange = (e) => {
      setEditedRazonSocial(e.target.value);
    };
    const handleNombreCompradorNameChange =(f) => { 
      setEditedNombreComprador(f.target.value);
    }
    const handleEstadoChange = (d) => {
        setEditedEstado(d.target.value);
      }
  
      const handleDireccionChange = (l) =>{  
        setEditedDireccion(l.target.value);
      }
    

    const handleTelefonoChange = (i) =>{  
        setEditedTelefono(i.target.value);
      }

   
  
    const handleSave = () => {
      onSave(customers.index, { ...customers, razonsocial: editedRazonSocial, nombrecomprador: editedNombreComprador,  telefono: editedTelefono, estado
        : editedEstado, direccion: editedDireccion });
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }}>
      {/* Modal content */}
      <div className="   sm:mt-0 rounded-lg shadow bg-gray-800" >
          <label className="block mb-2 text-sm font-medium  text-white">Razon Social:</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedRazonSocial} onChange={handleRazonSocialChange} />
          <label className="block mt-2 mb-2 text-sm font-medium  text-white">Nombre Comprador:</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedNombreComprador} onChange={handleNombreCompradorNameChange} />
         

          <label className="block mt-2 mb-2 text-sm font-medium  text-white">Direccion: </label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedDireccion} onChange={handleDireccionChange} />

          
          <label className="block mt-2 mb-2 text-sm font-medium  text-white">Telefono</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedTelefono} onChange={handleTelefonoChange} />

        <label className="block mt-2 mb-2 text-sm font-medium  text-white">Estado</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedEstado} onChange={handleEstadoChange} />
        
        </div>
        <div className="">
        <button  className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8" onClick={handleSave}>Guardar</button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  export default EditCustomerModal;