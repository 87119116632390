import { useState } from "react";
import Cloud from "../cloudinary/cloudOrder";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const EditOrderModalPreforma = ({ editableItem, onSave, onClose, mexicanTime, user, onCloseQr, role, options }) => {
  const { order, index } = editableItem;
  const [editedOrderNr, setEditedOrderNr] = useState(order.ordernr || "");
  const [editedCustomer, setEditedCustomer] = useState(order.customer || "");
  //model missing
  const [editedItemsToDo, setEditedItemsToDo] = useState(order.itemstodo || "");
  const [editedItemsScanned, setEditedItemsScanned] = useState(
    order.preformascanned || ""
  );
  const [editedPackScanned, setEditedPackScanned] = useState(
    order.preformapackscanned || ""
  );

  const [editedPackDelivery, setEditedPackDelivery] = useState(
    order.preformapackdelivery || ""
  );

  const [editedItemsDefected, setEditedItemsDefected] = useState(
    order.preformaitemdefected || ""
  );
  const [editedKgItemsDefected, setEditedKgItemsDefected] = useState(
    order.preformaitemdefectedkg || ""
  );
  //mat prima missing
  const [editedKgNeeded, setEditedKgNeeded] = useState(order.kgneeded || "");
  const [editedKgLoadedFromAlmacen, setEditedKgLoadedFromAlmacen] = useState(
    order.kgloadedfromalmacen || ""
  );
  const [editedScannedTrueFalse, setEditedScannedTrueFalse] = useState(
    order.preformascannedcompleted
  );

  const [editedScannedTrueFalseHide, setEditedScannedTrueFalseHide] = useState(
    order.preformastocktohide
  );
  const [editedPack, setEditedPack] = useState(order.preformapack || "");
  const [editedStock, setEditedStock] = useState(order.preformastock || "");
  const [editedPackStock, setEditedPackStock] = useState(order.preformapackitemsstock || "");

  const [editedStockCompleted, setEditedStockCompleted] = useState(
    order.preformastockcompleted
  );
  const [editedDelivery, setEditedDelivery] = useState(
    order.preformadelivery || ""
  );
  const [editedDeliveryCompleted, setEditedDeliveryCompleted] = useState(
    order.preformadeliverycompleted
  );

const [editedQR, setEditedQr] =useState(order.qrimage || "" )

const [isDataChanged, setDataChanged] = useState(false);

const handlePackDeliveryChange = (bb) => {
  setEditedPackDelivery(bb.target.value);
  setDataChanged(true);
};

const handlePackScannedChange = (zz) => {
  setEditedPackScanned(zz.target.value);
  setDataChanged(true);
};

const handlePackStockChange = (yy) => {
  setEditedPackStock(yy.target.value);
  setDataChanged(true);
};

const handleQrChange = (xx) => {
  setEditedQr(xx.target.value);
  setDataChanged(true);
}


  const handleDeliveryCompletedChange = (x) => {
    setEditedDeliveryCompleted(x.target.value.toLowerCase());
    setDataChanged(true);
  };

  const handleDeliveryChange = (y) => {
    setEditedDelivery(y.target.value);
    setDataChanged(true);
  };

  const handleStockCompletedChange = (z) => {
    setEditedStockCompleted(z.target.value.toLowerCase());
    setDataChanged(true);
  };

  const handleStockChange = (a) => {
    setEditedStock(a.target.value);
    setDataChanged(true);
  };

  const handlePackChange = (b) => {
    setEditedPack(b.target.value);
    setDataChanged(true);
  };

  const handleScannedTrueFalseChange = (c) => {
    
    setEditedScannedTrueFalse(c.target.value.toLowerCase());
    setDataChanged(true);
  };

  const handleScannedTrueFalseChangeHide = (cc) => {
    setEditedScannedTrueFalseHide(cc.target.value.toLowerCase());
    setDataChanged(true);
  };

  const handleKgItemsDefectedChange = (d) => {
    setEditedKgItemsDefected(d.target.value);
    setDataChanged(true);
  };

  const handleOrderNrChange = (e) => {
    setEditedOrderNr(e.target.value);
    setDataChanged(true);
  };
  const handleCustomerChange = (f) => {
    setEditedCustomer(f.target.value);
    setDataChanged(true);
  };
  const handleItemsToDoChange = (g) => {
    setEditedItemsToDo(g.target.value);
    setDataChanged(true);
  };

  const handleItemsScannedChange = (h) => {
    setEditedItemsScanned(h.target.value);
    setDataChanged(true);
  };

  const handleItemsDefectedChange = (i) => {
    setEditedItemsDefected(i.target.value);
    setDataChanged(true);
  };

  const handleKgChange = (l) => {
    setEditedKgNeeded(l.target.value);
    setDataChanged(true);
  };

  const handleMasterKgChange = (m) => {
    setEditedKgLoadedFromAlmacen(m.target.value);
    setDataChanged(true);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(editedQR);
      const blob = await response.blob();
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `order_${editedOrderNr}_${editedCustomer}.png`
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Clean up the object URL to free up resources.
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  

  const handleSave = () => {
    if(isDataChanged){ 
    onSave(order.index, {
      ...order,
      ordernr: editedOrderNr,
      customer: editedCustomer,
      itemstodo: editedItemsToDo,
      preformapack: editedPack,

      preformascanned: editedItemsScanned,
      preformapackscanned: editedPackScanned,
      preformascannedcompleted: editedScannedTrueFalse,
      preformastocktohide: editedScannedTrueFalseHide,
     
      preformaitemdefected: editedItemsDefected,
      preformaitemdefectedkg: editedKgItemsDefected,
      
      kgneeded: editedKgNeeded,
      kgloadedfromalmacen: editedKgLoadedFromAlmacen,


      preformastockcompleted: editedStockCompleted,
      preformastock: editedStock,
      preformapackitemsstock: editedPackStock,

      preformadelivery: editedDelivery,
      preformapackdelivery: editedPackDelivery,
      preformadeliverycompleted: editedDeliveryCompleted,

      mexicanTime: mexicanTime,
      user: user
      
    });}
    onClose();
  };
  // console.log(order._id)

  const [buttonClicked, setButtonClicked] = useState(false);
  const [buttonClickedExistencia, setButtonClickedExistencia] = useState(false);
  const [buttonClickedEntrega, setButtonClickedEntrega] = useState(false);

  return (
    <>
     <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }}>
        {/* Modal content */}
        <div className="   sm:mt-0 rounded-lg shadow bg-gray-800" >
         <div className=" block mb-2 mt-4 text-sm font-medium text-center  text-gray-400 uppercase "> Edit Preforma
         {(role === "admin" || role === "vendedor") && ( 
        <div className="mt-10 flex justify-center items-center">
         <Cloud orderId={order._id} mexicanTime={mexicanTime} user={user} options={options} />
         </div>
         )}
          </div>
          <label className=" block mb-2 mt-4 text-sm font-medium   text-gray-400 uppercase">
            QR:
    <FileDownloadOutlinedIcon onClick={handleDownload}/>
          </label>
          <a href={editedQR} target="_blank" rel="noopener noreferrer">
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedQR}
            onChange={handleQrChange} 
            readOnly         /></a>
              <div className="flex justify-between ">
              {(role === "admin" || role === "vendedor") && ( 
        <button  className="w-1/3 text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-2 py-2.5 text-center
                        bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8 mb-8" onClick={onCloseQr}>Guardar QR</button>
                        )}
        <button className="w-1/3 text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-2 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cancel</button>
        </div >
          {role === "admin" && ( 
          <>
       
          <label className=" block mb-2 mt-4 text-sm font-medium   text-gray-400 uppercase">
            Pedido NR
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedOrderNr}
            onChange={handleOrderNrChange}
          />
          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
            Cliente
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedCustomer}
            onChange={handleCustomerChange}
          />
           
 <button
                      className=" block px-6 py-3 text-center text-xs font-thin text-gray-400  -tracking-tight"
                      onClick={() => setButtonClicked(!buttonClicked )}
                    >
                      {buttonClicked ? "Ocultar datos de fase de producción" : "Mostrar datos de fase de produccion "}
                    </button>
                    {buttonClicked && (
  <> 
          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Preformas para hacer
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                          placeholder-gray-400 text-red-400 font-bold
                            "
            type="number"
            value={editedItemsToDo}
            onChange={handleItemsToDoChange}
          />

<label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
           Ud para Paquete
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-red-400 font-bold
                            "
            type="number"
            value={editedPack}
            onChange={handlePackChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Preformas Producido
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="number"
            value={editedItemsScanned}
            onChange={handleItemsScannedChange}
          />
             <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
             Paq de Preformas Prod  
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="number"
            value={editedPackScanned}
            onChange={handlePackScannedChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Preformas defectuosos
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="number"
            value={editedItemsDefected}
            onChange={handleItemsDefectedChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
            Resina NECESARIA PARA Pref DEF (Kg)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="number"
            value={editedKgItemsDefected}
            onChange={handleKgItemsDefectedChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          RESINA necesarias para Prod (Kg)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                          placeholder-gray-400 text-red-400 font-bold
                            "
            type="number"
            value={editedKgNeeded}
            onChange={handleKgChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          RESINA utilizada (kg)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-red-400 font-bold
                            "
            type="number"
            value={editedKgLoadedFromAlmacen}
            onChange={handleMasterKgChange}
          />

<label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          PRODUCCIÓN CONFIRMADA
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-red-400 font-bold
                            "
            type="boolean"
            value={editedScannedTrueFalse}
            onChange={handleScannedTrueFalseChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          PRODUCCIÓN COMPLETADA
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-red-400 font-bold
                            "
            type="boolean"
            value={editedScannedTrueFalseHide}
            onChange={handleScannedTrueFalseChangeHide}
          />
</>)}

<button
                      className="block px-6 py-3 text-center text-xs font-thin text-gray-400  -tracking-tight"
                      onClick={() => setButtonClickedExistencia(!buttonClickedExistencia )}
                    >
                      {buttonClickedExistencia ? "Ocultar datos de fase de Existencia" : "Mostrar datos de fase de Existencia "}
                    </button>
                    {buttonClickedExistencia && (
  <> 
          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
            Existencias (ud)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white font-bold
                            "
            type="number"
            value={editedStock}
            onChange={handleStockChange}
          />
           <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
            Paq Existencias (ud)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white font-bold
                            "
            type="number"
            value={editedPackStock}
            onChange={handlePackStockChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Existencia completada
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-red-400 font-bold
                            "
            type="boolean"forma en
            value={editedStockCompleted}
            onChange={handleStockCompletedChange}
          />
</>)}

<button
                      className="block px-6 py-3 text-center text-xs font-thin text-gray-400  -tracking-tight"
                      onClick={() => setButtonClickedEntrega(!buttonClickedEntrega )}
                    >
                      {buttonClickedEntrega ? "Ocultar datos de fase de Entrega" : "Mostrar datos de fase de Entrega "}
                    </button>
                    {buttonClickedEntrega && (
  <> 
          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Preformas en entrega (ud)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white font-bold
                            "
            type="number"
            value={editedDelivery}
            onChange={handleDeliveryChange}
          />

<label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Paq en entrega (ud)
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white font-bold
                            "
            type="number"
            value={editedPackDelivery}
            onChange={handlePackDeliveryChange}
          />

          <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Entrega completada
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5 
                          bg-gray-700 
                           placeholder-gray-400 text-red-400 font-bold
                            "
            type="boolean"
            value={editedDeliveryCompleted}
            onChange={handleDeliveryCompletedChange}
          />
        
        </>)}
        <button   className={`w-full text-white focus:ring-4 focus:outline-none
    font-medium rounded-lg text-sm px-5 py-2.5 text-center
    ${isDataChanged ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-4' : 'bg-gray-400 cursor-not-allowed mt-4'}`}
                        onClick={handleSave}
                        disabled={!isDataChanged}
                        >Guardar</button>
          <button
            className="w-full text-white  focus:ring-4 focus:outline-none 
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-3"
            onClick={onClose}
          >
            Cancel
          </button>
          </>)}   
          
         
        </div>
          </div>
          
     
      
    </>
  );
};

export default EditOrderModalPreforma;
