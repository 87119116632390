import { useState } from "react";
import QRScanOrderConsult from "../order/videoOrderConsult";

const EditConsultOrderModal = ({ addConsultModal, onClose,  }) => {
   
    const { order  } = addConsultModal;


   


  return (
    <div>
        <>
<QRScanOrderConsult />
<button
          className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-gray-600 hover:bg-red-700 mt-8"
          onClick={onClose}
        >
          Apagar la cámara de uso CONSULTA
        </button>
        </>
    </div>

  )
}

export default EditConsultOrderModal