import DashboardOrderPreforma from "../components/preforma/dashboardPreforma"

const DasboardPreformas = () => {
    
  return (
    <div className="text-gray-100">
            <DashboardOrderPreforma />
         </div>
  )
}

export default DasboardPreformas