import { useState, useEffect } from "react";
import Cloud from "../cloudinary/cloudAlmacen";
import Cloud3 from "../cloudinary/cloudAlmacenmulti";
import Cloud4 from "../cloudinary/cloudAlmacenmulti4";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import React from 'react';
//import { mexicanTime } from "../../utils/variousConst";
//import { getUserSession } from "../../utils/localStorage.utils";
//import { tokenDecoder } from "../../utils/tokenDecoder";

const EditModal = ({ editableItem, onSave, onClose, onCloseQr, user, mexicanTime, options, role  }) => {
    const { article, index,  } = editableItem;
    const [editedCode, setEditedCode] = useState(article.code);
    const [editedProvider, setEditedProvider] = useState(article.provider);
    const [editedValue, setEditedValue] = useState(Math.round(article.value * 100) / 100);
    const [editedKg, setEditedKg] = useState(Math.round(article.kg* 100) / 100);
    const [editedMasterKg, setEditedMasterKg] = useState(Math.round(article.masterkg* 100) / 100);
    const [editedCountry, setEditedCountry] = useState(article.country);
    const [editedQR, setEditedQr] =useState(article.qrimage || "" )
    const [editedQR3, setEditedQr3] =useState(article.qrimage3 || "" )
    const [editedQR4, setEditedQr4] =useState(article.qrimage4 || "" )

    const [editedColor, setEditedColor] = useState(article.color || "")
    const [editedLot, setEditedLot] = useState(article.lot || "")
    const [editedGrade, setEditedGrade] = useState(article.grade || "")

    const [buttonClicked, setButtonClicked] = useState(role !=="admin");
    const [isDataChanged, setDataChanged] = useState(false);
  
    const [editedModelName, sestEditedModelName] = useState(article.modelname || "");
    const [editedCodigoAlfa, setEditedCodigoAlfa] = useState(article.codealfa || "");
 //  const session = tokenDecoder(getUserSession());
 // const user = session.username;
//console.log(user)

useEffect(() => {
  // If the user role changes, update the buttonClicked state accordingly
  setButtonClicked(role !== 'admin');
}, [role]);


const handleCodigoAlfaChange =(zzzy) => {
  setEditedCodigoAlfa(zzzy.target.value);
  setDataChanged(true);
}


const handleModelNameChange =(aaa) => {
  sestEditedModelName(aaa.target.value);
  setDataChanged(true);
}

const handleColorChange =(abc) => {
  setEditedColor(abc.target.value);
  setDataChanged(true);
}

const handleLotChange = (def) => {
  setEditedLot(def.target.value);
  setDataChanged(true);
}

const handleGradeChange = (ghi) => {
  setEditedGrade(ghi.target.value);
  setDataChanged(true);
}

    const handleQrChange = (xx) => {
      setEditedQr(xx.target.value);
    }

    const handleQrChange3 = (xxx) => {
      setEditedQr3(xxx.target.value);
      
   
    }

    const handleQrChange4 = (yyy) => {
      setEditedQr4(yyy.target.value);
    }

    const handleCodeChange = (e) => {
      setEditedCode(e.target.value);
      setDataChanged(true);
    };
    const handleProviderChange =(f) => { 
      setEditedProvider(f.target.value);
       setDataChanged(true);
    }
    const handleValueChange = (g) => { 
      setEditedValue(g.target.value);
      setDataChanged(true);
    }
    const handleKgChange = (h) =>{  
      setEditedKg(h.target.value);
      setDataChanged(true);
    }

    const handleCountryChange = (i) =>{  
        setEditedCountry(i.target.value);
        setDataChanged(true);
      }

      const handleMasterKgChange = (l) =>{  
        setEditedMasterKg(l.target.value);
        setDataChanged(true);
      }
    
      const handleDownload = async () => {
        try {
          const response = await fetch(editedQR);
          const blob = await response.blob();
      
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `article_${editedCode}_${editedProvider}.png`
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href); // Clean up the object URL to free up resources.
        } catch (error) {
          console.error('Error downloading the image:', error);
        }
      };

      const handleDownload3 = async () => {
        try {
          const response = await fetch(editedQR3);
          const blob = await response.blob();
      
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `article_${editedCode}_${editedProvider}.png`
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href); // Clean up the object URL to free up resources.
        } catch (error) {
          console.error('Error downloading the image:', error);
        }
      };

      const handleDownload4 = async () => {
        try {
          const response = await fetch(editedQR4);
          const blob = await response.blob();
      
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `article_${editedCode}_${editedProvider}.png`
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href); // Clean up the object URL to free up resources.
        } catch (error) {
          console.error('Error downloading the image:', error);
        }
      };


  
    const handleSave = () => {
      if(isDataChanged){ 
      onSave(article.index, { ...article, code: editedCode, provider: editedProvider, value:editedValue,
         kg: editedKg, country: editedCountry, masterkg: editedMasterKg,
         color: editedColor, lot: editedLot, grade: editedGrade,
         mexicanTime: new Date().toLocaleString("en-US", options),
          user:user, modelname: editedModelName, codealfa: editedCodigoAlfa });
    }
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }}>
        {/* Modal content */}
        <div className="   sm:mt-0 rounded-lg shadow bg-gray-800" >
      
        <div id="cloud1all" className="text-center text-white uppercase text-sm font-medium mt-4 ">Edit Almacén </div>
         <button
                      className=" block px-6 py-3 text-center text-xs font-thin text-gray-400  -tracking-tight "
                      onClick={() => setButtonClicked((prev) => !prev)}
                    >
                      {buttonClicked ? "Ocultar datos QR" : "Mostrar datos QR "}
                    </button>
                    {buttonClicked && ( 
<>
{role === "admin" && (  
                <React.Fragment>
<div id="cloud1all" className="text-center text-white uppercase text-sm font-medium mt-4 "> 
        <div id="cloud1comp" className="mt-5 flex justify-center items-center">
         <Cloud articleId={article._id} user={user} mexicanTime={mexicanTime} options={options} />
         </div>
          <div id="cloud1QR" className="text-center mt-4 text-gray-400">
          
          <a href={editedQR} target="_blank" rel="noopener noreferrer">
          <input
            className="w-full
                          sm:text-xs rounded-lg   p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedQR}
            onChange={handleQrChange} 
            readOnly         /></a>
            <FileDownloadOutlinedIcon onClick={handleDownload}/>
          </div>
         </div>
         </React.Fragment>)}
        
         
         <>
                <div id="secondqrall" className="">
        <div className="mt-5 flex justify-center items-center">
        <Cloud3 articleId={article._id} user={user} mexicanTime={mexicanTime} options={options} />
         </div>
         
         <div id="qr2link" className="text-center mt-4 text-gray-400">
         
          <a href={editedQR3} target="_blank" rel="noopener noreferrer">
          <input
            className=" w-full
                          sm:text-xs rounded-lg   p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedQR3}
            onChange={handleQrChange3} 
            readOnly         /></a>
            <FileDownloadOutlinedIcon onClick={handleDownload3}/>
           
         </div>
          </div>
          <div id="thirdqrall" className="text-center  text-gray-400">
        <div className="mt-5 flex justify-center items-center ">
         <Cloud4 articleId={article._id} user={user} mexicanTime={mexicanTime} options={options} />
         </div>
         <div id="qr4link" className="text-center mt-4 text-gray-400">
         
          <a href={editedQR4} target="_blank" rel="noopener noreferrer">
          <input
            className=" w-full
                          sm:text-xs rounded-lg   p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedQR4}
            onChange={handleQrChange4} 
            readOnly         /></a>
            <FileDownloadOutlinedIcon onClick={handleDownload4}/>
         </div>
         <div className="">
        <button  className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8" onClick={onCloseQr}>Save QR</button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cancel</button>
        </div >
          </div>
         </> 
          </>
    )}
 {role === "admin" && (  
                <React.Fragment>
                 
     
          <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase" >Codigo</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCode} onChange={handleCodeChange} />
        
         <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase" >Modelo</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedModelName} onChange={handleModelNameChange} />
 
 <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase" >Codigo Alfa</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCodigoAlfa} onChange={handleCodigoAlfaChange} />

<label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Color</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedColor} onChange={handleColorChange} />

<label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Lot</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedLot} onChange={handleLotChange} />

<label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Grade</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedGrade} onChange={handleGradeChange} />

          <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Piezas</label>
          <input  className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"type="number" value={editedValue} onChange={handleValueChange} />

          <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Master-Kg <br ></br><p className="text-red-500 text-xs">(No se deberia modificar)</p></label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-red-500 font-bold
                            focus:ring-blue-500 focus:border-blue-500" type="number" value={editedMasterKg} onChange={handleMasterKgChange} />

          <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Peso (Kg)</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="number" value={editedKg} onChange={handleKgChange} />
                              <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Provedor</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedProvider} onChange={handleProviderChange} />
          <label className="block mb-2 text-sm font-medium  text-gray-400 mt-2 uppercase">Pais</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCountry} onChange={handleCountryChange} />
        </React.Fragment> )}
        </div>
        {role === "admin" && (  
                <React.Fragment>
        <div className="">
        <button   className={`w-full text-white focus:ring-4 focus:outline-none
    font-medium rounded-lg text-sm px-5 py-2.5 text-center
    ${isDataChanged ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-4' : 'bg-gray-400 cursor-not-allowed mt-4'}`}
                        onClick={handleSave}
                        disabled={!isDataChanged}
                        >Guardar</button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-4 mb-8" onClick={onClose}>Cancel</button>
        </div >
        </React.Fragment>)}
      </div>
    
    );
  };

  export default EditModal;