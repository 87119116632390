import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
import React from "react";
import EditOrderModal from "../editmodal/EditOrderModal";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CSVLink } from "react-csv";
import { headers } from "../../utils/variousConst.js";
import QRScanStock from "../stock/videoStock.js";
import SearchBar from "../searchbar/searchbar";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditAlertPhaseOrderStockModal from "../editmodal/EditPhaseOrderStock.js";
import EditAlertDeleteOrderModal from "../editmodal/EditAlertDeleteOrder";
import ReplaceQualityToOrderModal from "../editmodal/ReplaceQualityToOrderModal.js";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Alert } from "@mui/material";
import { socket } from "../../utils/variousConst.js";
import {
  date,
  options,
  mexicanTime,
  formatMexicanDate,
} from "../../utils/variousConst";
import EditAlertPhaseAutoStockOrderModal from "../editmodal/EditPhaseAutoStockOrder.js";

const CalidadYDefecto = ({ order }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  // console.log("ilruolo", role)

  //console.log("here the prop",stockcompleted)
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isQuality, setIsQuality] = useState(false);
  const [isPreformaDef, setIsPreformaDef] = useState(false);

  const [message, setMessage] = useState("");

  const filterOrders = (orders) =>
    orders.filter((order) => order.calidaditemdefected || order.itemdefected);

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on scannedcompleted and stockcompleted
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);

    // Merge the filtered updated orders with existing orders in the state
    setOrders((prevOrders) => {
      const mergedOrders = filteredUpdatedOrders.map((filteredOrder) => {
        const existingOrder = prevOrders.find(
          (order) => order._id === filteredOrder._id
        );
        return existingOrder ? existingOrder : filteredOrder;
      });
      return mergedOrders;
    });
  };
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      //console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  useEffect(() => {
    fetchOrders();

    // Listen for socket event to handle updates to orders
    socket.on("getAllOrders", (updatedOrderArray) => {
      setOrders(updatedOrderArray);
      handleUpdateOrders(updatedOrderArray);
    });

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off("getAllOrders");
    };
  }, []);

  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response);

      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false);
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchOrdersListCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response);
    //  setOrders(response);
      //setOrders(response)
      setIsDownloadReady(true);
      setTimeout(() => {
        fetchOrders();
        setIsDownloadReady(false);
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredOrders([]); // Clear the filtered orders list
    } else {
      const filtered = orders.filter((order) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseOrderNr = order.ordernr.toLowerCase();
        const lowerCaseCustomer = order.customer.toLowerCase();
        const lowerCaseDescription = order.preforma.toLowerCase();
        const lowerCaseFinalProduct = order.finalproduct.toLowerCase();
        return (
          lowerCaseOrderNr.includes(lowerCaseSearchQuery) ||
          lowerCaseCustomer.includes(lowerCaseSearchQuery) ||
          lowerCaseDescription.includes(lowerCaseSearchQuery)||
          lowerCaseFinalProduct.includes(lowerCaseSearchQuery)
        );
      });
      setFilteredOrders(filtered);
    }
  }, [searchQuery, orders]);

  const [editableItem, setEditableItem] = useState(null);
  const [isEditOrderModalOpen, setEditOrderModalOpen] = useState(false);

  const [alertOrder, setAlertOrder] = useState(null);
  const [isAlertOrderPhaseModalOpen, setIsAlertOrderPhaseModalOpen] =
    useState(false);

  const [alertDeleteOrder, setAlertDeleteOrder] = useState(null);
  const [isAlertOrderDeleteModalOpen, setIsAlertOrderDeleteModalOpen] =
    useState(false);

  const [colorUpdated, setColorUpdated] = useState(false);

  const openEditOrderModal = (order, index) => {
    setEditableItem({ order, index });
    setEditOrderModalOpen(true);
  };

  const closeEditOrderModal = () => {
    setEditableItem(null);
    setEditOrderModalOpen(false);
  };

  const openAlertOrderPhaseModal = (order, index) => {
    setAlertOrder({ order, index });
    setIsAlertOrderPhaseModalOpen(true);
  };

  const closeOpenAlertOrderPhaseModal = () => {
    setAlertOrder(null);
    setIsAlertOrderPhaseModalOpen(false);
  };

  const openAlertOrderDeleteModal = (order, index) => {
    setAlertDeleteOrder({ order, index });
    setIsAlertOrderDeleteModalOpen(true);
  };

  const closeOpenAlertOrderDeleteModal = () => {
    setAlertDeleteOrder(null);
    setIsAlertOrderDeleteModalOpen(false);
  };

  const handleSave = ({
    stockorderid, 
    stockordernr,
    stockorderdestinonr,
    stockorderdestinoid,
    stockorderdestinocliente,
    packdelivery,
    itemsdelivery,
    calidaditemdefectedkg
  }) => {
   
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
      
        await apiWrapper(
          `order/${stockorderid}/updatestockfromorder`,
          "PATCH",
          {
            stockorderid,
            stockorderdestinonr,
            stockorderdestinoid,
            stockorderdestinocliente,
           // itemsstock: formData.itemsstock,
          //  packitemsstock: formData.packitemsstock,
         
          itemsdelivery:itemsdelivery,
           packdelivery:packdelivery,
        
            employeestory: {
              stockorderid:stockorderid,
              user,
              mexicanTime: new Date().toLocaleString("en-US", options),
              subtractitemsstock: itemsdelivery,
              subtractpackitemsstock: packdelivery,
              itemsdelivery: itemsdelivery,
           packdelivery: packdelivery,
            stockorderid:stockorderid,
            stockorderdestinonr,
            stockorderdestinoid,
            stockorderdestinocliente,
            calidadcomment:"remplazo calidad",
           
            },
          }
        );

        fetchOrders();
        closeEditOrderModal();

        // Emit the updated order to the server through the WebSocket
        //socket.emit('updateOrder', updatedOrder); // Assuming the server listens for 'updateOrder' events
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
      try {
      const finalPatch =  await apiWrapper(`order/${stockorderdestinoid}/updateCalidadFromOrder`, "PATCH", 
        
        {
          stockorderdestinoid,
          stockordernr,
          calidaditemdefected:itemsdelivery,
          calidadpackdefected:packdelivery,
          calidaditemdefectedkg,
          itemsdelivery:itemsdelivery,
          packdelivery:packdelivery,
          stockorderid,
            stockorderdestinonr,

          employeestory: {
            calidadcomment:"paquetes remplazados",
            user,
            mexicanTime: new Date().toLocaleString("en-US", options), 
            calidaditemdefected:itemsdelivery,
          calidadpackdefected:packdelivery,
          calidaditemdefectedkg,
          itemsdelivery:itemsdelivery,
          packdelivery:packdelivery,
          stockorderid,
            stockorderdestinonr,
            stockordernr,
          }
        }
      );
     
      if (finalPatch._id) {
        setMessage(
          <Alert className="flex justify-center " severity="success"
          style={{ backgroundColor: 'green', color: 'white' }}>
         
            Paquetes remplazados correctamente!
          </Alert>
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      }else {
        setMessage(
          <Alert className="flex justify-center " severity="error"
          style={{ backgroundColor: 'red', color: 'white' }}>
            An error occurred
          </Alert>
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
      }
      } catch (error) {
        setMessage(
          <Alert className="flex justify-center " severity="error"
          style={{ backgroundColor: 'red', color: 'white' }}>
           {error.message}
          </Alert>
        );
        setTimeout(() => {
          setMessage("");
        }, 5000);
        console.error("Error updating order:", error);
      }
    };

    

    updateOrderOnServer();

  };

  const handleDelete = async (orderId) => {
    try {
      // Make a DELETE request to the API to delete the order with the given ID
      await apiWrapper(`order/${orderId}`, "DELETE");

      // Update the orders state by removing the deleted order from the array
      setOrders(orders.filter((order) => order._id !== orderId));
    } catch (error) {
      // Handle error
      console.error("Error deleting order:", error);
    }
  };

  const updateOrder = async (orderId) => {
    //console.log(orderId)
    try {
      const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        stockcompleted: true,
        deliverycompleted: true,
      });

      await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
        employeestory: {
          user,
          mexicanTime: new Date().toLocaleString("en-US", options),
          stockcompleted: true,
          deliverycompleted: true,
        },
      });

      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, stockcompleted: true, deliverycompleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
      fetchOrders();

      // console.log("Order updated:", updatedOrder);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  useEffect(() => {
    socket.on("updateStockIncrement", (updatedItemsstock) => {
      setColorUpdated(updatedItemsstock._id); // Set the ID of the updated order
      //console.log("heeel",updatedItemsstock._id)

      setTimeout(() => {
        setColorUpdated(null); // Clear the ID after 3 seconds
      }, 3000);

      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedItemsstock._id) {
            return {
              ...order,
              itemsstock: updatedItemsstock.itemsstock,
              packitemsstock: updatedItemsstock.packitemsstock,
            };
          }
          return order;
        });

        return updatedOrders;
      });
    });

    return () => {
      socket.off("updateStockIncrement");
    };
  }, []);

  //update stock generation from create order
  useEffect(() => {
    socket.on("updateStockFromOrder", (updatedItemsstock) => {
      setColorUpdated(updatedItemsstock._id); // Set the ID of the updated order
      //console.log("heeel",updatedItemsstock._id)

      setTimeout(() => {
        setColorUpdated(null); // Clear the ID after 3 seconds
      }, 3000);

      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedItemsstock._id) {
            return {
              ...order,
              //itemsstock: updatedItemsstock.itemsstock,
              //   packitemsstock:updatedItemsstock.packitemsstock,
              itemsdelivery: updatedItemsstock.itemsdelivery,
              packdelivery: updatedItemsstock.packdelivery,
              employeestory: [...order.employeestory, updatedItemsstock],
            };
          }
          return order;
        });

        return updatedOrders;
      });
    });

    return () => {
      socket.off("updateStockFromOrder");
    };
  }, []);

  //

  //phase completed
  useEffect(() => {
    socket.on("updateOrderDefectedSign", (updatedItemsDefected) => {
      setOrders((prevOrders) => {
        // Filter out the orders with stockcompleted set to true
        const filteredOrders = prevOrders.filter(
          (order) => !order.stockcompleted
        );
        return filteredOrders;
      });
    });

    return () => {
      socket.off("updateOrderDefectedSign");
    };
  }, []);

  useEffect(() => {
    socket.on("updateCalidadFromOrder", (updatedEditOrder) => {
      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedEditOrder._id) {
            return { ...order, ...updatedEditOrder };
          }
          return order;
        });
        return updatedOrders;
      });
      fetchOrders();
    });
    
  
    return () => {
      socket.off("updateCalidadFromOrder");
    };
  }, []);

  useEffect(() => {
    socket.on("deleteOrder", (deletedOrderId) => {
      setOrders((prevOrders) => {
        return prevOrders.filter((order) => order._id !== deletedOrderId);
      });
    });

    return () => {
      socket.off("deleteOrder");
    };
  }, []);

  //spinning loading
  useEffect(() => {
    if (orders.length === 0) {
      // If there are no articles to load, hide the loading animation after 5 seconds
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    } else {
      setLoading(true);
    }
  }, [orders]);


  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
              CALIDAD Y DEFECTO DE PRODUCCION
            </h1>
            {message}
            <div className="absolute top-10 left-10">
                  
                    {role === "admin" && (
                      <>
                        <button
                          className="text-white ml-5"
                          onClick={fetchOrdersListCSV}
                        >
                          {isDownloadReady ? (
                            <CSVLink
                              data={orders}
                              headers={headers}
                              filename={`ListaPedidos_.csv`}
                            >
                              <FileDownloadOutlinedIcon />
                            </CSVLink>
                          ) : (
                            <span>
                              <FileDownloadOffIcon />
                            </span>
                          )}
                        </button>
                      </>
                    )}
                  </div>
            <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
            <div className="flex justify-center"> 
            <select
            className="rounded-lg"
            onChange={(event) => {
  if (event.target.value === 'Calidad') {
    setIsQuality(true);
    setIsPreformaDef(false);
  } else if( event.target.value === 'Produccion'){
    setIsPreformaDef(true);
    setIsQuality(false);
  }  else if( event.target.value === 'All'){
    setIsPreformaDef(true);
    setIsQuality(true);
  }
  
  else {
    setIsQuality(false);
    setIsPreformaDef(false);
  }

}}>
  <option value="">Select</option>
  <option value="Calidad">Calidad</option>
  <option value="Produccion">Producción</option>
  <option value="All">Calidad y Producción</option>
</select>
</div>
          </div>

          <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido nr
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Fecha
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Cliente
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Modelo a producir
                  </th>
                  {isQuality && ( 
                    <React.Fragment>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paquetes descartados por Calidad:
                  </th>
                
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes de
                  </th>
                  </React.Fragment>)}
                  {isPreformaDef && ( 
                    <React.Fragment>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  KG Preformas descartadas en producción
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas descartadas en producción ud
                  </th>
                  </React.Fragment>)}
                 

                 
                  {role === "admin" && (
                    <React.Fragment>
                      <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Remplazo Calidad
                      </th>
                     

                      <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Export
                      </th>
                    </React.Fragment>
                  )}
                  {/* Add more table headers as needed */}
                </tr>
              </thead>

              <tbody>
                {filteredOrders.map((order, index) => (
                  <tr
                    className={
                      index % 2 === 0
                        ? "bg-amber-400 text-center"
                        : "bg-amber-300 text-center"
                    }
                    key={`${order.customer}-${order.ordernr}`}
                  >
                    <td>{order.ordernr.toString()}</td>
                    <td>{formatMexicanDate(order.createdAt)}</td>
                    <td>{order.customer.toString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-2xl font-medium">
                      {order.finalproduct}
                    </td>
                    <td className="px-6 py-4  font-medium whitespace-nowrap">
                      {Number.isInteger(order.calidadpackdefected)
                        ? order.calidadpackdefected
                        : Math.round(order.calidadpackdefected * 100) / 100}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.pack}
                    </td>
                    {isPreformaDef && ( 
                      <React.Fragment>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformaitemdefectedkg
}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap  font-medium">
                      {Number.isInteger(order.preformaitemdefected)
                        ? order.preformaitemdefected
                        : Math.round(order.preformaitemdefected* 100) / 100}
                    </td>
                    </React.Fragment>  )}
                   
                   
                    {role === "admin" && (
                      <React.Fragment>
                        <td className=" whitespace-nowrap    ">
                          <ChangeCircleIcon
                            onClick={() => openEditOrderModal(order, index)}
                          />
                        </td>

                       
                        <td className="px-2  whitespace-nowrap m-18  ">
                          <button onClick={fetchOrdersCSV}>
                            {isDownloadReady ? (
                              <CSVLink
                                data={[order, ...order.employeestory]}
                                headers={headers}
                                filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}
                              >
                                <FileDownloadOutlinedIcon />
                              </CSVLink>
                            ) : (
                              <span>
                                <FileDownloadOffIcon />
                              </span>
                            )}
                          </button>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                ))}
              </tbody>

              <tbody className=" divide-y divide-gray-200 text-center text-white">
                {orders.length === 0 || filteredOrders.length > 0 ? (
                  <tr className="text-center">
                    <td colSpan="8">
                      {loading ? (
                        <p className=" text-white text-center">-</p>
                      ) : (
                        <p className="text-center  font-medium text-white">
                          No hay datos en DB
                        </p>
                      )}
                    </td>
                  </tr>
                ) : (
                  orders
                    .slice()
                    .reverse()
                    .map((order, index) => (
                      <tr
                        className={
                          index % 2 === 0 ? " bg-sky-900" : " bg-sky-800"
                        }
                        key={order._id}
                      >
                        <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                          {order.ordernr}
                        </td>
                        <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                          {formatMexicanDate(order.createdAt)}
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-medium">
                          {order.customer}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-2xl font-medium">
                          {order.finalproduct}
                        </td>
                        {isQuality && ( 
                          <React.Fragment>
                        <td className="px-6 py-4  font-medium whitespace-nowrap">
                          {Number.isInteger(order.calidadpackdefected)
                            ? order.calidadpackdefected
                            : Math.round(order.calidadpackdefected * 100) / 100}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {order.pack}
                        </td>
                        </React.Fragment> )}
                        {isPreformaDef && ( 
                          <React.Fragment>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {order.preformaitemdefectedkg}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap  font-medium">
                          {Number.isInteger(order.preformaitemdefected)
                            ? order.preformaitemdefected
                            : Math.round(order.preformaitemdefected * 100) / 100}
                        </td>
                        </React.Fragment>  )}
                       

                      
                        {role === "admin" && (
                          <React.Fragment>
                            <td className=" whitespace-nowrap    ">
                              <ChangeCircleIcon
                                onClick={() => openEditOrderModal(order, index)}
                              />
                            </td>

                            
                            <td className="px-2  whitespace-nowrap m-18  ">
                              <button onClick={fetchOrdersCSV}>
                                {isDownloadReady ? (
                                  <CSVLink
                                    data={[order, ...order.employeestory]}
                                    headers={headers}
                                    filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}
                                  >
                                    <FileDownloadOutlinedIcon />
                                  </CSVLink>
                                ) : (
                                  <span>
                                    <FileDownloadOffIcon />
                                  </span>
                                )}
                              </button>
                            </td>
                          </React.Fragment>
                        )}
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 gap-4 md:hidden">
            <div className="flex justify-center items-center">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>

            <div>
              {filteredOrders.map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-amber-400 px-2 py-2.5"
                      : " border rounded-lg bg-amber-300 px-2 py-2.5"
                  }
                  key={`${order.customer}-${order.ordernr}`}
                >
                  <div className="bg-transparent">
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                      <span className="text-gray-900 text-sm"> Pedido nr:</span>
                      <span className="ml-auto font-bold text-2xl">
                        {order.ordernr}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span className="text-gray-900 text-sm"> Fecha:</span>
                    <span className="ml-auto font-bold text-xl">
                      {formatMexicanDate(order.createdAt)}
                    </span>
                  </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                    <span className="text-gray-900"> Cliente:</span>
                    <span>{order.customer}</span>
                  </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2  text-sm font-medium  ">
                    <span className="text-gray-900"> Modelo a producir:</span>
                    <span> {order.finalproduct}</span>
                  </div>
                  {isQuality && ( 
                    <>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                    <span className="text-gray-900">Paquetes descartados por Calidad:</span>
                    <span>
                      {" "}
                      {Number.isInteger(order.calidadpackdefected)
                        ? order.calidadpackdefected
                        : Math.round(order.calidadpackdefected * 100) / 100}
                      <span className="text-gray-900 text-sm font-medium ml-2">
                        ud
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                    <span className="text-gray-900">Paquetes de: </span>
                    <span className="text-sm">
                      {" "}
                      {order.pack}
                      <span className="text-gray-900 text-sm font-medium ml-2">
                        ud
                      </span>
                    </span>
                  </div>
                  </>
)}
 {isPreformaDef && ( 
                    <>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-5  text-sm font-medium break-all ">
                    <span className="text-gray-900">KG Preformas descartado en produccion:</span>
                    <span>
                      {" "}
                      {Number.isInteger(order.preformaitemdefectedkg)
                        ? order.preformaitemdefectedkg
                        : Math.round(order.preformaitemdefectedkg * 100) / 100}
                      <span className="text-gray-900 text-sm font-medium ml-2">
                        KG
                      </span>
                    </span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3   text-sm font-medium break-all ">
                    <span className="text-gray-900">Preformas descartado en produccion:</span>
                    <span>
                      {" "}
                      {Number.isInteger(order.preformaitemdefected)
                        ? order.preformaitemdefected
                        : Math.round(order.preformaitemdefected * 100) / 100}
                      <span className="text-gray-900 text-sm font-medium ml-2">
                        ud
                      </span>
                    </span>
                  </div>
                  </>)}
                  <div className="flex flex-col justify-between ">
                  
                    {role === "admin" && (
                      <React.Fragment>
                        <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <ChangeCircleIcon /> Remplazo Calidad
                        </button>
                       
                        <button
                          onClick={fetchOrdersCSV}
                          className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                        >
                          {isDownloadReady ? (
                            <CSVLink
                              data={[order, ...order.employeestory]}
                              headers={headers}
                              filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}
                            >
                              <FileDownloadOutlinedIcon /> <span>Listo</span>
                            </CSVLink>
                          ) : (
                            <span>
                              <FileDownloadOffIcon /> Download
                            </span>
                          )}
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {orders.length === 0 || filteredOrders.length > 0 ? (
              loading ? (
                <div className="border rounded-lg bg-sky-900">
                  <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
                    <div className="- text-white">-</div>
                  </div>
                </div>
              ) : (
                <div className="border rounded-lg bg-sky-900">
                  <p className="text-center text-white">No hay datos en DB</p>
                </div>
              )
            ) : (
              orders
                .slice()
                .reverse()
                .map((order, index) => (
                  <div
                    className={
                      index % 2 === 0
                        ? "border rounded-lg bg-sky-900 text-white"
                        : " border rounded-lg bg-sky-800 text-white"
                    }
                    key={order._id}
                  >
                    <div className="bg-transparent  ">
                      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                        <span className="text-gray-300 text-sm">
                          {" "}
                          Pedido nr:
                        </span>
                        <span className="ml-auto font-bold text-2xl">
                          {order.ordernr}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                      <span className="text-gray-300 text-sm"> Fecha:</span>
                      <span className="ml-auto font-bold text-xl">
                        {formatMexicanDate(order.createdAt)}
                      </span>
                    </div>
                    <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                      <span className="text-gray-300"> Cliente:</span>
                      <span>{order.customer}</span>
                    </div>
                    <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2  text-sm font-medium ">
                      <span className="text-gray-300"> Modelo a producir:</span>
                      <span> {order.finalproduct}</span>
                    </div>
                    {isQuality && ( 
                    <> 
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                      <span className="text-gray-300">
                      Paquetes descartado por Calidad:</span>
                    <span>
                      {" "}
                      {Number.isInteger(order.calidadpackdefected)
                        ? order.calidadpackdefected
                        : Math.round(order.calidadpackdefected * 100) / 100}
                      <span className="text-gray-300 text-sm font-medium ml-2">
                        ud
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                    <span className="text-gray-300">Paquetes de: </span>
                    <span className="text-sm">
                      {" "}
                      {order.pack}
                      <span className="text-gray-300 text-sm font-medium ml-2">
                        ud
                      </span>
                    </span>
                  </div>
                  </> )}
                  {isPreformaDef && ( 
                    <>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-5  text-sm font-medium break-all ">
                    <span className="text-gray-300">KG Preformas descartado en produccion:</span>
                    <span>
                      {" "}
                      {Number.isInteger(order.preformaitemdefectedkg)
                        ? order.preformaitemdefectedkg
                        : Math.round(order.preformaitemdefectedkg * 100) / 100}
                      <span className="text-gray-300 text-sm font-medium ml-2">
                        KG
                      </span>
                    </span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3   text-sm font-medium break-all ">
                    <span className="text-gray-300">Preformas descartado en produccion:</span>
                    <span>
                      {" "}
                      {Number.isInteger(order.preformaitemdefected)
                        ? order.preformaitemdefected
                        : Math.round(order.preformaitemdefected * 100) / 100}
                      <span className="text-gray-300 text-sm font-medium ml-2">
                        ud
                      </span>
                    </span>
                  </div>
                  </>)}
                    <div className="flex flex-col justify-between ">
                    
                      {role === "admin" && (
                        <React.Fragment>
                          <button
                            onClick={() => openEditOrderModal(order, index)}
                            className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                          >
                            <ChangeCircleIcon /> Remplazo Calidad
                          </button>
                         
                          <button
                            onClick={fetchOrdersCSV}
                            className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                          >
                            {isDownloadReady ? (
                              <CSVLink
                                data={[order, ...order.employeestory]}
                                headers={headers}
                                filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}
                              >
                                <FileDownloadOutlinedIcon /> <span>Listo</span>
                              </CSVLink>
                            ) : (
                              <span>
                                <FileDownloadOffIcon /> Download
                              </span>
                            )}
                          </button>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      {isEditOrderModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
          <div className="mt-10 flex items-center  justify-center">
            <ReplaceQualityToOrderModal
              editableItem={editableItem}
              onSave={handleSave}
              onClose={closeEditOrderModal}
              user={user}
              role={role}
            />
          </div>
        </div>
      )}
      {isAlertOrderPhaseModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhaseAutoStockOrderModal
              alertOrder={alertOrder}
              onSave={updateOrder}
              onClose={closeOpenAlertOrderPhaseModal}
            />
          </div>
        </div>
      )}
      {isAlertOrderDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteOrderModal
              alertDeleteOrder={alertDeleteOrder}
              onSave={handleDelete}
              onClose={closeOpenAlertOrderDeleteModal}
            />
          </div>
        </div>
      )}
      ;
    </section>
  );
};

export default CalidadYDefecto;
