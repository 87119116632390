import { useState } from "react";

const EditPreformaModelModal = ({ editableItem, onSave, onClose }) => {
    const { model, index } = editableItem;
    const [editedCode, setEditedCode] = useState(model.code);
    const [editedModel, setEditedModel] = useState(model.modelname);
    const [editedValue, setEditedValue] = useState(model.value);
    const [editedColor, setEditedColor] = useState(model.colordescription);
    const [editedMasterKg, setEditedMasterKg] = useState(model.masterkg);
    const [editedCuello, setEditedCuello] = useState(model.cuellodescription);
    const [editedCuelloTipoDescription, setEditedCuelloTipoDescription] = useState(model.cuellotipodescription);
  
    const handleColorChange = (d) => {
      setEditedColor(d.target.value);
    }

    const handleCodeChange = (e) => {
      setEditedCode(e.target.value);
    };
    const handleModelNameChange =(f) => { 
      setEditedModel(f.target.value);
    }
    const handleValueChange = (g) => { 
      setEditedValue(g.target.value);
    }
    

    const handleDescriptionChange = (i) =>{  
        setEditedCuello(i.target.value);
      }

      const handleCuelloTipoDescriptionChange = (ii) =>{  
        setEditedCuelloTipoDescription(ii.target.value);
      }

      const handleMasterKgChange = (l) =>{  
        setEditedMasterKg(l.target.value);
      }
    
  
    const handleSave = () => {
      onSave(model.index, { ...model, code: editedCode, modelname: editedModel,  
        cuellodescription: editedCuello, cuellotipodescription: editedCuelloTipoDescription,
        colordescription: editedColor, masterkg: editedMasterKg });
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }}>
      {/* Modal content */}
      <div className="   sm:mt-0 rounded-lg shadow bg-gray-800" >
          <label className="block mb-2 text-sm font-medium  text-white">Código:</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCode} onChange={handleCodeChange} />
          <label className="block mt-2 mb-2 text-sm font-medium  text-white">Modelo:</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedModel} onChange={handleModelNameChange} />
         

          <label className="block mt-2 mb-2 text-sm font-medium  text-white">Master-Kg: </label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="number" value={editedMasterKg} onChange={handleMasterKgChange} />

          
          <label className="block mt-2 mb-2 text-sm font-medium  text-white">Cuello</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCuello} onChange={handleDescriptionChange} />
 <label className="block mt-2 mb-2 text-sm font-medium  text-white">Cuello Tipo</label>
          <textarea className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCuelloTipoDescription} onChange={handleCuelloTipoDescriptionChange} />
                            
        <label className="block mt-2 mb-2 text-sm font-medium  text-white">Color</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedColor} onChange={handleColorChange} />
        
        </div>
        <div className="">
        <button  className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8" onClick={handleSave}>Guardar</button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  export default EditPreformaModelModal;