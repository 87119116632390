import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
import "./ChatBox.css";
import { format } from "timeago.js";
import InputEmoji from "react-input-emoji";
import { socket } from "../../utils/variousConst.js";

const ChatBox = ({ chat, currentUser, setSendMessage, receivedMessage, data }) => {
  const [userData, setUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const scroll = useRef(); 



  const receiverId = chat?.members?.find((id) => id !== currentUser); 
  useEffect(() => {
    if (receiverId && messages.length > 0) {
      const messagesWithReceiverId = messages.map(message => ({...message, receiverId}));
      setSendMessage(messagesWithReceiverId);
      
      }
      
  }, [receiverId, messages]);

  useEffect(() => {
    const userId = chat?.members?.find((id) => id !== currentUser);

    const getUserData = async () => {
      try {
        const response = await apiWrapper(`user/${userId}`, "GET");
        setUserData(response._id);
        console.log("chatbox", response);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (chat !== null) getUserData();
  }, [chat, currentUser]);

  const getMessages = async () => {
    const chatId = chat?._id;
    console.log("chatId chatbodx", chatId);
    try {
      const response = await apiWrapper(`message/${chatId}`, "GET");
      console.log("chat box messages", response);
      setMessages(response);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };
  useEffect(() => {
    if (chat !== null) 
    getMessages();
  }, [chat]);

  const handleChange = (newMessage)=> {
    setNewMessage(newMessage)
  }


  const handleSend = (e) => {
    e.preventDefault();
  
    const message = {
      senderId: currentUser,
      text: newMessage,
      chatId: chat._id,
    };
    getMessages();
    try {
      const response = apiWrapper("message", "POST", message);
      setMessages((prevMessages) => [...prevMessages, response]);
      setNewMessage("");
      
    } catch (error) {
      console.log(error)
    }
  }
  const [openConversationId, setOpenConversationId] = useState();

  const openConversation = (chatId) => {
      // Update the openConversationId state
    setOpenConversationId(chatId);
  };

 
 

  useEffect(() => {
    const handleCreateOrder =async (newOrder) => {
      const theId = chat
      console.log("new order", newOrder)
      setMessages((prevMessages) => [...prevMessages, newOrder]);
     console.log('checcando:',newOrder.chatId, chat._id);
    if ( theId === newOrder.chatId) {
  //    console.log('Inside if statement:', chat);
      // If the chat is selected, mark the message as read
      try {
        const response = await apiWrapper(`message/markAsRead/${newOrder._id}`, 'PUT');
        if (response.ok) {
          console.log('Message marked as read');
        } else {
          console.log('Failed to mark message as read');
        }
        getMessages();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  //  console.log('After if statement:', chat);
  };
    
    // Add the 'createOrder' event listener
    socket.on('NewMessage', handleCreateOrder);
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('NewMessage', handleCreateOrder);
    };
  }, [setMessages]);


  useEffect(() => {
    if (receivedMessage !== null && receivedMessage.chatId === chat._id) {
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    }
  }, [receivedMessage]);


  // Always scroll to last Message
  useEffect(()=> {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  },[messages])
 {/*
  useEffect(() => {
  const handleCreateOrder = (newOrder) => {
        console.log("new order", newOrder)
// Add the isRead property to the newOrder object
      newOrder.isRead = false;
    setMessages((prevMessages) => [...prevMessages, newOrder]);
    
      // Check if the browser supports notifications
    if ("Notification" in window) {
      // Check for permission
        if (Notification.permission === "granted") {
          // If it's okay, let's create a notification
          new Notification('New chat message', { body: newOrder.text });
          const response = apiWrapper(`message/markAsRead/${newOrder._id}`,'PUT', {
           
              });
              getMessages()
        }
  
        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              new Notification('New chat message', { body: newOrder.text });
            }
          });
      }
    }
  };

  // Add the 'createOrder' event listener
  socket.on('NewMessage', handleCreateOrder);
  // Clean up the event listener when the component unmounts
  return () => {
    socket.off('NewMessage', handleCreateOrder);
  };
}, [setMessages]);

*/}


  return (
    <>
      <div 
      
      className="ChatBox-container">
        {chat? ( 
        
        <>
        <div className="chat-header">
          {userData?.username}
        </div>
        <div className="chat-body">
  {messages.map((message) => {
    //console.log("what isREAD",message.isRead);
    return (
      <>
        <div ref={scroll} 
          className={
            message.senderId === currentUser ? "message own" : "message"
          }
        >
                  <span>{message.text}</span>
                  <span>{message.isRead ? 'Read' : 'Unread'}</span>
                  <span>{format(message.createdAt)}</span>
                </div>
              </>
             );
            })}
          </div>
          {/*chat sender */}
          <div className="chat-sender">
            <div>+</div>
            <InputEmoji 
               value={newMessage}
               onChange={handleChange}
            
            />
          
          <div className="send-button button" onClick = {handleSend}>Send</div>
              <input
                type="file"
                name=""
                id=""
                style={{ display: "none" }}
               
              />
            </div>{" "}
        </>
      ): (
        <span className="chatbox-empty-message">Open a chat to start messaging...</span>
      )}
      </div>
    </>
  );
};

export default ChatBox;
