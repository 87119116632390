import { useState } from "react";

const SubtractKgOrderModal = ({ KgEditableItem, onSave, onClose,mexicanTime }) => {
    const { article, customer, ordernr, index } = KgEditableItem;
    const [editedCode, setEditedCode] = useState(article.code);
    
    const [editedKg, setEditedKg] = useState("" );

    const [editedId, setEditedId] = useState(article._id)

    
    
    const handleKgChange = (h) =>{  
      setEditedKg(h.target.value);
    }

   // console.log("this is ordermodal", )
  
    const handleSave = (user) => {
     
      onSave(article.index, { ...article,  kg: editedKg, _id:editedId, mexicanTime }, ordernr, customer);
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }} >
        {/* Modal content */}
        <div
        className="sm:mt-10 rounded-lg shadow bg-gray-800  "
      >
       
       <div>
        <div className="block mb-2   text-sm font-medium  text-white uppercase text-center">Añadir resina en prodducción</div>
      <label className="block mb-2 mt-10  text-sm font-medium  text-gray-400 uppercase">pedido nr</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
                             type="text" 
                             value={ordernr}
                             readOnly  />

      </div>

       <div>
          <label className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase">Cliente</label>
          <input
            className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white "
            type="text"
            value={customer} // Display the customer value here
            readOnly
          />
        </div>


      <div>
      <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">resina</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " type="text" value={editedCode} readOnly  />

      </div>

    

          <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">Añadir Resina en producción (Kg)</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " type="number"  onChange={handleKgChange} />
         
         
        </div>

      
        <div className="mb-6">
        <button  className={`w-full text-white focus:ring-4 focus:outline-none
    font-medium rounded-lg text-sm px-5 py-2.5 text-center
    ${editedKg > 0 ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8' : 'bg-gray-400 cursor-not-allowed mt-8'}`}
                         onClick={() => editedKg > 0 ? handleSave() : null}
                        disabled={editedKg <= 0}
                      >
                        {editedKg > 0 ? "Save" : "Save "}
                      </button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  export default SubtractKgOrderModal;