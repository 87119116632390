import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';


import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const GuiaLogRegPage = () => {
    const navigate = useNavigate();
  
 
      const navigateToGuia = () => {
        navigate("/guia");
      };
  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
      <button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button> 
        
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
              Registro de Login
                  </h1> 
                  </div>
                  <div id="description" className="text-white ml-3 mr-3">



                  <p class="mb-4">En el menú "Registro de Logs", puedes encontrar información sobre los registros realizados por fecha y hora, así como su geolocalización, que se puede consultar. También se incluyen registros de intentos de inicio de sesión rechazados debido a que no estaban cerca de la fábrica.</p>

<p class="mb-4 text-amber-500">Esto agrega un mayor control a la seguridad de la aplicación y de la empresa en sí.</p>
<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8 mb-4"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1697028466/guia/loginrec.mp4")
                        }
                        >Ejemplo consulta Panel "Registro Log"</button>

                  </div>
                    
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaLogRegPage