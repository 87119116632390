import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../../utils/apiwrapper";


import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const GarNumDePlacas = ({ selectedComponent, setSelectedComponent }) => {

  const [lastPlacaCode, setLastPlacaCode] = useState(0);
  const fetchPlacaCode = async () => {
    try {
      const response = await apiWrapper("garnumdeplacas", "GET");
      setLastPlacaCode(response.length);
     // console.log(response);

      // Update placacode in formData state
      const placacode = String(response.length+0).padStart(3, '0');
      setFormData(prevFormData => ({
        ...prevFormData,
        placacode: placacode
        
      }));
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };
  
  useEffect(() => {

    fetchPlacaCode();
  }, []);

  {/* 
    const placatipocodes = ['SEL','S', 'P', 'D', 'T']; 

    const placatipoMapping = {
      'SEL': 'Seleccione',
      'S': 'Sin placa',
      'P': 'Una placa',
      'D': 'Doble Placa',
      'T': 'Triple Placa'
    };
    */}
    
  const [codeError, setCodeError] = useState("")
  const[message, setMessage]=useState("")
  const [formData, setFormData] = useState({
   placacode: "",
   placacodedescription:"",
 //  placatipocode:"",
 //  placatipodescription:''
   
  });
  const [model, setModel] = useState("");
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
  

    if (name === "value") {
      parsedValue = Number(value);
    }

  //  if (name === "value" || name === "placacode") {
 //    parsedValue = Number(value);
 //  }
  
    setFormData((prevFormData) => {
      // If placatipocode is being updated, also update placatipodescription
  {/* 
      if (name === "placatipocode") {
        return {
          ...prevFormData,
          [name]: parsedValue,
          placatipodescription: placatipoMapping[parsedValue]
        };
      }
      */}
  
      // Otherwise, update normally
      return {
        ...prevFormData,
        [name]: parsedValue,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
      };

      if (formData.placacode.length < 3) {
        setMessage(
          <Alert className="flex justify-center" severity="error">
            El código {formDataWithNumberValue.placacode}   es demasiado corto! Debe tener al menos 3 dígitos.
          Por favor asigne uno nuevo.
          </Alert>
        );
        setTimeout(() => {
          setMessage("");
        }, 4000);
        setTimeout(() => {
          setCodeError(false);
          
        }, 4000);
        return
      }
  

      const response = await apiWrapper("garnumdeplacas", "POST", formDataWithNumberValue);
      setFormData(
        { placacode: "",
      placacodedescription:"",
   //   placatipocode:"",
    //  placatipodescription:'' 
    });
      setModel(response._id);
      fetchPlacaCode()

    
      
  // to get all the data in the message
      const updatedFormData = { ...formDataWithNumberValue };
    
  //   console.log("Response:", response);
  if (response.msg) {
    if (response.msg === "Code already exists") {
      setCodeError(response.msg);
      setMessage(
        <Alert className="flex justify-center" severity="error">
          El código {formDataWithNumberValue.placacode}  ya existe.
        Por favor asigne uno nuevo.
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
      }, 6000);
      setTimeout(() => {
        setCodeError(false);
        
      }, 6000);
   }
  
   }
   else{ 

  
    setMessage(
      <Alert className="flex justify-center" severity="success">
        <AlertTitle>¡Nueva Placa Añadida !</AlertTitle>
        <div className="flex flex-col items-center">
      
    
       
         
          <div>
            <strong>Codigo:</strong> {updatedFormData.placacode}
          </div>
    
          <div>
            <strong>Descripción:</strong> {updatedFormData.placacodedescription}
          </div>
         
        </div>
      </Alert>
    );

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }} catch (error) {
    console.error(error);
    
    setMessage(
      <Alert className="flex justify-center" severity="error">
        
        Ocurrió un error,
        
        Inténtalo de nuevo.
      </Alert>
    );
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }
} 




  useEffect(() => {
    setFormData({placacode: "",
    placacodedescription:"",
  //  placatipocode:"",
 //   placatipodescription:''
   });
  }, []);

  
  return (

    <section className=" bg-gray-900  ">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 
        bg-gray-800 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                    Añadir Descripción de Placas Garrafon
                </h1>
 
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
           
                   
                 

                
                
                <div>
                        <label htmlFor="placacode"
                         className="block mb-2 text-sm font-medium  text-white">Codigo Disponible</label>
                        <input type="number" name="placacode" id="placacode" 
                        value={formData.placacode}
                        minLength={3}
                       // readOnly
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " 
                            placeholder="codigo" required/>
                             {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                   
                 

                    <div>
                        <label htmlFor="placacodedescription" className="block mb-2 text-sm font-medium  text-white">Descripción</label>
                        <input type="text" name="placacodedescription" id="placacodedescription" placeholder=" ... texto ..." 
                        value={formData.placacodedescription}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white  " required />
                    </div>

                    <button type="submit"
                     className="w-full text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800">Guardar</button>
                   
                    </form>
                                     {/* Display the message */}
                                     {message && (
        <div className="message-container">
          {message}
        </div>
      )}
                    <div>
                    <button type="dashboardmodels"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-800 mt-8"
                       onClick={() => setSelectedComponent("")}
                       >Cerrar</button>
                    </div>
           

            </div>
        </div>
    </div>
      
  </section>

    
   
  );
};

export default GarNumDePlacas;