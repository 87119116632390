import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const GuiaPedidoPage = () => {
    const navigate = useNavigate();
  
 
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };   

      const toggleDropdown2 = () => {
      setIsOpen2(!isOpen2);
    };  

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
      };  

      const toggleDropdown4 = () => {
        setIsOpen4(!isOpen4);
      };  

      const toggleDropdown5 = () => {
        setIsOpen5(!isOpen5);
      }; 
  
      const navigateToGuia = () => {
        navigate("/guia");
      };
  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
      <button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button> 
        
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
               Gestión de la Producción, Existencia y Entrega
                  </h1> 
                  </div>
                  <div id="description" className="text-white ml-3 mr-3">

                  <p class="mb-4">Caso 1:</p>

<p className="text-sky-700"><strong>Producción Preforma y Modelo Final y entrega</strong></p>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
      onClick={toggleDropdown1}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen1}
      aria-controls="options-menu"
    >    {isOpen1 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
    {isOpen1 && ( 
      <>
<p class="mb-4 text-sm"><strong>Parte 1</strong></p>


<p class="mb-4">Desde la ficha de pedido en "Pedidos de Preformas", el Supervisor / Almacenista debe agregar/confirmar los kilogramos de resina necesarios para la producción del pedido utilizando el botón "Añadir Resina en Producción".</p>

<p class="mb-4">Una vez confirmada y agregada la resina en producción, el supervisor/almacenista debe confirmar que la preforma está en producción presionando el botón correspondiente; de esta manera, la ficha será visible en otras etapas de la producción.</p>

<p class="mb-4">Hecho esto, los trabajadores pueden comenzar a producir la preforma, empacarla y, una vez aplicado el código QR, escanearlo.</p>

<p class="mb-4">Una vez completada la producción, el supervisor puede hacer clic en el botón "Producción Completada", que ocultará la ficha en el menú "Pedidos de Preformas".</p>

<p class="mb-4 text-amber-500">Si durante la producción hay productos defectuosos, el supervisor, al presionar el botón "Añadir Artículos Defectuosos", puede catalogar el tipo de defecto y, si es necesario, agregar un comentario. Al ingresar las unidades defectuosas, el programa calculará los kilogramos de resina necesarios para completar el pedido.</p>

<p class="mb-4">Una vez completada la producción o, en el caso de pedidos grandes, cuando sea necesario mover las preformas producidas para enfriar en el almacén, deberemos rastrear la transferencia de mercancía de la siguiente manera:</p>

<p class="mb-4">Ir al menú Existencia de Preformas</p>

<p class="mb-4">A medida que los paquetes se ubiquen en el almacén, deberán escanearse, y la ficha de existencia se actualizará en consecuencia, mostrando las preformas que aún están en producción y las que se han trasladado a Existencia.</p>

<p class="mb-4">Una vez completada esta operación, las preformas estarán disponibles para el pedido creado contemporaneamente.</p>



<p class="mb-4">*Opcional - Puede hacer clic a 'Existencia completada', el pedido va a desaparecer de el Panel "Preforma en Entrega" ma las preforma serán disponible para ser transferidas a "Pedidos (Modelo Final)".</p>
<p class="mb-4 text-red-500"><strong> No escanee el código QR en 'Entrega de Preformas' o ya no serán visibles en el menú 'Pedido' (Modelo final).</strong></p>
<p class="mb-4">En la parte 2 vamos a ver como agregar las preforma al pedido y seguir con la producción de el Modelo Final.</p>
<p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>




<p class="mt-4 mb-4 text-sm"><strong>Parte 2</strong></p>

<p class="text-lg font-bold mb-4">Agregar las Preformas en Existencia a un Pedido de Modelo Final:</p>

  <p class="mb-4">El Supervisor, desde el menú "Pedidos", identifiquera la ficha con el igual nr de pedido de las Preforma y hará clic en "Añadir Preformas en Producción".</p>

  <p class="mb-4">Aparecerá una ficha donde se solicita qué preformas se desean retirar de "Existencia de Preformas" y la cantidad.</p>

  <p class="mb-4">Una vez importadas las preformas necesarias, los trabajadores pueden comenzar a escanear los códigos QR de los paquetes de artículos producidos.</p>
  <p class="mb-4">Una vez completada la producción, el supervisor puede hacer clic en el botón "producción completada", que ocultará la ficha en el menú "Pedidos".</p>

  <p class="mb-4 text-amber-500">Si durante la producción hay productos defectuosos, el supervisor, al presionar el botón "Añadir Artículos Defectuosos", puede catalogar el tipo de defecto y, si es necesario, agregar un comentario. Al ingresar las unidades defectuosas, el programa calculará los kilogramos de resina necesarios para completar el pedido.</p>

  <p class="mb-4">A medida que los paquetes se ubiquen en el almacén, deberán escanearse, y la ficha de existencia se actualizará en consecuencia, mostrando los artículos que aún están en producción y los que se han trasladado a Existencia.</p>

  <p class="mb-4">Una vez completada la producción y la transferencia de los paquetes a Existencia, el supervisor puede hacer clic en "Existencia completada" para ocultar la ficha en este menú, y la orden se puede completar en el menú "Entrega".</p>

  <p class="mb-4">Desde la ficha de "Entrega", será posible escanear los paquetes en el proceso de carga del camión. La ficha mostrará el inventario en el almacén y los paquetes "en entrega", es decir, cargados en el camión.</p>

  <p class="mb-4">Una vez completada la operación, el supervisor puede hacer clic en el botón "Entrega completada" para ocultar la orden.</p>

  <p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>
  <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696868108/guia/ejemplo_caso1_preforma_y_modelo.mp4")
                        }
                        >Ejemplo desde Panel "Preformas Pedido" hasta Pedido Modelo Final y Entrega </button>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
      onClick={toggleDropdown1}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen1}
      aria-controls="options-menu"
    >    {isOpen1 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>

</>  )}

                  <p class="mb-4 mt-2">Caso 2:</p>

<p className="text-sky-700"><strong>Producción Solo Preforma para almacenar y</strong></p>
<p class="mb-2 text-sky-700"><strong>Creación de un Pedido Posterior y Uso de Preformas en Existencia</strong></p>
<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
      onClick={toggleDropdown2}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen2}
      aria-controls="options-menu"
    >    {isOpen2 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
    {isOpen2 && ( 
      <>
<p class="mb-4 text-sm"><strong>Parte 1</strong></p>
<p class="mb-4">Es posible producir preformas para tener un inventario en almacén disponible para atender pedidos no planificados o recibidos después de la producción de preformas.</p>

<p class="mb-4">Desde la ficha de pedido en "Pedidos de Preformas", el Supervisor / Almacenista debe agregar/confirmar los kilogramos de resina necesarios para la producción del pedido utilizando el botón "Añadir Resina en Producción".</p>

<p class="mb-4">Una vez confirmada y agregada la resina en producción, el supervisor/almacenista debe confirmar que la preforma está en producción presionando el botón correspondiente; de esta manera, la ficha será visible en otras etapas de la producción.</p>

<p class="mb-4">Hecho esto, los trabajadores pueden comenzar a producir la preforma, empacarla y, una vez aplicado el código QR, escanearlo.</p>

<p class="mb-4">Una vez completada la producción, el supervisor puede hacer clic en el botón "Producción Completada", que ocultará la ficha en el menú "Pedidos de Preformas".</p>

<p class="mb-4 text-amber-500">Si durante la producción hay productos defectuosos, el supervisor, al presionar el botón "Añadir Artículos Defectuosos", puede catalogar el tipo de defecto y, si es necesario, agregar un comentario. Al ingresar las unidades defectuosas, el programa calculará los kilogramos de resina necesarios para completar el pedido.</p>

<p class="mb-4">Una vez completada la producción o, en el caso de pedidos grandes, cuando sea necesario mover las preformas producidas para enfriar en el almacén, deberemos rastrear la transferencia de mercancía de la siguiente manera:</p>

<p class="mb-4">Ir al menú Existencia de Preformas</p>

<p class="mb-4">A medida que los paquetes se ubiquen en el almacén, deberán escanearse, y la ficha de existencia se actualizará en consecuencia, mostrando las preformas que aún están en producción y las que se han trasladado a Existencia.</p>

<p class="mb-4">Una vez completada esta operación, las preformas estarán disponibles para pedidos creados posteriormente que requieran el inventario generado para la realización del pedido y la entrega del producto final al cliente.</p>
<p class="mb-4">*Opcional - Puede hacer clic a 'Existencia completada', el pedido va a desaparecer de el Panel "Preforma en Entrega" ma las preforma serán disponible para ser transferidas a "Pedidos (Modelo Final)".</p>
<p class="mb-4 text-red-500"><strong> No escanee el código QR en 'Entrega de Preformas' o ya no serán visibles en el menú 'Pedido' (Modelo final).</strong></p>

<p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>

<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696860446/guia/ejemplo_caso2_parte_1.mp4")
                        }
                        >Ejemplo producción Preforma y Existencia </button>


<p class="mt-4 mb-4 text-sm"><strong>Parte 2</strong></p>

<p class="text-lg font-bold mb-4">Creación de un Pedido Posterior y Uso de Preformas en Existencia:</p>

  <p class="mb-4">El administrador creará un pedido seleccionando:</p>
  <ul class="list-disc ml-6">
    <li>Materia Prima: "Resina para Modelo Final".</li>
    <li>La resina utilizada para crear las preformas en existencia.</li>
    
    <li>Seleccionar el modelo final a producir y la cantidad deseada.</li>
    <li>En este caso, solo agregaremos la cantidad de modelos finales por paquete para obtener el número de códigos QR a imprimir.
       
    </li>
  </ul>

  <p class="mb-4 mt-4">El administrador adjuntará el código QR a la ficha del pedido como en los escenarios anteriores.</p>

  <p class="mb-4">El Supervisor, desde el menú "Pedidos", hará clic en "Añadir Preformas en Producción".</p>

  <p class="mb-4">Aparecerá una ficha donde se solicita qué preformas se desean retirar de "Existencia de Preformas" y la cantidad.</p>

  <p class="mb-4">Una vez importadas las preformas necesarias, los trabajadores pueden comenzar a escanear los códigos QR de los paquetes de artículos producidos.</p>

  <p class="mb-4 text-amber-500">Si durante la producción hay productos defectuosos, el supervisor, al presionar el botón "Añadir Artículos Defectuosos", puede catalogar el tipo de defecto y, si es necesario, agregar un comentario. Al ingresar las unidades defectuosas, el programa calculará los kilogramos de resina necesarios para completar el pedido.</p>

  <p class="mb-4">A medida que los paquetes se ubiquen en el almacén, deberán escanearse, y la ficha de existencia se actualizará en consecuencia, mostrando los artículos que aún están en producción y los que se han trasladado a Existencia.</p>

  <p class="mb-4">Una vez completada la producción y la transferencia de los paquetes a Existencia, el supervisor puede hacer clic en "Existencia completada" para ocultar la ficha en este menú, y la orden se puede completar en el menú "Entrega".</p>

  <p class="mb-4">Desde la ficha de "Entrega", será posible escanear los paquetes en el proceso de carga del camión. La ficha mostrará el inventario en el almacén y los paquetes "en entrega", es decir, cargados en el camión.</p>

  <p class="mb-4">Una vez completada la operación, el supervisor puede hacer clic en el botón "Entrega completada" para ocultar la orden.</p>

  <p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>
  <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696944911/guia/caso2_parte2.mp4")
                        }
                        >Ejemplo uso de Preformas en Existencia para Pedido a posteriori y Entrega </button>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
      onClick={toggleDropdown2}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen2}
      aria-controls="options-menu"
    >    {isOpen2 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>

</>  )}

  <p class="mb-4 mt-2">Caso 3:</p>

  <p class="mb-4 text-sky-700"><strong>Producción Solo Modelo Final y Entrega</strong></p>
  <button
        type="button"
        className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
        onClick={toggleDropdown}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="options-menu"
      >    {isOpen ? (
        <>
          
          Ocultar
        </>
      ) : (
        <>
          <AddCircleIcon className="mr-3" />
         Mostrar
        </>
      )}
      </button>
      {isOpen && ( 
        <>
  <p class="mb-4">Desde la ficha de pedido en "Pedidos", el Supervisor / Almacenista debe agregar/confirmar los kilogramos de resina necesarios para la producción del pedido utilizando el botón "Añadir Resina en Producción".</p>

  <p class="mb-4">Una vez confirmada y agregada la resina en producción, los trabajadores pueden comenzar a empacar los artículos producidos y, una vez que se aplique el código QR, escanearlo. La aplicación registrará los artículos producidos.</p>

  <p class="mb-4">Una vez completada la producción, el supervisor puede hacer clic en el botón "producción completada", que ocultará la ficha en el menú "Pedidos".</p>

  <p class="mb-4 text-amber-500">Si durante la producción hay productos defectuosos, el supervisor, al presionar el botón "Añadir Artículos Defectuosos", puede catalogar el tipo de defecto y, si es necesario, agregar un comentario. Al ingresar las unidades defectuosas, el programa calculará los kilogramos de resina necesarios para completar el pedido.</p>

  <p class="mb-4">Una vez completada la producción o, en caso de pedidos grandes, cuando sea necesario mover los artículos producidos a enfriar en el almacén, deberemos rastrear la transferencia de mercancía de la siguiente manera:</p>

  <p class="mb-4">Ir al menú Existencia</p>

  <p class="mb-4">A medida que los paquetes se ubiquen en el almacén, deberán escanearse, y la ficha de existencia se actualizará en consecuencia, mostrando los artículos que aún están en producción y los que se han trasladado a Existencia.</p>

  <p class="mb-4">Una vez completada la producción y la transferencia de los paquetes a Existencia, el supervisor puede hacer clic en "Existencia completada" para ocultar la ficha en este menú, y la orden se puede completar en el menú "Entrega".</p>

  <p class="mb-4">Desde la ficha de "Entrega", será posible escanear los paquetes en el proceso de carga del camión. La ficha mostrará el inventario en el almacén y los paquetes "en entrega", es decir, cargados en el camión.</p>

  <p class="mb-4">Una vez completada la operación, el supervisor puede hacer clic en el botón "Entrega completada" para ocultar la orden.</p>
  <p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>
  <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696849430/guia/ejemlocaso3solomodfinal.mp4")
                        }
                        >Ejemplo producción/entrega </button>
  
  <button
        type="button"
        className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
        onClick={toggleDropdown}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="options-menu"
      >    {isOpen ? (
        <>
          
          Ocultar
        </>
      ) : (
        <>
          <AddCircleIcon className="mr-3" />
         Mostrar
        </>
      )}
      </button>
  </>  )}

  <p class="mb-4 mt-2">Caso 4:</p>

<p class="mb-4 text-sky-700"><strong>Producción Solo Preformas y Entrega a otro Valdotec</strong></p>
<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
      onClick={toggleDropdown4}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen4}
      aria-controls="options-menu"
    >    {isOpen4 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
    {isOpen4 && ( 
      <>
<p class="mb-4">Desde la ficha de pedido en "Pedidos de Preformas", el Supervisor / Almacenista debe agregar/confirmar los kilogramos de resina necesarios para la producción del pedido utilizando el botón "Añadir Resina en Producción".</p>

<p class="mb-4">Una vez confirmada y agregada la resina en producción, el supervisor/almacenista debe confirmar que la preforma está en producción presionando el botón correspondiente; de esta manera, la ficha será visible en otras etapas de la producción.</p>

<p class="mb-4">Una vez confirmada y agregada la resina en producción, los trabajadores pueden comenzar a empacar las preformas producidas y, una vez que se aplique el código QR, escanearlo. La aplicación registrará las preformas producidas.</p>

<p class="mb-4">Una vez completada la producción, el supervisor puede hacer clic en el botón "producción completada", que ocultará la ficha en el menú "Preforma Pedidos".</p>

<p class="mb-4 text-amber-500">Si durante la producción hay productos defectuosos, el supervisor, al presionar el botón "Añadir Preformas Defectuosas", puede catalogar el tipo de defecto y, si es necesario, agregar un comentario. Al ingresar las unidades defectuosas, el programa calculará los kilogramos de resina necesarios para completar el pedido.</p>

<p class="mb-4">Una vez completada la producción o, en caso de pedidos grandes, cuando sea necesario mover los artículos producidos a enfriar en el almacén, deberemos rastrear la transferencia de mercancía de la siguiente manera:</p>

<p class="mb-4">Ir al menú Preforma Existencia</p>

<p class="mb-4">A medida que los paquetes se ubiquen en el almacén, deberán escanearse, y la ficha de existencia se actualizará en consecuencia, mostrando los artículos que aún están en producción y los que se han trasladado a  Existencia.</p>

<p class="mb-4">Una vez completada la producción y la transferencia de los paquetes a Existencia, el supervisor puede hacer clic en "Existencia completada" para ocultar la ficha en este menú, y la orden se puede completar en el menú "Entrega".</p>

<p class="mb-4">Desde la ficha de "Preforma Entrega", será posible escanear los paquetes en el proceso de carga del camión. La ficha mostrará el inventario en el almacén y los paquetes "en entrega", es decir, cargados en el camión.</p>

<p class="mb-4">Una vez completada la operación, el supervisor puede hacer clic en el botón "Entrega completada" para ocultar la orden.</p>
<p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>
<button type="dashboard"
                     className="w-full text-white  focus:ring-4 focus:outline-none  
                     font-medium rounded-lg text-sm px-5 py-2.5 text-center
                     bg-sky-900 hover:bg-sky-700 mt-8"
                      onClick={() =>
                          window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696869736/guia/ejemplo_caso4_preforma_y_entrega.mp4")
                      }
                      >Ejemplo producción Preformas y entrega </button>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
      onClick={toggleDropdown4}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen4}
      aria-controls="options-menu"
    >    {isOpen4 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
</>  )}

<p class="mb-4 mt-2">Caso 5:</p>

<p class="mb-4 text-sky-700"><strong>Como manejar Preformas en entrada de otro Valdotec</strong></p>
<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-2 mb-2"
      onClick={toggleDropdown5}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen5}
      aria-controls="options-menu"
    >    {isOpen5 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
    {isOpen5 && ( 
      <>
 <p class="text-lg font-bold mb-4">En caso de Preformas enviadas por otro Valdotec, se deben seguir los siguientes pasos:</p>

<p class="mb-4">El administrador debe crear un pedido seleccionando:</p>
<ul class="list-disc ml-6">
  <li>Materia Prima: "Resina para preforma".</li>
  <li>Seleccionar la resina y el modelo de la preforma producida.</li>
  <li>Ingresar la cantidad de artículos a producir.</li>
  <li>PIngresar la cantidad de unidades de preformas por paquete.</li>
  
  <li>Guardar el pedido, imprimir los códigos QR y adjuntarlos a la ficha del pedido preforma.</li>
  <li>En la ficha de "preforma pedido", hacer clic en "Editar" y modificar: </li>
  <li>preformas producida (ud), paquetes de preformas prod (ud).</li>
  <li>producción confirmada: "true", producción completada "true" .</li>
</ul>

<p class="mb-4">Ahora el pedido es visible en la ficha de "Existencia de Preformas". Cuando se descargue el camión, el almacenista, el supervisor o el trabajador adjuntarán el código QR de la Preforma a los paquetes descargados y procederán con la escaneo desde la ficha "Existencia de Preformas", actualizando así el almacén.</p>

<p class="mb-4">Una vez completada esta operación, las preformas estarán disponibles para pedidos creados posteriormente que requieran el inventario generado para la realización del pedido y la entrega del producto final al cliente.</p>
<p class="mb-4">*Opcional - Puede hacer clic a 'Existencia completada', el pedido va a desaparecer de el Panel "Preforma en Entrega" ma las preforma serán disponible para ser transferidas a "Pedidos (Modelo Final)".</p>
<p class="mb-4 text-red-500"><strong> No escanee el código QR en 'Entrega de Preformas' o ya no serán visibles en el menú 'Pedido' (Modelo final).</strong></p>


<p class="mb-4 text-amber-500"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>
<button type="dashboard"
                     className="w-full text-white  focus:ring-4 focus:outline-none  
                     font-medium rounded-lg text-sm px-5 py-2.5 text-center
                     bg-sky-900 hover:bg-sky-700 mt-8"
                      onClick={() =>
                          window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696945807/guia/caso5_cut.mp4")
                      }
                      >Ejemplo de como manejar preformas en entrada de otro Valdotec </button>

<button
      type="button"
      className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-4 mb-2"
      onClick={toggleDropdown5}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded={isOpen5}
      aria-controls="options-menu"
    >    {isOpen5 ? (
      <>
        
        Ocultar
      </>
    ) : (
      <>
        <AddCircleIcon className="mr-3" />
       Mostrar
      </>
    )}
    </button>
</>  )}
                  </div>
                  <div>

</div>

<div>

                        </div>
                     
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaPedidoPage