import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {CSVLink} from "react-csv";
import{ preformaheaders} from "../../utils/variousConst.js"
import EditOrderModalPreforma from "../editmodal/EditOrderPreformaModal";
import EditAlertPhaseOrderModal from "../editmodal/EditAlertPhaseOrder";
import EditAlertDeleteOrderModal from "../editmodal/EditAlertDeleteOrder";
import EditAlertPhaseOrderModalPreforma from "../editmodal/EditAlertPhasePreforma";
import EditAlertPhaseOrderModalPreformaExistencia from "../editmodal/EditAlertPhasePreformaExistencia";
import SubtractKgOrderModal from "../editmodal/SubtractkgOrderModal";
import EditConsultOrderModal from "../editmodal/EditConsultOrderModal";
import EditDefectedModal from "../editmodal/EditDefectedModal";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import EditDefectedModalPreforma from "../editmodal/EditDefectedModalPreforma";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import QRScanOrderPreforma from "../preforma/videoPreforma";
import HelpIcon from '@mui/icons-material/Help';
import { successSound, errorSound } from "../../utils/variousConst";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AddPreformaToOrderModal from "../editmodal/AddPreformaToOrderModal";
import { socket } from "../../utils/variousConst.js";
import {date, options, mexicanTime, formatMexicanDate} from "../../utils/variousConst"
import SearchBar from "../searchbar/searchbar";

const DashboardOrderPreforma = ({ order }) => {

 // State for 'itemsscanned'
const [colorUpdated, setColorUpdated] = useState(false)
const [colorUpdatedDefected, setColorUpdatedDefected] = useState(false)
const [colorUpdatedDefectedKg, setColorUpdatedDefectedKg] = useState(false)
const [colorUpdatedDefectedPreforma,setColorUpdatedDefectedPreforma] = useState(false)
const [colorUpdatedDefectedKgPreforma, setColorUpdatedDefectedKgPreforma] = useState(false)
const [colorUpdatedSubtractKg, setColorUpdatedSubtractKg] = useState(false)
const [colorUpdatedAlmacenSubtractKg, setColorUpdatedAlmacenSubtractKg] = useState(false)
const[colorUpdatedArticleIncrement, setColorUpdatedArticleIncrement] = useState(false)

const [isDownloadReady, setIsDownloadReady] = useState(false);

const [message, setMessage] = useState('')
  
  const [messageError, setMessageError] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false);

  const [loading, setLoading] = useState(true);

  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  // console.log("ilruolo", role)

  //setIsAdmin(role === "admin");
  //setIsSupervisor(role==="supervisor");
  //setIsAlmacenista(role ==="almacenista")

const [selectedArticleCode, setSelectedArticleCode] = useState("");

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await apiWrapper("almacen", "GET");
        setArticles(response);
      //  console.log("setartres",response)
      } catch (error) {
        // Handle error
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      const filteredOrders = response.filter(
        (order) => order.scannedcompleted === false && order.preformastocktohide === false && order.preforma !== ""
      );
      setOrders(filteredOrders);
      //setOrders(response)
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      const filteredOrders = response.filter(
        (order) => order.scannedcompleted === false 
      );
      setOrders(filteredOrders);
      //setOrders(response)
      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };

  const fetchOrdersListCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
     
      setOrders(response);
      //setOrders(response)
      setIsDownloadReady(true);
      setTimeout(() => {
        fetchOrders();
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };


 
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
  
    if (searchQuery === '') {
      setFilteredOrders([]); // Clear the filtered orders list
    } else {
      const filtered = orders.filter((order) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseOrderNr = order.ordernr.toLowerCase();
        const lowerCaseCustomer = order.customer.toLowerCase();
        const lowerCaseDescription = order.preforma.toLowerCase();
        const lowerCasePersonalizacion = order.personalizacion.toLowerCase();

        return (
          lowerCaseOrderNr.includes(lowerCaseSearchQuery) ||
          lowerCaseCustomer.includes(lowerCaseSearchQuery) ||
          lowerCaseDescription.includes(lowerCaseSearchQuery) ||
          lowerCasePersonalizacion.includes(lowerCaseSearchQuery)
        );
      });
      setFilteredOrders(filtered);
    }
  }, [searchQuery, orders]); 


  const [editableItem, setEditableItem] = useState(null);
  const [isEditOrderModalOpen, setEditOrderModalOpen] = useState(false);
  //modal kg ratio
  const [KgEditableItem, setKgEditableItem] = useState(null);
  const [isKgModalOpen, setIsKgModalOpen] = useState(false);


  const [defectedEditableItem, setDefectedEditableItem] = useState(null);
  const [isDefectedModalOpen, setDefectedEditOrderModalOpen] = useState(false);

  const [defectedEditablePreforma,setDefectedEditablePreforma]= useState(null);
  const [isDefectedModalOpenPreforma, setDefectedEditOrderModalOpenPreforma] = useState(false);

  const [alertOrder, setAlertOrder] = useState(null);
  const [isAlertOrderPhaseModalOpen, setIsAlertOrderPhaseModalOpen] = useState(false)

  const [alertOrderPreforma, setAlertOrderPreforma] = useState(null);
  const [isAlertOrderPhaseModalOpenPreforma, setIsAlertOrderPhaseModalOpenPreforma] = useState(false)

  const [alertOrderPreformaExistencia, setAlertOrderPreformaExistencia] = useState(null);
  const [isAlertOrderPhaseModalOpenPreformaExistencia, setIsAlertOrderPhaseModalOpenPreformaExistencia] = useState(false)

  const [alertDeleteOrder, setAlertDeleteOrder] = useState(null);
  const [isAlertOrderDeleteModalOpen, setIsAlertOrderDeleteModalOpen] = useState(false)

  const [addPreformaModal, setAddPreformaModal] = useState(null);
  const [isAddPreformaModalOpen,setIsAddPreformaModalOpen ]= useState( false )

  const [addConsultModal, setConsultModal] = useState(null);
  const [isConsultModalOpen,setIsConsultModalOpen ]= useState( false )


  const openConsultModal = (order, index) =>{
    setConsultModal({order, index});
    setIsConsultModalOpen(true)
  }

  const closeConsultModal =() =>{
    setConsultModal(null);
    setIsConsultModalOpen(false)
  }


  const openKgModal = (order) => {
    const matchingArticle = articles.find(
      (article) => article.code === order.code
    );
    const customer = order.customer;
    const ordernr = order.ordernr;

    if (matchingArticle) {
      setKgEditableItem({ article: matchingArticle, customer: customer, ordernr: ordernr });
      setIsKgModalOpen(true);
    }
  };

  const closeKgModal = () => {
    setKgEditableItem(null);
    setIsKgModalOpen(false);
  };
  //

  const openAlertOrderDeleteModal = (order, index) =>{
    setAlertDeleteOrder({order, index});
    setIsAlertOrderDeleteModalOpen(true)
  }

  const closeOpenAlertOrderDeleteModal =() =>{
    setAlertDeleteOrder(null);
    setIsAlertOrderDeleteModalOpen(false)
  }



  const openAlertOrderPhaseModal = (order, index) =>{
    setAlertOrder({order, index});
    setIsAlertOrderPhaseModalOpen(true)
  }

  const closeOpenAlertOrderPhaseModal =() =>{
    setAlertOrder(null);
    setIsAlertOrderPhaseModalOpen(false)
  }

  const openAlertOrderPhaseModalPreforma = (order, index) =>{
    setAlertOrderPreforma({order, index});
    setIsAlertOrderPhaseModalOpenPreforma(true)
  }

  const closeOpenAlertOrderPhaseModalPreforma =() =>{
    setAlertOrderPreforma(null);
    setIsAlertOrderPhaseModalOpenPreforma(false)
  }


  const openAlertOrderPhaseModalPreformaExistencia = (order, index) => {
    //console.log("Function called with order:", order, "and index:", index);
  
    if (order.preformascannedcompleted) {
      
      setAlertOrderPreformaExistencia({order, index});
      setIsAlertOrderPhaseModalOpenPreformaExistencia(true);
    } else {
      
      setButtonClicked(true);
      setMessage(
        <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={() => setMessage(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="error">
            Es necesario confirmar "PREFORMA EN PRODUCCIÓN" antes de continuar.
            <div className="flex flex-col items-center">
              
            Revisa, inténtalo de nuevo
            </div >
            
          </Alert>
        </Snackbar>
      );  
     
    } ; 
  }

  const closeOpenAlertOrderPhaseModalPreformaExistencia =() =>{
    setAlertOrderPreformaExistencia(null);
    setIsAlertOrderPhaseModalOpenPreformaExistencia(false)
  }


  const openEditOrderModal = (order, index) => {
    setEditableItem({ order, index });
    setEditOrderModalOpen(true);
  };

  const closeEditOrderModal = () => {
    setEditableItem(null);
    setEditOrderModalOpen(false);
  };

  const closeEditModalQr = () => {
    setEditableItem(null);
    setEditOrderModalOpen(false);
    fetchOrders()
  };


  const openEditDefectedModal = (order, index) => {
    setDefectedEditableItem({ order, index });
    setDefectedEditOrderModalOpen(true);
  };

  const closeEditDefectedModal = () => {
    setDefectedEditableItem(null);
    setDefectedEditOrderModalOpen(false);
  };

  const openEditPreformaDefectedModal = (order, index) => {
    setDefectedEditablePreforma({ order, index });
    setDefectedEditOrderModalOpenPreforma(true);
  };

  const closeEditPreformaDefectedModal = () => {
    setDefectedEditablePreforma(null);
    setDefectedEditOrderModalOpenPreforma(false);
  };

//add preforma from existencia modal
  const openEditAddPreformaModal = (order, index) => {
    setAddPreformaModal({ order, index });
    setIsAddPreformaModalOpen(true);
  };

  const closeEditAddPreformaModal = () => {
    setAddPreformaModal(null);
    setIsAddPreformaModalOpen(false);
  };

//

  //apikg
  const handleSaveSubtract = (
    index,
    updatedArticle,
    updatedOrder,
    customer,
    ordernr
  ) => {
    //cliente uguale a ordernr OCCHIO!
   // console.log("nr ordine", ordernr)
   // console.log("ecco i dati dell articolo", updatedArticle)
  //  console.log("i dati di order prima del fetch", updatedOrder)
    // Make a PATCH request to update the order on the server
    const updateArticleOnServer = async () => {
      customer = updatedOrder;
      //console.log(customer)
      //console.log(eluser)

      const valueToRemove = updatedArticle.kg / updatedArticle.masterkg;
      //console.log(valueToRemove)
      const kgToRemove = updatedArticle.kg;
      //console.log(kgToRemove)

      try {
        const res =  await apiWrapper(`almacen/${updatedArticle._id}/subtract`, "PATCH", {
          value: valueToRemove,
          kg: kgToRemove,
          employee: {user,
            mexicanTime: new Date().toLocaleString("en-US", options), 
              value: valueToRemove,  kg: kgToRemove}
        });

//check if kg in almacen are more than requested
if(res.message) {
  if(res.message === "Updated value exceeds itemtodo. Patch denied.") {
    errorSound.play()
   // window.alert('An error occurred while updating the article. Please try again.');

   setMessage(
    <Snackbar
      open={true}
      autoHideDuration={4000}
      onClose={() => setMessage(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ top: '50%', transform: 'translateY(-50%)' }}
    >
      <Alert className="flex justify-center" severity="error">
        Intentaste sacar más kg de los almacenados. Operación no permitida.
        <div className="flex flex-col items-center">
          <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
         
            <div>   Intentaste sacar: {kgToRemove} Kg </div>
          </div>
        Revisa, y inténtalo de nuevo
        </div >
        
      </Alert>
    </Snackbar>
  );   
    console.log('Error block reached.') 
      


// If the PATCH request is successful, update the article in the state
return;
//
//to fix HERERERERERERERERERE
}}
        // If the PATCH request is successful, update the order in the state
        const updatedArticles = [...articles];
        updatedArticles[index] = updatedArticle;
        setArticles(updatedArticles);

        const orderCode = updatedArticle.code; // Assuming article.code represents the order code
     //     console.log(orderCode)
        const orderResponse = await apiWrapper(
          `order?code=${orderCode}`,
          "GET"
        );
      
     //   console.log(orderResponse)
        const updatedOrder = orderResponse.find(
          (order) => order.code === orderCode && order.ordernr === customer
        );
   //     console.log(updatedOrder)
     
        if (updatedOrder) {
          const previousKgLoaded = Number(updatedOrder.kgloadedfromalmacen);
         // console.log(previousKgLoaded)
          const combinedKgLoaded = previousKgLoaded + Number(kgToRemove);
      //    console.log(combinedKgLoaded)
          await apiWrapper(`order/${updatedOrder._id}`, "PATCH", {
            kgloadedfromalmacen: combinedKgLoaded,
          });
          await apiWrapper(`order/${updatedOrder._id}/subtractsign`, "PATCH", {
            employeestory: {
              user,
              mexicanTime: new Date().toLocaleString("en-US", options),
              kgloadedfromalmacen: kgToRemove,
            },
          });
        }
        // console.log(user)
      
        const response = await apiWrapper("almacen", "GET");

        setArticles(response);
        // const res = await apiWrapper('order', 'GET');
        fetchOrders();
        closeKgModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };
  
    updateArticleOnServer();
  };
  //

 



  const handleSaveResina = (order, selectedArticleCode) => {
    // Find the order that needs to be updated
    const updatedOrder = order;
  
    // Log the order._id and selectedArticleCode
   // console.log("Order ID:", updatedOrder);
   // console.log("Selected Article Code:", selectedArticleCode);
  
    // Update the order with the selected article code
    updatedOrder.code = selectedArticleCode;
   updatedOrder.employeestory.push({
      user, 
      mexicanTime: new Date().toLocaleString("en-US", options),
     code: updatedOrder.code,
    });

      // If updatedOrder.code is "resina_ya_producida", set kgneeded to 0
  if (updatedOrder.code === "resina_ya_producida") {
    updatedOrder.kgneeded = 0;
  }
  
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", { ...updatedOrder, code: selectedArticleCode });
        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[order] = updatedOrder;
        setOrders(updatedOrders);
        fetchOrders();
        closeEditOrderModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };
  
    updateOrderOnServer();
  };


  const handleSave = (index, updatedOrder) => {
   // console.log(updatedOrder)
    updatedOrder.mexicanTime = mexicanTime
    updatedOrder.user = user
   
    updatedOrder.employeestory.push({
      mexicanTime: new Date().toLocaleString("en-US", options),
      user,
      ordernr: updatedOrder.ordernr,
      customer:  updatedOrder.customer,
      itemstodo: updatedOrder.itemstodo,
      preformapack: updatedOrder.preformapack,

      preformascanned: updatedOrder.preformascanned,
      preformapackscanned: updatedOrder.preformapackscanned,
      preformascannedcompleted: updatedOrder.preformascannedcompleted,
      preformastocktohide: updatedOrder.preformastocktohide,
     
      preformaitemdefected: updatedOrder.preformaitemdefected,
      preformaitemdefectedkg: updatedOrder.preformaitemdefectedkg,
      
      kgneeded: updatedOrder.kgneeded,
      kgloadedfromalmacen: updatedOrder.kgloadedfromalmacen,


      preformastockcompleted: updatedOrder.preformastockcompleted,
      preformastock: updatedOrder.preformastock,
      preformapackitemsstock: updatedOrder.preformapackitemsstock,

      preformadelivery: updatedOrder.preformadelivery,
      preformapackdelivery: updatedOrder.preformapackdelivery,
      preformadeliverycompleted: updatedOrder.preformadeliverycompleted,
    }
    
    )
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", updatedOrder);
        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[index] = updatedOrder;
        setOrders(updatedOrders);
        //const response = await apiWrapper('order', 'GET');
        //setOrders(response);
        fetchOrders();
        closeEditOrderModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };

    updateOrderOnServer();
  };

 

  const handleSaveDefectedPreforma = (
    index,
    updatedOrder,
    preformaitemdefected,
    preformaitemdefectedkg
  ) => {
    // Make a GET request to retrieve the order from the server
    const getOrderFromServer = async () => {
      try {
        const response = await apiWrapper(`order/${updatedOrder._id}`, "GET");

        const actualDefected = response.preformaitemdefected;
        const actualDefectedKg = response.preformaitemdefectedkg;

        const itemDefectedAdded = updatedOrder.preformaitemdefected;
        const kgItemDefectedAdded = updatedOrder.preformaitemdefectedkg;
        //console.log(itemDefectedAdded)
        //console.log(kgItemDefectedAdded)
        // Sum the actual values with the new values
        updatedOrder.preformaitemdefected =
          Number(actualDefected) + Number(updatedOrder.preformaitemdefected);
        updatedOrder.preformaitemdefectedkg =
          Number(actualDefectedKg) + Number(updatedOrder.preformaitemdefectedkg);

        // Make a PATCH request to update the order on the server
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", updatedOrder);

        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[index] = updatedOrder;
        setOrders(updatedOrders);

        await apiWrapper(`order/${updatedOrder._id}/defectedsignpreforma`, "PATCH", {
          employeestory: {
            user,
            mexicanTime: new Date().toLocaleString("en-US", options),
            preformaitemdefected: itemDefectedAdded,
            preformaitemdefectedkg: kgItemDefectedAdded,

            desperdiciopreformatf:updatedOrder.desperdiciopreforma.desperdiciopreformatf,
            defectedtypepreforma: updatedOrder.desperdiciopreforma.defectedtypepreforma,
            defectedcommentpreforma: updatedOrder.desperdiciopreforma.defectedcommentpreforma,
          },
        });

        fetchOrders();
        // Close the edit order modal
        closeEditOrderModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };

    getOrderFromServer();
  };

  const handleDelete = async (orderId) => {
    try {
      // Make a DELETE request to the API to delete the order with the given ID
      await apiWrapper(`order/${orderId}`, "DELETE");

      // Update the orders state by removing the deleted order from the array
      setOrders(orders.filter((order) => order._id !== orderId));
    } catch (error) {
      // Handle error
      console.error("Error deleting order:", error);
    }
  };

 

  const updateOrder = async (orderId) => {
   // console.log(orderId)
    try {
      const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        scannedcompleted: true,
      });

      await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
        employeestory: { user, 
          mexicanTime: new Date().toLocaleString("en-US", options), 
          scannedcompleted: true },
      });

      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, scannedcompleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
      fetchOrders();

    //   console.log("Order updated:", updatedOrder);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const updateOrderPreforma = async (order) => {
     console.log("what i get",order)
     console.log("prefpack",order.preformapack)
     console.log("totpack",order.preformatotpack)
     const orderId = order._id
     try {
       const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
         preformascannedcompleted: true,
         preformapack:order.preformapack,
         preformatotpack:order.preformatotpack,
       });
 
       await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
         employeestory: { user,
          mexicanTime: new Date().toLocaleString("en-US", options),
          preformascannedcompleted: true,
          preformapack:order.preformapack,
          preformatotpack:order.preformatotpack,
        },
       });
 
       const updatedOrders = orders.map((order) => {
         if (order._id === orderId) {
           return { ...order, preformascannedcompleted: true };
         }
         return order;
       });
       setOrders(updatedOrders);
       fetchOrders();
 
     //   console.log("Order updated:", updatedOrder);
     } catch (error) {
       console.error("Error updating order:", error);
     }
   };

   const updateOrderPreformaExistencia = async (orderId) => {
    // console.log(orderId)
     try {
       const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        preformastocktohide: true,
       });
 
       await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
         employeestory: { user, 
          mexicanTime: new Date().toLocaleString("en-US", options),
          preformastocktohide: true },
       });
 
       const updatedOrders = orders.map((order) => {
         if (order._id === orderId) {
           return { ...order, preformastocktohide: true };
         }
         return order;
       });
       setOrders(updatedOrders);
       fetchOrders();
 
     //   console.log("Order updated:", updatedOrder);
     } catch (error) {
       console.error("Error updating order:", error);
     }
   };


//const itemtoupdate= orders.map((order) => (order.itemsscanned))
//console.log(itemtoupdate) 

//phase completed
useEffect(() => {
  socket.on('updateOrderDefectedSign', (updatedItemsDefected) => {
    setOrders((prevOrders) => {
      // Filter out the orders with scannedcompleted set to true
      const filteredOrders = prevOrders.filter((order) => !order.scannedcompleted);
      return filteredOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSign');
  };
}, []);

//phase completed preforma
useEffect(() => {
  socket.on('updateOrderDefectedSignPreforma', (updatedItemsDefected) => {
    setOrders((prevOrders) => {
      // Filter out the orders with scannedcompleted set to true
      const filteredOrders = prevOrders.filter((order) => !order.scannedcompleted);
      return filteredOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSignPreforma');
  };
}, []);



useEffect(() => {
  socket.on('updateOrder', (updatedEditOrder) => {
   //console.log("cheedita",updatedEditOrder.data.customer)
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order._id === updatedEditOrder._id) {
          //console.log("check",updatedEditOrder.customer)
          
          return { ...order, ...updatedEditOrder.data };
          
        }
        return order;
      });
      //console.log("check2", updatedOrders)
      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrder');
  };
}, []);




useEffect(() => {
  const handleCreateOrder = (newOrder) => {
    setOrders((prevOrders) => [...prevOrders, newOrder]);
  };

  // Add the 'createOrder' event listener
  socket.on('createOrder', handleCreateOrder);

  // Clean up the event listener when the component unmounts
  return () => {
    socket.off('createOrder', handleCreateOrder);
  };
}, []);


useEffect(() => {
  socket.on('deleteOrder', (deletedOrderId) => {
    setOrders((prevOrders) => {
      return prevOrders.filter((order) => order._id !== deletedOrderId);
    });
  });

  return () => {
    socket.off('deleteOrder');
  };
}, []);




useEffect(() => {
  socket.on('updateOrderIncrement',(updatedItemsscanned) => {
    setColorUpdated(updatedItemsscanned._id); // Set the ID of the updated order
    //console.log("heeel",updatedItemsscanned._id)

    setTimeout(() => {
      setColorUpdated(null); // Clear the ID after 3 seconds
    }, 3000);

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order._id === updatedItemsscanned._id) {
          return { ...order, itemsscanned: updatedItemsscanned.itemsscanned,
            packscanned: updatedItemsscanned.packscanned };
        }
        return order;
           });

      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrderIncrement');
     };
}, []);


// preforma increment
useEffect(() => {
  socket.on('updateOrderIncrementPreforma',(updatedItemsscanned) => {
    setColorUpdated(updatedItemsscanned._id); // Set the ID of the updated order
    //console.log("heeel",updatedItemsscanned._id)

    setTimeout(() => {
      setColorUpdated(null); // Clear the ID after 3 seconds
    }, 3000);

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order._id === updatedItemsscanned._id) {
          return { ...order, preformascanned: updatedItemsscanned.preformascanned,
            preformapackscanned: updatedItemsscanned.preformapackscanned };
        }
        return order;
           });

      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrderIncrementPreforma');
     };
}, []);

//


useEffect(() => {
  socket.on('updateOrderDefectedSign', (updatedItemsDefected) => {
   // console.log("heeessl",updatedItemsDefected._id)
    setColorUpdatedDefected(updatedItemsDefected._id); // Set the ID of the updated order
    setColorUpdatedDefectedKg(updatedItemsDefected._id)
   // console.log("heeel",updatedItemsDefected._id)

    setTimeout(() => {
     setColorUpdatedDefected(null);
     setColorUpdatedDefectedKg(null); // Clear the ID after 3 seconds
   }, 3000);

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        
        if(order._id ===updatedItemsDefected._id) {
          return{...order, itemdefected:updatedItemsDefected.itemdefected, 
            itemdefectedkg:updatedItemsDefected.itemdefectedkg,

          }
        }
        return order;
      });

      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSign');
  };
}, []);

useEffect(() => {
  socket.on('updateOrderDefectedSignPreforma', (updatedItemsDefected) => {
   // console.log("heeessl",updatedItemsDefected._id)
    setColorUpdatedDefectedPreforma(updatedItemsDefected._id); // Set the ID of the updated order
    setColorUpdatedDefectedKgPreforma(updatedItemsDefected._id)
   // console.log("heeel",updatedItemsDefected._id)

    setTimeout(() => {
     setColorUpdatedDefectedPreforma(null);
     setColorUpdatedDefectedKgPreforma(null); // Clear the ID after 3 seconds
   }, 3000);

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        
        if(order._id ===updatedItemsDefected._id) {
          return{...order, preformaitemdefected:updatedItemsDefected.preformaitemdefected, 
            preformaitemdefectedkg:updatedItemsDefected.preformaitemdefectedkg,

          }
        }
        return order;
      });

      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSignPreforma');
  };
}, []);


useEffect(() => {
  socket.on('updateOrderSubtractSign', (updatedItemsSubtract) => {
   // console.log("heeessl",updatedItemsDefected._id)
    setColorUpdatedSubtractKg(updatedItemsSubtract._id); // Set the ID of the updated order
   // console.log("heeel",updatedItemsDefected._id)

    setTimeout(() => {
      setColorUpdatedSubtractKg(null); // Clear the ID after 3 seconds
   }, 3000);

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        
        if(order._id ===updatedItemsSubtract._id) {
          return{...order, kgloadedfromalmacen:updatedItemsSubtract.kgloadedfromalmacen, 
            

          }
        }
        return order;
      });

      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrderSubtractSign');
  };
}, []);

useEffect(() => {
  socket.on('subtractArticleDecrement', (updatedAlmacenSubtract) => {
   //console.log(updatedAlmacenSubtract)
    setColorUpdatedAlmacenSubtractKg(updatedAlmacenSubtract._id); // Set the ID of the updated order
    

    setTimeout(() => {
      setColorUpdatedAlmacenSubtractKg(null); // Clear the ID after 3 seconds
    
   }, 3000);

   setArticles((prevArticles) => {
      const updatedArticles = prevArticles.map((article) => {
        
        if(article._id ===updatedAlmacenSubtract._id) {
         
          return{...article, kg:updatedAlmacenSubtract.kg, 
         
          }
          
        }
        return article;
        
      });
      //console.log(updatedArticles)
      return updatedArticles;
      
    });
  });

  return () => {
    socket.off('subtractArticleDecrement');
  };
}, []);


useEffect(() => {
  socket.on('updateArticleIncrement',(updatedItemsscanned) => {
    //console.log(updatedItemsscanned)
   setColorUpdatedArticleIncrement(updatedItemsscanned._id); // Set the ID of the updated order
 //   console.log("heeel",updatedItemsscanned._id)

    setTimeout(() => {
      setColorUpdatedArticleIncrement(null); // Clear the ID after 3 seconds
    }, 3000);

    setArticles((prevArticles) => {
      const updatedArticles = prevArticles.map((article) => {
        if (article._id === updatedItemsscanned._id) {
       //   console.log(updatedItemsscanned)
       //   console.log(article)
       //  console.log(updatedItemsscanned.value)
        //  console.log(updatedItemsscanned.kg)
          return { ...article, value: updatedItemsscanned.value, kg:updatedItemsscanned.kg };
         
        }
      //  console.log(article.provider)
        return article;
           });
     //      console.log(updatedArticles)
           return updatedArticles;
           
    });
  });

  return () => {
    
    socket.off('updateArticleIncrement');
     };
}, []);

const [isPreformaExpanded, setIsPreformaExpanded] = useState(true);

const [isColumnLayout, setColumnLayout] = useState(false);
const [showQRScanOrderPreforma, setShowQRScanOrderPreforma] = useState(true);
const [showQRScanOrder, setShowQRScanOrder] = useState(true);

const handleQRScanOrderPreformaClick = () => {
  setColumnLayout(true);
  setShowButton(true)
  setShowQRScanOrderPreforma(true);
  setShowQRScanOrder(false);
};

const handleQRScanOrderClick = () => {
  setColumnLayout(true);
  setShowButton(true)
  setShowQRScanOrderPreforma(false);
  setShowQRScanOrder(true);
};

const handleSwitchButtonClick = () => {
  setColumnLayout(false);
  
  setShowQRScanOrderPreforma(!showQRScanOrderPreforma);
  setShowQRScanOrder(!showQRScanOrder);
};

const [showButton, setShowButton] = useState(false);

useEffect(() => {
  if (orders.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [orders]);



  
  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
       
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
      

         
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
              PREFORMA Pedidos
            </h1>
            <div>{message} </div>
            {role !== "vendedor" && (
                      <React.Fragment>

                         
            <div className= 'flex-row flex items-center justify-center '>
            <div
        className="bg-transparent text-yellow-400 hover:text-yellow-600 "
      >
        <QRScanOrderPreforma id={456} />
      </div>
      
      <div
        className="absolute top-10 left-10"
        >
        <HelpIcon 
          sx={{ fontSize: 20,    
          }}
             onClick= {() =>
              openConsultModal(order)
            }          />

{ role === "admin" && ( 
          <>
          <button 
          className="text-white ml-5"
          onClick={fetchOrdersListCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={orders} headers={preformaheaders} filename={`ListaPREFORMAorder.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
      </>
        )}
            </div> 
           
            </div>
            </React.Fragment>)}
            {showButton && (
        <div className="flex justify-center text-xs ">
          <button onClick={handleSwitchButtonClick}>Cambiar a Preforma/Producto</button>
        </div>
      )}


         
        <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg text-black">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
  
          </div>

          <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido nr
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Fecha
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Cliente
                    <button
                      className="px-6 py-3 text-center text-xs font-thin text-gray-400  -tracking-tight"
                      onClick={() => setIsPreformaExpanded(!isPreformaExpanded )}
                    >
                      {isPreformaExpanded ? "Ocultar Preforma" : "Mostrar Preforma"}
                    </button>
                  </th>
{isPreformaExpanded && (
  <> 
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Modelo Preforma
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                   Ud de Preformas por Paquete
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Nr Paquetes Preformas necesarios
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Preformas para Hacer
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas Producidos
                  </th>
                  
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paquetes Preformas Producidos
                  </th>
                  {(role !== "user" && role !== "vendedor") && (
                      <React.Fragment>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas Defectuosas
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  KG necesarios para Preformas  Defectuosas
                  </th>
                  </React.Fragment>)}
                  {(role === "supervisor" || role === "admin") && (
                      <React.Fragment>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Añadir Preformas defectuosas
                    </th>
                    </React.Fragment>
                    
                    )}
                    {(role !== "user" && role !=="vendedor") && (
                      <React.Fragment>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Preformas en Producción
                    </th>
                    </React.Fragment>)}
                    {(role === "supervisor" || role === "admin") && (
                      <React.Fragment>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Preformas Completadas
                    </th>
                    </React.Fragment>
                    
                  )}
                  </>)}
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Modelo a producir
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Observaciones
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Color
                  </th>
                 
                  {(role === "supervisor" ||
                    role === "admin" ||
                    role === "almacenista") && (
                    <React.Fragment>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Resina
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Resina disponible en almacén (Kg)
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                       Kg de resina necesarios
                      </th>

                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Kg de resina utilizados
                      </th>
                    </React.Fragment>
                  )}
                 
                 
                 {( role === "admin" || role === "vendedor") && (
                    <React.Fragment>
                      <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Edit
                      </th>
                      </React.Fragment>
                      )}
                  {role === "admin" && (
                    <React.Fragment>
                      <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Delete
                      </th>
                    <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Export
                    </th>
                      
                    </React.Fragment>
                  )}
                  {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                    <React.Fragment>
                    <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Añadir Resina en producción
                    </th>
                    
                   </React.Fragment>
                  )}
                  
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
               {/* search result table */}
              <tbody>
                {filteredOrders.map((order, index) => (
                  <tr className={
                    index % 2 === 0 ? "bg-amber-400 text-center text-black" : "bg-amber-300 text-center text-black" 
                  }
                  key={order._id}
                  >
                      <td className=" px-6 py-4  font-medium  whitespace-nowrap">
                        {order.ordernr}
                      </td>
                      <td className=" px-6 py-4  font-medium  whitespace-nowrap">
                      {formatMexicanDate(order.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.customer}
                      </td>
                      { isPreformaExpanded && (
                     <>
                     
                      <td className="px-6 py-4 text-2xl font-medium whitespace-nowrap">
                      {order.preforma}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformapack}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformatotpack}
                      </td>

                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {Number.isInteger(order.preformascanned)
                          ? order.preformascanned
                          : Math.round(order.preformascanned * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {Number.isInteger(order.preformapackscanned)
                          ? order.preformapackscanned
                          : Math.round(order.preformapackscanned * 100) / 100}
                      </td>
                          
                      {(role === "supervisor" ||
                        role === "admin" ||
                        role === "almacenista") && (
                          <React.Fragment>
                           <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdatedDefectedPreforma  === order._id ? 'text-green-500' : ''}`}>
                            {Number.isInteger(order.preformaitemdefected)
                              ? order.preformaitemdefected
                              : Math.round(order.preformaitemdefected * 100) / 100}
                          </td>
                          <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdatedDefectedKgPreforma  === order._id ? 'text-green-500' : ''}`}>
                          {Number.isInteger(order.preformaitemdefectedkg)
                              ? order.preformaitemdefectedkg
                              : Math.round(order.preformaitemdefectedkg * 100) / 100}
                          
                          </td>
                        </React.Fragment>
                      )}
                      {(role === "supervisor" || role === "admin") && (
                        <React.Fragment>
                          <td className=" whitespace-nowrap    ">
                            <ControlPointIcon
                              onClick={() =>
                                openEditPreformaDefectedModal(order, index)
                              }
                            />
                          </td>
                          
                          </React.Fragment>
                      )}  

                      {(role !== "user" && role !=="vendedor")  && (
                        <React.Fragment>
                          <td className=" whitespace-nowrap    ">
                            <button
                            onClick={()=>openAlertOrderPhaseModalPreforma(order, index) }
                            disabled={order.preformascannedcompleted}
                              className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                                order.preformascannedcompleted
                                  ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                                  : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                              }`}
                            >
                              {order.preformascannedcompleted ? (
                                <CheckBoxIcon className="text-green-500" />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )}
                            </button>
                          </td>
                          </React.Fragment>
                           )}
                          {(role === "supervisor" || role === "admin") && (
                        <React.Fragment>
                          <td className="whitespace-nowrap">
  <button
    onClick={() => openAlertOrderPhaseModalPreformaExistencia(order, index)}
    
    className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
      order.preformastocktohide
        ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
        : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
    }`}
   // disabled={!order.preformascannedcompleted}
  >
    {order.preformastocktohide ? (
      <CheckBoxIcon className="text-green-500" />
    ) : (
      <CheckBoxOutlineBlankIcon />
    )}
  </button>
  {buttonClicked && !order.preformascannedcompleted && (
    
    <div>{message} </div>
     
    
  )}
</td>


                          </React.Fragment>
                          
                      )}
                      </> )}
                      <td className="px-6 py-4  whitespace-nowrap">
                        {order.finalproduct}
                      </td>
                      <td className="px-6 py-4   whitespace-nowrap">
                        {order.personalizacion}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformacolor}
                      </td>
                     

                     

                      {(role === "supervisor" ||
                        role === "admin" ||
                        role === "almacenista") && (
                          <React.Fragment>
                          <td className="px-6 py-4 whitespace-nowrap flex flex-col">
                          {order.code ? (
    order.code
  ) : (
    
    <select className="p-2 mb-4 w-full rounded-lg bg-red-500 text-white text-center"
    onChange={(event) => {
      setSelectedArticleCode(event.target.value);
    }}
  >
    <option value="">Seleccionar resina</option>
    <option value="resina_ya_producida">RESINA YA PRODUCIDA</option>
    {articles.map((article) => (
      <option key={article._id} value={article.code}>
        {article.code} - {article.kg} Kg
      </option>
    ))}
  </select>
  
  
  )}
 {!order.code && (
 <button 
 className={`rounded-lg p-2 ${selectedArticleCode ? 'bg-green-700' : 'bg-gray-700 text-white'}`}
 onClick={() => {
  //  console.log("Button clicked. Selected article code:", order, selectedArticleCode );
   handleSaveResina(order, selectedArticleCode);
 }}
 disabled={!selectedArticleCode}
>
 actualizar
</button>
)}
                          </td>
                          
                        
                          {articles.map((article) =>
  article.code === order.code ? (
    <td
    key={`article-${article.code}-${article.kg}`}
      className={`px-6 py-4  font-medium whitespace-nowrap ${
        colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : ''} ${
        colorUpdatedArticleIncrement === article._id ? 'text-green-500' : ''}`}
    >
      {Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100}
    </td>
  ) : null
)}
   {order.code === "resina_ya_producida" && (  
  <td>Resina ya Producida</td>                     
  )}     
                          
                          
                          <td className="px-6 py-4  font-medium whitespace-nowrap">
                            {Number.isInteger(order.kgneeded)
                              ? order.kgneeded
                              : (order.kgneeded * 100) / 100}
                          </td>
                          <td className={`px-6 py-4  font-medium whitespace-nowrap ${colorUpdatedSubtractKg  === order._id ? 'text-green-500' : ''}`}>
                            {Number.isInteger(order.kgloadedfromalmacen)
                              ? order.kgloadedfromalmacen
                              : (order.kgloadedfromalmacen * 100) /
                                100}
                          </td>
                          </React.Fragment>
                      )}
                     
                  
                       
                     {(role !=="user") && (
                        <React.Fragment>
                          <td className=" whitespace-nowrap    ">
                            <EditIcon
                              onClick={() => openEditOrderModal(order, index)}
                            />
                          </td>
                         </React.Fragment>
                          )}
                         {role === "admin" && ( 
                           <React.Fragment>
                          <td className="px-2  whitespace-nowrap m-18  ">
                            <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                          </td>
                          <td className="px-2  whitespace-nowrap m-18  ">
                          <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                          </React.Fragment>
                      )}
                                           {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                        <React.Fragment>
                       <td className=" whitespace-nowrap    ">
                            <PlaylistAddIcon
                              onClick={() => openKgModal(order, index)}
                            />
                          </td>
                          
                         </React.Fragment>
                      )}
                  </tr>
                ))}
              </tbody>
              
              <tbody className=" divide-y divide-gray-200 text-center text-white ">
                {orders.length === 0 || filteredOrders.length > 0 ? (
    <tr className="text-center">
    <td colSpan="8">
  {loading ? (
    <p className=" text-white text-center">-</p>
  ) : (
    <p className="text-center  font-medium text-white">No hay datos en DB</p>
  )}
</td>
    </tr>
  ) : (orders
                  .slice()
                  .reverse()
                  .map((order, index) => (
                    <tr className={
                        index % 2 === 0 ? " bg-sky-900" : " bg-sky-800" 
                      }
                      key={order._id}
                    >
                      <td className=" px-6 py-4  font-medium  whitespace-nowrap">
                        {order.ordernr}
                      </td>
                      <td className=" px-6 py-4  font-medium  whitespace-nowrap">
                      {formatMexicanDate(order.createdAt)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.customer}
                      </td>
                      { isPreformaExpanded && (
                     <>
                     
                      <td className="px-6 py-4 text-2xl font-medium whitespace-nowrap">
                      {order.preforma}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformapack}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformatotpack}
                      </td>

                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {Number.isInteger(order.preformascanned)
                          ? order.preformascanned
                          : Math.round(order.preformascanned * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {Number.isInteger(order.preformapackscanned)
                          ? order.preformapackscanned
                          : Math.round(order.preformapackscanned * 100) / 100}
                      </td>
                          
                      {(role === "supervisor" ||
                        role === "admin" ||
                        role === "almacenista") && (
                          <React.Fragment>
                           <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdatedDefectedPreforma  === order._id ? 'text-green-500' : ''}`}>
                            {Number.isInteger(order.preformaitemdefected)
                              ? order.preformaitemdefected
                              : Math.round(order.preformaitemdefected * 100) / 100}
                          </td>
                          <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdatedDefectedKgPreforma  === order._id ? 'text-green-500' : ''}`}>
                          {Number.isInteger(order.preformaitemdefectedkg)
                              ? order.preformaitemdefectedkg
                              : Math.round(order.preformaitemdefectedkg * 100) / 100}
                            
                          </td>
                        </React.Fragment>
                      )}
                      {(role === "supervisor" || role === "admin") && (
                        <React.Fragment>
                          <td className=" whitespace-nowrap    ">
                            <ControlPointIcon
                              onClick={() =>
                                openEditPreformaDefectedModal(order, index)
                              }
                            />
                          </td>
                          
                          </React.Fragment>
                      )}  

                        {(role !== "user" && role !=="vendedor")  && (
                        <React.Fragment>
                          <td className=" whitespace-nowrap    ">
                            <button
                            onClick={()=>openAlertOrderPhaseModalPreforma(order, index) }
                            disabled={order.preformascannedcompleted}
                              className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                                order.preformascannedcompleted
                                  ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                                  : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                              }`}
                            >
                              {order.preformascannedcompleted ? (
                                <CheckBoxIcon className="text-green-500" />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )}
                            </button>
                          </td>
                          </React.Fragment>
                           )}
                          {(role === "supervisor" || role === "admin") && (
                        <React.Fragment>
                          <td className="whitespace-nowrap">
  <button
    onClick={() => openAlertOrderPhaseModalPreformaExistencia(order, index)}
    
    className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
      order.preformastocktohide
        ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
        : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
    }`}
   // disabled={!order.preformascannedcompleted}
  >
    {order.preformastocktohide ? (
      <CheckBoxIcon className="text-green-500" />
    ) : (
      <CheckBoxOutlineBlankIcon />
    )}
  </button>
  {buttonClicked && !order.preformascannedcompleted && (
    
    <div>{message} </div>
     
    
  )}
</td>


                          </React.Fragment>
                          
                      )}
                      </> )}
                      <td className="px-6 py-4  whitespace-nowrap">
                        {order.finalproduct}
                      </td>
                      <td className="px-6 py-4   whitespace-nowrap">
                        {order.personalizacion}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformacolor}
                      </td>
                     

                     

                      {(role === "supervisor" ||
                        role === "admin" ||
                        role === "almacenista") && (
                          <React.Fragment>
                     <td className="px-6 py-4 whitespace-nowrap flex flex-col ">
  {order.code ? (
    order.code
  ) : (
    
    <select className="p-2 mb-4 w-full rounded-lg bg-red-500 text-center"
    onChange={(event) => {
      setSelectedArticleCode(event.target.value);
    }}
  >
    <option value="">Seleccionar resina</option>
    <option value="resina_ya_producida">RESINA YA PRODUCIDA</option>
    {articles.map((article) => (
      <option key={article._id} value={article.code}>
        {article.code} - {article.kg} Kg
      </option>
    ))}
  </select>
  
  
  )}
 {!order.code && (
 <button 
 className={`rounded-lg p-2 ${selectedArticleCode ? 'bg-green-700' : 'bg-gray-700 '}`}
 onClick={() => {
   // console.log("Button clicked. Selected article code:", selectedArticleCode);
   handleSaveResina(order, selectedArticleCode);
 }}
 disabled={!selectedArticleCode}
>
 actualizar
</button>
)}
</td>
                          
                        
                          {articles.map((article) =>
  article.code === order.code ? (
    <td
      key={`article-${article.code}-${article._id}`}
      className={`px-6 py-4  font-medium whitespace-nowrap ${
        colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : ''} ${
        colorUpdatedArticleIncrement === article._id ? 'text-green-500' : ''}`}
    >
      {Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100}
    </td>
  ) : null
)}
 {order.code === "resina_ya_producida" && (  
  <td>Resina ya Producida</td>                     
  )}                  
                          <td className="px-6 py-4  font-medium whitespace-nowrap">
                            {Number.isInteger(order.kgneeded)
                              ? order.kgneeded
                              : (order.kgneeded * 100) / 100}
                          </td>
                          <td className={`px-6 py-4  font-medium whitespace-nowrap ${colorUpdatedSubtractKg  === order._id ? 'text-green-500' : ''}`}>
                            {Number.isInteger(order.kgloadedfromalmacen)
                              ? order.kgloadedfromalmacen
                              : (order.kgloadedfromalmacen * 100) /
                                100}
                          </td>
                          </React.Fragment>
                      )}
                     
                  
                     {(role !=="user") && (
                          <td className=" whitespace-nowrap    ">
                            <EditIcon
                              onClick={() => openEditOrderModal(order, index)}
                            />
                          </td>
                     )}
                      {role === "admin" && (
                        <React.Fragment>
                          <td className="px-2  whitespace-nowrap m-18  ">
                            <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                          </td>
                          <td className="px-2  whitespace-nowrap m-18  ">
                          <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                          </React.Fragment>
                      )}
                                           {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                        <React.Fragment>
                       <td className=" whitespace-nowrap    ">
                            <PlaylistAddIcon
                              onClick={() => openKgModal(order, index)}
                            />
                          </td>
                          
                         </React.Fragment>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 gap-4 md:hidden">
            <div className="flex justify-center items-center text-black" >
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
                      
       
            <div>
              {filteredOrders.map((order, index) => (
                <div
                className={
                  index % 2 === 0
                    ? "border rounded-lg bg-amber-400 px-2 py-2.5 text-black"
                    : " border rounded-lg bg-amber-300 px-2 py-2.5 text-black"
                }
                  key={`${order.customer}-${order.ordernr}`}
                >
               <div className="bg-transparent">
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                    <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" >Fecha:</span>
                    <span className="ml-auto font-bold text-xl">{formatMexicanDate(order.createdAt)}</span>
                    </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  
                    <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2 text-sm font-medium  ">
                  <span className="text-gray-900"> Modelo a producir:</span> 
                  <span> {order.preforma}</span>
                    </div>
                    {order.personalizacion && ( 
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Observaciones:</span> 
                  <span> {order.personalizacion}</span>
                    </div>
                    )}
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                    <span className="text-gray-900"> Color:</span>
                    <span>  {order.preformacolor}</span>
                  </div>

                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquete de: </span>
                  <span className="text-sm">  {order.preformapack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Preformas para hacer:</span>
                  <span className="text-lg">  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium break-all text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900 text-sm font-medium">Preformas Producidos:{" "}</span>
                  <span>  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium break-all text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900 text-sm font-medium"> Paquetes Producidos:{" "}</span>
                  <span>  {Number.isInteger(order.preformapackscanned)
                      ? order.preformapackscanned
                      : Math.round(order.preformapackscanned * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                       {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                    <React.Fragment>
                      <div>
                 <div className="w-4/5 mx-auto border-t-2 border-black opacity-10 mt-4 mb-4"></div>
  {articles.map((article) =>
    article.code === order.code ? (
      <div key={`article2-${article.code}-${article.kg}-${article._id}`} className="my-custom-div">
        {/* Additional content above the article details */}
        <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
        <span className="text-gray-900"> Resina</span>
           {article.code}
           </div>
        <div
          className={`flex items-center justify-between space-x-2 text-sm font-medium break-all ml-3 mr-3 ${
            colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : ''
          }`}
        >
        <span className="text-gray-900">Resina disponible en almacén (Kg):</span>  
        <span className={` text-2xl font-medium whitespace-nowrap ${
        colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : ''} ${
        colorUpdatedArticleIncrement === article._id ? 'text-green-500' : ''}`}
    >  {Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">Kg</span></span>
        </div>
      </div>
    ) : null
  )}
  {order.code === "resina_ya_producida" && (<div className="ml-3">Resina ya Producida</div>)}
   {!order.code && (
        <>
          <select className="p-2 mb-4 w-full rounded-lg bg-red-500 text-white"
            onChange={(event) => {
              setSelectedArticleCode(event.target.value);
            }}
          >
            <option value="">Seleccionar resina</option>
            <option value="resina_ya_producida">RESINA YA PRODUCIDA</option>
            {articles.map((article) => (
              <option key={article._id} value={article.code}>
                {article.code} - {article.kg} Kg
              </option>
            ))}
          </select>
          <button 
            className={`rounded-lg p-2  mb-2 ml-3  ${selectedArticleCode ? 'bg-green-700' : 'bg-gray-700 text-white'}`}
            onClick={() => {
              // console.log("Button clicked. Selected article code:", selectedArticleCode);
              handleSaveResina(order, selectedArticleCode);
            }}
            disabled={!selectedArticleCode}
          >
            actualizar
          </button>
        </>)}
</div>
  

<div className="flex items-center justify-between space-x-2 font-medium break-all text-1xl ml-3 mr-3 ">
<span className="text-gray-900 text-sm">    Resina necesarias:</span>
<span>      {Number.isInteger(order.kgneeded)
                              ? order.kgneeded
                              : (order.kgneeded * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">Kg</span></span>
                       
                      </div>
                      <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdatedSubtractKg === order._id ? 'text-green-500' : ''}`}>
                      <span className="text-gray-900 text-sm">      Resina utilizadas:</span>
                      <span>   {order.kgloadedfromalmacen}<span className="text-gray-900 text-sm font-medium ml-2">Kg</span></span>
                      </div>{" "}
                      </React.Fragment>
                  )}
                   {(role === "supervisor" ||
                        role === "admin" ||
                        role === "almacenista") && (
                          <React.Fragment>
                            <div className="w-4/5 mx-auto border-t-2 border-black opacity-10 mt-4 mb-4"></div>
                         <div className={`flex items-center justify-between space-x-2  font-medium text-lg ml-3 mr-3 ${colorUpdatedDefectedPreforma === order._id ? 'text-green-500' : ''}`}>
                         <span className="text-gray-900 text-sm">    Preformas defectuosos: </span>
                         <span>    {Number.isInteger(order.preformaitemdefected)
                              ? order.preformaitemdefected
                              : Math.round(order.preformaitemdefected * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                          </div>
                          <div className={`flex items-center justify-between space-x-2  font-medium text-lg ml-3 mr-3 ${colorUpdatedDefectedKgPreforma === order._id ? 'text-green-500' : ''}`}>
                          <span className="text-gray-900 text-sm">    Preformas defectuosos:</span>
                         <span>   {Number.isInteger(order.preformaitemdefectedkg)
                              ? order.preformaitemdefectedkg
                              : Math.round(order.preformaitemdefectedkg * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">Kg</span></span>
                          </div>
                        </React.Fragment>
                      )}
                  <div className="flex flex-col justify-between ">
                    {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                      <React.Fragment>
                        <button
                          onClick={() => openKgModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                      bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3 mt-3"
                        >
                          <PlaylistAddIcon /> Añadir Resina en producción
                        </button>{" "}
                        </React.Fragment>
                    )}

                   
                         <div className="flex justify-between">
                         {(role !== "user" && role !=="vendedor")  && (
                      <React.Fragment>  
                        <button
                           onClick={()=>openAlertOrderPhaseModalPreforma(order, index) }
                           disabled={order.preformascannedcompleted}
                           className={`text-white focus:ring-4 focus:outline-none  
                           font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3 mt-3 ${
                            order.preformascannedcompleted
                              ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformascannedcompleted ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Producción Confirmada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Preformas en Producción</span>
                            </>
                          )}
                        </button>
                        </React.Fragment>)}
                        {(role === "supervisor" || role === "admin") && (
                      <React.Fragment>  
                        <button
                           
                           onClick={() => openAlertOrderPhaseModalPreformaExistencia(order, index)}
                           className={`text-white focus:ring-4 focus:outline-none  
                           font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3 mt-3 ${
                            order.preformastocktohide
                              ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformastocktohide ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Completada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Producción completada</span>
                            </>
                          )}
                        </button>
                        </React.Fragment>)}  
                        </div>
                        {(role === "supervisor" || role === "admin") && (
                      <React.Fragment>
                        <button
                          onClick={() => openEditPreformaDefectedModal (order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3"
                        >
                          <ControlPointIcon /> Añadir Preformas defectuosos
                        </button>
                        </React.Fragment>
                    )}

                        {(role !=="user") && (
                        <button
                          onClick={() =>  openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit
                        </button>
                        )}
                        {role === "admin" && (
                             <React.Fragment>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>

                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
                        </React.Fragment>
                    )}
                  </div>
                
                </div>
              ))}
            </div>
           
{orders.length === 0 || filteredOrders.length >0 ? (
  
loading ? (
    <div className="border rounded-lg bg-sky-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className=" text-white">-</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-sky-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)

  ) : (
    
    orders
              .slice()
              .reverse()
              .map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-sky-900"
                      : " border rounded-lg bg-sky-800"
                  }
                  key={order._id}
                >
                  <div className="bg-transparent">
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                    <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" >Fecha:</span>
                    <span className="ml-auto font-bold text-xl">{formatMexicanDate(order.createdAt)}</span>
                    </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  
                    <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2 text-sm font-medium  ">
                  <span className="text-gray-300"> Modelo a producir:</span> 
                  <span> {order.preforma}</span>
                    </div>
                    {order.personalizacion && ( 
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Observaciones:</span> 
                  <span> {order.personalizacion}</span>
                    </div>
                    )}
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                    <span className="text-gray-300"> Color:</span>
                    <span>  {order.preformacolor}</span>
                  </div>

                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquete de: </span>
                  <span className="text-sm">  {order.preformapack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Preformas para hacer:</span>
                  <span className="text-lg">  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium break-all text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300 text-sm font-medium">Preformas Producidos:{" "}</span>
                  <span>  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium break-all text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300 text-sm font-medium"> Paquetes Producidos:{" "}</span>
                  <span>  {Number.isInteger(order.preformapackscanned)
                      ? order.preformapackscanned
                      : Math.round(order.preformapackscanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>

                  {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                    <React.Fragment>
                      <div>
                 <div className="w-4/5 mx-auto border-t-2 border-black opacity-10 mt-4 mb-4"></div>
  {articles.map((article) =>
    article.code === order.code ? (
      <div key={`article2-${article.code}-${article.kg}-${article._id}`} className="my-custom-div">
        {/* Additional content above the article details */}
        <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
        <span className="text-gray-300"> Resina</span>
           {article.code}
           </div>
        <div
          className={`flex items-center justify-between space-x-2 text-sm font-medium break-all ml-3 mr-3 ${
            colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : ''
          }`}
        >
        <span className="text-gray-300">Resina disponible en almacén (Kg):</span>  
        <span className={` text-2xl font-medium whitespace-nowrap ${
        colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : ''} ${
        colorUpdatedArticleIncrement === article._id ? 'text-green-500' : ''}`}
    >  {Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">Kg</span></span>
        </div>
      </div>
    ) : null
  )}
  {order.code === "resina_ya_producida" && (<div className="ml-3">Resina ya Producida</div>)}
   {!order.code && (
        <>
          <select className="p-2 mb-4 w-full rounded-lg bg-red-500"
            onChange={(event) => {
              setSelectedArticleCode(event.target.value);
            }}
          >
            <option value="">Seleccionar resina</option>
            <option value="resina_ya_producida">RESINA YA PRODUCIDA</option>
            {articles.map((article) => (
              <option key={article._id} value={article.code}>
                {article.code} - {article.kg} Kg
              </option>
            ))}
          </select>
          <button 
            className={`rounded-lg p-2 mb-2 ml-3 ${selectedArticleCode ? 'bg-green-700' : 'bg-gray-700 text-white'}`}
            onClick={() => {
              // console.log("Button clicked. Selected article code:", selectedArticleCode);
              handleSaveResina(order, selectedArticleCode);
            }}
            disabled={!selectedArticleCode}
          >
            actualizar
          </button>
        </>)}
</div>
  

<div className="flex items-center justify-between space-x-2 font-medium break-all text-1xl ml-3 mr-3 ">
<span className="text-gray-300 text-sm">    Resina necesarias:</span>
<span>      {Number.isInteger(order.kgneeded)
                              ? order.kgneeded
                              : (order.kgneeded * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">Kg</span></span>
                       
                      </div>
                      <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdatedSubtractKg === order._id ? 'text-green-500' : ''}`}>
                      <span className="text-gray-300 text-sm">      Resina utilizadas:</span>
                      <span>   {order.kgloadedfromalmacen}<span className="text-gray-300 text-sm font-medium ml-2">Kg</span></span>
                      </div>{" "}
                      </React.Fragment>
                  )}
                   {(role === "supervisor" ||
                        role === "admin" ||
                        role === "almacenista") && (
                          <React.Fragment>
                            <div className="w-4/5 mx-auto border-t-2 border-black opacity-10 mt-4 mb-4"></div>
                         <div className={`flex items-center justify-between space-x-2  font-medium text-lg ml-3 mr-3 ${colorUpdatedDefectedPreforma === order._id ? 'text-green-500' : ''}`}>
                         <span className="text-gray-300 text-sm">    Preformas defectuosos: </span>
                         <span>    {Number.isInteger(order.preformaitemdefected)
                              ? order.preformaitemdefected
                              : Math.round(order.preformaitemdefected * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                          </div>
                          <div className={`flex items-center justify-between space-x-2  font-medium text-lg ml-3 mr-3 ${colorUpdatedDefectedKgPreforma === order._id ? 'text-green-500' : ''}`}>
                          <span className="text-gray-300 text-sm">    Preformas defectuosos:</span>
                         <span>   {Number.isInteger(order.preformaitemdefectedkg)
                              ? order.preformaitemdefectedkg
                              : Math.round(order.preformaitemdefectedkg * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">Kg</span></span>
                          </div>
                        </React.Fragment>
                      )}
                  <div className="flex flex-col justify-between ">
                    {(role === "almacenista" || role === "admin" || role === "supervisor") && (
                      <React.Fragment>
                        <button
                          onClick={() => openKgModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                      bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3 mt-3"
                        >
                          <PlaylistAddIcon /> Añadir Resina en producción
                        </button>{" "}
                        </React.Fragment>
                    )}

                   
                         <div className="flex justify-between">
                         {(role !== "user" && role !=="vendedor")  && (
                      <React.Fragment>  
                        <button
                           onClick={()=>openAlertOrderPhaseModalPreforma(order, index) }
                           disabled={order.preformascannedcompleted}
                           className={`text-white focus:ring-4 focus:outline-none  
                           font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3 mt-3 ${
                            order.preformascannedcompleted
                              ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformascannedcompleted ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Producción Confirmada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Preformas en Producción</span>
                            </>
                          )}
                        </button>
                        </React.Fragment>)}
                        {(role === "supervisor" || role === "admin") && (
                      <React.Fragment>  
                        <button
                           
                           onClick={() => openAlertOrderPhaseModalPreformaExistencia(order, index)}
                           className={`text-white focus:ring-4 focus:outline-none  
                           font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3 mt-3 ${
                            order.preformastocktohide
                              ? "bg-emerald-900 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformastocktohide ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Completada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Producción completada</span>
                            </>
                          )}
                        </button>
                        </React.Fragment>)}  
                        </div>
                        {(role === "supervisor" || role === "admin") && (
                      <React.Fragment>
                        <button
                          onClick={() => openEditPreformaDefectedModal (order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3"
                        >
                          <ControlPointIcon /> Añadir Preformas defectuosos
                        </button>
                        </React.Fragment>
                    )}
                      {(role !=="user") && (
                        <button
                          onClick={() =>  openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit
                        </button>
                        )}
                          {role === "admin" && (
                            <React.Fragment>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>

                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
                        </React.Fragment>
                    )}
                  </div>
                </div>
              )))}
          </div>
        </div>
      </div>
      {isEditOrderModalOpen && (
 <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">

 <div className="mt-10 flex items-center  justify-center">
   <EditOrderModalPreforma
     editableItem={editableItem}
     onSave={handleSave}
     user = {user}
     role = {role}
     mexicanTime = {mexicanTime}
     options = {options}
     onClose={closeEditOrderModal}
     onCloseQr={closeEditModalQr}
   />
 </div>
</div>

)}
      {isKgModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
         <div className="mt-10 flex items-center  justify-center">
            <SubtractKgOrderModal
              KgEditableItem={KgEditableItem}
              onSave={handleSaveSubtract}
              onClose={closeKgModal}
              mexicanTime={mexicanTime}
              
            />
          </div>
        </div>
      )}



      

{isDefectedModalOpenPreforma && (
        <div className="fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
          <div className="mt-10 flex items-center  justify-center">
            <EditDefectedModalPreforma
              defectedEditablePreforma={defectedEditablePreforma}
              onSave={handleSaveDefectedPreforma}
              onClose={closeEditPreformaDefectedModal}
            />
          </div>
        </div>
      )}

            {isAlertOrderPhaseModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhaseOrderModal
              alertOrder={alertOrder}
              onSave={updateOrder}
              onClose={closeOpenAlertOrderPhaseModal}
            />
          </div>
        </div>
      )}

{isAlertOrderPhaseModalOpenPreforma && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhaseOrderModalPreforma
              alertOrderPreforma={alertOrderPreforma}
              onSave={updateOrderPreforma}
              onClose={closeOpenAlertOrderPhaseModalPreforma}
            />
          </div>
        </div>
      )}

{isAlertOrderPhaseModalOpenPreformaExistencia && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhaseOrderModalPreformaExistencia
              alertOrderPreformaExistencia={alertOrderPreformaExistencia}
              onSave={updateOrderPreformaExistencia}
              onClose={closeOpenAlertOrderPhaseModalPreformaExistencia}
            />
          </div>
        </div>
      )}


 {isAlertOrderDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteOrderModal
              alertDeleteOrder={alertDeleteOrder}
              onSave={handleDelete}
              onClose={closeOpenAlertOrderDeleteModal}
            />
          </div>
        </div>
      )}
          {isConsultModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45 bg-gray-800 opacity-90">
        
          <div className="mt-10">
            <EditConsultOrderModal
              addConsultModal={addConsultModal}
              
              onClose={closeConsultModal}
            />
          </div>
        </div>
      )}

    </section>
  );
  };
  

export default DashboardOrderPreforma;
