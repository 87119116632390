import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

const EditAlertPhaseOrderModalPreforma = ({ alertOrderPreforma, onSave, onClose,  }) => {
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const { order  } = alertOrderPreforma;

    const [formData, setFormData] = useState({
      preformapack: order.preformapack || "",
      preformatotpack: order.preformatotpack || "",
    });

    const handleInputChange = (event) => {
      const name = event.target.name;
      const parsedValue = parseInt(event.target.value, 10);
    
     // console.log('Input changed:', name, parsedValue); // Log the name and value of the changed input
    
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [name]: parsedValue };
    
        if (name === "preformapack") {
          const preformapack = parsedValue;
          let preformatotpack = 0;
    
          if (preformapack) {
            preformatotpack = order.itemstodo / preformapack;
          }
    
      //    console.log('Calculated preformatotpack:', preformatotpack); // Log the calculated preformatotpack
    
          updatedFormData.preformatotpack = preformatotpack;
        }
    
        return updatedFormData;
      });
    };
  

    const updateOrder = () => {
     
      onSave( { ...order, preformapack: formData.preformapack, preformatotpack: formData.preformatotpack });
      onClose();
    };
   
      const handleNoClick = () => {
        setSelectedOrderId(null);
        onClose(); // Close the modal
      };


  return (

    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid  sm:gap-2 md:grid-cols-1 grid-rows-1"> 
     <div className="text-white flex flex-col text-center"> 
    <div className="mt-4 mb-2">PREFORMA EN PRODUCCIÓN</div>
    <div className="text-white">Tipo de Resina:</div>
    <div className="text-white"> {order.code} </div>
    </div>
    { order.code !=="resina_ya_producida" &&( 
    <>
    <div className="ml-3 mr-3 mt-4">Resina necesarias: {order.kgneeded} Kg </div>
    <div className="ml-3 mr-3 ">Resina utilizadas: {order.kgloadedfromalmacen} Kg</div>
    </>
    )}
    <div className="ml-3 mr-3 ">Quedan para empezar:   <span className={`font-bold ${order.kgneeded - order.kgloadedfromalmacen > 0 ? 'text-red-500' : 'text-green-500'}`}>
    {order.kgneeded - order.kgloadedfromalmacen}
  </span> Kg   </div>
    <div >
   <div className="mt-4 ml-3 mr-3 font-bold flex flex-col text-center">
   <div >
   ARTÍCULOS PARA HACER 
    </div>
   <span className="font-thin">(UNIDADES)</span> 
    <span className="font-bold text-center text-xl ">{order.itemstodo}</span>
    </div>
    <div className="flex flex-col ml-3 mr-3">
    <div className="">
                        <label htmlFor="preformapack" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Cantidad de PREFORMAS por Paquete</label>
                        <input type="number" name="preformapack" id="preformapack" placeholder="ud preforma por paq" 
                        value={formData.preformapack}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required />
                    <button className="bg-gray-700 p-1 rounded-lg hover:bg-red-700 block mb-2 mt-2 text-sm font-medium text-white  uppercase"
                       onClick={() => setFormData({ ...formData, preformapack: "", preformatotpack:""})}>Reset</button>
                    </div>
                    <div className="">
  <label htmlFor="preformatotpack" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Tot Paq PREFORMA Y QR para Imprimir</label>
  <input type="number" name="preformatotpack" id="preformatotpack" placeholder="paq Preforma Tot" 
    value={formData.preformatotpack}
    readOnly
    className=" sm:text-sm rounded-lg 
    block w-full p-2.5
    bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required />
</div>
    </div>
    </div>
    <div className="flex justify-center mt-4 mb-4 ">
    <Button onClick={handleNoClick}>No</Button>
      <Button onClick={updateOrder} 
      variant="contained"
      disabled={order.kgneeded - order.kgloadedfromalmacen > 0 || !formData.preformapack || !formData.preformatotpack
        || order.itemstodo < formData.preformapack || !Number.isInteger(Number(formData.preformatotpack))
      }
      style={{ color: (order.kgneeded - order.kgloadedfromalmacen > 0 || !formData.preformapack || !formData.preformatotpack) ? 'gray' : 'white' }}
      >
        Guardar
      </Button>
    </div>
  </div>

  )
}

export default EditAlertPhaseOrderModalPreforma