import { useState, useEffect } from "react";
import React from 'react';
import { apiWrapper } from "../../utils/apiwrapper";
import SearchBarResume from "../searchbar/searchbarResuse";
//import {date, options, mexicanTime} from "../../utils/variousConst"
import { format } from 'date-fns';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditAlertDeleteOrderModal from "../editmodal/EditAlertDeleteOrder";
import { formatMexicanTime } from "../../utils/variousConst.js";

import { socket } from "../../utils/variousConst.js";



const DashboardReviewOrder = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      const filteredOrders = response.filter(
        (order) => order.scannedcompleted === false
      );
      setOrders(filteredOrders);
      //trick to see all anyway:
     setOrders(response);
      //
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []); 

  
  const handleSearch = (searchQuery, filterDate ) => {
    if (searchQuery === "" && !filterDate) {
      setFilteredOrders([]); // Clear the filtered orders list
    } else {
      const filtered = orders.filter((order) => {
        // Apply the search query filter
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseOrderNr = order.ordernr?.toLowerCase() || ""; // Use empty string if ordernr is undefined
      const lowerCaseCustomer = order.customer?.toLowerCase() || ""; // Use empty string if customer is undefined
      const lowerCaseUser = order.employeestory
      ?.map((story) => story.user || "") // Use empty string if user is undefined
      .map((user) => user.toLowerCase())
      .join(" ") || "";

       
        //console.log("lowerCaseSearchQuery:", lowerCaseSearchQuery);
       // console.log("lowerCaseOrderNr:", lowerCaseOrderNr);
      //  console.log("lowerCaseCustomer:", lowerCaseCustomer);
       // console.log("lowerCaseUser:", lowerCaseUser);

  
        const matchesSearchQuery =
          lowerCaseOrderNr.includes(lowerCaseSearchQuery) ||
          lowerCaseCustomer.includes(lowerCaseSearchQuery) ||
          lowerCaseUser.includes(lowerCaseSearchQuery);
  
        // Apply the date filter
        const matchesDate = filterDate
          ? order.employeestory.some((story) => {
              const storyDate = story.mexicanTime;
              if (!storyDate) {
                return false; // Return false if storyDate is undefined
              }
              const parts = storyDate.split(" ");
              const monthName = parts[0];
              const day = parts[1].slice(0, -1); // Remove the comma at the end of the day
              const year = parts[2];
              const monthIndex =
                new Date(Date.parse(`${monthName} 1, 2000`)).getMonth() + 1; // Get the numeric representation of the month
              const formattedDatez = `${monthIndex}/${day}/${year}`;
  
              const filterDateValue = new Date(filterDate).toLocaleDateString(
                "en-US"
              );
  
              return formattedDatez === filterDateValue;
            })
          : true;
  
        return matchesSearchQuery && matchesDate;
      });
      
      setFilteredOrders(filtered);
    }
  };

  const [expandedUsers, setExpandedUsers] = useState([]);

  const toggleUserExpand = (orderIndex, userIndex) => {
    setExpandedUsers((prevState) => {
      const key = `${orderIndex}-${userIndex}`;
      if (prevState.includes(key)) {
        return prevState.filter((item) => item !== key);
      } else {
        return [...prevState, key];
      }
    });
  };

  const [alertDeleteOrder, setAlertDeleteOrder] = useState(null);
  const [isAlertOrderDeleteModalOpen, setIsAlertOrderDeleteModalOpen] = useState(false)

  const openAlertOrderDeleteModal = (order, index) =>{
    setAlertDeleteOrder({order, index});
    setIsAlertOrderDeleteModalOpen(true)
  }

  const closeOpenAlertOrderDeleteModal =() =>{
    setAlertDeleteOrder(null);
    setIsAlertOrderDeleteModalOpen(false)
  }

  const handleDelete = async (orderId) => {
    try {
      // Make a DELETE request to the API to delete the order with the given ID
      await apiWrapper(`order/${orderId}`, "DELETE");

      // Update the orders state by removing the deleted order from the array
      setOrders(orders.filter((order) => order._id !== orderId));
    } catch (error) {
      // Handle error
      console.error("Error deleting order:", error);
    }
  };

  //delete
  useEffect(() => {
    socket.on('deleteOrder', (deletedOrderId) => {
      setOrders((prevOrders) => {
        return prevOrders.filter((order) => order._id !== deletedOrderId);
      });
    });
  
    return () => {
      socket.off('deleteOrder');
    };
  }, []);

 //spinning loading
 useEffect(() => {
  if (orders.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [orders])


 let totalMermaDefected = 0;
 let totalMermaDefectedProduct = 0;
 

  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
    
        <div className="flex   justify-around  rounded-lg ml-5 mr-5 mt-3 ">
      <SearchBarResume key="searchbar" onSearch={handleSearch} />
    </div>
    <div>
      {filteredOrders.map((order, orderIndex) => (
        <div
        className={
          orderIndex % 2 === 0
          ? " flex-col ml-5 mr-5 mb-5  bg-orange-400  text-black rounded-lg"
          : " flex-col ml-5 mr-5 mb-5  bg-orange-500  text-black rounded-lg"
        }
        
        key={`order-${orderIndex}`}
      >
        <div id="orderdashfirstdiv" className=" grid-cols-3 gap-2 mt-3 mb-3  ml-3 mr-3 rounded-lg  md:grid hidden">
          
          <div className=" ml-3  mt-3 ">Pedido nr: 
          <div className=" text-lg font-semibold">{order.ordernr}</div> 
          <div >Cliente:
            <div className=" text-lg font-semibold ">{order.customer}</div> 
            <div className="  ">Preforma:
          <div className=" text-lg font-semibold ">{order.preforma}</div>
          <div className="  ">Modelo:
          <div className=" text-lg font-semibold ">{order.finalproduct}</div>
          <div className=" mb-3  ">Color: {order.finalproductcolor}</div>
          </div></div></div></div>
          
          

          <div id="orderdashcenterdiv" className="mt-3  ">Art para hacer: {order.itemstodo} ud
          <div >Producido: {order.itemsscanned} ud
          <div >Existencia: {order.itemsstock} ud
          <span className="text-gray-900 text-sm"> - E. real:{" "} </span> <span>{order.itemsstock - order.itemsdelivery} </span>
          <span className="text-gray-900 text-sm font-medium ml-2">ud</span>
          </div>
          <div className="  mb-3">Entregados: {order.itemsdelivery} ud</div>
          <div> Preformas producido: {order.preformascanned} ud</div>
          <div> Preformas en Existencia: {order.preformastock} ud
          <span className="text-gray-900 text-sm"> - E. real:{" "} </span>
                        <span>{order.preformastock - order.preformadelivery}</span>
                        <span className="text-gray-900 text-sm font-medium ml-2">ud</span>
          </div>
          <div> Preformas Entregados: {order.preformadelivery} ud</div>
          </div> 
          
          </div>
          
          <div id="orderdashrightdiv" className=" mt-3  ">Paquetes para hacer: {order.totpack} <span> - Paq de {order.pack} ud</span>
          <div >Paquetes Producidos: {order.packscanned}</div>
          <div >Paquetes en Existencia: {order.packitemsstock}
          <span className="text-gray-900 text-sm"> - E. real:{" "} </span> <span>{order.packitemsstock - order.packdelivery} </span>
                        <span className="text-gray-900 text-sm font-medium ml-2">ud</span>
          </div>
          <div className="  mb-3">Paquetes Entregados: {order.packdelivery}</div>
          <div> Paq Preformas producido: {order.preformapackscanned} <span> - Paq de {order.preformapack} ud</span> </div>
          <div> Paq Preformas en Existencia: {order.preformapackitemsstock} 
          <span className="text-gray-900 text-sm"> - E. real:{" "} </span>
                        <span>{order.preformapackitemsstock - order.preformapackdelivery}</span>
                        <span className="text-gray-900 text-sm font-medium ml-2">ud</span>
                        
          
          </div>
          <div> Paq Preformas Entregados: {order.preformapackdelivery} </div>
          <div className="  mt-2 mr-3  text-sm font-medium break-all ">
            
            <span > Preforma Original Order: </span>
            <span className="text-sm">  {order.preformaoriginalorder}

            <span  className="text-gray-800 text-sm font-medium ml-2">de </span><span className="text-sm">{order.preformaclienteorigen}  </span></span>
            </div>
            <div className={`mt-2 mr-3  text-sm font-medium break-all  `}>
                  <span className=" text-sm">Enviado a Prod final:{" "} </span>
                  <span> {order.preformaaddedtoprod}<span className=" text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-800 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackaddedtoprod}  </span></span>
                  </div>
          <div className="  mt-3  mb-4 text-green-800 font-bold">Fecha creación: {formatMexicanTime((order.createdAt))}</div>
          <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            {Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    } = entry;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push({
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    });
    return acc;
  }, {})
).map(([user, entries], userIndex) =>
  entries &&
  entries.length > 0 &&
  entries.map((entry, entryIndex) => (
    <React.Fragment key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}>
      {entry.deliverycompleted !== undefined &&
        entry.deliverycompleted !== null && 
        entry.deliverycompleted === true && (
          <div key={`entry-${orderIndex}-${userIndex}-${entryIndex}`} className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Garraffones Entregados</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
      {entry.preformadeliverycompleted !== undefined &&
        entry.preformadeliverycompleted !== null &&
        entry.preformadeliverycompleted === true && (
          <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Preformas Entregada</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
      </React.Fragment>
  ))
)}
      


            </div>
          <div className="  mt-3  mb-4 text-red-800 font-bold">Eliminar:
          <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         hover:bg-red-800 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> 
                        </button>
           </div>
          </div>

        </div>
        <div id="responsivediv" className="grid grid-cols-1 gap-1 md:hidden text-gray-300 bg-gray-800 mb-3 mt-3 rounded-lg">
        <div className="bg-transparent">
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
              <span  className="text-gray-300 text-sm" > Pedido nr:</span>
              <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
              </div>
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Cliente:</span> 
            <span>{order.customer}</span> 
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Fecha de Creación:</span> 
            <span>{formatMexicanTime((order.createdAt))}</span> 
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            {Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    } = entry;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push({
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    });
    return acc;
  }, {})
).map(([user, entries], userIndex) =>
  entries &&
  entries.length > 0 &&
  entries.map((entry, entryIndex) => (
    <React.Fragment key={`function2-entry-${orderIndex}-${userIndex}-${entryIndex}`}>
      {entry.deliverycompleted !== undefined &&
        entry.deliverycompleted !== null && 
        entry.deliverycompleted === true && (
          <div key={`entry-${orderIndex}-${userIndex}-${entryIndex}`} className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Garraffones Entregados</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
      {entry.preformadeliverycompleted !== undefined &&
        entry.preformadeliverycompleted !== null &&
        entry.preformadeliverycompleted === true && (
          <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Preformas Entregada</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
   </React.Fragment>
  ))
)}
      


            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Eliminar:</span> 
          <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         hover:bg-red-800 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> 
                        </button>
           </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Preforma:</span> 
            <span>{order.preforma}</span> 
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Modelo:</span> 
            <span>{order.finalproduct}</span> 
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Color:</span> 
            <span>{order.finalproductcolor || order.preformacolor}</span> 
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300">Artículos para hacer:</span>
            <span >  {Number.isInteger(order.itemstodo)
                ? order.itemstodo
                : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
            </div>
            
            
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300">Paquete Totales: </span>
            <span className="text-sm">  {order.totpack}
            <span  className="text-gray-300 text-sm font-medium ml-2">de </span><span className="text-sm">{order.pack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>  </span></span>
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Producidos:{" "}</span>
            <span className="text-sm">  {Number.isInteger(order.itemsscanned)
                ? order.itemsscanned
                : Math.round(order.itemsscanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.packscanned}  </span></span>
            </div>
            <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
            <span className="text-gray-300 text-sm"> Existencia:{" "} </span>
            <span>  {Number.isInteger(order.itemsstock)
                ? order.itemsstock
                : Math.round(order.itemsstock
                  * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                  <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.packitemsstock}  </span></span>
            </div>
            <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm"> Existencia Real:{" "} </span>
                  <span> {order.preformastock - order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackitemsstock - order.preformapackdelivery}  </span></span>
                  </div>
            <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap `}>
            <span className="text-gray-300"> Artículos en entrega: </span>
            <span className="text-lg">{order.itemsdelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
            <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.packdelivery}  </span></span>
            </div>
            
            <div className="text-center text-sm font-medium break-all">Preforma</div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            
            <span className="text-gray-300"> Original Order: </span>
            <span className="text-sm">  {order.preformaoriginalorder}

            <span  className="text-gray-300 text-sm font-medium ml-2">de </span><span className="text-sm">{order.preformaclienteorigen}  </span></span>
            </div>
            
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            
            <span className="text-gray-300"> Paquete Totales: </span>
            <span className="text-sm">  {order.preformatotpack}

            <span  className="text-gray-300 text-sm font-medium ml-2">de </span><span className="text-sm">{order.preformapack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>  </span></span>
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Producidos:{" "}</span>
            <span className="text-sm">  {Number.isInteger(order.preformascanned)
                ? order.preformascanned
                : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackscanned}  </span></span>
            </div>
            <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
            <span className="text-gray-300 text-sm"> Existencia:{" "} </span>
            <span>  {Number.isInteger(order.preformastock)
                ? order.preformastock
                : Math.round(order.preformastock
                  * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                  <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackitemsstock}  </span></span>
            </div>
            <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm"> Existencia Real:{" "} </span>
                  <span> {order.preformastock - order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackitemsstock - order.preformapackdelivery}  </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm">Enviado a Prod final:{" "} </span>
                  <span> {order.preformaaddedtoprod}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackaddedtoprod}  </span></span>
                  </div>
            <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap `}>
            <span className="text-gray-300"> En entrega: </span>
            <span className="text-lg">{order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
            <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackdelivery}  </span></span>
            </div>
</div>
<div id="orderdashseconddiv" className=" ml-3 mr-3 mb-4  bg-gray-500  rounded-lg   ">
               <div className="text-center sm:text-left ml-2" > Almacén  </div>
                <div className="ml-2" >Resina necesaria: 
                <span className="ml-2">{Number.isInteger(order.kgneeded)
                      ? order.kgneeded
                      : Math.round(order.kgneeded * 100) / 100} Kg</span>
                </div>
                <div className="ml-2">Resina en producción: 
                <span className="ml-2">{Number.isInteger(order.kgloadedfromalmacen)
                      ? order.kgloadedfromalmacen
                      : Math.round(order.kgloadedfromalmacen * 100) / 100} Kg</span>
                </div>
                <div className="mt-4 ml-2">Producto defectuoso: {order.itemdefected} ud</div>
                <div className="ml-2">Resina para Producto defectuoso: 
                <span className="ml-2">{Number.isInteger(order.itemdefectedkg)
                      ? order.itemdefectedkg
                      : Math.round(order.itemdefectedkg * 100) / 100} Kg</span>
                </div>
                
                <div className="mt-4 ml-2">Preformas defectuoso: {order.preformaitemdefected} ud</div>
                <div className="ml-2">Resina para Preformas defectuoso: 
                <span className="ml-2">{Number.isInteger(order.preformaitemdefectedkg)
                      ? order.preformaitemdefectedkg
                      : Math.round(order.preformaitemdefectedkg * 100) / 100} Kg</span>
                </div>
                <div id="totaldefectedsearchmaindiv">


{Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      preformaitemdefectedkg,
      defectedtypepreforma,
      defectedtypemodel,
      itemdefectedkg,
    } = entry;

    if (!acc[user]) {
      acc[user] = {
        preformaitemdefectedkg: 0,
        defectedtypepreforma: "",
        defectedtypemodel:"",
        itemdefectedkg: 0
      };
    }
//resume merma total in search

    if (defectedtypepreforma === "merma") {
      acc[user].preformaitemdefectedkg += preformaitemdefectedkg || 0;
      acc[user].defectedtypepreforma = defectedtypepreforma;
      // Accumulate the preformaitemdefectedkg for "merma"
      totalMermaDefected += preformaitemdefectedkg || 0;
    }

    if (defectedtypemodel === "merma") {
      acc[user].itemdefectedkg += itemdefectedkg || 0;
      acc[user].defectedtypemodel = defectedtypemodel;
      // Accumulate the preformaitemdefectedkg for "merma"
      totalMermaDefectedProduct += itemdefectedkg || 0;
    }

    return acc;
  }, {})
).map(([user, totals], userIndex) => (
  <div
    className="bg-gray-500 "
    key={`user-summary-${orderIndex}-${userIndex}`}
  > 

  </div>
))}

{/* Display the total defected (merma) here */}
<div className="  font-bold">
 <div className="ml-2"> Desperdicio Preformas Reutilizable: 
  <span className="ml-2"> {Math.round(totalMermaDefected * 100) / 100}</span>
   Kg
   </div>
   <div className="ml-2"> Desperdicio Producto Reutilizable: 
  <span className="ml-2"> {Math.round(totalMermaDefectedProduct * 100) / 100}</span>
   Kg
   </div>
  </div>
</div>
              </div>
              
              <div id="orderdashthirddiv"
                className="grid  gap-2 mb-3 ml-3 mr-3 "
                key={`order-summary-${orderIndex}`}
              >
                {/* Summary for different users */}
                {Object.entries(
                  order.employeestory.reduce((acc, entry) => {
                    const {
                      user,
                      itemsscanned,
                      itemsstock,
                      itemsdelivery,
                      kgloadedfromalmacen,
                      itemdefectedkg,
                      itemdefected,
                      scannedcompleted,
                      stockcompleted,
                      deliverycompleted,
                    
                      packscanned,
                      packitemsstock,
                      packdelivery,

                      preformascanned,
                      preformastock,
                      preformadelivery,
                      preformapackscanned,
                      preformapackitemsstock,
                      preformapackdelivery,
                      preformaitemdefected,
                      preformaitemdefectedkg,
                      preformascannedcompleted,
                      preformastocktohide,
                      preformastockcompleted,
                      preformadeliverycompleted,

                      desperdiciopreformatf,
                      defectedtypepreforma,



                    } = entry;
                    if (!acc[user]) {
                      acc[user] = {
                        itemsscanned: 0,
                        itemsstock: 0,
                        itemsdelivery: 0,
                        kgloadedfromalmacen: 0,
                        itemdefectedkg: 0,
                        itemdefected: 0,
                        scannedcompleted: Boolean,
                        stockcompleted: Boolean,
                        deliverycompleted: Boolean,
                    
                        packscanned: 0,
                      packitemsstock: 0,
                      packdelivery: 0,

                      preformascanned: 0,
                      preformastock: 0,
                      preformadelivery: 0,
                      preformapackscanned: 0,
                      preformapackitemsstock: 0,
                      preformapackdelivery: 0,
                      preformaitemdefected: 0,
                      preformaitemdefectedkg: 0,
                      preformascannedcompleted: Boolean,
                      preformastocktohide: Boolean,
                      preformastockcompleted: Boolean,
                      preformadeliverycompleted: Boolean,

                      desperdiciopreformatf: Boolean,
                      defectedtypepreforma: "",

                      };
                    
                  
                    }
                  
                    acc[user].itemsscanned += itemsscanned || 0;
                    acc[user].itemsstock += itemsstock || 0;
                    acc[user].itemsdelivery += itemsdelivery || 0;
                    acc[user].kgloadedfromalmacen += kgloadedfromalmacen || 0;
                    acc[user].itemdefectedkg += itemdefectedkg || 0;
                    acc[user].itemdefected += itemdefected || 0;
                    acc[user].scannedcompleted = scannedcompleted;
                    acc[user].stockcompleted = stockcompleted;
                    acc[user].deliverycompleted = deliverycompleted;

                    acc[user].packscanned += packscanned || 0;
                    acc[user].packitemsstock += packitemsstock || 0;
                    acc[user].packdelivery += packdelivery || 0;
                    acc[user].preformascanned += preformascanned || 0;
                    acc[user].preformastock += preformastock || 0;
                    acc[user].preformadelivery += preformadelivery || 0;
                    acc[user].preformapackscanned += preformapackscanned || 0;
                    acc[user].preformapackitemsstock += preformapackitemsstock || 0;
                    acc[user].preformapackdelivery += preformapackdelivery || 0;
                    acc[user].preformaitemdefected += preformaitemdefected || 0;
                    acc[user].preformaitemdefectedkg += preformaitemdefectedkg || 0;
                    acc[user].preformascannedcompleted = preformascannedcompleted;
                    acc[user].preformastocktohide = preformastocktohide;
                    acc[user].preformastockcompleted = preformastockcompleted;
                    acc[user].preformadeliverycompleted = preformadeliverycompleted;

                    acc[user].desperdiciopreformatf =desperdiciopreformatf;
                    acc[user].defectedtypepreforma =defectedtypepreforma;
                    
                

                    return acc;
                  }, {})
                ).map(([user, totals], userIndex) => (
                  <div
                    className=" bg-gray-500 rounded-lg"
                    key={`user-summary-${orderIndex}-${userIndex}`}
                  >
                    {user !== undefined && user.trim().length > 0 && (
                      <div className="text-xl text-white ml-3">User: {user}</div>
                    )}

                    {totals.itemsscanned  > 0 && totals.packscanned  > 0 && (
                      <div className="ml-3" key={`scanned-summary-${orderIndex}-${userIndex}`}>
                        Producto producido: {totals.itemsscanned} ud
                       <span className="ml-2">Paq: {totals.packscanned}</span>  
                      </div>
                    )}
                    
                    
                    {totals.itemsstock > 0 && totals.packitemsstock  > 0 && (
                      <div className="ml-3" key={`stock-summary-${orderIndex}-${userIndex}`}>
                        Producto en Existencia: {totals.itemsstock} ud
                        <span className="ml-2">Paq: {totals.packitemsstock} </span>
                      </div>
                    )}
                   

                    {totals.itemsdelivery > 0 && totals.packdelivery > 0 && (
                      <div className="ml-3" key={`delivery-summary-${orderIndex}-${userIndex}`}>
                        Producto Entregados: {totals.itemsdelivery} ud
                        <span className="ml-2">Paq: {totals.packdelivery} </span>
                      </div>
                    )}

                    

                    {totals.kgloadedfromalmacen > 0 && (
                      <div className="ml-3" key={`resina-summary-${orderIndex}-${userIndex}`}>
                        Resina en producción:
                        <span className="ml-2">{Number.isInteger(totals.kgloadedfromalmacen)
                      ? totals.kgloadedfromalmacen
                      : Math.round(totals.kgloadedfromalmacen * 100) / 100} Kg</span>
                         
                      </div>
                    )}
                    {totals.itemdefectedkg > 0 && (
                      <div className="ml-3"
                        key={`resinadefected-summary-${orderIndex}-${userIndex}`}
                      >
                        Resina para Producto defectuoso:{" "}
                        
                        <span className="ml-2">{Number.isInteger(totals.itemdefectedkg)
                      ? totals.itemdefectedkg
                      : Math.round(totals.itemdefectedkg * 100) / 100}</span> Kg
                      </div>
                    )}
                    {totals.itemdefected > 0 && (
                      <div className="ml-3"
                        key={`itemsdefected-summary-${orderIndex}-${userIndex}`}
                      >
                        Producto defectuoso: {totals.itemdefected} ud
                      </div>
                    )}
                    {totals.scannedcompleted === true && (
                      <div className="ml-3"
                        key={`scannedcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        Phase1 Producción completada: {totals.scannedcompleted.toString()}
                      </div>
                    )}
                    {totals.stockcompleted === true && (
                      <div className="ml-3"
                        key={`stockcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        Phase2 Existencia completada: {totals.stockcompleted.toString()}
                      </div>
                    )}
                    {totals.deliverycompleted === true && (
                      <div className="ml-3"
                        key={`deliverycompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        Phase3 Entrega completada:{" "}
                        {totals.deliverycompleted.toString()}
                      </div>
                     )}

      
                      {totals.preformascanned  > 0 && totals.preformapackscanned  > 0 &&(
                      <div className="ml-3" key={`preformascanned-summary-${orderIndex}-${userIndex}`}>
                        Preformas producido: {totals.preformascanned} ud
                        <span className="ml-2"> Paq: {totals.preformapackscanned}</span>
                      </div>
                    )}
                     
                     
                    {totals.preformastock > 0 && totals.preformapackitemsstock  > 0 && (
                      <div className="ml-3" key={`preformastock-summary-${orderIndex}-${userIndex}`}>
                        Preformas en Existencia: {totals.preformastock} ud
                        <span className="ml-2"> Paq: {totals.preformapackitemsstock}</span>
                      </div>
                    )}
                     

                    {totals.preformadelivery > 0 && totals.preformapackdelivery > 0 && (
                      <div className="ml-3" key={`preformadelivery-summary-${orderIndex}-${userIndex}`}>
                        Preformas Entregados: {totals.preformadelivery}
                        <span className="ml-2"> Paq: {totals.preformapackdelivery}</span>
                      </div>
                    )}

                 
                    {totals.preformaitemdefectedkg >0 && (
                      <div className="ml-3"
                        key={`preformaitemdefectedkg-summary-${orderIndex}-${userIndex}`}
                      >
                        Resina para preforma defectuoso:{" "}
                        
                        <span className="ml-2">{Number.isInteger(totals.preformaitemdefectedkg)
                      ? totals.preformaitemdefectedkg
                      : Math.round(totals.preformaitemdefectedkg * 100) / 100}</span> Kg
                      </div>
                    )}
                    {totals.preformaitemdefected > 0 && (
                      <div className="ml-3"
                        key={`preformaitemdefected-summary-${orderIndex}-${userIndex}`}
                      >
                        Preformas defectuoso: {totals.preformaitemdefected} ud
                      </div>
                    )}
                    {totals.preformascannedcompleted === true && (
                      <div className="ml-3"
                        key={`preformascannedcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        phase0 Preformas en Producción: {totals.preformascannedcompleted.toString()}
                      </div>
                    )}
                     {totals.preformastocktohide === true && (
                      <div className="ml-3"
                        key={`preformastocktohide-summary-${orderIndex}-${userIndex}`}
                      >
                        phase1 Preformas Producción completada: {totals.preformastocktohide.toString()}
                      </div>
                    )}
                    {totals.preformastockcompleted === true && (
                      <div className="ml-3"
                        key={`preformastockcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        phase2 Preformas en Existencia completada: {totals.preformastockcompleted.toString()}
                      </div>
                    )}
                    {totals.preformadeliverycompleted === true && (
                      <div className="ml-3"
                        key={`preformadeliverycompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        phase3 Preformas Entregada completada:{" "}
                        {totals.preformadeliverycompleted.toString()}
                      </div>
                    )}


                  </div>
                ))}
<div>


{Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      preformaitemdefectedkg,
      defectedtypepreforma,
      itemdefectedkg,
      defectedtypemodel,
    } = entry;

    if (!acc[user]) {
      acc[user] = {
        preformaitemdefectedkg: 0,
        defectedtypepreforma: "",
        itemdefectedkg: 0,
        defectedtypemodel: 0,
      };
    }

    if (defectedtypepreforma === "merma") {
      acc[user].preformaitemdefectedkg += preformaitemdefectedkg || 0;
      acc[user].defectedtypepreforma = defectedtypepreforma;
      }

      if (defectedtypemodel === "merma") {
        acc[user].itemdefectedkg += itemdefectedkg || 0;
        acc[user].defectedtypemodel = defectedtypemodel;
        }

    return acc;
  }, {})
).map(([user, totals], userIndex) => (
  <div
    className="bg-gray-500 "
    key={`user-summary-${orderIndex}-${userIndex}`}
  > 
{user !== undefined && user.trim().length > 0 && (totals.defectedtypepreforma === "merma" || totals.defectedtypemodel === "merma") && (
      <div className="text-xl text-white ml-3">User: {user}</div>
    )}

{(totals.defectedtypepreforma === "merma" || totals.preformaitemdefectedkg  >0) &&
  (
      
      <div className="ml-3">Desperdicio Preformas Reutilizable: {totals.preformaitemdefectedkg} Kg
      </div>
     )}
      {(totals.defectedtypepreforma === "merma" || totals.itemdefectedkg >0) &&( 
      <div className="ml-3"> Desperdicio Producto Reutilizable: {totals.itemdefectedkg} Kg</div>
      )}
    
   
  </div>
))}

</div>

            
              </div>

              {/* Nesting the detailed items per user */}
              {Object.entries(
                order.employeestory.reduce((acc, entry) => {
                  const {
                    user,
                    itemsscanned,
                    itemsstock,
                    itemsdelivery,
                    kgloadedfromalmacen,
                    itemdefectedkg,
                    itemdefected,
                    scannedcompleted,
                    stockcompleted,
                    deliverycompleted,
                    mexicanTime,

                    packscanned,
                    packitemsstock,
                    packdelivery,

                    preformascanned,
                    preformastock,
                    preformadelivery,
                    preformapackscanned,
                    preformapackitemsstock,
                    preformapackdelivery,
                    preformaitemdefected,
                    preformaitemdefectedkg,
                    preformascannedcompleted,
                    preformastocktohide,
                    preformastockcompleted,
                    preformadeliverycompleted,


                  } = entry;
                  if (!acc[user]) {
                    acc[user] = [];
                  }
                  acc[user].push({
                    itemsscanned,
                    itemsstock,
                    itemsdelivery,
                    kgloadedfromalmacen,
                    itemdefectedkg,
                    itemdefected,
                    scannedcompleted,
                    stockcompleted,
                    deliverycompleted,
                    mexicanTime,

                    packscanned,
                    packitemsstock,
                    packdelivery,

                    preformascanned,
                    preformastock,
                    preformadelivery,
                    preformapackscanned,
                    preformapackitemsstock,
                    preformapackdelivery,
                    preformaitemdefected,
                    preformaitemdefectedkg,
                    preformascannedcompleted,
                    preformastocktohide,
                    preformastockcompleted,
                    preformadeliverycompleted,
                  });
                  return acc;
                }, {})
              ).map(([user, entries], userIndex) => (
                <div id="detaileddiv" key={`entry-${orderIndex}-${userIndex}`} >
                  <div className="flex items-center bg-sky-800 ml-3 mr-3 mb-3 rounded-lg">
                    <button
                      className="text-emerald-400 ml-3 mr-3 focus:outline-none text-3xl "
                      onClick={() => toggleUserExpand(orderIndex, userIndex)}
                    >
                      {expandedUsers.includes(`${orderIndex}-${userIndex}`)
                        ? "-"
                        : "+"}
                    </button>
                    {user && user.trim().length > 0 && (
                      <div className="text-xl text-white ">
                        User: {user}
                        <div>Order Number: {order.ordernr}</div>
                      </div>
                    )}
                  </div>
                  {expandedUsers.includes(`${orderIndex}-${userIndex}`) && (
                    <div className="grid grid-cols-3 gap-4 bg-gray-700 ">
                      <div id="prefscanned" className="border border-gray-500 bg-slate-800 p-4 ml-3 mt-3 rounded-lg">
                        {/* PreformaItemsscanned column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div 
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformascanned !== undefined &&
                                entry.preformascanned !== null && (
                                  <div className="text-emerald-400 ">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Preformas Producido: {entry.preformascanned} ud
                                      <span className="ml-2">Paq:{entry.preformapackscanned}</span>
                                    </div>
                                    <div className="text-xs text-emerald-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="prefstock" className="border border-gray-500 bg-slate-800 p-4  mt-3 rounded-lg">
                        {/* PreformaItemsstock column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformastock !== undefined &&
                                entry.preformastock !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Preformas en Extistencia: {entry.preformastock} ud
                                      <span className="ml-2">Paq:{entry.preformapackitemsstock}</span>
                                      </div>
                                      <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div  id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3  rounded-lg">
                        {/* PreformaItemsdelivered column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformadelivery !== undefined &&
                                entry.preformadelivery !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Pref Entregados: {entry.preformadelivery} ud
                                    <span>Paq:{entry.preformapackdelivery} </span>
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="preformaresinafordefecteditems" className="border border-gray-500 bg-slate-800 p-4   rounded-lg">
                        {/* Resina Needed and taken for defected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformaitemdefectedkg !== undefined &&
                                entry.preformaitemdefectedkg !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Resina necesaria para preforma defectuoso:{" "}
                                      {entry.preformaitemdefectedkg} Kg
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="preformaitemsdefected" className="border border-gray-500 bg-slate-800 p-4 mr-3  rounded-lg">
                        {/* PreformaDefected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformaitemdefected !== undefined &&
                                entry.preformaitemdefected !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Preformas defectuso: {entry.preformaitemdefected} ud
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="preformaphaseitems" className="border border-gray-500 bg-slate-800 p-4 ml-3 mb-3 rounded-lg">
                        {/* PrefomraPhases column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformascannedcompleted !== undefined &&
                                entry.preformascannedcompleted !== null &&
                                entry.preformascannedcompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                    Phase0  Preformas en Producción:{" "}
                                      {entry.preformascannedcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                                  {entry.preformastocktohide !== undefined &&
                                entry.preformastocktohide !== null &&
                                entry.preformastocktohide === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                    Phase1  Producción Preformas completada:{" "}
                                      {entry.preformastocktohide.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.preformastockcompleted !== undefined &&
                                entry.preformastockcompleted !== null &&
                                entry.preformastockcompleted === true && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase2 Existencia Preformas completada:{" "}
                                      {entry.preformastockcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.preformadeliverycompleted !== undefined &&
                                entry.preformadeliverycompleted !== null &&
                                entry.preformadeliverycompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase3 Entrega Preformas completada:{" "}
                                      {entry.preformadeliverycompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="itemscanned" className="border border-gray-500 bg-slate-800 p-4 ml-3 mt-3 rounded-lg">
                        {/* Itemsscanned column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div 
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemsscanned !== undefined &&
                                entry.itemsscanned !== null && (
                                  <div className="text-emerald-400 ">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Prod Producido: {entry.itemsscanned} ud
                                      <span className="ml-2">Paq:{entry.packscanned}</span>
                                    </div>
                                    <div className="text-xs text-emerald-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="itemstock" className="border border-gray-500 bg-slate-800 p-4  mt-3 rounded-lg">
                        {/* Itemsstock column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemsstock !== undefined &&
                                entry.itemsstock !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Prod en Extistencia: {entry.itemsstock} ud
                                      <span className="ml-2">Paq:{entry.packitemsstock}</span>
                                      </div>
                                      <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div  id="itemdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3  rounded-lg">
                        {/* Itemsdelivered column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemsdelivery !== undefined &&
                                entry.itemsdelivery !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Prod Entregados: {entry.itemsdelivery} ud
                                    <span>Paq:{entry.packdelivery} </span>
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="resinaneedandtakenfromalmacen" className="border border-gray-500  bg-slate-800 p-4 ml-3  rounded-lg">
                        {/* Resina Needed and taken from almacen column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.kgloadedfromalmacen !== undefined &&
                                entry.kgloadedfromalmacen !== null && (
                                  <div className="text-blue-500">
                                   <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Resina de almacen en producción:{" "}
                                      {entry.kgloadedfromalmacen} Kg
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="resinafordefecteditems" className="border border-gray-500 bg-slate-800 p-4   rounded-lg">
                        {/* Resina Needed and taken for defected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemdefectedkg !== undefined &&
                                entry.itemdefectedkg !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Resina necesaria para producto defectuoso:{" "}
                                      {entry.itemdefectedkg} Kg
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="itemsdefected" className="border border-gray-500 bg-slate-800 p-4 mr-3  rounded-lg">
                        {/* Defected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemdefected !== undefined &&
                                entry.itemdefected !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Producto defectuso: {entry.itemdefected} ud
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="phaseitems" className="border border-gray-500 bg-slate-800 p-4 ml-3 mb-3 rounded-lg">
                        {/* Phases column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.scannedcompleted !== undefined &&
                                entry.scannedcompleted !== null &&
                                entry.scannedcompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                    Phase1  Producción completada:{" "}
                                      {entry.scannedcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.stockcompleted !== undefined &&
                                entry.stockcompleted !== null &&
                                entry.stockcompleted === true && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase2 Existencia completada:{" "}
                                      {entry.stockcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.deliverycompleted !== undefined &&
                                entry.deliverycompleted !== null && 
                                entry.deliverycompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase3 Entrega completada:{" "}
                                      {entry.deliverycompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="mb-1 text-transparent"> .</div>
            </div>
     
     

      ))}
      </div> 
{/* // start dashboard // */}

{orders.length === 0 || filteredOrders.length > 0 ? (
  loading ? (
    <div className="border rounded-lg bg-gray-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className=" text-white">-</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-gray-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)
  ) : (
    orders
          .slice()
          .reverse()

          .map((order, orderIndex) => 
        
          (
         
            <div
              className="flex flex-col ml-5 mr-5  bg-black  text-white rounded-lg "
              
              key={`order-${orderIndex}`}
            >
              <div id="orderdashfirstdiv" className=" grid-cols-3 gap-2 mt-3 mb-3 bg-sky-900 ml-3 mr-3 rounded-lg  md:grid hidden">
                <div className=" ml-3  mt-3 ">Pedido nr: 
                <div className=" text-lg font-semibold">{order.ordernr}</div> 
                <div >Cliente:
                  <div className=" text-lg font-semibold ">{order.customer}</div> 
                  <div className="  ">Preforma:
                <div className=" text-lg font-semibold ">{order.preforma}</div>
                <div className="  ">Modelo:
                <div className=" text-lg font-semibold ">{order.finalproduct}</div>
                <div className=" mb-3  ">Color: {order.finalproductcolor}</div>
                </div></div></div></div>
                
                

                <div id="orderdashcenterdiv" className="mt-3  ">Art para hacer: {order.itemstodo} ud
                <div >Producido: {order.itemsscanned} ud
                <div >Existencia: {order.itemsstock} ud
                <span className=" text-sm"> - E. real:{" "} </span> <span>{order.itemsstock - order.itemsdelivery} </span>
                        <span className=" text-sm font-medium ml-2">ud</span>
                </div>
                <div className="  mb-3">Entregados: {order.itemsdelivery} ud</div>
                <div> Preformas producido: {order.preformascanned} ud</div>
                <div> Preformas en Existencia: {order.preformastock} ud
                <span className=" text-sm"> - E. real:{" "} </span>
                        <span>{order.preformastock - order.preformadelivery}</span>
                        <span className=" text-sm font-medium ml-2">ud</span>
                
                </div>
                <div> Preformas Entregados: {order.preformadelivery} ud</div>
                </div> 
                
                </div>
                
                <div id="orderdashrightdiv" className=" mt-3  "><span className="text-white font-semibold ">Paquetes para hacer: {order.totpack}  - Paq de {order.pack} ud</span>
                <div >Paquetes Producidos: {order.packscanned}</div>
                <div >Paquetes en Existencia: {order.packitemsstock}
                <span className=" text-sm"> - E. real:{" "} </span> <span>{order.packitemsstock - order.packdelivery} </span>
                        <span className=" text-sm font-medium ml-2">ud</span>
                </div>
                <div className="  mb-3">Paquetes Entregados: {order.packdelivery}</div>
                <div > Paq Preformas producido: {order.preformapackscanned} <span> - Paq de {order.preformapack} ud</span> </div>
                <div> Paq Preformas en Existencia: {order.preformapackitemsstock} 
                <span className=" text-sm"> - E. real:{" "} </span>
                        <span>{order.preformapackitemsstock - order.preformapackdelivery}</span>
                        <span className=" text-sm font-medium ml-2">ud</span>
                </div>
                <div> Paq Preformas Entregados: {order.preformapackdelivery} </div>
                <div className="  mt-2 mr-3  text-sm font-medium break-all ">
            
            <span > Preforma Original Order: </span>
            <span className="text-sm">  {order.preformaoriginalorder}

            <span  className=" text-sm font-medium ml-2">de </span><span className="text-sm">{order.preformaclienteorigen}  </span></span>
            </div>
            <div className={`mt-2 mr-3  text-sm font-medium break-all  `}>
                  <span className=" text-sm">Enviado a Prod final:{" "} </span>
                  <span> {order.preformaaddedtoprod}<span className=" text-sm font-medium ml-2">ud</span>
                        <span  className=" text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackaddedtoprod}  </span></span>
                  </div>
                <div className=" mt-3 font-bold mb-4 text-emerald-300">Fecha creación: {formatMexicanTime((order.createdAt))}</div>
                <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            {Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    } = entry;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push({
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    });
    return acc;
  }, {})
).map(([user, entries], userIndex) =>
  entries &&
  entries.length > 0 &&
  entries.map((entry, entryIndex) => (
    <React.Fragment key={`function3-entry-${orderIndex}-${userIndex}-${entryIndex}`}>
      {entry.deliverycompleted !== undefined &&
        entry.deliverycompleted !== null && 
        entry.deliverycompleted === true && (
          <div key={`entry-${orderIndex}-${userIndex}-${entryIndex}`} className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Garraffones Entregados</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
      {entry.preformadeliverycompleted !== undefined &&
        entry.preformadeliverycompleted !== null &&
        entry.preformadeliverycompleted === true && (
          <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Preformas Entregada</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
    </React.Fragment>
  ))
)}
      


            </div>

                <div className="  mt-3  mb-4 text-red-500 font-bold">Eliminar:
          <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         hover:bg-red-800 focus:ring-gray-800 ml-3 mr-3 "
                        >
                          <DeleteOutlineIcon /> 
                        </button>
           </div>
                </div>

              </div>
              <div id="responsivediv" className="grid grid-cols-1 gap-1 md:hidden text-gray-300 bg-black mb-3 rounded-lg">
              <div className="bg-transparent">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Fecha de Creación:</span> 
            <span>{formatMexicanTime((order.createdAt))}</span> 
            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            {Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    } = entry;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push({
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    });
    return acc;
  }, {})
).map(([user, entries], userIndex) =>
  entries &&
  entries.length > 0 &&
  entries.map((entry, entryIndex) => (
    <React.Fragment key={`function4-entry-${orderIndex}-${userIndex}-${entryIndex}`}>
      {entry.deliverycompleted !== undefined &&
        entry.deliverycompleted !== null && 
        entry.deliverycompleted === true && (
          <div key={`entry-${orderIndex}-${userIndex}-${entryIndex}`} className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Garraffones Entregados</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
      {entry.preformadeliverycompleted !== undefined &&
        entry.preformadeliverycompleted !== null &&
        entry.preformadeliverycompleted === true && (
          <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Preformas Entregada</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
    </React.Fragment>
  ))
)}
      


            </div>
            <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            <span className="text-gray-300"> Eliminar:</span> 
          <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                         hover:bg-red-800 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> 
                        </button>
           </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Preforma:</span> 
                  <span>{order.preforma}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Modelo:</span> 
                  <span>{order.finalproduct}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Color:</span> 
                  <span>{order.finalproductcolor || order.preformacolor}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Artículos para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  
                  
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquete Totales: </span>
                  <span className="text-sm">  {order.totpack}
                  <span  className="text-gray-300 text-sm font-medium ml-2">de </span><span className="text-sm">{order.pack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>  </span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Producidos:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.itemsscanned)
                      ? order.itemsscanned
                      : Math.round(order.itemsscanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                      <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.packscanned}  </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm"> Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.itemsstock)
                      ? order.itemsstock
                      : Math.round(order.itemsstock
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.packitemsstock}  </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3  `}>
                  <span className="text-gray-300 text-sm "> Existencia Real:{" "} </span>
                  <span>{order.itemsstock - order.itemsdelivery} <span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm"> {order.packitemsstock - order.packdelivery} </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap `}>
                  <span className="text-gray-300"> Artículos en entrega: </span>
                  <span className="text-lg">{order.itemsdelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                  <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.packdelivery}  </span></span>
                  </div>
                  <div className="text-center text-sm font-medium break-all">Preforma</div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
            
            <span className="text-gray-300"> Original Order: </span>
            <span className="text-sm">  {order.preformaoriginalorder}

            <span  className="text-gray-300 text-sm font-medium ml-2">de </span><span className="text-sm">{order.preformaclienteorigen}  </span></span>
            </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Paquete Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}
                  <span  className="text-gray-300 text-sm font-medium ml-2">de </span><span className="text-sm">{order.preformapack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>  </span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Producidos:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                      <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackscanned}  </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm"> Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.preformastock)
                      ? order.preformastock
                      : Math.round(order.preformastock
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackitemsstock}  </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm"> Existencia Real:{" "} </span>
                  <span> {order.preformastock - order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackitemsstock - order.preformapackdelivery}  </span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                  <span className="text-gray-300 text-sm">Enviado a Prod final:{" "} </span>
                  <span> {order.preformaaddedtoprod}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackaddedtoprod}  </span></span>
                  </div>

                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap `}>
                  <span className="text-gray-300"> En entrega: </span>
                  <span className="text-lg">{order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                  <span  className="text-gray-300 text-sm font-medium ml-2">Paq </span><span className="text-sm">{order.preformapackdelivery}  </span></span>
                  </div>
</div>


              <div id="orderdashseconddiv" className=" ml-3 mr-3 mb-4  bg-gray-700  rounded-lg   ">
               <div className="text-center sm:text-left ml-2" > Almacen </div>
                <div className="ml-2" >Resina necesaria: 
                <span className="ml-2">{Number.isInteger(order.kgneeded)
                      ? order.kgneeded
                      : Math.round(order.kgneeded * 100) / 100} Kg</span>
                </div>
                <div className="ml-2">Resina en producción: 
                <span className="ml-2">{Number.isInteger(order.kgloadedfromalmacen)
                      ? order.kgloadedfromalmacen
                      : Math.round(order.kgloadedfromalmacen * 100) / 100} Kg</span>
                </div>
                <div className="mt-4 ml-2">Producto defectuoso: {order.itemdefected} ud</div>
                <div className="ml-2">Resina para Producto defectuoso: 
                <span className="ml-2">{Number.isInteger(order.itemdefectedkg)
                      ? order.itemdefectedkg
                      : Math.round(order.itemdefectedkg * 100) / 100} Kg</span>
                </div>
                
                <div className="mt-4 ml-2">Preformas defectuoso: {order.preformaitemdefected} ud</div>
                <div className="ml-2">Resina para Preformas defectuoso: 
                <span className="ml-2">{Number.isInteger(order.preformaitemdefectedkg)
                      ? order.preformaitemdefectedkg
                      : Math.round(order.preformaitemdefectedkg * 100) / 100} Kg</span>
                </div>
 
              </div>
              
              <div id="orderdashthirddiv"
                className="grid  gap-2 mb-3 ml-3 mr-3 "
                key={`order-summary-${orderIndex}`}
              >
                {/* Summary for different users */}
                {Object.entries(
                  order.employeestory.reduce((acc, entry) => {
                    const {
                      user,
                      itemsscanned,
                      itemsstock,
                      itemsdelivery,
                      kgloadedfromalmacen,
                      itemdefectedkg,
                      itemdefected,
                      scannedcompleted,
                      stockcompleted,
                      deliverycompleted,
                    
                      packscanned,
                      packitemsstock,
                      packdelivery,

                      preformascanned,
                      preformastock,
                      preformadelivery,
                      preformapackscanned,
                      preformapackitemsstock,
                      preformapackdelivery,
                      preformaitemdefected,
                      preformaitemdefectedkg,
                      preformascannedcompleted,
                      preformastocktohide,
                      preformastockcompleted,
                      preformadeliverycompleted,

                    } = entry;
                    if (!acc[user]) {
                      acc[user] = {
                        itemsscanned: 0,
                        itemsstock: 0,
                        itemsdelivery: 0,
                        kgloadedfromalmacen: 0,
                        itemdefectedkg: 0,
                        itemdefected: 0,
                        scannedcompleted: Boolean,
                        stockcompleted: Boolean,
                        deliverycompleted: Boolean,
                    
                        packscanned: 0,
                      packitemsstock: 0,
                      packdelivery: 0,

                      preformascanned: 0,
                      preformastock: 0,
                      preformadelivery: 0,
                      preformapackscanned: 0,
                      preformapackitemsstock: 0,
                      preformapackdelivery: 0,
                      preformaitemdefected: 0,
                      preformaitemdefectedkg: 0,
                      preformascannedcompleted: Boolean,
                      preformastocktohide: Boolean,
                      preformastockcompleted: Boolean,
                      preformadeliverycompleted: Boolean,

                      };
                    }
                    acc[user].itemsscanned += itemsscanned || 0;
                    acc[user].itemsstock += itemsstock || 0;
                    acc[user].itemsdelivery += itemsdelivery || 0;
                    acc[user].kgloadedfromalmacen += kgloadedfromalmacen || 0;
                    acc[user].itemdefectedkg += itemdefectedkg || 0;
                    acc[user].itemdefected += itemdefected || 0;
                    acc[user].scannedcompleted = scannedcompleted;
                    acc[user].stockcompleted = stockcompleted;
                    acc[user].deliverycompleted = deliverycompleted;

                    acc[user].packscanned += packscanned || 0;
                    acc[user].packitemsstock += packitemsstock || 0;
                    acc[user].packdelivery += packdelivery || 0;
                    acc[user].preformascanned += preformascanned || 0;
                    acc[user].preformastock += preformastock || 0;
                    acc[user].preformadelivery += preformadelivery || 0;
                    acc[user].preformapackscanned += preformapackscanned || 0;
                    acc[user].preformapackitemsstock += preformapackitemsstock || 0;
                    acc[user].preformapackdelivery += preformapackdelivery || 0;
                    acc[user].preformaitemdefected += preformaitemdefected || 0;
                    acc[user].preformaitemdefectedkg += preformaitemdefectedkg || 0;
                    acc[user].preformascannedcompleted = preformascannedcompleted;
                    acc[user].preformastocktohide = preformastocktohide;
                    acc[user].preformastockcompleted = preformastockcompleted;
                    acc[user].preformadeliverycompleted = preformadeliverycompleted;


                    return acc;
                  }, {})
                ).map(([user, totals], userIndex) => (
                  <div
                    className=" bg-gray-600 rounded-lg"
                    key={`user-summary-${orderIndex}-${userIndex}`}
                  >
                    {user !== undefined && user.trim().length > 0 && (
                      <div className="text-xl text-white ml-3">User: {user}</div>
                    )}
                    {totals.itemsscanned  > 0 && totals.packscanned  > 0 && (
                      <div className="ml-3" key={`scanned-summary-${orderIndex}-${userIndex}`}>
                        Producto producido: {totals.itemsscanned} ud
                       <span className="ml-2">Paq: {totals.packscanned}</span>  
                      </div>
                    )}
                    
                    
                    {totals.itemsstock > 0 && totals.packitemsstock  > 0 && (
                      <div className="ml-3" key={`stock-summary-${orderIndex}-${userIndex}`}>
                        Producto en Existencia: {totals.itemsstock} ud
                        <span className="ml-2">Paq: {totals.packitemsstock} </span>
                      </div>
                    )}
                   

                    {totals.itemsdelivery > 0 && totals.packdelivery > 0 && (
                      <div className="ml-3" key={`delivery-summary-${orderIndex}-${userIndex}`}>
                        Producto Entregados: {totals.itemsdelivery} ud
                        <span className="ml-2">Paq: {totals.packdelivery} </span>
                      </div>
                    )}

                    

                    {totals.kgloadedfromalmacen > 0 && (
                      <div className="ml-3" key={`resina-summary-${orderIndex}-${userIndex}`}>
                        Resina en producción:
                        <span className="ml-2">{Number.isInteger(totals.kgloadedfromalmacen)
                      ? totals.kgloadedfromalmacen
                      : Math.round(totals.kgloadedfromalmacen * 100) / 100} Kg</span>
                         
                      </div>
                    )}
                    {totals.itemdefectedkg > 0 && (
                      <div className="ml-3"
                        key={`resinadefected-summary-${orderIndex}-${userIndex}`}
                      >
                        Resina para Producto defectuoso:{" "}
                        
                        <span className="ml-2">{Number.isInteger(totals.itemdefectedkg)
                      ? totals.itemdefectedkg
                      : Math.round(totals.itemdefectedkg * 100) / 100}</span> Kg
                      </div>
                    )}
                    {totals.itemdefected > 0 && (
                      <div className="ml-3"
                        key={`itemsdefected-summary-${orderIndex}-${userIndex}`}
                      >
                        Producto defectuoso: {totals.itemdefected} ud
                      </div>
                    )}
                    {totals.scannedcompleted === true && (
                      <div className="ml-3"
                        key={`scannedcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        Phase1 Producción completada: {totals.scannedcompleted.toString()}
                      </div>
                    )}
                    {totals.stockcompleted === true && (
                      <div className="ml-3"
                        key={`stockcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        Phase2 Existencia completada: {totals.stockcompleted.toString()}
                      </div>
                    )}
                    {totals.deliverycompleted === true && (
                      <div className="ml-3"
                        key={`deliverycompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        Phase3 Entrega completada:{" "}
                        {totals.deliverycompleted.toString()}
                      </div>
                     )}

      
                      {totals.preformascanned  > 0 && totals.preformapackscanned  > 0 &&(
                      <div className="ml-3" key={`preformascanned-summary-${orderIndex}-${userIndex}`}>
                        Preformas producido: {totals.preformascanned} ud
                        <span className="ml-2"> Paq: {totals.preformapackscanned}</span>
                      </div>
                    )}
                     
                     
                    {totals.preformastock > 0 && totals.preformapackitemsstock  > 0 && (
                      <div className="ml-3" key={`preformastock-summary-${orderIndex}-${userIndex}`}>
                        Preformas en Existencia: {totals.preformastock} ud
                        <span className="ml-2"> Paq: {totals.preformapackitemsstock}</span>
                      </div>
                    )}
                     

                    {totals.preformadelivery > 0 && totals.preformapackdelivery > 0 && (
                      <div className="ml-3" key={`preformadelivery-summary-${orderIndex}-${userIndex}`}>
                        Preformas Entregados: {totals.preformadelivery}
                        <span className="ml-2"> Paq: {totals.preformapackdelivery}</span>
                      </div>
                    )}

                     

                 
                    {totals.preformaitemdefectedkg > 0 && (
                      <div className="ml-3"
                        key={`preformaitemdefectedkg-summary-${orderIndex}-${userIndex}`}
                      >
                        Resina para preforma defectuoso:{" "}
                        
                        <span className="ml-2">{Number.isInteger(totals.preformaitemdefectedkg)
                      ? totals.preformaitemdefectedkg
                      : Math.round(totals.preformaitemdefectedkg * 100) / 100}</span> Kg
                      </div>
                    )}
                    {totals.preformaitemdefected > 0 && (
                      <div className="ml-3"
                        key={`preformaitemdefected-summary-${orderIndex}-${userIndex}`}
                      >
                        Preformas defectuoso: {totals.preformaitemdefected} ud
                      </div>
                    )}
                    {totals.preformascannedcompleted === true && (
                      <div className="ml-3"
                        key={`preformascannedcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        phase0 Preformas en Producción: {totals.preformascannedcompleted.toString()}
                      </div>
                    )}
                    {totals.preformastocktohide === true && (
                      <div className="ml-3"
                        key={`preformastocktohide-summary-${orderIndex}-${userIndex}`}
                      >
                        phase1 Preformas Producida completada: {totals.preformastocktohide.toString()}
                      </div>
                    )}
                    {totals.preformastockcompleted === true && (
                      <div className="ml-3"
                        key={`preformastockcompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        phase2 Preformas en Existencia completada: {totals.preformastockcompleted.toString()}
                      </div>
                    )}
                    {totals.preformadeliverycompleted === true && (
                      <div className="ml-3"
                        key={`preformadeliverycompleted-summary-${orderIndex}-${userIndex}`}
                      >
                        phase3 Preformas Entregada completada:{" "}
                        {totals.preformadeliverycompleted.toString()}
                      </div>
                    )}



                  </div>
                ))}

<div>


{Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      preformaitemdefectedkg,
      defectedtypepreforma,
      itemdefectedkg,
      defectedtypemodel,
    } = entry;

    if (!acc[user]) {
      acc[user] = {
        preformaitemdefectedkg: 0,
        defectedtypepreforma: "",
        itemdefectedkg: 0,
        defectedtypemodel: 0,
      };
    }

    if (defectedtypepreforma === "merma") {
      acc[user].preformaitemdefectedkg += preformaitemdefectedkg || 0;
      acc[user].defectedtypepreforma = defectedtypepreforma;
      }

      if (defectedtypemodel === "merma") {
        acc[user].itemdefectedkg += itemdefectedkg || 0;
        acc[user].defectedtypemodel = defectedtypemodel;
        }

    return acc;
  }, {})
).map(([user, totals], userIndex) => (
  <div
    className="bg-gray-500 "
    key={`user-summary-${orderIndex}-${userIndex}`}
  > 
{user !== undefined && user.trim().length > 0 && (totals.defectedtypepreforma === "merma" || totals.defectedtypemodel === "merma") && (
      <div className="text-xl text-white ml-3">User: {user}</div>
    )}

{(totals.defectedtypepreforma === "merma" || totals.preformaitemdefectedkg  >0) &&
  (
      
      <div className="ml-3">Desperdicio Preformas Reutilizable: {totals.preformaitemdefectedkg} Kg
      </div>
     )}
      {(totals.defectedtypepreforma === "merma" || totals.itemdefectedkg >0) &&( 
      <div className="ml-3"> Desperdicio Producto Reutilizable: {totals.itemdefectedkg} Kg</div>
      )}
    
   
  </div>
))}
</div>


                
              </div>

              {/* Nesting the detailed items per user */}
              {Object.entries(
                order.employeestory.reduce((acc, entry) => {
                  const {
                    user,
                    itemsscanned,
                    itemsstock,
                    itemsdelivery,
                    kgloadedfromalmacen,
                    itemdefectedkg,
                    itemdefected,
                    scannedcompleted,
                    stockcompleted,
                    deliverycompleted,
                    mexicanTime,

                    packscanned,
                    packitemsstock,
                    packdelivery,

                    preformascanned,
                    preformastock,
                    preformadelivery,
                    preformapackscanned,
                    preformapackitemsstock,
                    preformapackdelivery,
                    preformaitemdefected,
                    preformaitemdefectedkg,
                    preformascannedcompleted,
                    preformastocktohide,
                    preformastockcompleted,
                    preformadeliverycompleted,


                  } = entry;
                  if (!acc[user]) {
                    acc[user] = [];
                  }
                  acc[user].push({
                    itemsscanned,
                    itemsstock,
                    itemsdelivery,
                    kgloadedfromalmacen,
                    itemdefectedkg,
                    itemdefected,
                    scannedcompleted,
                    stockcompleted,
                    deliverycompleted,
                    mexicanTime,

                    packscanned,
                    packitemsstock,
                    packdelivery,

                    preformascanned,
                    preformastock,
                    preformadelivery,
                    preformapackscanned,
                    preformapackitemsstock,
                    preformapackdelivery,
                    preformaitemdefected,
                    preformaitemdefectedkg,
                    preformascannedcompleted,
                    preformastocktohide,
                    preformastockcompleted,
                    preformadeliverycompleted,
                  });
                  return acc;
                }, {})
              ).map(([user, entries], userIndex) => (
                <div id="detaileddiv" key={`entry-${orderIndex}-${userIndex}`}>
                  <div className="flex items-center bg-sky-800 ml-3 mr-3 mb-3 rounded-lg">
                    <button
                      className="text-emerald-400 ml-3 mr-3 focus:outline-none text-3xl "
                      onClick={() => toggleUserExpand(orderIndex, userIndex)}
                    >
                      {expandedUsers.includes(`${orderIndex}-${userIndex}`)
                        ? "-"
                        : "+"}
                    </button>
                    {user && user.trim().length > 0 && (
                      <div className="text-xl text-white ">
                        User: {user}
                        <div>Order Number: {order.ordernr}</div>
                      </div>
                    )}
                  </div>
                  {expandedUsers.includes(`${orderIndex}-${userIndex}`) && (
                    <div className="grid grid-cols-3 gap-4 bg-gray-700 ">
                      <div id="prefscanned" className="border border-gray-500 bg-slate-800 p-4 ml-3 mt-3 rounded-lg">
                        {/* PreformaItemsscanned column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div 
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformascanned !== undefined &&
                                entry.preformascanned !== null && (
                                  <div className="text-emerald-400 ">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Preformas Producido: {entry.preformascanned} ud
                                      <span className="ml-2">Paq:{entry.preformapackscanned}</span>
                                    </div>
                                    <div className="text-xs text-emerald-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="prefstock" className="border border-gray-500 bg-slate-800 p-4  mt-3 rounded-lg">
                        {/* PreformaItemsstock column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformastock !== undefined &&
                                entry.preformastock !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Preformas en Extistencia: {entry.preformastock} ud
                                      <span className="ml-2">Paq:{entry.preformapackitemsstock}</span>
                                      </div>
                                      <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div  id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3  rounded-lg">
                        {/* PreformaItemsdelivered column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformadelivery !== undefined &&
                                entry.preformadelivery !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Pref Entregados: {entry.preformadelivery} ud
                                    <span>Paq:{entry.preformapackdelivery} </span>
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="preformaresinafordefecteditems" className="border border-gray-500 bg-slate-800 p-4   rounded-lg">
                        {/* Resina Needed and taken for defected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformaitemdefectedkg !== undefined &&
                                entry.preformaitemdefectedkg !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Resina necesaria para preforma defectuoso:{" "}
                                      {entry.preformaitemdefectedkg} Kg
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="preformaitemsdefected" className="border border-gray-500 bg-slate-800 p-4 mr-3  rounded-lg">
                        {/* PreformaDefected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformaitemdefected !== undefined &&
                                entry.preformaitemdefected !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Preformas defectuso: {entry.preformaitemdefected} ud
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="preformaphaseitems" className="border border-gray-500 bg-slate-800 p-4 ml-3 mb-3 rounded-lg">
                        {/* PrefomraPhases column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.preformascannedcompleted !== undefined &&
                                entry.preformascannedcompleted !== null &&
                                entry.preformascannedcompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                    Phase0  Preformas en Producción:{" "}
                                      {entry.preformascannedcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                                 {entry.preformastocktohide !== undefined &&
                                entry.preformastocktohide !== null &&
                                entry.preformastocktohide === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                    Phase1  Producción Preformas completada:{" "}
                                      {entry.preformastocktohide.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.preformastockcompleted !== undefined &&
                                entry.preformastockcompleted !== null &&
                                entry.preformastockcompleted === true && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase2 Existencia Preformas completada:{" "}
                                      {entry.preformastockcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.preformadeliverycompleted !== undefined &&
                                entry.preformadeliverycompleted !== null &&
                                entry.preformadeliverycompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase3 Entrega Preformas completada:{" "}
                                      {entry.preformadeliverycompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="itemscanned" className="border border-gray-500 bg-slate-800 p-4 ml-3 mt-3 rounded-lg">
                        {/* Itemsscanned column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div 
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemsscanned !== undefined &&
                                entry.itemsscanned !== null && (
                                  <div className="text-emerald-400 ">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Prod Producido: {entry.itemsscanned} ud
                                      <span className="ml-2">Paq:{entry.packscanned}</span>
                                    </div>
                                    <div className="text-xs text-emerald-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="itemstock" className="border border-gray-500 bg-slate-800 p-4  mt-3 rounded-lg">
                        {/* Itemsstock column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemsstock !== undefined &&
                                entry.itemsstock !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Prod en Extistencia: {entry.itemsstock} ud
                                      <span className="ml-2">Paq:{entry.packitemsstock}</span>
                                      </div>
                                      <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div  id="itemdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3  rounded-lg">
                        {/* Itemsdelivered column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemsdelivery !== undefined &&
                                entry.itemsdelivery !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Prod Entregados: {entry.itemsdelivery} ud
                                    <span>Paq:{entry.packdelivery} </span>
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="resinaneedandtakenfromalmacen" className="border border-gray-500  bg-slate-800 p-4 ml-3  rounded-lg">
                        {/* Resina Needed and taken from almacen column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.kgloadedfromalmacen !== undefined &&
                                entry.kgloadedfromalmacen !== null && (
                                  <div className="text-blue-500">
                                   <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Resina de almacen en producción:{" "}
                                      {entry.kgloadedfromalmacen} Kg
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="resinafordefecteditems" className="border border-gray-500 bg-slate-800 p-4   rounded-lg">
                        {/* Resina Needed and taken for defected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemdefectedkg !== undefined &&
                                entry.itemdefectedkg !== null && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Resina necesaria para producto defectuoso:{" "}
                                      {entry.itemdefectedkg} Kg
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="itemsdefected" className="border border-gray-500 bg-slate-800 p-4 mr-3  rounded-lg">
                        {/* Defected items column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.itemdefected !== undefined &&
                                entry.itemdefected !== null && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Producto defectuso: {entry.itemdefected} ud
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                      <div id="phaseitems" className="border border-gray-500 bg-slate-800 p-4 ml-3 mb-3 rounded-lg">
                        {/* Phases column */}
                        {entries &&
                          entries.length > 0 &&
                          entries.map((entry, entryIndex) => (
                            <div
                              key={`entry-${orderIndex}-${userIndex}-${entryIndex}`}
                            >
                              {entry.scannedcompleted !== undefined &&
                                entry.scannedcompleted !== null &&
                                entry.scannedcompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                    Phase1  Producción completada:{" "}
                                      {entry.scannedcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.stockcompleted !== undefined &&
                                entry.stockcompleted !== null &&
                                entry.stockcompleted === true && (
                                  <div className="text-blue-500">
                                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase2 Existencia completada:{" "}
                                      {entry.stockcompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                              {entry.deliverycompleted !== undefined &&
                                entry.deliverycompleted !== null && 
                                entry.deliverycompleted === true && (
                                  <div className="text-blue-500">
                                     <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                                      Phase3 Entrega completada:{" "}
                                      {entry.deliverycompleted.toString()}
                                    </div>
                                    <div className="text-xs text-blue-700">{entry.mexicanTime}</div>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
      
         )))}
        {isAlertOrderDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteOrderModal
              alertDeleteOrder={alertDeleteOrder}
              onSave={handleDelete}
              onClose={closeOpenAlertOrderDeleteModal}
            />
          </div>
        </div>
      )}
    
    </section>
  );

};

export default DashboardReviewOrder;
