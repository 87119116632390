export const apiWrapper = (url, method, body = {}, headers = {}) => {
  //const URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
  const site = sessionStorage.getItem("site");

  let URL_BACKEND;

  if (window.location.hostname === "www.valdoapp.com" && site === "tlaxcala") {
    URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
    //    URL_BACKEND = "https://tlaxcala.dadaisma.com/";
  } else if (
    window.location.hostname === "www.valdoapp.com" &&
    site === "cdmx"
  ) {
    URL_BACKEND = "https://bkmg-production.up.railway.app/";
    //   URL_BACKEND = "https://mexico.dadaisma.com/";
  } else if (
    window.location.hostname === "https://test-tlaxcala.vercel.app/" &&
    site === "tlaxcala"
  ) {
    URL_BACKEND = "https://m14sxgd6-3001.uks1.devtunnels.ms/";
    //   TEST
  } else {
    URL_BACKEND =
      site === "cdmx" ? "http://localhost:3002/" : "http://localhost:3001/";
  }

  //console.log('Site:', site);
  //console.log('URL_BACKEND:', URL_BACKEND);
  {
    /* 
  const URL_BACKEND =
  window.location.hostname === "www.valdoapp.com"
  ? "https://tlaxcala-production.up.railway.app/"
  : "http://localhost:3001/";
  
  
      export      let URL_BACKEND
      
      if (window.location.hostname === "www.valdoapp.com") {
        URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
      } else {
        // Assume site is accessible here
        URL_BACKEND = site === "cdmx" ? "http://localhost:3002/" : "http://localhost:3001/";
      }
    */
  }

  //render host
  //const URL_BACKEND = window.location.hostname === 'www.valdoapp.com' ? "https://tlaxcala.onrender.com/" : 'http://localhost:3001/';

  //angelo server
  //const URL_BACKEND = window.location.hostname === 'www.valdoapp.com' ? "https://217-133-34-187.static.clienti.tiscali.it:8080/"  : 'http://localhost:3001/';
  const options = {
    method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application.json",
      ...headers,
    },
  };
  // Get the token from local storage
  const token = window.localStorage.getItem("user-session");
  // console.log(token)
  // Include the authorization header if a token is provided
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  if (body && JSON.stringify(body) !== "{}")
    options.body = JSON.stringify(body);

  return fetch(URL_BACKEND + url, options).then((response) => {
    if (response.status >= 200 && response.status < 500) {
      return response.json();
    } else {
      return Promise.reject();
    }
  });
};
