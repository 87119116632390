
import { useState } from "react";
import {Cloudinary} from "@cloudinary/url-gen";
import { apiWrapper } from "../../utils/apiwrapper";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
//import { mexicanTime } from "../../utils/variousConst";
//import { getUserSession } from "../../utils/localStorage.utils";

const Cloud3 = ( { articleId, user, mexicanTime, options  }) => {

    const [postphotoUrl, setPostphotoUrl] = useState("");
  //const cld = new Cloudinary({cloud: {cloudName: 'dytsb4ocs'}});

  //const session = tokenDecoder(getUserSession());
 // const user = session.username;



  function showUploadWidget3 () {
    // console.log('showUploadWidget');
    const cloudinary =  window.cloudinary;
    cloudinary.openUploadWidget ({ 
      cloudName: "dytsb4ocs" ,  
      uploadPreset: "qrcodepreset", 
      folder: "tlaxcala_qrcodefolderalmacenprovedor", 
      sources: ["local", "camera"],
      googleApiKey: "<image_search_google_api_key>",
      showAdvancedOptions: true,
      cropping: false,
      multiple: false,
      defaultSource: "local",
      styles: {
        palette: {
          window: "#F5F5F5",
          sourceBg: "#FFFFFF",
          windowBorder: "#90a0b3",
          tabIcon: "#0094c7",
          inactiveTabIcon: "#69778A",
          menuIcons: "#0094C7",
          link: "#53ad9d",
          action: "#8F5DA5",
          inProgress: "#0194c7",
          complete: "#53ad9d",
          error: "#c43737",
          textDark: "#000000",
          textLight: "#FFFFFF"
        },
        fonts: {
          default: null,
          "'Poppins', sans-serif": {
            url: "https://fonts.googleapis.com/css?family=Poppins",
            active: true
          }
        }
      }
    }, 
    (err, info,result) => {
     //console.log("info, err, result,", info, err, result);
      if (!err && info && info.event === "success") {
       
        const phototoup = info.info.secure_url;
        setPostphotoUrl(phototoup)
        //console.log(setPostphotoUrl)
     //   console.log(phototoup)
        handleSave(phototoup, articleId, user, mexicanTime)
       }
   
    });
  }

  const handleSave = async (phototoup, articleId, user, mexicanTime) => {
   // console.log(articleId);
  //  console.log(phototoup);
  //console.log(user);
 //   console.log(mexicanTime);
    
    try {
      // Make a PATCH request to update the order on the server
      const response = await apiWrapper(`almacen/${articleId}/qrimage`, "PATCH", {
        qrimage3: phototoup,
        employeecloud: {
          mexicanTime: new Date().toLocaleString("en-US", options),
        user: user}
        
         
      });
  
      // Handle the response as needed
      console.log(response); // Assuming the response contains the updated order
  
    } catch (error) {
      console.error("Error updating article:", error); }
  };

  return ( 
    
    <button id="upload_widget" className="cloudinary-button bg-slate-700 flex justify-center items-center" onClick={showUploadWidget3} >
      <AddAPhotoIcon fontSize="small" className="mr-2" />  QR Provedor 1
      </button>
    
  )
};


export default Cloud3;

