import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const EditDefectedModal = ({ defectedEditableItem, onSave, onClose }) => {
  const { order, index } = defectedEditableItem;
  const [editedOrderNr, setEditedOrderNr] = useState(order.ordernr || "");
  const [editedFinalProduct, setEditedFinalProduct] = useState(
    order.finalproduct || "");

    const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
    const [personalComment, setPersonalComment] = useState(""); // State to store the personal comment
  
    const defectTypes = [
      { value: 'acumulacion', label: 'Acumulacion de material' },
      { value: 'asaFracturada', label: 'Asa fracturada' },
      { value: 'asaZafada', label: 'Asa Zafada' },
      { value: 'deformes', label: 'Deformes' },
      { value: 'distribucion', label: 'Distribucion' },
      { value: 'malFechado', label: 'Mal fechado' },
      { value: 'rebabas', label: 'Rebabas' },
      { value: 'variacionColor', label: 'Variacion de color' },
      { value: 'aperlado', label: 'Aperlado' },
      { value: 'arranque', label: 'Arranque' },
      { value: 'estrella', label: 'Estrella' },
      { value: 'grasaAceite', label: 'Grasa o aceite' },
      { value: 'impurezas', label: 'Impurezas (Contaminacion)' },
      { value: 'labioIncompleto', label: 'Labio incompleto' },
      { value: 'marcaAgua', label: 'Marca de agua' },
      { value: 'marcas', label: 'Marcas (rayas)' },
      { value: 'materialFrio', label: 'Material frio' },
      { value: 'nube', label: 'Nube (punto blanco)' },
      { value: 'opaco', label: 'Opaco' },
      { value: 'sinAza', label: 'Sin aza' },
      { value: 'burbuja', label: 'Burbuja' },
      { value: 'crater', label: 'Crater' },
      { value: 'hilo', label: 'Hilo' },
      { value: 'perforado', label: 'Perforado' },
      { value: 'puntoInyeccion', label: 'Punto de inyeccion desviado' },
      { value: 'rafagasPigmento', label: 'Rafagas de pigmento' },
      { value: 'reventados', label: 'Reventados' },
      { value: 'quemada', label: 'Quemada' },
      { value: 'arana', label: 'Araña' },
      { value: 'polvo', label: 'Polvo' },
      { value: 'barredura', label: 'Barredura' },
      { value: 'purgas', label: 'Purgas' },
      { value: 'otro', label: 'Otros' },
    ];
    defectTypes.sort((a, b) => a.label.localeCompare(b.label));
    // ...
    
 



    
     // Event handler for when the select input changes
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const isOptionSelected = ["arana", "polvo", "quemada", "barredura", "purgas"].includes(selectedValue);
    setSelectedOption(selectedValue);
    setEditedDesperdicioModelTf(isOptionSelected);
  };

  // Event handler for when the personal comment input changes
  const handleCommentChange = (e) => {
    const comment = e.target.value;
    setPersonalComment(comment);
  }; 

  //model missing
  const [editedFinalProductKg, setEditedFinalProductKg] = useState(
    order.finalproductkg || ""
  );
  const [editedItemsDefected, setEditedItemsDefected] = useState(
     ""
  );
  const [editedKgItemsDefected, setEditedKgItemsDefected] = useState(
     ""
  );

  const [editedDesperdicioModelTf, setEditedDesperdicioModelTf] = useState(false)
  const handleToggle = () => {
    setEditedDesperdicioModelTf(!editedDesperdicioModelTf);
  };

  const isSaveEnabled = editedItemsDefected > 0
  //mat prima missing

  const handleKgItemsDefectedChange = (e) => {
    const inputValue = e.target.value;
    const calculatedValue =
      Math.round(editedFinalProductKg * editedItemsDefected * 100) / 100;
    setEditedKgItemsDefected(calculatedValue);
  };

  const handleItemsDefectedChange = (f) => {
    const inputValue = f.target.value;
    const calculatedValue = Math.round(editedFinalProductKg * inputValue*100) /100;
    setEditedItemsDefected(inputValue);
    setEditedKgItemsDefected(calculatedValue);
  };

  const handleSave = () => {
    //console.log("editedDesperdicioModelTf:", editedDesperdicioModelTf);
   // console.log("selectedOption:", selectedOption);
    //console.log("personalComment:", personalComment);
    onSave(order.index, {
      ...order,

      itemdefected: editedItemsDefected,
      itemdefectedkg: editedKgItemsDefected,
      desperdiciomodel: {  
        desperdiciomodeltf: editedDesperdicioModelTf,
      defectedtypemodel: selectedOption,
      defectedcommentmodel: personalComment
    }
    });
    onClose();
  };

  return (
    <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }} >
      {/* Modal content */}
      <div
        className="sm:mt-10 rounded-lg shadow bg-gray-800  "
      >
        <label className="block mb-2 mt-10 text-sm font-medium  text-gray-400 uppercase">
          Pedido NR
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="text"
          value={editedOrderNr}
          readOnly
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Modelo
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="text"
          value={editedFinalProduct}
          readOnly
        />

        <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">
        Artículos defectuosos
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          onChange={handleItemsDefectedChange}
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Peso unitario (Kg)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          value={editedFinalProductKg}
          readOnly
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Resina necesarias (Kg)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          value={editedKgItemsDefected}
          onChange={handleKgItemsDefectedChange}
        />
      </div>


      <div className="flex justify-between">
      <label className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase">
  {selectedOption !== "quemada" && selectedOption !== "barredura" && selectedOption !== "purgas" && selectedOption !== 'polvo' && selectedOption !== "arana" ? (
    "se puede recuperar" 
  ) : selectedOption === "arana" || selectedOption === "polvo" || selectedOption === "quemada" 
  || selectedOption === "barredura" || selectedOption === "purgas"
  ? (
    "es desperdicio"
  ) : selectedOption === "otro" ? (
    "marcar si es desperdicio"
  ) : (
    "elige una opción"
  )}
</label>

{/* Conditionally render the checkbox */}
{selectedOption !== "quemada" && selectedOption !== "barredura" && selectedOption !== "purgas" && selectedOption !== 'polvo' && selectedOption !== "arana" &&  (
  <button onClick={handleToggle}>
    {editedDesperdicioModelTf === false ? (
      <CheckBoxIcon className="text-green-500" />
    ) : (
      <CheckBoxOutlineBlankIcon />
    )}
  </button>
)}
      </div>
      <div>
      
      <select
  className="text-white bg-gray-700 rounded-lg mt-4 p-2 "
  value={selectedOption}
  onChange={handleSelectChange}
>
  {defectTypes.map((type) => (
    <option key={type.value} value={type.value}>
      {type.label}
    </option>
  ))}
</select>

       
        <div>
          <label
            htmlFor="comment"
            className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase"
          >
            Comentario:
          </label>
          <input
            type="text"
            id="comment"
            name="comment"
            className="text-white bg-gray-700 rounded-lg block w-full p-2.5 "
            value={personalComment}
            onChange={handleCommentChange}
          />
        </div>
      
      
      </div>


      
      <div className="mb-6">
        <button
          className={`w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center 
          ${isSaveEnabled ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800'
        : 'bg-gray-400 cursor-not-allowed' } mt-8`}
                        onClick={isSaveEnabled ? handleSave : null}
                        disabled={!isSaveEnabled}
        >
           {isSaveEnabled ? 'Guardar' : 'Eligir unidad'}
        </button>
        <button
          className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditDefectedModal;
