import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

const EditAlertPhasePreformaStockModal = ({ alertOrder, onSave, onClose,  }) => {
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const { order  } = alertOrder;
//console.log(order)

    const existenciaReal = order.preformapackitemsstock + order.preformapackaddedtoprod;

    const updateOrder = () => {
        onSave(order._id, order);
        onClose();
      };
   
      const handleNoClick = () => {
        setSelectedOrderId(null);
        onClose(); // Close the modal
      };


  return (
    <Alert
    severity="warning"
    sx={{ backgroundColor: "rgba(255, 255, 255)" }}
  >
    <AlertTitle>¿Estás seguro?</AlertTitle>
    Esta acción no se puede deshacer
    <div className="mt-4">Paquetes Pedido:{order.preformatotpack} </div>
    <div>Paq en Existencia + Paq añadido a prod Gar:{existenciaReal} </div>
    <div>Quedan:<span className="font-bold">{order.preformatotpack - existenciaReal}</span>  </div>
    <div className="flex justify-center mt-4">
    <Button onClick={handleNoClick}>No</Button>
      <Button onClick={updateOrder} 
      variant="contained"
      disabled={order.preformatotpack - existenciaReal !== 0}
      >
        Si
      </Button>
    </div>
  </Alert>
  )
}

export default EditAlertPhasePreformaStockModal