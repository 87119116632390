import Html5QrcodePlugin from "./VideoProductNewQrReader";
import React, { useState } from 'react';

const QrApp = (props) => {
    const [decodedResults, setDecodedResults] = useState([]);
    const onNewScanResult = (decodedText, decodedResult) => {
      console.log("QR Data:", decodedText);
      return alert( decodedText)
       // console.log("App [result]", decodedResult);
      //  setDecodedResults(prev => [...prev, decodedResult]);// handle decoded results here
    };

    return (
        <div className="App">
            <Html5QrcodePlugin
            
                fps={10}
                qrbox={250}
                disableFlip={false}
                facingMode= { true}
                qrCodeSuccessCallback={onNewScanResult}
            />
        </div>
    );
};

export default QrApp;