import io from "socket.io-client";
const site = sessionStorage.getItem("site");
let URL_BACKEND;

if (window.location.hostname === "www.valdoapp.com" && site === "tlaxcala") {
  URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
} else if (window.location.hostname === "www.valdoapp.com" && site === "cdmx") {
  URL_BACKEND = "https://bkmg-production.up.railway.app/";
} else if (
  window.location.hostname === "https://test-tlaxcala.vercel.app/" &&
  site === "tlaxcala"
) {
  URL_BACKEND = "https://m14sxgd6-3001.uks1.devtunnels.ms/";
  //   TEST //
} else {
  URL_BACKEND =
    site === "cdmx" ? "http://localhost:3002/" : "http://localhost:3001/";
}

export const socket = io(URL_BACKEND);

{
  /*
export function getSocket() {
  const site = sessionStorage.getItem('site');
  let URL_BACKEND;

  if (window.location.hostname === "www.valdoapp.com" && site === "tlaxcala") {
    URL_BACKEND = "https://tlaxcala-production.up.railway.app/";
  } else if (window.location.hostname === "www.valdoapp.com" && site === "cdmx") {
    URL_BACKEND = "https://bkmg-production.up.railway.app/";
  } else {
    URL_BACKEND = site === "cdmx" ? "http://localhost:3002/" : "http://localhost:3001/";
  }

  return io(URL_BACKEND);
}
*/
}
//render host
//export const socket = io(window.location.hostname === 'www.valdoapp.com' ? "https://tlaxcala.onrender.com/" : 'http://localhost:3001/');

//angelo server
//export const socket = io(window.location.hostname === 'www.valdoapp.com' ? "https://217-133-34-187.static.clienti.tiscali.it:8080/"  : 'http://localhost:3001/');

// headers for excel export

export const listacustomersheaders = [
  { label: "id", key: "_id" },
  { label: "razonsocial", key: "razonsocial" },
  { label: "nombrecomprador", key: "nombrecomprador" },
  { label: "estado", key: "estado" },
  { label: "direccion", key: "direccion" },
  { label: "telefono", key: "telefono" },
];

export const listamodelosgaraffonesheaders = [
  { label: "Fecha Creacion", key: "createdAt" },

  { label: "FechaMX", key: "createdAtMx" },

  { label: "Codigo", key: "code" },
  { label: "Modelo", key: "modelname" },
  { label: "Fam", key: "familia" },
  { label: "Familia", key: "familiadescription" },
  { label: "SubFam", key: "subfamilia" },
  { label: "SubFamDesc", key: "subfamiliadescription" },
  { label: "Color", key: "colordescription" },
  { label: "Peso", key: "masterkg" },
  { label: "Asa Col", key: "asacolor" },
  { label: "Asa Desc", key: "asacolodescription" },
  { label: "Capacitad", key: "capacitad" },
  { label: "ListaCode", key: "listacode" },
  { label: "ListaDesc", key: "listadescription" },
  { label: "Placa", key: "placa" },
  { label: "PlacaDesc", key: "placacodedescription" },
  { label: "PlacaTipo", key: "placatipocode" },
  { label: "PlacaTipoDesc", key: "placatipodescription" },
  { label: "Rosca", key: "rosca" },
  { label: "RoscaDesc", key: "roscadescription" },
  { label: "TipoMod", key: "tipomodelo" },
  { label: "TipoModDesc", key: "tipomodelodescription" },
];

//
export const listamodelospreformaheaders = [
  { label: "Fecha Creacion", key: "createdAt" },

  { label: "FechaMX", key: "createdAtMx" },

  { label: "Modelo", key: "modelname" },
  { label: "Codigo", key: "code" },
  { label: "Color", key: "colordescription" },
  { label: "Cuello", key: "cuello" },
  { label: "Descr Cuello", key: "cuellodescription" },
  { label: "Cuello Tipo", key: "cuellotipo" },
  { label: "Descr Cuello Tipo", key: "cuellotipodescription" },
  { label: "Familia", key: "familiadescription" },
  { label: "Rosca", key: "rosca" },
  { label: "Descr Rosca", key: "roscadescription" },
  { label: "Peso", key: "masterkg" },
  { label: "SubFam", key: "subfamiliadescription" },
];

//

export const headers = [
  // { label: 'ID', key: '_id' },
  { label: "Order Number", key: "ordernr" },
  { label: "Fecha", key: "createdAt" },

  { label: "FechaMX", key: "createdAtMx" },

  { label: "Customer", key: "customer" },
  { label: "Mod Preforma", key: "preforma" },
  { label: "Pref de Existencia", key: "preformaaddedtoprod" },
  { label: "Paq Pref de Existencia", key: "preformapackaddedtoprod" },
  { label: "Pref Orig nrOrder", key: "preformaoriginalorder" },
  { label: "Pref Cliente Orig", key: "preformaclienteorigen" },
  { label: "Resina", key: "code" },
  { label: "Modelo", key: "finalproduct" },
  { label: "Color", key: "finalproductcolor" },
  { label: "Peso (kg) Modelo por ud", key: "finalproductkg" },
  { label: "Ud por Paquete", key: "pack" },
  { label: "Paquete Totales", key: "totpack" },
  { label: "Art. para hacer (ud)", key: "itemstodo" },
  { label: "Resina necesaria (Kg)", key: "kgneeded" },
  { label: "Resina en producción (Kg)", key: "kgloadedfromalmacen" },
  { label: "Art defectuoso (ud)", key: "itemdefected" },
  { label: "Art defectuoso (kg) necesarios", key: "itemdefectedkg" },
  { label: "desperdicio Y/N", key: "desperdiciomodeltf" },
  { label: "tipo de defecto", key: "defectedtypemodel" },
  { label: "comentario", key: "defectedcommentmodel" },
  { label: "Art Producido (ud)", key: "itemsscanned" },
  { label: "Art en existencia (ud)", key: "itemsstock" },
  { label: "Art subtracto de existencia (ud)", key: "subtractitemsstock" },
  { label: "Art entregados (ud)", key: "itemsdelivery" },
  { label: "Paq Producido (ud)", key: "packscanned" },
  { label: "Paq en Existencia (ud)", key: "packitemsstock" },
  { label: "Paq subtracto de Existencia (ud)", key: "subtractpackitemsstock" },
  { label: "Paq en Entrega (ud)", key: "packdelivery" },
  { label: "Prod confirmada", key: "startorder" },
  { label: "Prod completa", key: "scannedcompleted" },
  { label: "Existencia completa", key: "stockcompleted" },
  { label: "Entrega completa", key: "deliverycompleted" },

  { label: "Calidad Art defectuoso (ud)", key: "calidaditemdefected" },
  { label: "Calidad Paq defectuoso (ud)", key: "calidadpackdefected" },
  { label: "Calidad defectuoso (kg) necesarios", key: "calidaditemdefectedkg" },
  { label: "desperdicio Y/N", key: "desperdiciomodeltf" },
  { label: "tipo de defecto", key: "defectedtypemodel" },
  { label: "comentario", key: "defectedcommentmodel" },

  { label: "comentario calidad", key: "calidadcomment" },
  { label: "stock order ID", key: "stockorderid" },
  { label: "stock order NR", key: "stockordernr" },
  { label: "stock order NR destino", key: "stockorderdestinonr" },
  { label: "stock order ID destino", key: "stockorderdestinoid" },
  { label: "stock order Cliente", key: "stockorderdestinocliente" },

  { label: "Qr interno", key: "qrimage" },

  //{ label: 'extrakgloadedf_almacen_notinuse', key: 'extrakgloadedfromalmacen' },

  //{ label: 'Employee Story - Resina', key: 'kgloadedfromalmacen' },
  //{ label: 'Employee Story - Prod defectuoso', key: 'itemdefected' },
  //{ label: 'Employee Story - Scan completed', key: 'scannedcompleted' },
  //{ label: 'Employee Story - Stock completed', key: 'stockcompleted' },
  //{ label: 'Employee Story - Items Scanned', key: 'itemsscanned' },
  { label: "Employee Story - User", key: "user" },
  { label: "Employee Story - Fecha y Hora", key: "mexicanTime" },
  { label: "Date", key: "employeestory" },
];
//

export const almacenheaders = [
  { label: "Fecha creacion", key: "createdAt" },

  { label: "FechaMX", key: "createdAtMx" },

  { label: "Codigo", key: "code" },
  { label: "CodigoAlfa", key: "codealfa" },
  { label: "Modelo", key: "modelname" },
  { label: "Familia", key: "familiadescription" },
  { label: "Provedor", key: "provider" },
  { label: "Color", key: "colordescription" },
  { label: "Lot", key: "lot" },
  { label: "Grade", key: "grade" },
  { label: "Pais", key: "country" },
  { label: "Peso unitario (Kg)", key: "masterkg" },
  { label: "Unidades", key: "value" },
  { label: "Existencias (Kg)", key: "kg" },
  { label: "Qr interno", key: "qrimage" },
  { label: "Qr 1 Provedor", key: "qrimage3" },
  { label: "Qr 2 Provedor", key: "qrimage4" },
  { label: "Employee Story - User", key: "user" },
  { label: "Employee Story - Fecha y Hora", key: "mexicanTime" },
];

export const preformaheaders = [
  // { label: 'ID', key: '_id' },
  { label: "Order Number", key: "ordernr" },
  { label: "Fecha", key: "createdAt" },

  { label: "FechaMX", key: "createdAtMx" },

  { label: "Customer", key: "customer" },
  { label: "Mod Preforma", key: "preforma" },
  { label: "Pref enviado a producción", key: "preformaaddedtoprod" },
  { label: "Paq Pref enviado producción", key: "preformapackaddedtoprod" },
  { label: "Pref Destino nr Order", key: "preformaodestinoorder" },
  { label: "Pref Destino Cliente", key: "preformaclientedestino" },
  { label: "Resina", key: "code" },
  { label: "Modelo", key: "finalproduct" },
  { label: "Color", key: "preformacolor" },
  { label: "Peso (kg) Modelo por ud", key: "preformakg" },
  { label: "Paquete", key: "preformapack" },
  { label: "Paquete Totales", key: "preformatotpack" },
  { label: "Pref. para hacer (ud)", key: "itemstodo" },
  { label: "Resina necesaria (Kg)", key: "kgneeded" },
  { label: "Resina en producción (Kg)", key: "kgloadedfromalmacen" },
  { label: "Pref defectuoso (ud)", key: "preformaitemdefected" },
  { label: "Pref defectuoso (kg) necesarios", key: "preformaitemdefectedkg" },
  { label: "desperdicio Y/N", key: "desperdiciopreformatf" },
  { label: "tipo de defecto", key: "defectedtypepreforma" },
  { label: "comentario", key: "defectedcommentpreforma" },
  { label: "Pref Producido (ud)", key: "preformascanned" },
  { label: "Pref en existencia (ud)", key: "preformastock" },
  { label: "Pref entregados (ud)", key: "preformadelivery" },
  { label: "Paq Producido (ud)", key: "preformapackscanned" },
  { label: "Paq en Existencia (ud)", key: "preformapackitemsstock" },
  { label: "Paq en Entrega (ud)", key: "preformapackdelivery" },
  { label: "Prod completa", key: "preformascannedcompleted" },
  { label: "Existencia completa", key: "preformastockcompleted" },
  { label: "Entrega completa", key: "preformadeliverycompleted" },
  { label: "Qr interno", key: "qrimage" },
  //{ label: 'extrakgloadedf_almacen_notinuse', key: 'extrakgloadedfromalmacen' },

  //{ label: 'Employee Story - Resina', key: 'kgloadedfromalmacen' },
  //{ label: 'Employee Story - Prod defectuoso', key: 'itemdefected' },
  //{ label: 'Employee Story - Scan completed', key: 'scannedcompleted' },
  //{ label: 'Employee Story - Stock completed', key: 'stockcompleted' },
  //{ label: 'Employee Story - Items Scanned', key: 'itemsscanned' },
  { label: "Employee Story - User", key: "user" },
  { label: "Employee Story - Fecha y Hora", key: "mexicanTime" },
];

export const date = new Date();
export const options = {
  timeZone: "America/Mexico_City",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const mexicanTime = date.toLocaleString("en-US", options);
//console.log(mexicanTime);

// Function to format date in Mexican time
export function formatMexicanTime(date) {
  return new Date(date).toLocaleString("en-US", options);
}

export const dateOptions = {
  timeZone: "America/Mexico_City",
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};

export const timeOptions = {
  timeZone: "America/Mexico_City",
  hour: "2-digit",
  minute: "2-digit",
};

// Function to format date in Mexican time
export function formatMexicanDate(date) {
  return new Date(date).toLocaleString("en-GB", dateOptions);
}

// Function to format time in Mexican time
export function formatMexicanSoloTime(date) {
  return new Date(date).toLocaleString("en-US", timeOptions);
}

export const successSound = new Audio("/sounds/success.wav");
export const errorSound = new Audio("/sounds/error.wav");
