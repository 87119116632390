import { Link, useLocation } from "react-router-dom";
import Logout from "../Logout/Logout";
import { getUserSession, removeSession } from "../../utils/localStorage.utils";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { useState, useEffect } from "react";
//import AdminPage from "../../pages/Admin";
import { useNavigate } from "react-router-dom";
import RoofingOutlinedIcon from '@mui/icons-material/RoofingOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import HomeIcon from '@mui/icons-material/Home';


const NavBar = () => {
  const navigate = useNavigate();

  const navigateToAdminPage = () => {
    navigate("/admin");
  };

  const navigateToHomePage = () => {
    navigate("/home");
  };

  const location = useLocation();
  const [session, setSession] = useState(getUserSession());
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const userToken = session ? session.token : "";
    const userIdToken = tokenDecoder(userToken);
    const userId = userIdToken ? userIdToken.userId : "";
    const username = userIdToken ? userIdToken.username : "";
    const role = userIdToken ? userIdToken.role : "";
  
    //console.log("Step 1 - useEffect - session:", session);
    //console.log("Step 2 - useEffect - userToken:", userToken);
   // console.log("Step 3 - useEffect - userIdToken:", userIdToken);
  //  console.log("Step 4 - useEffect - userId:", userId);
   // console.log("Step 5 - useEffect - username:", username);
   // console.log("Step 6 - useEffect - role:", role);
  
    setUserId(userId);
    setUsername(username);
    setIsAdmin(role === "admin");
  
    //console.log("Step 7 - useEffect - isAdmin:", isAdmin);
  }, [session, location]);

  //setUsername(session ? session.username : "");
   // setIsAdmin(session ? session.role === "admin" : false);

  const handleLogout = () => {
    removeSession();
    setSession(null);
  };

 // console.log("Step 6 - Render - session:", session);
 // console.log("Step 7 - Render - isAdmin:", isAdmin);

  return (
    <>
      <nav className="bg-gray-900 text-slate-500 sticky top-0 z-50 ">
      <div className="bg-gray-900 max-w-screen-xl flex items-center justify-between ml-auto mr-auto px-1 py-2.5">
    {!username && (
      <Link to="/" className="flex items-center mx-auto">
        <img
        src="https://res.cloudinary.com/dytsb4ocs/image/upload/v1691881429/logo/VALDOTEC-text-logoW_pjxaan.png"
       //src="https://res.cloudinary.com/dytsb4ocs/image/upload/v1697284449/logo/black_altzez.png"
          className="h-10 mt-10 mb-5"
          alt="Valdotec Logo"
         
        />
      </Link>
    )}
    {username && (
      <div className="flex items-center justify-between w-full ml-5 mr-5 mb-4 mt-2">
        <Link to="/" className="flex items-center">
          <img
         src="https://res.cloudinary.com/dytsb4ocs/image/upload/v1691881429/logo/VALDOTEC-text-logoW_pjxaan.png"
        //  src="https://res.cloudinary.com/dytsb4ocs/image/upload/v1697284449/logo/black_altzez.png"
            className="h-8"
            alt="Valdotec Logo"
           
          />
        </Link>
        <div className="flex justify-end">
          <h1>{username}</h1>
          {!userId && <p></p>}
       
        <span className="ml-2">
          {userId && <Logout onLogout={handleLogout} />}
        </span>
      </div> </div>
    )}
      
        </div>
      
      {userId && ( 
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
        bg-transparent border-gray-900 flex-center    mx-auto sticky top-20 z-50">
          
      <div className="flex mb-4  bg-gray-900  ">
         
      <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mr-2  "
                        onClick={navigateToHomePage}
                        >
                       <HomeIcon fontSize="small"/>
                        </button>
                       

{isAdmin && (
        
           <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-gray-600 hover:bg-gray-700 focus:ring-gray-800  "
                        onClick={navigateToAdminPage}
                        >
                          <AdminPanelSettingsOutlinedIcon fontSize="small" />
                        </button>
                       
       
      )}
      </div>
      </div>
    )}
   </nav>
    </>
  );
};

export default NavBar;