import React, { useEffect, useState } from 'react';

const ErrorPage = () => {
  const [catImage, setCatImage] = useState('');

  const fetchRandomCatImage = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", "live_7NGc0TregXwNYjClivmmVQcJaeIFuTTHLVMbawsQVsGcMxbYpO3ojQghohp1FVME");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch("https://api.thecatapi.com/v1/images/search?format=json", requestOptions);
      const data = await response.json();

      if (data && data[0] && data[0].url) {
        setCatImage(data[0].url);
      }
    } catch (error) {
      console.error('Error fetching cat image:', error);
    }
  };

   // Fetch the image when the component mounts
   useEffect(() => {
    fetchRandomCatImage();
  }, []);

  // Redirect to the home page after 3 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = '/'; // Redirect to the home page
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className="text-white font-bold text-3xl flex flex-col justify-center items-center">
      <p>ErrorPage</p>
      {catImage && <img src={catImage} alt="Random Cat" />}
      <p>You will be redirected in 3 seconds</p>
    </div>
  );
};

export default ErrorPage;