import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../../utils/apiwrapper";


import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const GarListaSubFamilia = ({ selectedComponent, setSelectedComponent })=> {



  const [lastListaCode, setLastListaCode] = useState(0);
 
  const fetchListaCode = async () => {
    try {
      const response = await apiWrapper("garlistasubfamilia", "GET");
      setLastListaCode(response);
   //   console.log(response);

      // Update listacode in formData state
      //const listacode = String(response.length).padStart(0, '0');
      setFormData(prevFormData => ({
        ...prevFormData,
        listacode:String(response.length+1)
      }));
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };
  
  useEffect(() => {

    fetchListaCode();
  }, []);


    
  const [codeError, setCodeError] = useState("")
  const[message, setMessage]=useState("")
  const [formData, setFormData] = useState({
   listacode: "",
   listadescription:"",

   
  });
  const [model, setModel] = useState("");
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
   
    setFormData((prevFormData) => {
 
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
    

      const response = await apiWrapper("garlistasubfamilia", "POST", formData);
      setFormData(
        { listacode: "",
      listadescription:"",
   
    });
      setModel(response._id);
      fetchListaCode()

    
      
  // to get all the data in the message
      const updatedFormData = { ...formData };
    
  //   console.log("Response:", response);
  if (response.msg) {
    if (response.msg === "Code already exists") {
      setCodeError(response.msg);
      setMessage(
        <Alert className="flex justify-center" severity="error">
          El código {formData.listacode}  ya existe.
        Por favor asigne uno nuevo.
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
      }, 6000);
      setTimeout(() => {
        setCodeError(false);
        
      }, 6000);
   }
  
   }
   else{ 

  
    setMessage(
      <Alert className="flex justify-center" severity="success">
        <AlertTitle>¡Nueva Descripción Añadida !</AlertTitle>
        <div className="flex flex-col items-center">
      
    
       
         
          <div>
            <strong>Codigo:</strong> {updatedFormData.listacode}
          </div>
    
          <div>
            <strong>Descripción:</strong> {updatedFormData.listadescription}
          </div>
         
        </div>
      </Alert>
    );

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }} catch (error) {
    console.error(error);
    
    setMessage(
      <Alert className="flex justify-center" severity="error">
        
        Ocurrió un error,
        
        Inténtalo de nuevo.
      </Alert>
    );
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }
} 




  useEffect(() => {
    setFormData({listacode: "",
    listadescription:"",

   });
  }, []);

  
   

  return (
   
    <section className=" bg-gray-900  ">
      
     
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 
        bg-gray-800 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                    Añadir Descripción de SubFamilia Garrafon
                </h1>
 
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
           
                   
                 

                
                
                <div>
                        <label htmlFor="listacode"
                         className="block mb-2 text-sm font-medium  text-white">Codigo Disponible</label>
                        <input type="number" name="listacode" id="listacode" 
                        value={formData.listacode}
                       // readOnly
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-pink-600
                           placeholder-gray-400 text-white
                            focus:ring-pink-500 focus:border-pink-500" 
                            placeholder="codigo" required/>
                             {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                   
                 

                    <div>
                        <label htmlFor="listadescription" className="block mb-2 text-sm font-medium  text-white">Descripción</label>
                        <input type="text" name="listadescription" id="listadescription" placeholder=" ... texto ..." 
                        value={formData.listadescription}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-pink-600 placeholder-gray-400 text-white  focus:ring-pink-500 focus:border-pink-500" required />
                    </div>

                    <button type="submit"
                     className="w-full text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800">Guardar</button>
                   
                    </form>
                                     {/* Display the message */}
                                     {message && (
        <div className="message-container">
          {message}
        </div>
      )}
                    <div>
                    <button type="dashboardmodels"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-800  mt-8"
                      onClick={() => setSelectedComponent("")}
                        >Cerrar</button>
                    </div>
           

            </div>
        </div>
    </div>
  
  </section>
  
  
 
  );
};

export default GarListaSubFamilia;