import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { formatMexicanDate } from "../../utils/variousConst";

const NewProduct = () => {
  const [codeError, setCodeError] = useState("")
  const[message, setMessage]=useState("")

  const [selectedFamiliaCodeAlfa, setSelectedFamiliaCodeAlfa] = useState("");
  const [selectedFamiliaDescription, setSelectedFamiliaDescription] = useState("");
  const [selectedSubFamilia, setSelectedSubFamilia] = useState("");
  const [selectedSubFamiliaDescription, setSelectedSubFamiliaDescription] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedColor, setSelectedColor] = useState("")
  const [selectedColorDescription, setSelectedColorDescription] = useState("")

  const [formData, setFormData] = useState({
    code: "",
    provider: "",
    value:"",
    kg: 0,
    country:"",
    masterkg:"",
    qr:"",

    color:"",
    lot:"",
    grade:"",

    modelname:"",
    provider:"",
  //  country:"",

    subfamilia:"",
// subfamdescription:"",
    
    colordescription:"",
    colorcode:"",

    codealfa:"",
    codefamilia:"",
    familiadescription:""
   


  });

  const [articles, setArticles] = useState([]);
  const fetchArticles = async () => {
    try {
      const response = await apiWrapper("almacen", "GET");
      setArticles(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching articles:", error);
    }
  };

  useEffect(() => {
    fetchArticles(); 
  },[]);

  useEffect(() => {
    const fetchResinaFamilias = async () => {
      try {
        const response = await apiWrapper("resinafamilia", "GET");
        setResinaFamilias(response);
   //     console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching resinafamilia:", error);
      }
    };

    const fetchResinaSubFamilias = async () => {
      try {
        const response = await apiWrapper("resinasubfamilia", "GET");
        setResinaSubFamilias(response);
   //     console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching resinasubfamilia:", error);
      }
    };

    const fetchResinaProviders = async () => {
      try {
        const response = await apiWrapper("provider", "GET");
        setProviders(response);
   //     console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching provider:", error);
      }
    };
  
    const fetchColores = async () => {
      try {
        const response = await apiWrapper("colores", "GET");
        setColor(response);
    //    console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching asacolor:", error);
      }
    };
    fetchResinaFamilias();
    fetchResinaSubFamilias();
    fetchColores();
    fetchResinaProviders()
   
  }, []);
  
const resetAllStates = () => {
  setFormData({ code: "", provider: "", value: "", kg: 0, country: "", masterkg:"",qr:"", 
  color:"",
  lot:"",
  grade:"",

  modelname:"",
  provider:"",
  country:"",

  subfamilia:"",
// subfamdescription:"",
  
  colordescription:"",
  colorcode:"",
  codealfa:"",
  codefamilia:"",
  familiadescription:""


});

fetchArticles(); 
}

    const [resinaFamilias, setResinaFamilias] = useState([]);
    const [resinaSubFamilias, setResinaSubFamilias] = useState([]);
    const [providers, setProviders] = useState([]);
    const [color, setColor] = useState([]);



  const [product, setProduct] = useState("");
  const qrCodeRef = useRef(null);

  const [qrData, setQrData] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;

    if (name === "value" || name === "kg") {
      parsedValue = Number(value);
    }else if (name === "codefamilia") {
      const selectedFamilia = resinaFamilias.find(
        (familia) => familia.codefamilia === value
      );
      setSelectedFamiliaCodeAlfa(
        selectedFamilia ? selectedFamilia.codealfa : ""
      );
      setSelectedFamiliaDescription(
        selectedFamilia ? selectedFamilia.familiadescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        familiadescription: value === "SEL" ? "" : selectedFamilia.familiadescription,
       codealfa: value === "SEL" ? "" : selectedFamilia.codealfa,
        codefamilia: value === "SEL" ? "" : value,
      }));
    } else if (name === "subfamilia") {
      const selectedSubFamilia = resinaSubFamilias.find(
        (subfamilia) => subfamilia.subfamilia === value
      );
      setSelectedSubFamilia(
        selectedSubFamilia ? selectedSubFamilia.subfamilia : ""
      );
      setSelectedSubFamiliaDescription(
        selectedSubFamilia ? selectedSubFamilia.subfamdescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        subfamdescription: value === "SEL" ? "" : selectedSubFamilia.subfamdescription,
       subfamilia: value === "SEL" ? "" : value,
      }));
    }  else if (name === "provider") {
      const selectedProvider = providers.find(
        (provider) => provider.provider === value
      );
      setSelectedProvider(
        selectedProvider ? selectedProvider.provider : ""
      );
      setSelectedCountry(
        selectedProvider ? selectedProvider.country : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        country: value === "SEL" ? "" : selectedProvider.country,
       provider: value === "SEL" ? "" : value,
      }));
    }  else if (name === "color") {
      const selectedListaColor = color.find(
        (col) => col.colorcode === value
      );
      setSelectedColor(
        selectedListaColor ? selectedListaColor.colorcode : ""
      );
      setSelectedColorDescription(
        selectedListaColor ? selectedListaColor.colordescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        colordescription: value === "SEL" ? "" : selectedListaColor.colordescription,
       colorcode: value === "SEL" ? "" : value,
       color: value === "SEL" ? "" : selectedListaColor.colordescription,
      }));
    } 

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value === "SEL" ? "" : value,
      };

      // console.log(newFormData.tipomodelo, value);

      // Create codeConcatenation using the newFormData
    let codeConcatenation = `${newFormData.codealfa || ''}${newFormData.subfamilia|| ''}${newFormData.masterkg || ''}${newFormData.provider || ''}${newFormData.country || ''}${newFormData.colorcode || ''}` ;
      
      // Update "code" field
    newFormData.code = codeConcatenation;

    //Garrafon Asa rosca 19 Lts. R-49 Morado Tepa
      let descriptionConcatenation = `${newFormData.familiadescription || ''} ${newFormData.subfamilia || ''} ${newFormData.provider || ''} ${newFormData.colordescription || ''}`
     newFormData.modelname = descriptionConcatenation;
      return newFormData;
    });

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
      };
  
      const response = await apiWrapper("almacen", "POST", formDataWithNumberValue);
      resetAllStates()
  
      setProduct(response._id);
      
    //   console.log("Response:", response);
 if (response.msg) {
  if (response.msg === "Code already exists") {
    setCodeError(response.msg);
    setMessage(
      <Alert className="flex justify-center" severity="error">
        El código  {formDataWithNumberValue.code}   ya existe.
        Por favor asigne uno nuevo.
      </Alert>
    );
    setTimeout(() => {
      setMessage("");
    }, 6000);
    setTimeout(() => {
      setCodeError(false);
      
    }, 6000);
 }

 }
else{ 




      //to get all the data in the qr
      const updatedFormData = { ...formDataWithNumberValue };
      const updatedQRData = JSON.stringify({ 
        code: updatedFormData.code , 
        provider: updatedFormData.provider,
        masterkg: updatedFormData.masterkg,
       // country: updatedFormData.country,

        id: response._id });
      setQrData(updatedQRData);
  
    
       
       setMessage(
         <Alert className="flex justify-center " severity="success">
           <AlertTitle>¡Nueva Resina añadida!</AlertTitle>
           <div className="flex flex-col items-center justify-center h-full">
             <div>
               <strong>Codigo:</strong> {updatedFormData.code}
             </div>
             <div>
               <strong>Model:</strong> {updatedFormData.modelname}
             </div>
             <div>
               <strong>kg:</strong> {updatedFormData.masterkg}
             </div>
             <div>
               <strong>Proveedor:</strong> {updatedFormData.provider}
             </div>
             <div>
               <strong>Pais:</strong> {updatedFormData.country}
             </div>
             
             <div>
               <strong>¡Recuerda descargar el código QR!</strong>
             </div>
           </div>
         </Alert>
       );
   
       // Clear the message after 1 second
       setTimeout(() => {
         setMessage("");
       }, 9000);
     }} catch (error) {
       console.error(error);
       
       setMessage(
         <Alert className="flex justify-center" severity="error">
           El codigo ya existe en el DB, 
           
           Por favor, compruébalo de nuevo.
         </Alert>
       );
       setTimeout(() => {
         setMessage("");
       }, 9000);
     }
   } 

  const handleSaveQRCode = (event) => {
    event.stopPropagation();
    const canvas = qrCodeRef.current.querySelector("canvas");
    if (canvas) {
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = `${JSON.parse(qrData).code}_qr_code.png`;
      link.click();
      
    }
    
  };

  const handleResetQRCode = () => {
    //setQrData("");
   // qrCodeRef.current = null;
   setQrData('');
  setProduct('');
   // window.location.reload()
   
  }


 {/*
   useEffect(() => {
    setFormData({ code: "", provider: "", value: 0, country:"", masterkg:"" , kg:0});
  }, []);
*/}

 // const qrData = JSON.stringify({ ...formData, id: product, country: formData.country });
  //console.log(qrData)

  
    const navigate = useNavigate();

    const navigateToDashboardProduct = () => {
      navigate("/dashboardproducts");
    };

  return (

    <section className=" bg-gray-900  ">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 
        bg-gray-800 border-purple-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                    Añadir Resina
                </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                        <label htmlFor="code"
                          className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Codigo</label>
                        <input type="text" name="code" id="code" 
                        value={formData.code}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             "
                            placeholder="codigo" required/> 
                            {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                    <div>
                        <label htmlFor="modelname"
                        className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Nombre Modelo</label>
                        <textarea type="text" name="modelname" id="modelname" 
                        value={formData.modelname}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             "
                            placeholder="modelo" required/> 
                            {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-1 grid-rows-1">
              <div>

                <label
                  htmlFor="provider"
                  className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Proveedor y País
                </label>

                <select
                 className={`rounded-lg  w-full p-2 text-white ${formData.provider === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="provider"
                  value={formData.provider}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {providers.map((provider) => (
                    <option key={provider._id} value={provider.provider}>
                      {provider.provider}
                    </option>
                  ))}
                </select>
              </div>

             

                </div>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid xl:gap-20 md:gap-10 sm:gap-2 md:grid-cols-2 ">
             
             
            
                <div className="flex flex-col flex-grow">
                  <input
                    type="text"
                    name="provider"
                    placeholder="proveedor..."
                    className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             "
                    value={formData.provider}
                    onChange={() => {}}
                //    readOnly
                    required
                  /> 
                   </div>
                   <div className="flex flex-col flex-grow mt-2">
                  <input
                    type="text"
                    name="country"
                    placeholder="país..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.country}
                    onChange={() => {}}
                 //   readOnly
                    required
                  />
                
                </div>
              </div>

                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
              <div>

                <label
                  htmlFor="codefamilia"
                  className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Familia
                </label>

                <select
                 className={`rounded-lg  w-full p-2 text-white ${formData.codefamilia === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="codefamilia"
                  value={formData.codefamilia}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {resinaFamilias.map((fam) => (
                    <option key={fam._id} value={fam.codefamilia}>
                      {fam.familiadescription}
                    </option>
                  ))}
                </select>
              </div>

              <div>

                  <label
                    htmlFor="subfamilia"
                    className="block mb-2 mt-2 text-sm font-medium  text-white xl:text-xl uppercase"
                  >
                    SubFamilia
                  </label>
                  <select
                 className={`rounded-lg  w-full p-2 text-white ${formData.subfamilia === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="subfamilia"
                  value={formData.subfamilia}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {resinaSubFamilias.map((subfam) => (
                    <option key={subfam._id} value={subfam.subfamilia}>
                      {subfam.subfamilia}
                    </option>
                  ))}
                </select>
              </div>

                </div>

                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid xl:gap-20 md:gap-10 sm:gap-2 md:grid-cols-2 ">
                <div className="flex flex-col gap-2">
                  <input
                    type="text"
                    name="codealfa"
                    placeholder="código alfa..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.codealfa}
                    onChange={() => {}}
                 //  readOnly
                   required
                  />

                  <input
                    type="text"
                    name="familiadescription"
                    placeholder="familia descripción ..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.familiadescription}
                    onChange={() => {}}
                    //readOnly
                    required
                  />
                 </div>
             
            
                <div className="flex flex-col flex-grow mt-2">
                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamiliatipo código..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.subfamilia}
                    onChange={() => {}}
                //    readOnly
                    required
                  />

            {selectedSubFamiliaDescription && ( 
                  <input
                    type="text"
                    name="subfamdescription"
                    placeholder="subfamilia descripción..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.subfamdescription}
                    onChange={() => {}}
                 //   readOnly
                    required
                  />
                  )}
                </div>
              </div>

              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-1 grid-rows-1">
              <div>

                <label
                  htmlFor="provider"
                  className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Color
                </label>

                <select
                 className={`rounded-lg  w-full p-2 text-white ${formData.colorcode === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="color"
                  value={formData.colorcode}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {color.map((col) => (
                    <option key={col._id} value={col.colorcode}>
                      {col.colordescription}
                    </option>
                  ))}
                </select>
              </div>

             

                </div>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid xl:gap-20 md:gap-10 sm:gap-2 md:grid-cols-2 ">
             
             
            
                <div className="flex flex-col flex-grow">
                  <input
                    type="text"
                    name="colorcode"
                    placeholder="codigo col..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.colorcode}
                    onChange={() => {}}
                //    readOnly
                    required
                  /> 
                   </div>
                   <div className="flex flex-col flex-grow mt-2">
                  <input
                    type="text"
                    name="colordescription"
                    placeholder="color descripción..."
                    className="
                    sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                    bg-gray-700 
                     placeholder-gray-400 text-white
                       "
                    value={formData.colordescription}
                    onChange={() => {}}
                 //   readOnly
                    required
                    />
                    {codeError && <p className="error-message text-white">{codeError}</p>}
                
                </div>
              </div>



                 

                    <div>
                        <label htmlFor="lot"
                         className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Lot</label>
                        <input type="text" name="lot" id="lot" 
                        value={formData.lot}
                        onChange={handleInputChange}
                        className="
                        sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                        bg-gray-700 
                         placeholder-gray-400 text-white
                           "
                            placeholder="lot" /> 
                            {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>

                    <div>
                        <label htmlFor="grade"
                          className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Grade</label>
                        <input type="grade" name="grade" id="grade" 
                        value={formData.grade}
                        onChange={handleInputChange}
                        className="
                        sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                        bg-gray-700 
                         placeholder-gray-400 text-white
                           "
                            placeholder="grade"/> 
                            {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                   
               
                   {/* 
                    <div>
                        <label htmlFor="value" className="block mb-2 text-sm font-medium  text-white">Value</label>
                        <input type="number" name="value" id="value" placeholder="if new product should be zero" 
                        value={formData.value}
                        onChange={handleInputChange}
                       className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             " required=""/>
                    </div>
  */}
                    <div>
                        <label htmlFor="masterkg"  className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Master-Kg</label>
                        <input type="number" name="masterkg" id="masterkg" placeholder="añadir kg" 
                        value={formData.masterkg}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             " required/>
                    </div>
{/* 
                    <div>
                        <label htmlFor="kg" className="block mb-2 text-sm font-medium  text-white">Kg</label>
                        <input type="number" name="kg" id="kg" placeholder="at first time it will take masterkg value" 
                        value={formData.kg}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             " required=""/>
                    </div>
*/}
                  
                  <span className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p0 bg-gray-800  grid gap-2 md:grid-cols-2 md:justify-between xl:justify-between">
                    <button type="submit"
                     className="w-full text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800 mt-8">Guardar</button>
                    <button type="dashboardproducts"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-800 mt-8"
                        onClick={navigateToDashboardProduct}
                        >Panel Resina</button>
                   </span>
                    </form>
                    {message && (
        <div className="message-container">
          {message}
        </div>
      )}
                    
                    <div className="bg-white flex flex-col items-center justify-center">
                        {/* Display QR code */}
  {product &&  (
        <div className="mt-4" ref={qrCodeRef}>
          <QRCode value={qrData} size={300} />
        </div>
      )}
      
      {/* Save QR code button */}
      {product && (
        <>
        <button  className=" text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4
         bg-pink-600 hover:bg-pink-700 focus:ring-pink-800" onClick={handleSaveQRCode}>Download QR Code</button>
         <button  className=" text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4
         bg-pink-600 hover:bg-pink-700 focus:ring-pink-800" onClick={ handleResetQRCode}>Reset & New QR Code</button>
     <div className="max-w-50 overflow-hidden break-all border  p-4 my-2">
     
     {/* Display raw data for testing */}
     <pre className="whitespace-pre-wrap">{qrData}</pre>
     </div>
         </>
      )}



                    </div>
                


            </div>
        </div>
    </div>
  </section>

    
   
  );
};

export default NewProduct;