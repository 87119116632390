import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
import EditUserModal from "../editmodal/EditUserModal";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import SearchBar from "../searchbar/searchbar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import EditAlertDeleteUserModal from "../editmodal/EditAlertDeleteUserModal";
import { socket } from "../../utils/variousConst.js";
import { formatMexicanTime } from "../../utils/variousConst.js";



const UsersDashboardComponent = () => {
  const [users, setUsers] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);

  const [editableItem, setEditableItem] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [alertDeleteUser, setAlertDeleteUser] = useState(null);
  const [isAlertUserDeleteModalOpen, setIsAlertUserDeleteModalOpen] =
    useState(false);

    const [loading, setLoading] = useState(true);

  const openAlertUserDeleteModal = (user, index) => {
    setAlertDeleteUser({ user, index });
    setIsAlertUserDeleteModalOpen(true);
  };

  const closeOpenAlertUserDeleteModal = () => {
    setAlertDeleteUser(null);
    setIsAlertUserDeleteModalOpen(false);
  };

  const openEditModal = (user, index) => {
    setEditableItem({ user, index });
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditableItem(null);
    setEditModalOpen(false);
  };

  const handleSave = (index, editUser) => {
    
    // Make a PATCH request to update the user on the server
    const updateArticleOnServer = async () => {
      try {
    //    console.log(editUser);
        await apiWrapper(`user/${editUser._id}`, "PATCH", editUser);
        // If the PATCH request is successful, update the user in the state
        const editUsers = [...users];
        editUsers[index] = editUser;
        setUsers(editUsers);
        const response = await apiWrapper("user", "GET");
        setUsers(response);

        // Update the filtered roles as well
        const updatedFilteredEmails = [...filteredEmails];
        updatedFilteredEmails[index] = editUser;
        setFilteredEmails(updatedFilteredEmails);

        closeEditModal();
      } catch (error) {
        console.error("Error updating user:", error);
        // Handle error updating user
      }
    };

    updateArticleOnServer();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiWrapper("user", "GET");
        setUsers(response);
      //  setFilteredEmails([...response])
      //  console.log([...response])
      } catch (error) {
        // Handle error
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    try {
      // Make a DELETE request to the API to delete the user with the given ID
      await apiWrapper(`user/${userId}`, "DELETE");

      // Update the users state by removing the deleted user from the array
      setUsers(users.filter((user) => user._id !== userId));
    } catch (error) {
      // Handle error
      console.error("Error deleting user:", error);
    }
  };

  const navigate = useNavigate();
  const navigateToNewUser = () => {
    navigate("/register");
  };

  const [isPasswordExpanded, setIsPasswordExpanded] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

 const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredEmails([]); // Clear the filtered email list
    } else {
      const filtered = users.filter((user) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseCode = user.username.toLowerCase();
        const lowerCaseProvider = user.email.toLowerCase();
        const lowerCaseRole = user.role.toLowerCase();
        return (
          lowerCaseCode.includes(lowerCaseSearchQuery) ||
          lowerCaseProvider.includes(lowerCaseSearchQuery) ||
          lowerCaseRole.includes(lowerCaseSearchQuery)
        );
      });
      setFilteredEmails(filtered);
    }
  }, [searchQuery, users]); 
     

  useEffect(() => {
    const handleCreateUser = (newUser) => {
      setUsers((prevUsers) => [...prevUsers, newUser]);
    };
    // Add the 'createUser' event listener
    socket.on("register", handleCreateUser);
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("register", handleCreateUser);
    };
  }, []);

  useEffect(() => {
    socket.on("deleteUser", (deletedUserId) => {
      setUsers((prevUsers) => {
        return prevUsers.filter((user) => user._id !== deletedUserId);
      });
    });

    return () => {
      socket.off("deleteUser");
    };
  }, []);

  useEffect(() => {
    socket.on("editUser", (updatedEditUser) => {
      // console.log("cheedita",updatedEditUser.data.username)
      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) => {
          if (user._id === updatedEditUser._id) {
            //console.log("check",updatedEditUser.customer)

            return { ...user, ...updatedEditUser.data };
          }
          return user;
        });
        //console.log("check2", updatedUsers)
        return updatedUsers;
      });
    });

    return () => {
      socket.off("editUser");
    };
  }, []);

 //spinning loading
 useEffect(() => {
  if (users.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [users]);

  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
              Panel de Usuarios
            </h1>
            <div className="flex items-center justify-center">
              <button
                onClick={navigateToNewUser}
                className=" text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                         bg-red-600 hover:bg-red-700 focus:ring-red-800 mt-8"
              >
                Añadir Usuario
              </button>
            </div>
            <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
          </div>
          <div className=" overflow-auto rounded-lg hidden md:block">
            <table className="min-w-full divide-y divide-gray-200 table-auto ">
              <thead className="bg-gray-300 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider flex justify-center items-center">
                    Usuario
                  </th>

                  {isPasswordExpanded && (
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider ">
                      Contraseña
                    </th>
                  )}
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Correo <br></br>
                    <button
                      className="px-6 py-3 text-center text-xs font-thin text-gray-500  -tracking-tight"
                      onClick={() => setIsPasswordExpanded(!isPasswordExpanded)}
                    >
                      {isPasswordExpanded ? "Ocultar Contraseña" : "Mostrar Contraseña"  }
                    </button>
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider flex justify-center items-center">
                    Role
                  </th>
                 
                  <th className="text-center text-xs font-medium text-gray-500 uppercase tracking-wider   ">
                    Fecha
                  </th>

                  <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Edit
                  </th>
                  <th className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Borrar
                  </th>
               
                  {/* Add more table headers as needed */}
                </tr>
              </thead>

              <tbody>
                {filteredEmails.map((user, index) => (
                  <tr
                  className={
                    index % 2 === 0 ? "bg-amber-200 text-center" : "bg-amber-300 text-center" 
                  }
                    key={`${user.username}-${user.email}`}
                  >
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                   
                    <td> {formatMexicanTime(user.createdAt)}</td>
                    <td className=" whitespace-nowrap">
                      <EditIcon onClick={() => openEditModal(user, index)} />
                    </td>
                    <td className="px-2  whitespace-nowrap m-18">
                      <DeleteOutlineIcon
                        onClick={() => openAlertUserDeleteModal(user)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>

              <tbody className=" divide-y divide-gray-200 text-center">
                {users.length === 0 || filteredEmails.length > 0 ? (
                 <tr className="text-center">
                 <td colSpan="8">
               {loading ? (
                 <p className="animate-spin text-white text-center">&#9696;</p>
               ) : (
                 <p className="text-center  font-medium text-white">No hay datos en DB</p>
               )}
               </td>
                 </tr>
                ) : (
                  users
                    .slice()
                    .reverse()
                    .map((user, index) => (
                      <tr
                        className={
                          index % 2 === 0 ? "bg-gray-400" : "bg-gray-300"
                        }
                        key={user._id}
                      >
                        <td className="px-6 py-4 whitespace-nowrap font-medium">
                          {user.username}
                        </td>
                        {isPasswordExpanded && (
                          <td className="px-6 py-4 whitespace-nowrap text-xs">
                            {user.password}
                          </td>
                        )}
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user.email}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap font-medium">
                          {user.role}
                        </td>
                        
                        <td className="px-6 py-4 whitespace-nowrap text-xs">
                        {formatMexicanTime(user.createdAt)}
                        </td>

                        <td className=" whitespace-nowrap">
                          <EditIcon
                            onClick={() => openEditModal(user, index)}
                          />
                        </td>
                        <td className="px-2  whitespace-nowrap m-18">
                          <DeleteOutlineIcon
                            onClick={() => openAlertUserDeleteModal(user)}
                          />
                        </td>
                        
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 gap-4 md:hidden">
            <div className="flex justify-center items-center" >
            <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
            <div>
              {filteredEmails.map((user, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-amber-200"
                      : " border rounded-lg bg-amber-300"
                  }
                  key={`${user.username}-${user.email}`}
                >
                  <div className="  border rounded-lg  space-x-2 text-sm">
                    <div className="  text-sm font-medium break-all   ">
                      Usuario:{user.username}
                    </div>
                  </div>
                  <div className="text-sm font-medium break-all   ">
                    Correo:{user.email}
                  </div>
                  <div className="text-sm font-medium break-all   ">
                    Role:{user.role}
                  </div>
                  <div className="text-sm font-medium break-all   ">
                  Fecha:{formatMexicanTime(user.createdAt)}
                  </div>
                  <div className="flex flex-row justify-between ">
                      <button
                        onClick={() => openEditModal(user, index)}
                        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                      >
                        <EditIcon />
                      </button>
                      <button
                        onClick={() => openAlertUserDeleteModal(user)}
                        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                      >
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                </div>
              ))}
            </div>

            {users.length === 0 || filteredEmails.length > 0 ? (
             loading ? (
              <div className="border rounded-lg bg-sky-900">
                <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
                <div className="animate-spin text-white">&#9696;</div>
                
                </div>
              </div>
            ): (
            <div className="border rounded-lg bg-sky-900">
              <p className="text-center text-white">No hay datos en DB</p>
            </div>
            )
            ) : (
              users
                .slice()
                .reverse()
                .map((user, index) => (
                  <div
                    className={
                      index % 2 === 0
                        ? "border rounded-lg bg-gray-400"
                        : "border rounded-lg bg-gray-300"
                    }
                    key={user._id}
                  >
                    <div className="bg-gray-500  border rounded-lg flex items-center justify-center space-x-2 text-sm">
                      <div className="  flex justify-center items-center font-medium break-all text-2xl mb-1  ">
                        {" "}
                        {user.username}
                      </div>
                    </div>
                    <div className="font-medium break-all">
                      Correo: {user.email}
                    </div>
                    <div className="text-sm font-medium break-all ">
                      Role: {user.role}
                    </div>
                    
                    <div className=" text-xs ">
                      Fecha:{" "}
                      {formatMexicanTime(user.createdAt)}
                    </div>
                    <div className="flex flex-row justify-between ">
                      <button
                        onClick={() => openEditModal(user, index)}
                        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                      >
                        <EditIcon />
                      </button>
                      <button
                        onClick={() => openAlertUserDeleteModal(user)}
                        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                      >
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
          <div className="mt-10 flex items-center  justify-center">
            <EditUserModal
              editableItem={editableItem}
              onSave={handleSave}
              onClose={closeEditModal}
            />
          </div>
        </div>
      )}
      {isAlertUserDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteUserModal
              alertDeleteUser={alertDeleteUser}
              onSave={handleDelete}
              onClose={closeOpenAlertUserDeleteModal}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default UsersDashboardComponent;
