import { useState, useEffect } from "react";
import { apiWrapper } from "../utils/apiwrapper";
import { useNavigate } from "react-router-dom";
import { setUserSession } from "../utils/localStorage.utils";
import { socket } from "../utils/variousConst";
//import { getSocket } from "../utils/variousConst";
import { tokenDecoder } from "../utils/tokenDecoder";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

const Login = () => {


  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [coordinateError, setCoordinateError] = useState("");

  const [site, setSite] = useState("");

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    site: "",
  });

  const [userId, setUserId] = useState("");

  const handleInputChange = (event) => {
    // console.log("handleInputChange called");
    const { name, value } = event.target;
    let parsedValue = value;

    if (name === "value") {
      parsedValue = Number(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
    // If the user is changing the 'site', store it in local storage
    if (name === "site") {
      // console.log('Storing site in session storage:', parsedValue);
      sessionStorage.setItem("site", parsedValue);
    }
  };

  const loginUser = async (event) => {
    event.preventDefault();
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
      };
      //
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      //   console.log("io mi loggo da qui:",latitude, longitude)

      //

      const response = await apiWrapper("user/login", "POST", {
        ...formDataWithNumberValue,
        latitude,
        longitude,
      });
      setFormData({ email: "", password: "", site: "" });
      setUserId(response._id);

      //console.log("Response:", response);
      if (response.msg) {
        // Handle specific error messages from the backend
        if (
          response.msg === "El password es obligatorio" ||
          response.msg === "El email es obligatorio"
        ) {
          setEmailError(response.msg);
          setPasswordError("");
          setTimeout(() => {
            setEmailError(false);
          }, 6000);
        } else if (response.msg === "Usuario no encontrado") {
          // Show message for user not found
          setEmailError(response.msg);
          setShowErrorMessage(true);
          setPasswordError("");
          setTimeout(() => {
            setEmailError(false);
            setShowErrorMessage(false);
          }, 6000);
        } else if (response.msg === "Contraseña incorrecta") {
          setEmailError("");
          setPasswordError(response.msg);
          setShowErrorMessage(true);
          setTimeout(() => {
            setPasswordError(false);
            setShowErrorMessage(false);
          }, 6000);
        } else if (response.msg === "Location not within tolerance") {
          setCoordinateError(response.msg);

          setTimeout(() => {
            setCoordinateError(false);
          }, 6000);
        } else {
          // For other unexpected errors, you can display a generic error message to the user
          setEmailError("");
          setPasswordError("");
        }
      } else {
        // Login was successful, set the access token in local storage or session
        setUserSession({
          token: response.accessToken,
          user: response.user,
        });

        // Navigate to the appropriate route based on user role
        const userIdToken = tokenDecoder(setUserSession);
        const role = userIdToken.role;
        const isAdmin = role === "admin";
        isAdmin
          ? (window.location.href = "/admin")
          : (window.location.href = "/home");
      }
    } catch (error) {
      //setShowErrorMessage(true);
      console.error("Request failed:", error);
    }
  };

  return (
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        <div
          className="w-full rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 
        bg-gray-800 "
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-slate-500">
              Iniciar sesión
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={loginUser}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium  text-slate-500"
                >
                  Correo
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-sky-800
                           placeholder-gray-400 text-white
                            focus:ring-sky-500 focus:border-sky-500"
                  placeholder="nombre@xxxx.com"
                  required
                />
                {emailError && (
                  <Snackbar
                    open={true}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    //style={{ top: '50%', transform: 'translateY(-50%)' }}
                  >
                    <Alert className="flex justify-center" severity="error">
                      <div className="flex flex-col items-center">
                        <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                          {emailError}
                        </div>
                        {showErrorMessage && (
                          <p className="error-message text-red-500 text-center">
                            Compruebe sus credenciales de inicio de sesión.
                          </p>
                        )}
                      </div>
                    </Alert>
                  </Snackbar>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium  text-slate-500"
                >
                  Contraseña
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  value={formData.password}
                  onChange={handleInputChange}
                  className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-sky-800 placeholder-gray-400 text-white
                          focus:ring-sky-500 focus:border-sky-500"
                  required
                />
                {passwordError && (
                  <Snackbar
                    open={true}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    //style={{ top: '50%', transform: 'translateY(-50%)' }}
                  >
                    <Alert className="flex justify-center" severity="error">
                      <div className="flex flex-col items-center">
                        <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                          {passwordError}
                        </div>
                        {showErrorMessage && (
                          <p className="error-message text-red-500 text-center">
                            Compruebe sus credenciales de inicio de sesión.
                          </p>
                        )}
                      </div>
                    </Alert>
                  </Snackbar>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="site"
                  className="block mb-2 text-sm font-medium  text-slate-500"
                >
                  Sitio
                </label>
                <select
                  name="site"
                  id="site"
                  value={formData.site}
                  onChange={handleInputChange}
                  className="block w-full p-2.5 bg-gray-700 border-red-600 placeholder-slate-500 text-slate-300 focus:ring-red-500 focus:border-red-500 rounded-lg"
                >
                  <option className="text-red-400" value="">
                    elegir ubicacion de trabajo
                  </option>
                  <option value="cdmx">CDMX</option>
                  <option value="tlaxcala">TLX</option>
                </select>
              </div>

              <button
                type="submit"
                className="w-full text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sky-800 hover:bg-sky-700 focus:ring-sky-800"
              >
                Login
              </button>
            </form>

            {coordinateError && (
              <Snackbar
                open={true}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                //style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                  <div className="flex flex-col items-center text-center">
                    Es necesario perimitir la geolocalización por utilizar la
                    app.
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2 text-center">
                      Solo se puede acceder desde la Fábrica
                    </div>
                  </div>
                </Alert>
              </Snackbar>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
