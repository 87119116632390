import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Select from 'react-select';
import ViewModelModal from "../editmodal/ViewModelModal";
import { Snackbar } from "@mui/material";
import Modal from '@mui/material/Modal';
import { socket } from "../../utils/variousConst.js";
import { mexicanTime, options } from "../../utils/variousConst.js";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";

 
const NewOrder = () => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  
  const [orderError, setOrderError] = useState("")
  const[message, setMessage]=useState("")
  const [messageQRDownload, setMessageQRDownload] = useState("");
const [models, setModels] = useState([]);
const [preformas, setPreformas] = useState([]);
const [articles, setArticles] = useState([])
const [selectedCode, setSelectedCode] = useState('');
const [selectedModel, setSelectedModel] = useState('');
const [selectedPreforma, setSelectedPreforma] = useState('');
const [selectedStock, setSelectedStock] = useState('')
const [paq, setPaq] = useState('');
const [paqstock, setPaqstock] = useState('');
const [udstock, setUdstock] = useState('');
const [stockOrderNr, setStockOrderNr] = useState('');
 const [stockOrderId, setStockOrderId] = useState('');
 const [codemodel, setCodeModel]= useState('')
 const [tipomodeldescription, setTipoModelDescription]= useState('');
const [selectedMasterKg, setSelectedMasterKg] = useState('');
const [selectedColor, setSelectedColor] = useState('')
const [selectedColorPreforma, setSelectedColorPreforma] = useState('');
const [selectedColorResina, setSelectedColorResina] = useState('')
const [orders, setOrders] = useState([]);
const [selectedMateria, setSelectedMateria] = useState('')
const [lastOrderNr, setLastOrderNr]= useState([])
const [customers, setCustomers] = useState([])
const [selectedCustomer, setSelectedCustomer] = useState('')

const [viewItem, setViewItem] = useState(null);
const [isViewModalOpen, setViewModalOpen] = useState(false);

const [isDownloaded, setIsDownloaded] = useState(false);


const enumToText = {
  
  resinaandpreforma: 'Garrafón 2 etapas',
  solo_preforma: 'Preforma',
  solo_resina: 'Garrafón',
  stock: 'Garrafónes  en Stock'
  //resina_ya_producida:'Resina ya producida',
  
};

const openViewModal = (model,index) => {
  setViewItem({model, index});
  setViewModalOpen(true);
};

const closeViewModal = () => {
  setViewItem(null);
  setViewModalOpen(false);
};

const handleSelectCMChange = (option) => {
  if (option === null) {
    setSelectedCustomer("");
    setFormData(prevFormData => ({
      ...prevFormData,
      customer: "",
    }));
  } else {
   // console.log(`Option selected:`, option.value);
    const customer = option.value;
    setSelectedCustomer(customer.razonsocial);
    setFormData(prevFormData => ({
      ...prevFormData,
      customer: `${customer.razonsocial}, ${customer.direccion} , ${customer.estado} `,
    }));
  }
}

const handleSelectChange = (event) => {
  const code = event.target.value;

  if (code === "") {
    // "Seleccionar resina" is selected, reset the selected code and color
    setSelectedCode("");
    setSelectedColorResina("");

    // Also reset the corresponding fields in formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      code: "",
      color: "",
    }));
  } else {
    // Find the article with the selected code
    const selectedArticle = articles.find(article => article.code === code);

    if (selectedArticle) {
      const { code, color } = selectedArticle;
      
      // Set both the selected code and color
      setSelectedCode(code);
      setSelectedColorResina(color);

      // Update your form data
      setFormData((prevFormData) => ({
        ...prevFormData,
        code: code,
        color: color,
      }));
    }
  }
};

const handleSelectChangez = (selectedValue, selectedSetter, models, isPreforma) => {
  selectedSetter(selectedValue);
  
  // Find the selected model/preforma object
  const selectedItem = models.find((item) => item.code === selectedValue);
//console.log("dallapref",selectedValue)
  if (selectedItem) {
    const selectedMasterKg = selectedItem.masterkg || '';
    const selectedColor = selectedItem.color || '';

    if (isPreforma) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        preforma: selectedItem.modelname,
        preformakg: selectedMasterKg,
        preformacolor: selectedColor,
        codepreforma:selectedValue
      }));

      setSelectedColorPreforma(selectedColor); // Update selectedColorPreforma
    } else {
     // console.log("modello", selectedItem.tipomodelodescription)
      setFormData((prevFormData) => ({
        ...prevFormData,
        finalproduct: selectedItem.modelname,
        finalproductkg: selectedMasterKg,
        finalproductcolor: selectedColor,
        codemodel:selectedItem.code,
        tipomodeldescription:selectedItem.tipomodelodescription
      }));
    }
    
    setSelectedColor(selectedColor);
    setSelectedMasterKg(selectedMasterKg);
  }
};

// Assuming you have setSelectedModel and setSelectedPreforma as state setters
const handleSelectModelChange = (selectedOption) => {
 // console.log(selectedOption)
  //const selectedValue = event.target.value === "Seleccionar modelo" ? "" : event.target.value;
  const selectedValue = selectedOption ? selectedOption.code : '';

  if (!selectedValue) {
    // Model is deselected, clear the color fields
    setSelectedColor('');
    setSelectedModel('')
     // Also reset the finalproduct field in formData
     setFormData((prevFormData) => ({
      ...prevFormData,
      finalproduct: "",
    }));
  } else {
    handleSelectChangez(selectedValue, setSelectedModel, models, false); // false indicates it's not preforma
  }
};

const handleSelectPreformaChange = (selectedOption) => {
  console.log(selectedOption)
  const selectedValue = selectedOption ? selectedOption.code : '';

  if (!selectedValue) {
    // Preforma is deselected, clear the color fields
    setSelectedPreforma('');
    setSelectedColorPreforma('');

    // Also reset the preforma field in formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      preforma: "",
    }));
  } else {
    handleSelectChangez(selectedValue, setSelectedPreforma, preformas, true); // true indicates it's preforma
  }
};

const handleSelectStockChange = (selectedOption) => {
  // console.log(selectedOption)
  
   const selectedValue = selectedOption ? selectedOption.finalproduct : '';
 //console.log(selectedValue)
 const paq = selectedOption ? selectedOption.pack : '';
 const paqstock = selectedOption ? selectedOption.packitemsstock  : '';
 const udstock = selectedOption ? selectedOption.itemsstock  : '';
 const stockordernr = selectedOption ? selectedOption.ordernr : '';
 const stockorderid = selectedOption ? selectedOption._id : '';

 const codemodel =selectedOption ? selectedOption.codemodel : '';
const tipomodeldescription = selectedOption ? selectedOption.tipomodeldescription : '';


 setSelectedStock(selectedValue)
 setPaq(paq)
 setPaqstock(paqstock)
 setUdstock(udstock)
 setStockOrderNr(stockordernr)
 setStockOrderId(stockorderid)
 setCodeModel(codemodel)
 setTipoModelDescription(tipomodeldescription)

   if (!selectedValue) {
     // Preforma is deselected, clear the color fields
     setSelectedStock('');
     setPaq('');
    setPaqstock('');
    setUdstock('');
    setStockOrderNr('')
    setStockOrderId('')
      // Also reset the preforma field in formData
     setFormData((prevFormData) => ({
       ...prevFormData,
       finalproduct: "",
     }));
   } 
   //else {
 //    handleSelectChangez(selectedValue, setSelectedPreforma, preformas, true); // true indicates it's preforma
 //  }
 };

useEffect(() => {
  const fetchCustomers = async () => {
    try {
      const response = await apiWrapper("customers", "GET");
      setCustomers(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching customers:", error);
    }
  };

  fetchCustomers();
}, []);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await apiWrapper("almacen", "GET");
        setArticles(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await apiWrapper("model", "GET");
        setModels(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  useEffect(() => {
    const fetchPreforma = async () => {
      try {
        const response = await apiWrapper("preforma", "GET");
        setPreformas(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching models:", error);
      }
    };

    fetchPreforma();
  }, []);

  const fetchOrder = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      setOrders(response);

      if (response.length > 0) {
        setLastOrderNr(response[response.length - 1].ordernr);
      } else {
        setLastOrderNr(0);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching models:", error);
    }
  };
  useEffect(() => {

    fetchOrder();
  }, []);

const [stockOrders, setStockOrders] = useState([]);
  const autostock = `CENTRO DE INVESTIGACION DE INGENIERIA Y ADM,, CAMINO REAL #26, SANTA MARIA IXTULCO. TLAXCALA, TLAXCALA CP. 90105 , TLAXCALA, TLAX `
 

  const filterOrders = (stockOrders) =>
  stockOrders.filter((order) => !order.stockcompleted && order.finalproduct !== "" && order.customer === autostock);

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on scannedcompleted and stockcompleted
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);
  
    // Merge the filtered updated orders with existing orders in the state
    setStockOrders((prevStockOrders) => {
      const mergedOrders = filteredUpdatedOrders.map((filteredOrder) => {
        const existingOrder = prevStockOrders.find((order) => order._id === filteredOrder._id);
        return existingOrder ? existingOrder : filteredOrder;
      });
      
      return mergedOrders;
    });
  };
  const fetchStockGaraffones = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      //console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error); 
        }
  };
  useEffect(() => {
    fetchStockGaraffones();
  

  

    // Listen for socket event to handle updates to orders
    socket.on('getAllOrders', (updatedOrderArray) => {
      setStockOrders(updatedOrderArray)
      handleUpdateOrders(updatedOrderArray);
    });
  
    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off('getAllOrders');
    };
  }, []);


  const [formData, setFormData] = useState({
   // ordernr:"",
    customer:"",
     code:"",
     codemodel:'',
     codepreforma:'',
     tipomodeldescription:'',
      totpack:"",
      preforma:"",
      preformakg:"",
       finalproduct:"",
        finalproductkg:"",
        finalproductcolor:"",
         itemstodo:"",
          kgneeded:"",
          kgloadedfromalmacen:"",
           extrakgloadedfromalmacen:"", 
           itemsscanned:"", 
           itemdefected:"", 
           itemdefectedkg:"",
           preformaitemdefected:"", 
           preformaitemdefectedkg:"",
           itemsstock:"", 
           itemsdelivery:"", 
           scannedcompleted:false, 
         stockcompleted:"", 
           deliverycompleted:"", 
           pack:"",
           qr:"", 
           packscanned:"",
           packitemsstock:"", 
           packdelivery:"",
           preformascanned:"",
           preformastock:"",
           preformadelivery:0,
           preformascannedcompleted:false,
           preformastockcompleted:false,
           preformadeliverycompleted:false,
           preformapack:"",
            preformatotpack:"",
           preformapackscanned:"",
            preformapackitemsstock:"",
            preformapackdelivery:0,
            preformacolor:"",
            preformaaddedtoprod:0,
            preformapackaddedtoprod:0,
            materia: selectedMateria,
            personalizacion:"",
          // employeestory:"", 
          stockordernr:"",
          stockorderid:"",
          stockorderdestinonr:"",
          stockorderdestinoid:"",
          stockorderdestinocliente:"",
        
  });
  const [order, setOrder] = useState("");
  const qrCodeRef = useRef(null);

  const [qrData, setQrData] = useState("");



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
     
  

    if (
    name === "finalproductkg" || 
    name === "preformakg" ||
    name === "finalproductcolor" ||
    name === "kg" || 
    name === "itemstodo" ||
    name === "kgneeded" ||
    name === "kgloadedfromalmacen" ||
    name === "extrakgloadedfromalmacen" ||
    name === "itemsscanned" ||
    name === "itemdefected" ||
    name === "preformaitemdefected" ||
    name === "itemsstock" ||
    name === "itemsdelivery" ||
    name === "pack" ||
    name === "totpack"||
    name === "packscanned" ||
    name === "packitemsstock" ||
    name === "packdelivery" ||
    name === "preformascanned" ||
    name === "preformastock" ||
    name === "preformadelivery" ||
    name === "preformapack" ||
    name === "preformatotpack" ||
    name === "preformapackscanned" ||
    name === "preformapackitemsstock" ||
    name === "preformapackdelivery" ||
    name === "preformacolor" ||
    name === "etiquetas" ||
    name === "preformaaddedtoprod" ||
    name === "preformapackaddedtoprod"  ||
    name === 'customer' 
    
   
    ) 
    
    
    {
      parsedValue = Number(value);
    }

   // if (name === "personalizacion") {
  //    parsedValue = value; // handle "personalizacion" separately, without converting to a number
 //   }
   
 if (name === "materia") {
  setSelectedMateria(value);
  if (value === "resina_ya_producida") {
    setFormData(prevState => ({ ...prevState, kgneeded: 0, code:"resina_ya_producida", kgloadedfromalmacen: 0  }));
  }
}

    if ((name === "finalproductkg" || name === "itemstodo") && (parsedValue === 0 || parsedValue === "")) {
      parsedValue = "";
    }
  
    if (name === "finalproductkg" && parsedValue === "") {
      parsedValue = formData.finalproductkg;
    }

   
  
    if (name === "itemstodo" && parsedValue === "") {
      parsedValue = formData.itemstodo;
    }

    if (name === "pack" && parsedValue === "") {
      parsedValue = formData.pack;
    }
  
    if (name === "packscanned" && parsedValue === "") {
      parsedValue = formData.packscanned;
    }

    if (name === "packitemsstock" && parsedValue === "" ) {
      parsedValue = formData.packitemsstock;
    }

    if (name === "packdelivery" && parsedValue === "" ) {
      parsedValue = formData.packdelivery;
    }
    
    if (name === "preformapack" && parsedValue === "") {
      parsedValue = formData.preformapack;
    }
  
    if (name === "preformapackscanned" && parsedValue === "") {
      parsedValue = formData.preformapackscanned;
    }

    if (name === "preformapackitemsstock" && parsedValue === "" ) {
      parsedValue = formData.preformapackitemsstock;
    }

    if (name === "preformapackdelivery" && parsedValue === "" ) {
      parsedValue = formData.preformapackdelivery;
    }
    
    if (name === "preformakg" && parsedValue === "" ) {
      parsedValue = formData.preformakg;
    }

    if (name === "preformaaddedtoprod" && parsedValue === "" ) {
      parsedValue = formData.preformaaddedtoprod;
    }

    if (name === "preformapackaddedtoprod" && parsedValue === "" ) {
      parsedValue = formData.preformapackaddedtoprod;
    }

    if (name === "preformatotpack" || name === "totpack") {
      parsedValue = value === "" ? "0" : value;
      parsedValue = Number(parsedValue);
    }

   

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  
    if (name === "finalproductkg" || name === "itemstodo" || name === "finalproduct" || name === "preforma") {
      const finalproductkg = name === "finalproductkg" ? parsedValue : formData.finalproductkg;
      const itemstodo = name === "itemstodo" ? parsedValue : formData.itemstodo;
      const finalproduct = name === "finalproduct" ? parsedValue : formData.finalproduct;
      const preforma = name === "preforma" ? parsedValue : formData.preforma;
      const preformakg = name === "preformakg" ? parsedValue : formData.preformakg;
    
        // Calculate kgneeded if either "finalproduct" or "preforma" is filled
        let kgneededForFinalProduct = "";
         let kgneededForPreforma = "";


  if (selectedMateria !== "resina_ya_producida") {
    kgneededForFinalProduct =
      formData.finalproduct !== "" && finalproductkg !== 0 && itemstodo !== 0 ? ((finalproductkg * 100) / 100) * ((itemstodo * 100) / 100): "";

    kgneededForPreforma =
      formData.preforma !== "" && preformakg !== 0 && itemstodo !== 0 ? ((preformakg * 100) / 100) * ((itemstodo * 100) / 100): "";
  }
        
          const kgneeded = kgneededForFinalProduct || kgneededForPreforma || "";
       
      // Round kgneeded to 2 decimal places
const roundedKgNeeded = kgneeded !== "" ? parseFloat(kgneeded).toFixed(2) : "";

setFormData((prevFormData) => ({
  ...prevFormData,
  kgneeded: selectedMateria === "resina_ya_producida" ? "0" : roundedKgNeeded,
  
}));
           //let kgneeded = "";

  
   // const finalproductkg = formData.finalproductkg || 0;
   // const preformakg = formData.preformakg || 0;
   // const itemstodo = formData.itemstodo || 0;
   // const kgneeded =
     // finalproductkg !== 0 && itemstodo !== 0
   //     ? (Math.round(finalproductkg * 100) / 100) * (Math.round(itemstodo * 100) / 100)
   //     : "";


   
  
};

    if (name === "itemstodo" || name === "pack"){
      const itemstodo = name === "itemstodo" ? parsedValue : formData.itemstodo;
      const pack = name === "pack" ? parsedValue : formData.pack;
      let totpack = 0;

      if(!pack) {
        totpack = 0;
      } else{
        totpack = itemstodo / pack
      }
       
      
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        totpack: parseFloat(parseFloat(totpack).toFixed(2)),
        
        
      }));
    
    
    }
    
    if (name === "itemstodo" || name === "preformapack"){
      const itemstodo = name === "itemstodo" ? parsedValue : formData.itemstodo;
      const preformapack = name === "preformapack" ? parsedValue : formData.preformapack;
      let preformatotpack = 0;
      
      if (!preformapack){
        preformatotpack = 0;
      } else {
        preformatotpack = itemstodo / preformapack
      }
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        
        preformatotpack: parseFloat(parseFloat(preformatotpack).toFixed(2)),
      
      }));
   
    }
    if (name === "itemsstock") {
        const itemsstock = parsedValue;
        let packitemsstock = 0;
        //let pack =0;

        if (itemsstock > udstock) {
            setMessage(
                <Alert   className="flex justify-center" 
                severity="error"
                style={{ backgroundColor: 'red', color: 'white' }}>
                Los artículos a descontar no pueden ser más que las unidades en stock. Tenemos <span className='text-xl ml-2'>{udstock}</span>  unidades disponibles y intentaste sacar<span className='text-xl ml-2'>{itemsstock}</span> .
                </Alert>
              );          
            setFormData((prevFormData) => ({
                ...prevFormData,
                itemsstock: '',
                packitemsstock:'',
                scannedcompleted:false,
                startorder:false,
                pack:''
              }));
              setTimeout(() => {
                setMessage("");
              }, 5000);

            return;
          }
    
        if (paq) {
          packitemsstock = itemsstock / paq;
        }
    
        if (!Number.isInteger(packitemsstock)){
            setMessage(
                <Alert className="flex justify-center" severity="warning">
               Los paquetes tienen que ser enteros. Por favor, ajusta el pedido
                </Alert>
              );
              setTimeout(() => {
                setMessage("");
              }, 3000);
        }


        setFormData((prevFormData) => ({
          ...prevFormData,
          packitemsstock: parseFloat(packitemsstock.toFixed(2)),
          pack: paq,
          startorder:true,
          scannedcompleted:true,
          stockordernr:stockOrderNr,
          stockorderid:stockOrderId,
          finalproduct:selectedStock,
          codemodel:codemodel,
          totpack:packitemsstock,
          itemstodo:itemsstock,
          
          [name]: parsedValue,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: parsedValue,
        }));
      
      }
   
  };
 
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.materia === "stock" && !Number.isInteger(formData.packitemsstock)){
        setMessage(
            <Alert 
            className="flex justify-center" 
            severity="error"
            style={{ backgroundColor: 'red', color: 'white' }}
          >
           Los paquetes tienen que ser enteros. Por favor, ajusta el pedido
             </Alert>  
        );
        setTimeout(() => {
            setMessage("");
          }, 7000);
        return;
    }
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
       
      };
  
      const response = await apiWrapper("order", "POST", formDataWithNumberValue);

    //  console.log(user)
      const stockorderid = formData.stockorderid;
    //  console.log("questo e l id dello stock", stockorderid)
      //console.log(response._id, response.ordernr, response.customer)

      const stockorderdestinonr =response.ordernr;
      const stockorderdestinoid = response._id;
      const stockorderdestinocliente = response.customer;

      if (formData.materia === "stock" ) {
      const res = await apiWrapper(
        `order/${stockorderid}/updatestockfromorder`,
        "PATCH",
  
        {
          stockorderid:formData.stockorderid,
          stockorderdestinonr,
          stockorderdestinoid,
          stockorderdestinocliente,
         // itemsstock: formData.itemsstock,
        //  packitemsstock: formData.packitemsstock,
        itemsdelivery: formData.itemsstock,
         packdelivery: formData.packitemsstock,
      
          employeestory: {
            stockorderid:formData.stockorderid,
            user,
            mexicanTime: new Date().toLocaleString("en-US", options),
            subtractitemsstock: formData.itemsstock,
            subtractpackitemsstock: formData.packitemsstock,
            itemsdelivery: formData.itemsstock,
         packdelivery: formData.packitemsstock,
          stockorderid:formData.stockorderid,
          stockorderdestinonr,
          stockorderdestinoid,
          stockorderdestinocliente,
         
          },
        }
      );
    }


      setFormData({ 
      //  ordernr:"",
        customer:"",
         code:"",
         codemodel:'',
         codepreforma:'',
         tipomodeldescription:'',
          pack:"",
          totpack:"",
          preforma:"",
          preformakg:"",
           finalproduct:"",
           finalproductcolor:"",
            finalproductkg:"",
             itemstodo:"",
              kgneeded:"",
               kgloadedfromalmacen:"",
               extrakgloadedfromalmacen:"", 
               itemsscanned:"", 
               itemdefected:"", 
               itemdefectedkg:"",
               preformaitemdefected:"", 
               preformaitemdefectedkg:"",  
               itemsstock:"", 
               itemsdelivery:"", 
               scannedcompleted:false, 
               stockcompleted:"", 
               deliverycompleted:"", 
               qr:"", 
               packscanned:"",
               packitemsstock:"",
               packdelivery:"",
               preformascanned:"",
               preformastock:"",
               preformadelivery:0,
               preformascannedcompleted:false,
               preformastockcompleted:false,
               preformadeliverycompleted:false,
               preformapack:"",
            preformatotpack:"",
           preformapackscanned:"",
            preformapackitemsstock:"",
            preformapackdelivery:0,
            preformacolor:"",
            preformaaddedtoprod:0,
            preformapackaddedtoprod:0,
            materia:setSelectedMateria,
            personalizacion:"",
            //   employeestory:"", 
            stockordernr:"",
          stockorderid:"",
          stockorderdestinonr:"",
          stockorderdestinoid:"",
          stockorderdestinocliente:"",
            });
      setOrder(response._id);
 // console.log("Response:", response);
 if (response.msg) {
  if (response.msg === "Order number already exists") {
    setOrderError(response.msg);
    setMessage(
      <Alert className="flex justify-center" severity="error">
        Pedido {formDataWithNumberValue.ordernr} ya existe.
        Por favor asigne uno nuevo.
      </Alert>
    );
    setTimeout(() => {
      setMessage("");
    }, 5000);
    setTimeout(() => {
      setOrderError(false);
      
    }, 6000);
 }

 }

 else {

      //to get all the data in the qr, what to store in qrcode

      const updatedFormData = {
        ordernr: response.ordernr,
         customer: formDataWithNumberValue.customer,
          };
    //  console.log(updatedFormData)
      const updatedQRData = JSON.stringify({ ...updatedFormData, id: response._id });
      //const updatedFormData = { ...formDataWithNumberValue };
      //const updatedQRData = JSON.stringify({ ...updatedFormData, id: response._id });
      setQrData(updatedQRData);
      
      setMessage(
      
       
        <Alert className="flex justify-center " severity="success">
          <AlertTitle>¡Nuevo pedido añadido!</AlertTitle>
          <div className="flex flex-col items-center justify-center h-full">
            <div>
              <strong>Pedido NR:</strong> {response.ordernr}
            </div>
            <div>
              <strong>Cliente:</strong> {response.customer}
            </div>
            
            {response.preforma && ( 
            <div>
              <strong>Modelo Preforma:</strong> {response.preforma}
              <strong>Color:</strong> {response.preformacolor}
            </div>
            )}
            {response.finalproduct && (
              <>
            <div>
              <strong>Modelo </strong> {response.finalproduct}
            </div>
            <div>
              <strong>Color:</strong> {response.finalproductcolor}
            </div>
            </>  )}
            {response.materia !== "stock" & ( 
            <div>
              <strong>Artículos para hacer:</strong> {response.itemstodo}
            </div>
            )}
              {response.materia === "stock" & ( 
            <div>
              <strong>Artículos STOCK Vendidos:</strong> {response.itemsstock}
            </div>
            )}
            <div>
              <strong>¡Recuerda descargar el código QR!</strong>
            </div>
          </div>
        </Alert>
       
      );
  
      // Clear the message after 
   
      setTimeout(() => {
        setMessage("");
      }, 9000);
    }} catch (error) {
      console.error(error);
      
      setMessage(
        <Alert className="flex justify-center" severity="error">
          Ocurrió un error.
          
          Inténtalo de nuevo.
        </Alert>
      );
{/*
      setTimeout(() => {
        setMessage("");
      }, 9000);
    */  }
    }


    
  


        // Reset the selected values
        setSelectedCode("");
        setSelectedModel("");
        setSelectedMasterKg("");
        setSelectedColor("");
        setSelectedPreforma("")
        setSelectedColorPreforma("")
        setSelectedColorResina("")
        setSelectedMateria("")
      
        

  } 

  const handleKeyDown = (event) => {
  //  if (event.key === ' ' || event.key === 'Enter') {
   //   event.preventDefault();
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  
  const handleSaveQRCode = (event) => {
    event.stopPropagation();
    const canvas = qrCodeRef.current.querySelector("canvas");
    if (canvas) {
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = `CUSTOMER_${JSON.parse(qrData).customer}_ORDER_${JSON.parse(qrData).ordernr}.png`;
      link.click();
    }
     };
    

    

  const handleResetQRCode = () => {
    
    setQrData("");
    setOrder('')
    fetchOrder();
   // qrCodeRef.current = null;
    //window.location.reload()
  }

  const handleClearClick = () => {
    setFormData({
      ...formData,
      itemstodo: '',
      kgneeded: '',
      finalproductkg: '',
      preformakg: '',
      codemodel:'',
     codepreforma:'',
     finalproduct:'',
     preforma:'',
     finalproductcolor:'',
     preformacolor:'',
      etiquetas:"",
      customer:"",
      personalizacion:"",
      preformapack:"",
      preformatotpack:"",
      totpack:"",
      pack:"",
      ordernr:'',
      stockordernr:"",
      stockorderid:"",
      stockorderdestinonr:"",
      stockorderdestinoid:"",
      stockorderdestinocliente:"",
      itemsstock:'',
      packitemsstock:'',
      scannedcompleted:false,
      materia:''
    });
    setSelectedPreforma('');
    setSelectedModel('');
    setSelectedMateria('');
    setSelectedMasterKg('');
    setSelectedColorPreforma('');
    setSelectedColor('');
    setCodeModel('');
    setStockOrderNr('');
    setStockOrderId('');
    setSelectedStock('');
    setSelectedModel("");
    setTipoModelDescription('');
    setSelectedStock('');
 setPaq('');
 setPaqstock('');
 setUdstock('');
  };

  useEffect(() => {
    setFormData({ 
        ordernr:"",
    customer:"",
     code:"",
      pack:"",
      totpack:"",
      preforma:"",
      preformakg:"",
       finalproduct:"",
        finalproductkg:"",
        finalproductcolor:"",
         itemstodo:"",
          kgneeded:"",
           kgloadedfromalmacen:"",
           extrakgloadedfromalmacen:"", 
           itemsscanned:"", 
           itemdefected:"", 
           itemdefectedkg:"", 
           preformaitemdefected:"", 
           preformaitemdefectedkg:"", 
           itemsstock:"", 
           itemsdelivery:"", 
           scannedcompleted:false, 
           stockcompleted:"", 
           deliverycompleted:"", 
           qr:"",
           packscanned:"",
           packitemsstock:"",
           packdelivery:"",
           preformascanned:"",
           preformastock:"",
           preformadelivery:0,
           preformapack:"",
           preformatotpack:"",
          preformapackscanned:"",
           preformapackitemsstock:"",
           preformapackdelivery:0,
           preformacolor:"",
           etiquetas:"",
           preformaaddedtoprod:0,
            preformapackaddedtoprod:0,
           materia:"",
           personalizacion:"",
        //   employeestory:"", 
        stockordernr:"",
        stockorderid:"",
        stockorderdestinonr:"",
        stockorderdestinoid:"",
        stockorderdestinocliente:"",
           
         });
  }, []);

 // const qrData = JSON.stringify({ ...formData, id: product, finalproductkg: formData.finalproductkg });
  //console.log(qrData)

  
    const navigate = useNavigate();

    const navigateToDashboardOrder = () => {
      navigate("/dashboardorders");
    };

    const navigateToDashboardOrderPreforma = () => {
      navigate("/dashboardpreformaorder");
    };

    const customerOptions = customers.map(customer => ({
      value: { razonsocial: customer.razonsocial, direccion: customer.direccion, estado: customer.estado},
      label: `${customer.razonsocial} - direccion: ${customer.direccion}`,
    }));


    useEffect(() => {
      if (order) {
        setMessageQRDownload(
          <Modal
            open={true}
            
          >
            <Snackbar
              open={true}
             // onClose={() => setMessageQRDownload(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <div className="bg-yellow-500 rounded-lg p-2">
                <div className="text-pink-600 font-bold text-xl text-center">IMPORTANTE</div>
                <div className="break-all">Tienes que asociar el código QR al pedido que acaba de ingresar </div>
                <div className="break-all"> en el menú de “Preforma pedidos” o “Garrafones pedidos”</div>
                <div className="text-center">
                  <div className="mt-4 hidden lg:block" ref={qrCodeRef}>
                    <QRCode value={qrData} size={300} />
                  </div>
                  <button
                    className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4 bg-pink-600 hover:bg-pink-700 focus:ring-pink-800"
                    onClick={(event) => {
                      handleSaveQRCode(event);
                      setIsDownloaded(true);
                    }}
                  >
                    Download QR Code
                  </button>
                  <button
                    className={`focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4 ml-5 ${isDownloaded ? 'bg-pink-600 hover:bg-pink-700 focus:ring-pink-800 text-white' : 'bg-gray-600 text-gray-300 cursor-not-allowed'}`}
                    disabled={!isDownloaded}
                    onClick={() => {
                      setMessageQRDownload(null);
                      handleResetQRCode();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Snackbar> 
          </Modal>
        );
      }
    }, [order, isDownloaded]);

  return (
 
    <section className=" bg-gray-900  ">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 
        bg-gray-800 border-purple-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                Añadir pedido
                </h1>
     
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-2 md:grid-cols-2 grid-rows-1">
                <div>
                        <label htmlFor="ordernr"
                         className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Pedido Nr</label>
                        <input type="text" name="ordernr" id="ordernr" 
                        value={Number(lastOrderNr)+1}
                        readOnly
                        //onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-orange-600
                           placeholder-gray-400 text-white
                            focus:ring-orange-500 focus:border-orange-500" 
                            placeholder="nr de pedido"  />
                    </div>
                    <div>
                        <label htmlFor="customer"
                        className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Cliente</label>
  <Select
  id="customer"
  placeholder="Seleccionar"
  value={customerOptions.find(option => option.value === selectedCustomer)}
  onChange={handleSelectCMChange} 
  options={customerOptions}
  isClearable
  filterOption={(option, inputValue) => {
    const words = inputValue.toLowerCase().split(' ');
    return words.every(word => option.label.toLowerCase().includes(word));
  }}
  styles={{
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1A202C', // bg-gray-800
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1A202C', // bg-gray-800
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#3256a8' : provided.color, // blue-500 if selected
      backgroundColor: state.isFocused ? '#3256a8' : provided.backgroundColor, // blue-500 if focused
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D5DB', // white-300
    }),
    input: (provided) => ({
      ...provided,
      color: '#FFFFFF', // white
    }),
  }}
  className={`rounded-lg bg-gray-800 text-white  p-2 mb-4 w-full ${formData.customer === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
/>

                        <textarea type="text" name="customer" id="customer" 
                        value={formData.customer}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-orange-600
                           placeholder-gray-400 text-white
                            focus:ring-orange-500 focus:border-orange-500" 
                            placeholder="cliente" required/>
                    </div>
                    </div>
                    
                    <div>
<div>
<label className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase" htmlFor="materia" >Proceso de Venta</label>
<select
    name="materia"
    id="materia"
    value={selectedMateria}
    onChange={ handleInputChange}
    className={`rounded-lg  text-white p-2  w-full ${formData.materia === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                          required
  >
    <option value="">Seleccionar</option>
  {Object.keys(enumToText).map((value) => (
    <option key={value} value={value}>
      {enumToText[value]}
    </option>
  ))}
 
  </select>
 
  
</div>
{/* 
{ selectedMateria !=="" && selectedMateria !=="resina_ya_producida" && (
  <>
                    <label className="block mt-4 mb-4 text-sm font-medium text-white xl:text-xl uppercase" htmlFor="article-select" >Seleccionar resina</label>
      <select  id="article-select" value={selectedCode} onChange={handleSelectChange} 
       className={`rounded-lg  text-white p-2 mb-4  w-full ${formData.code === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
      //required
      >
        <option  value="">Seleccionar resina</option>
        {articles.map(article => (
          <option key={article.code} value={article.code}> {article.code} - color: {article.color} - kg:{article.kg} </option>
        ))}
      </select>
      <div className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 placeholder-gray-400 text-white">
  {selectedCode} {selectedColorResina}
</div>
 </>)}
*/}
<div>{messageQRDownload}</div>
                    </div>


                    {selectedMateria !=="" && selectedMateria ==="stock" && (
                    <div className="text-white">
               <label htmlFor="stock" className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">Garraffones en Stock</label>
  <Select
    id="stock-select"
    value={stockOrders.find(order => order.codemodel === selectedModel)}
    onChange={handleSelectStockChange}
    options={stockOrders
     // .filter(order => order.itemsstock - order.employeestory.flat().reduce((sum, story) => sum + (story.subtractitemsstock || 0), 0) > 0)
     .filter(order => order.itemsstock - order.itemsdelivery > 0)
      .map(order => {
      //  const model = models.find(model => model.code === order.codemodel);
        return {
          ...order,
        //  ...model,
      //    itemsstock: order.itemsstock - order.employeestory.flat().reduce((sum, story) => sum + (story.subtractitemsstock || 0), 0),
       //   packitemsstock: order.packitemsstock - order.employeestory.flat().reduce((sum, story) => sum + (story.subtractpackitemsstock || 0), 0),
       itemsstock: order.itemsstock - order.itemsdelivery,
       packitemsstock: order.packitemsstock - order.packdelivery,
         codemodel:order.codemodel,
         tipomodeldescription:order.tipomodeldescription
        };
      })
    }
    getOptionLabel={(order) => `${order.finalproduct} - 
    Paquetes(${order.pack}): ${order.packitemsstock } 
    - Ud: ${order.itemsstock} 
    - order stock:${order.ordernr}
    ${order.codemodel ? order.codemodel :''}
    ${order.tipomodeldescription ? order.tipomodeldescription : ""}
    `}
   
    getOptionValue={(order) => order.finalproduct}
    placeholder="Seleccionar"
    isClearable
    filterOption={(option, inputValue) => {
      const words = inputValue.toLowerCase().split(' ');
      return words.every(word => option.label.toLowerCase().includes(word));
    }}
    styles={{
      control: (provided) => ({
        ...provided,
        backgroundColor: '#1A202C', // bg-gray-800
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#1A202C', // bg-gray-800
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#3256a8' : provided.color, // blue-500 if selected
        backgroundColor: state.isFocused ? '#3256a8' : provided.backgroundColor, // blue-500 if focused
        color: '#D1D5DB', // white-300
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#D1D5DB', // white-300
      }),
      input: (provided) => ({
        ...provided,
        color: '#FFFFFF', // white
      }),
    }}
    className="mb-4 w-full"
  />
  <p className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 placeholder-gray-400 text-white">
  <span className="text-xs">{selectedStock} {" "}</span>
  <span className="ml-3 mr-3">Paquetes({paq}): {paqstock}</span><span>Ud:{udstock} </span>
  <span className="ml-5 ">{codemodel}</span>
  <span className="ml-5 ">{tipomodeldescription ? tipomodeldescription : ""}</span>
</p>

<div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-3 grid-rows-1">
<div>
                        <label htmlFor="udstock" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Garaffones en Stock (Unidades)</label>
                        <input type="number" name="udstock" id="udstock" placeholder="cantidad de artículos a producir..." 
                        value={udstock}
                        readOnly
                      //  onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white"  />
                           <label htmlFor="paqstock" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Garaffones en Stock (Paquetes)</label>
                        <input type="number" name="paqstock" id="udstock" placeholder="cantidad de artículos a producir..." 
                        value={paqstock}
                        readOnly
                      //  onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white"  />
                      
                    </div>
                    <div >
                        <label htmlFor="itemsstock" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Descontar (Unidades)
   
                        </label>
                        <input type="number" name="itemsstock" id="itemsstock" placeholder="cantidad de artículos a producir..." 
                        value={formData.itemsstock}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required ></input>
                     

                          <label htmlFor="packitemsstock" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Descontar Paquetes de {paq} (Unidades)</label>
                        <input type="number" name="packitemsstock" id="packitemsstock" placeholder="cantidad de artículos a producir..." 
                        value={formData.packitemsstock}
                        readOnly
                       // onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" 
                          />
                          

                      
                    </div>
                    <div> 
<label className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase"> </label>
<button className="bg-gray-700 p-1 rounded-lg hover:bg-red-700 block mb-2 sm:mt-10 text-sm font-medium text-white  uppercase"
 onClick={() => setFormData({ ...formData, itemsstock: "", kgneeded: "", packitemsstock:'', pack:'', scannedcompleted:false, stockordernr:'',
 stockorderid:'' , })}>Reset</button>
</div>  
                    </div> 
                    </div>
                  )} 

                   {selectedMateria !=="" && selectedMateria !=="solo_resina" && selectedMateria !=="stock" && (
                    <div className="text-white">
               <label htmlFor="preforma" className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">Preforma a producir</label>
  <Select
    id="preforma-select"
    value={preformas.find(model => model.code === selectedPreforma)}
    onChange={handleSelectPreformaChange}
    options={preformas}
    getOptionLabel={(model) => `${model.code} - ${model.modelname}`}
    getOptionValue={(model) => model.code}
    placeholder="Seleccionar"
    isClearable
    filterOption={(option, inputValue) => {
      const words = inputValue.toLowerCase().split(' ');
      return words.every(word => option.label.toLowerCase().includes(word));
    }}
    styles={{
      control: (provided) => ({
        ...provided,
        backgroundColor: '#1A202C', // bg-gray-800
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#1A202C', // bg-gray-800
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#3256a8' : provided.color, // blue-500 if selected
        backgroundColor: state.isFocused ? '#3256a8' : provided.backgroundColor, // blue-500 if focused
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#D1D5DB', // white-300
      }),
      input: (provided) => ({
        ...provided,
        color: '#FFFFFF', // white
      }),
    }}
    className="mb-4 w-full"
  />
  <p className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 placeholder-gray-400 text-white">
  <span className="text-xs">{selectedPreforma} -{" "}</span> {selectedPreforma && preformas.find(model => model.code === selectedPreforma)?.modelname}
</p>


                       
                    </div>
                  )} 
{selectedMateria !== "" && selectedMateria !== "solo_preforma" && selectedMateria !=="stock" && (
 <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
   
    <div className="text-white">
    <label htmlFor="finalproduct" className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">Modelo a producir</label>
<Select
  id="model-select"
  value={models.find(model => model.code === selectedModel)}
  onChange={handleSelectModelChange}
  options={models}
  getOptionLabel={(model) => `${model.tipomodelodescription} - ${model.modelname}`}
  getOptionValue={(model) => model.code}
  placeholder="Seleccionar"
  isClearable
  filterOption={(option, inputValue) => {
    const words = inputValue.toLowerCase().split(' ');
    return words.every(word => option.label.toLowerCase().includes(word));
  }}
  styles={{
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1A202C', // bg-gray-800
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1A202C', // bg-gray-800
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#3256a8' : provided.color, // blue-500 if selected
      backgroundColor: state.isFocused ? '#3256a8' : provided.backgroundColor, // blue-500 if focused
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D5DB', // white-300
    }),
    input: (provided) => ({
      ...provided,
      color: '#FFFFFF', // white
    }),
  }}
  className={`rounded-lg  text-white p-2 mb-4  w-full ${formData.finalproduct === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
/>
        </div>

      <div className="sm:text-sm rounded-lg   p-2.5 bg-gray-700 border-orange-600 placeholder-gray-400 text-white focus:ring-orange-500 focus:border-orange-500">
      <div className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">{selectedModel} {" "}</div>
       {selectedModel && models.find(model => model.code === selectedModel)?.modelname} 
      <div className="block mt-2 text-sm font-medium text-white xl:text-xl uppercase">
      <button onClick={() => {
    const model = models.find(model => model.code === selectedModel);
    const index = models.indexOf(model);
    openViewModal(model, index);
  }}>
    
          {selectedModel && models.find(model => model.code === selectedModel)?.tipomodelodescription}
          </button> </div> 
        
    </div>
   
  </div>
)}
 <div className="mt-4">
                        <label htmlFor="personalizacion"
                         className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">Observaciones</label>
                        <input type="text" name="personalizacion" id="personalizacion" 
                        value={formData.personalizacion}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-orange-600
                           placeholder-gray-400 text-white
                            focus:ring-orange-500 focus:border-orange-500" 
                            placeholder="Observaciones" />
                    </div>
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
                    { selectedMateria !=="stock" && (
                    <div className="">
                        <label htmlFor="finalproductcolor" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Pref/Model <span className="text-xs text-white">Color</span></label>
                        <p className={`sm:text-sm rounded-lg block  p-2.5 bg-gray-700 border-orange-600
                         placeholder-gray-400 ${selectedColor === selectedColorResina || selectedColorPreforma === "" || selectedColor === selectedColorPreforma  ? 'text-green-500' : 'text-red-500'}
                          focus:ring-orange-500 focus:border-orange-500`} required="">{selectedColor}</p>
                     </div> 
                    )} 
                     { selectedMateria !=="solo_resina" && selectedMateria !=="stock" && (
                     <div className={` ${selectedColor === selectedColorPreforma ? 'text-green-500' : 'text-red-500'}`}>
                           <label htmlFor="preformacolor" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Preforma Color <span className="text-xs text-gray-400">Check</span></label>
                           <p className={`sm:text-sm rounded-lg block p-2.5 bg-gray-700 border-orange-600
                         placeholder-gray-400 
                          focus:ring-orange-500 focus:border-orange-500`} required="">{selectedColorPreforma}</p>
                    </div>
                    ) }
                    </div>
                    { selectedMateria !=="stock" && (
                    <div>
                        <label htmlFor="finalproductkg" className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">Peso del modelo a producir (Kg)</label>
                        <p  className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase" required="">{selectedMasterKg}</p>
                    </div>
                      ) }
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
                    { selectedMateria !=="stock" && (
                    <div>
                        <label htmlFor="itemstodo" className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase">Artículos para hacer (Unidades)</label>
                        <input type="number" name="itemstodo" id="itemstodo" placeholder="cantidad de artículos a producir..." 
                        value={formData.itemstodo}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required />
                      <button className="bg-gray-700 p-1 rounded-lg hover:bg-red-700 block mb-2 mt-2 text-sm font-medium text-white  uppercase"
                       onClick={() => setFormData({ ...formData, itemstodo: "", kgneeded: "" })}>Reset</button>
                      
                    </div>
                     ) }
                    { selectedMateria !=="resina_ya_producida" && selectedMateria !=="stock" && (
                    <div>
                        <label htmlFor="kgneeded" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">kg de Resina necesarias</label>
                        <input type="number" name="kgneeded" id="kgneeded" placeholder="kg de resina necesarias..." 
                        value={formData.kgneeded}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500"/>
                    </div>
                    )}
                    </div>
{/*
                    { selectedMateria !=="solo_resina" && (
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
                    <div className="">
                        <label htmlFor="preformapack" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Cantidad de PREFORMAS por Paquete</label>
                        <input type="number" name="preformapack" id="preformapack" placeholder="ud preforma por paq" 
                        value={formData.preformapack}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required />
                    <button className="bg-gray-700 p-1 rounded-lg hover:bg-red-700 block mb-2 mt-2 text-sm font-medium text-white  uppercase"
                       onClick={() => setFormData({ ...formData, preformapack: "", preformatotpack:""})}>Reset</button>
                    </div>

                    <div className="">
                        <label htmlFor="preformatotpack" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Tot Paq PREFORMA</label>
                        <input type="number" name="preformatotpack" id="preformatotpack" placeholder="paq Preforma Tot" 
                        value={formData.preformatotpack}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required />
                    </div>

                    </div>
                    )}
                    { selectedMateria !=="solo_preforma" && (
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-2 grid-rows-1">
                    <div className="">
                        <label htmlFor="pack" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Cantidad de GARRAFONES por Paquete</label>
                        <input type="number" name="pack" id="pack" placeholder="ud artículos por paq" 
                        value={formData.pack}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" required />
                      <button className="bg-gray-700 p-1 rounded-lg hover:bg-red-700 block mb-2 mt-2 text-sm font-medium text-white  uppercase"
                       onClick={() => setFormData({ ...formData, pack: "", totpack:""})}>Reset</button>
                    </div>

                    <div className="">
                        <label htmlFor="totpack" className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase">Tot Paq GARRAFONES</label>
                        <input type="number" name="totpack" id="totpack" placeholder="paq necesarias..." 
                        value={formData.totpack}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-orange-600 placeholder-gray-400 text-white  focus:ring-orange-500 focus:border-orange-500" 
                         required 
                         />
                    </div>

                    </div>
                    )}
                   
                    <div>
                        <label htmlFor="etiquetas"
                         className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase">Codigo QR para imprimir</label>
                        <input type="number" name="etiquetas" id="etiquetas" 
                        value={(formData.totpack || 0) + (formData.preformatotpack || 0)}
                       readOnly
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-orange-600
                           placeholder-gray-400 text-white
                            focus:ring-orange-500 focus:border-orange-500" 
                            placeholder="etiquetas" />
                    </div>
                    */ }
                    <span className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p0 bg-gray-800  grid gap-2 md:grid-cols-2 md:justify-between xl:justify-between">
                    <button 
                    type="submit"
                    className="w-full text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800">Guardar</button>
                      <button className=" w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center   bg-gray-400 hover:bg-red-700   xl:ml-auto md:ml-auto sm:ml-0" onClick={handleClearClick}>Borrar</button>
                   </span>
                    </form>

          
                  {message && (
        <div className="message-container">
          {message}
        </div>
          )}
                
                    <div>
                    <button type="dashboardorders"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-4"
                        onClick={navigateToDashboardOrderPreforma}
                        >Panel Pedidos PREFORMA</button>
                        
                    <button type="dashboardorders"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardOrder}
                        >Panel Pedidos GARRAFONES</button>
                    
                 
                 
                    </div>
                    <div className="bg-white flex flex-col items-center justify-center">
                        {/* Display QR code  
  {order &&  (
        <div className="mt-4 hidden lg:block  " ref={qrCodeRef}>
          <QRCode value={qrData} size={300} />
        </div>
      )}
     */}
      {/* Save QR code button
      {order && (
        <>
     
        <button  className=" text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4
         bg-pink-600 hover:bg-pink-700 focus:ring-pink-800" onClick={handleSaveQRCode}>Download QR Code</button>
         <button  className=" text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4
         bg-pink-600 hover:bg-pink-700 focus:ring-pink-800" onClick={handleResetQRCode}>Reset & New QR Code</button>
    
     <div className="max-w-50 overflow-hidden break-all border  p-4 my-2">
      
     
      <pre className="whitespace-pre-wrap  ">{qrData}</pre>
      </div>
         </>
      )}
*/}

                    </div>

                


            </div>
        </div>
    </div>
    {isViewModalOpen && (
      <div className="  fixed inset-0 flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
      <div className="mt-10 flex items-center  justify-center">
  <ViewModelModal viewItem={viewItem} onClose={closeViewModal} />
  </div>
        </div>
)}
  </section>


    
   
  );
};

export default NewOrder;
