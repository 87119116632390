import React, { useState, useEffect, useRef } from "react";
import { apiWrapper } from "../../../utils/apiwrapper";


import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const GarColorDeAsaNum = ({ selectedComponent, setSelectedComponent }) => {

const [LastAsaCode, setLastAsaCode] = useState(0);

const fetchAsaCode = async () => {
  try {
    const response = await apiWrapper("garcolordeasanum", "GET");
    setLastAsaCode(response);
 //   console.log(response);

    // Update asacolorcode in formData state
    const asacolorcode = String(response.length+1).padStart(2, '0');
    setFormData(prevFormData => ({
      ...prevFormData,
      asacolorcode: asacolorcode
    }));
  } catch (error) {
    // Handle error
    console.error("Error fetching asacolor:", error);
  }
};


useEffect(() => {

  fetchAsaCode();
}, []);





  const [codeError, setCodeError] = useState("")
  const[message, setMessage]=useState("")
  const [formData, setFormData] = useState({
    asacolorcode: "",
   // value: "",
    asacolordescription:"",
    masterkg:"",
    //qr:""
  });
  const [model, setModel] = useState("");
  //const qrCodeRef = useRef(null);

  //const [qrData, setQrData] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;
  
    if (name === "value" || name === "kg") {
      parsedValue = Number(value);
    } else if (name === "asacolorcode" || name === "asacolordescription") {
      parsedValue = value.toUpperCase();
    }
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
      };
  

      const response = await apiWrapper("garcolordeasanum", "POST", formDataWithNumberValue);
      setFormData({ asacolorcode: "", value: "1", asacolordescription: "",  masterkg:"" });
      setModel(response._id);
      fetchAsaCode()
    
      
  // to get all the data in the message
      const updatedFormData = { ...formDataWithNumberValue };
    
  //   console.log("Response:", response);
  if (response.msg) {
    if (response.msg === "Code already exists") {
      setCodeError(response.msg);
      setMessage(
        <Alert className="flex justify-center" severity="error">
          El código {formDataWithNumberValue.asacolorcode}  ya existe.
        Por favor asigne uno nuevo.
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
      }, 6000);
      setTimeout(() => {
        setCodeError(false);
        
      }, 6000);
   }
  
   }
   else{ 

  
    setMessage(
      <Alert className="flex justify-center" severity="success">
        <AlertTitle>¡Nuevo Color Asa Numérico Añadido !</AlertTitle>
        <div className="flex flex-col items-center">
          <div>
            <strong>Codigo:</strong> {updatedFormData.asacolorcode}
          </div>
    
          <div>
            <strong>Color:</strong> {updatedFormData.asacolordescription}
          </div>
         
        </div>
      </Alert>
    );

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }} catch (error) {
    console.error(error);
    
    setMessage(
      <Alert className="flex justify-center" severity="error">
        
        Ocurrió un error,
        
        Inténtalo de nuevo.
      </Alert>
    );
    setTimeout(() => {
      setMessage("");
    }, 9000);
  }
 } 




  useEffect(() => {
    setFormData({ asacolorcode: "", value: 1, asacolordescription:"",  masterkg:"" });
    
  }, []);

   

  return (

    <section className=" bg-gray-900  ">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 
        bg-gray-800 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                    Añadir Color Asa Numérico Garaffon
                </h1>
 
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                        <label htmlFor="asacolorcode"
                         className="block mb-2 text-sm font-medium  text-white">Codigo</label>
                        <input type="text" name="asacolorcode" id="asacolorcode" 
                        value={formData.asacolorcode}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " 
                            placeholder="codigo" required/>
                             {codeError && <p className="error-message text-white">{codeError}</p>}
                    </div>
                   
                 

                    <div>
                        <label htmlFor="asacolordescription" className="block mb-2 text-sm font-medium  text-white">Color</label>
                        <input type="text" name="asacolordescription" id="asacolordescription" placeholder="color..." 
                        value={formData.asacolordescription}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white  " required />
                    </div>

                   

                  

                    <div>
                        <label htmlFor="masterkg" className="block mb-2 text-sm font-medium  text-white">Peso (Kg)</label>
                        <input type="number" name="masterkg" id="masterkg" placeholder="añadir peso en kg" 
                        value={formData.masterkg}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white
                          " 
                          //required
                          />
                    </div>

                    
                    
                   
                    <button type="submit"
                     className="w-full text-white  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800">Guardar</button>
                   
                    </form>
                                     {/* Display the message */}
                                     {message && (
        <div className="message-container">
          {message}
        </div>
      )}
                    <div>
                    <button type="dashboardmodels"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-800 mt-8"
                        onClick={() => setSelectedComponent("")}
                        >Cerrar</button>
                    </div>
           

            </div>
        </div>
    </div>
      
  </section>

    
   
  );
};

export default GarColorDeAsaNum;