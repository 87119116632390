

const ViewModelModal = ({ viewItem, onClose }) => {
    const { model, index } = viewItem;

 
  return (
    <section className=" bg-gray-900   ">
     <div className="flex flex-col my-modal">
        
     <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 
        bg-gray-800 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
         

            <div className="space-y-4 md:space-y-6" >
              <div>
                <label
                  htmlFor="code"
                  className="block mt-6 mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Código
                </label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  value={model.code}
              
                  className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             "
                  placeholder="código"
                 readOnly
                />
               
              </div>

              <div>
                <label
                  htmlFor="modelname"
                  className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Nombre Modelo
                </label>
                <textarea
                  name="modelname"
                  id="modelname"
                  value={model.modelname} 
              
                  className="
    sm:text-sm rounded-lg block w-full p-2.5
    bg-gray-700 
    placeholder-gray-400 text-white xl:text-xl
     "
                  placeholder="Nombre Modelo"
                 readOnly
                />
              </div>

              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-3 grid-rows-1">
              <div>

                <label
                  htmlFor="familia"
                  className="block mt-2 mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Familia
                </label>

        
              </div>
<div className="flex-grow">

                <label
                    htmlFor="listasubfam"
                    className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase"
                  >
                    subFamilia
                  </label>
                 
</div>
              <div>

                  <label
                    htmlFor="subfamilia"
                    className="block mb-2 mt-2 text-sm font-medium  text-white xl:text-xl uppercase"
                  >
                    subfamiliatipo
                  </label>
              
              </div>

                </div>
             

                
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid xl:gap-20 md:gap-10 sm:gap-2 md:grid-cols-3 ">
                <div className="flex flex-col gap-2">
                  <input
                    type="text"
                    name="familia"
                    placeholder="familia código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white md:w-full   "
                    value={model.familia}
                    onChange={() => {}}
                  readOnly
                //   required
                  />

                  <input
                    type="text"
                    name="familiadescription"
                    placeholder="familia descripción ..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white mb-1 md:w-full  "
                    value={model.familiadescription}
                    onChange={() => {}}
                    readOnly
                 //   required
                  />
                 </div>
                <div className="flex flex-col flex-grow ">
                  <input
                    type="text"
                    name="listasubfam"
                    placeholder="subfam código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white mt-1   mb-2 md:w-full"
                    value={model.listacode}
                    onChange={() => {}}
                   readOnly
                //    required
                  />

                  <input
                    type="text"
                    name="listasubfam"
                    placeholder="subfam descripción..."
                   className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                    value={model.listadescription}
                    onChange={() => {}}
                   readOnly
                //    required
                  />
                </div>
            
                <div className="flex flex-col flex-grow">
                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamiliatipo código..."
                    className="sm:text-sm rounded-lg mt-2  w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white mb-2"
                    value={model.subfamilia}
                    onChange={() => {}}
                //    readOnly
                    required
                  />

                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamilia descripción..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={model.subfamiliadescription}
                    onChange={() => {}}
                    readOnly
                 //   required
                  />
                </div>
              </div>
             
              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-1">
                 

                  <label
                    htmlFor="tipomodelo"
                    className="block mb-2 text-sm   font-medium  text-white xl:text-xl uppercase"
                  >
                    Tipo de Modelos
                  </label>
                
                 
                </div>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-3 ">
                  <input
                    type="text"
                    name="tipomodelo"
                    placeholder="capacitad..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={model.capacitad}
                    onChange={() => {}}
                   readOnly
               //     required
                  />

                  <input
                    type="text"
                    name="tipomodelo"
                    placeholder="tipos código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={model.tipomodelo}
                   onChange={() => {}}
                    readOnly
                //    required
                  />
                    <input
                    type="text"
                    name="tipomodelodescription"
                    placeholder="descripción mod..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={model.tipomodelodescription}
                   onChange={() => {}}
                    readOnly
                //    required
                  />
                </div>

         

              <div>
              <div className="w-full rounded-lg shadow  mb-4 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-2 ">
<div >
                <label
                  htmlFor="rosca"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Rosca
                </label>
            
                </div>
                <div>

                <label
                  htmlFor="color"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Color
                </label>
             
                </div>
               </div>
               <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-4 ">
               <input
                  type="text"
                  name="rosca"
                  id="rosca"
                  placeholder="rosca..."
                  value={model.rosca}
               //  onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                 onChange={() => {}}
             readOnly
              //    required
                />
                 <input
                  type="text"
                  name="roscadescription"
                  id="roscadescription"
                  placeholder="rosca descripción..."
                  value={model.roscadescription} 
              //
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                 onChange={() => {}}
                  readOnly
                //  required
                />
                <input
                  type="text"
                  name="color"
                  id="color"
                  placeholder="color código..."
                  value={model.color}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
                 readOnly
              //    required
                />
                <input
                  type="text"
                  name="colordescription"
                  id="colordescription"
                  placeholder="blue, rojo, transparente..."
                  value={ model.colordescription}
                //  onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
                  readOnly
              //    required
                />
               </div>
              </div>
              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-1 ">
                <label
                  htmlFor="asacolor"
                  className="block text-sm font-medium  text-white xl:text-xl uppercase "
                >
                  Asacolor
                </label>
              
                </div>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
                  <input
                    type="text"
                    name="asacolorCode"
                    placeholder="asa código..."
                    className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                    value={model.asacolor}
                  onChange={() => {}}
                    readOnly
                 //   required
                  />

                  <input
                    type="text"
                    name="asacolorDisplay"
                    placeholder="asa color..."
                    className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                    value={model.asacolodescription}
                   onChange={() => {}}
                    readOnly
                 //   required
                  />
                </div>
             
              
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
            <div>

              <label
                  htmlFor="placa"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Placa Tipo
                </label>
               
            </div>
            <div>

                <label
                  htmlFor="placa"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Placa Descripción
                </label>
            
            </div>
           
               
              </div>
              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-4 ">
              <input
                  type="text"
                  name="placatipocode"
                  placeholder="placa código..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={model.placatipocode}
                 // onChange={() => {}}
                 readOnly
                //  required
                />
                       <input
                  type="text"
                  name="placatipocode"
                  placeholder="placa tipo descripción..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={model.placatipodescription}
               //   onChange={() => {}}
                 readOnly
               //   required
                />
                <input
                  type="text"
                  name="placa"
                  placeholder="placa código..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={model.placa}
             //     onChange={() => {}}
                readOnly
              //    required
                />

                <input
                  type="text"
                  name="placaDisplay"
                  placeholder="placa descripción..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={model.placacodedescription}
               //   onChange={() => {}}
                readOnly
              //    required
                />
              </div>
              <div>
                <label
                  htmlFor="masterkg"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Peso (Kg)
                </label>
                <input
                  type="number"
                  name="masterkg"
                  id="masterkg"
                  placeholder="añadir peso en kg"
                  value={model.masterkg}
                  readOnly
                  className={`sm:text-sm md:w-1/4 sm:w-full rounded-lg p-2 text-white  block flex-grow  placeholder-gray-400 ${model.masterkg === '' ? 'border-2 border-pink-700 bg-gray-700' : 'border-2 border-green-700 bg-gray-700'}`}
                  
              //    required
                />
              </div>
              <span className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p0 bg-gray-800  grid gap-2 md:grid-cols-2 md:justify-between xl:justify-between">


</span>
            </div>
            <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cerrar</button>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewModelModal;
