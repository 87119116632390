import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';


const GuiaLoadPage = () => {
  const navigate = useNavigate();
  const navigateToGuia = () => {
    navigate("/guia");
  }; 

  

  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
     <span><button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button></span> 
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                  Almacen instrucciones
                  </h1> 
                  </div>
                  <div id="description" className="text-white ml-3 mr-3">

                  <p class="text-lg font-semibold mb-4 text-sky-700">Cuando llegan sacos de resina a la fábrica, el administrador prepara el código QR interno que contiene todos los detalles (proveedor, tipo de resina, color, etc.) y lo entrega al almacenista o supervisor.</p>

<p class="mb-4"><strong>El almacenista deberá:</strong></p>
<ul class="list-disc ml-6">
  <li>Asignar un código QR (facilidato por el admin) para cada saco que ingrese.</li>
  <li>Escanear estos mismos código QR desde el menú de almacén, pulsando el botón de escaneo código QR.</li>
  <li>Adjuntar cualquier código QR del proveedor a la ficha del "producto" con una foto, haciendo clic en el botón "Editar".</li>
</ul>

<p class="mb-2"><strong>Ejemplos de uso de opciones especiales:</strong></p>
<ul class="list-disc ml-6">
  <li>Se ha escaneado un saco adicional por error.</li>
  <p class="ml-6">Botón: "Eliminar Resina": permite quitar los kilogramos en exceso.</p>
  <li>Tenemos resina recuperada que se puede volver a poner en producción.</li>
  <p class="ml-6">Botón: "Añadir Resina"</p>
</ul>

<p class="mt-4">Los mismos permisos se asignan al supervisor en caso de necesidad.</p>

<p class="mt-4"><strong>El administrador dispone de acciones adicionales disponibles, como la modificación de varios parámetros, la eliminación del producto y la posibilidad de descarga de la ficha del producto.</strong></p>
                  </div>
                  <div>
          

</div>

<div>

<button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696801567/guia/load_resina.mp4"
                        )
                      }
           
          >
           Como escanear Resina
          </button>

<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696801571/guia/load_qr_provedor.mp4")
                        }
                        >Como Añadir QR de Provedor </button>
                        
                        <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696801569/guia/quitar_resina_error.mp4")
                        }
                        >Como Quitar Resina </button>
  <button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696801566/guia/add_resina_recuperable.mp4")
                        }
                        >Como Añadir Resina Reutilizable </button>
                        </div>
                        
                        
                       
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaLoadPage