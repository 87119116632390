import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

const SearchBarResume = ({ onSearch }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filterDate, setFilterDate] = useState('');
  
    const handleSearchInputChange = (event) => {
      setSearchQuery(event.target.value);
    };
  
    const handleFilterDateChange = (event) => {
      setFilterDate(event.target.value);
    };
  
    const handleSearchSubmit = (event) => {
      event.preventDefault();
      onSearch(searchQuery, filterDate);
    };
  
    const clearAll = () => {
      setSearchQuery('');
      setFilterDate('');
      onSearch('', '');
    };
  
    return (
      <>
     <div className='rounded-lg bg-transparent ml-2 flex-col justify-around items-center'>
    <form onSubmit={handleSearchSubmit}>
      <div className="flex items-center">
        <button className='rounded-lg rounded-r-none bg-white px-1 py-0.5'>
          <SearchIcon fontSize="small" type="submit" />
        </button>
        <input
          className='px-3 py-0.5 rounded-lg rounded-l-none'
          type="text"
          placeholder="...texto..."
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="mt-3">
        <input
          className='rounded-lg px-2 py-0.5'
          type="date"
          value={filterDate}
          onChange={handleFilterDateChange}
          placeholder="mm/dd/yyy"
        />
        <button
          className='text-white bg-slate-400 font-semibold rounded-lg text-sm px-3 py-1 ml-3'
          type="submit">Buscar</button>
      </div>
    </form>
    <div>
      <button
        className='text-white bg-slate-700 rounded-lg text-sm px-3 py-0 mt-3 w-full mb-4'
        type="button" onClick={clearAll}>Clear</button>
    </div>
  </div>
      </>
    );
  };

export default SearchBarResume;