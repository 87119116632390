import jwtDecode from "jwt-decode";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { removeSession } from "./utils/localStorage.utils";
import { Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Order from "./pages/Order";
import AdminPage from "./pages/Admin";
import LoginPage from "./pages/LoginPage";
import NewProductPage from "./pages/NewProduct";
import DashboardProducts from "./pages/DashboardProducts";
import DashboardUsers from "./pages/DashboardUsers";
import DashboardOrder from "./pages/DasboardOrders";
import NewModelPage from "./pages/NewModel";
import DashboardModels from "./pages/DashboardModels";
import DashboardStockPage from "./pages/DashboardStock";
import DashboardDeliveryPage from "./pages/DasboardDelivery";
import DashboardReview from "./pages/DashboardReview";
import ErrorPage from "./pages/ErrorPage";
import QrApp from "./components/temp/tempnewqrcodepage";
import DashboardStockPagePreforma from "./pages/DashboardStockPreforma";
import { useState, useEffect } from "react";
import { socket } from "./utils/variousConst";
import QRScanNewTest from "./components/temp/prodnewqrtest";
import DashboardCompletedOrder from "./pages/DashboardCompletedOrder";
import NewPreformaModelPage from "./pages/NewModelPreforma";
import DashboardPreforma from "./pages/DashboardPreforma";
import DashboardDeliveryPagePreforma from "./pages/DashboardDeliveryPreforma";
import DasboardPreformas from "./pages/DashboardPreformas";
import GuiaPage from "./pages/Guia";
import GuiaLoadPage from "./pages/AlmacenGuiaLoad";
import GuiaCrearPedidoPage from "./pages/CrearPedidoGuia";
import GuiaPedidoPage from "./pages/PedidoGuia";
import GuiaConsultaPage from "./pages/GuiaConsulta";
import GuiaRevisoPage from "./pages/GuiaRevisiones";
import LogRegPage from "./pages/LogReg";
import GuiaLogRegPage from "./pages/GuiaLogReg";
import GuiaConfigCameraPage from "./pages/GuiaCameraConfig";
import GarColorDeAsaNum from "./components/models/AsaElements/GarColorDeAsaNum";
import GarNumDePlacas from "./components/models/AsaElements/GarNumDePlacas";
import GarListaSubFamilia from "./components/models/AsaElements/GarListaSubFamilia";
import Roscas from "./components/models/AsaElements/Roscas";
import GarTipoModelos from "./components/models/AsaElements/GarTipoModelos";
import ListaColores from "./components/models/AsaElements/ListaColores";
import AllModelsComponent from "./components/models/DashboardAllModelsComponent";
import Customers from "./pages/NewCustomer";
import ChatPage from "./pages/ChatPage";
import DashboardAutoStockPage from "./pages/DashboardAutoStock";
import CalidadYDefecto from "./components/calidadydef/calidadydef";


const jwtSecretKey = process.env.JWT_SECRET_KEY;

const handleLogout = () => {
  window.localStorage.removeItem("user-session");
  //removeSession();
  //setSession(null);
};

// Simulated authentication logic
const isAdmin = () => {
  const token = window.localStorage.getItem("user-session");

  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    //console.log("test11", decodedToken)
    const { exp, role } = decodedToken;

    // Check if the token is expired
    const currentTime = Date.now();
    // console.log("cheroraes", currentTime)
    if (exp < currentTime) {
      handleLogout();
      return false; // Token has expired
    }

    return role === "admin";
  } catch (error) {
    console.error("Invalid token:", error);
    return false;
  }
};

const isVendedor = () => {
  const token = window.localStorage.getItem("user-session");

  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    //console.log("test11", decodedToken)
    const { exp, role } = decodedToken;

    // Check if the token is expired
    const currentTime = Date.now();
    // console.log("cheroraes", currentTime)
    if (exp < currentTime) {
      handleLogout();
      return false; // Token has expired
    }

    return role === "vendedor";
  } catch (error) {
    console.error("Invalid token:", error);
    return false;
  }
};

// Higher-order component for role-based authorization
const AdminRoute = ({ element }) => {
  const token = window.localStorage.getItem("user-session");
  if (!isAdmin()) {
    return <Navigate to="/home" replace />;
  }
  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { exp } = decodedToken;

    // Check if the token is expired
    const currentTime = Date.now();
    if (exp < currentTime) {
      handleLogout();
      return <Navigate to="/home" replace />; // Token has expired
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/home" replace />;
  }

  return element;
};

// Higher-order component for role-based authorization ADMIN & VENDEDOR
const AdminAndVendedorRoute = ({ element }) => {
  const token = window.localStorage.getItem("user-session");
  if (!isAdmin() && !isVendedor()) {
    return <Navigate to="/home" replace />;
  }
  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { exp } = decodedToken;

    // Check if the token is expired
    const currentTime = Date.now();
    if (exp < currentTime) {
      handleLogout();
      return <Navigate to="/home" replace />; // Token has expired
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/home" replace />;
  }

  return element;
};

// Higher-order component for role-based authorization

const ScanningRoute = ({ element }) => {
  const token = window.localStorage.getItem("user-session");
  if (!token) {
    return <Navigate to="/" replace />;
  }
  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { exp, role } = decodedToken;
    // Check if the token is expired
    const currentTime = Date.now();
    if (exp < currentTime) {
      handleLogout();
      return <Navigate to="/" replace />; // Token has expired
    }
    if (role === "almacenista") {
      return <Navigate to="/home" replace />;
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/" replace />;
  }
  return element;
};

const AlmacenRoute = ({ element }) => {
  const token = window.localStorage.getItem("user-session");
  if (!token) {
    return <Navigate to="/" replace />;
  }
  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { exp, role } = decodedToken;
    // Check if the token is expired
    const currentTime = Date.now();
    if (exp < currentTime) {
      handleLogout();
      return <Navigate to="/" replace />; // Token has expired
    }
    if (role === "user") {
      return <Navigate to="/home" replace />;
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/" replace />;
  }
  return element;
};

const ProtectedRoute = ({ element }) => {
  const token = window.localStorage.getItem("user-session");

  if (!token) {
    return <Navigate to="/" replace />;
  }
  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { exp } = decodedToken;
    // Check if the token is expired
    const currentTime = Date.now();
    if (exp < currentTime) {
      handleLogout();
      return <Navigate to="/" replace />; // Token has expired
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/" replace />;
  }
  return element;
};

const AlreadyLoggedRoute = ({ element }) => {
  const token = window.localStorage.getItem("user-session");
  if (!token) {
    return <LoginPage />;
  }
  try {
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { exp, role } = decodedToken;
    // Check if the token is expired
    const currentTime = Date.now();
    if (exp < currentTime) {
      handleLogout();
      return <Navigate to="/" replace />; // Token has expired
    }
    if (role === "admin") {
      return <Navigate to="/admin" replace />;
    }

    // For other roles (user, supervisor, almacenista), navigate to "/home"
    return <Navigate to="/home" replace />;
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/" replace />;
  }
};

function App() {
  useEffect(() => {
    const handleOrderCreatedMessage = (message) => {
      const messageDetails = (
        <div className={`flex flex-col justify-center items-center ${message.materia === "stock" ? "bg-red-500 text-white rounded-lg" : ""}`}>
          
          <div className="font-bold ">Nuevo Pedido</div>
          <div>Pedido Nr: {message.ordernr}</div>
          <div>Cliente: {message.customer}</div>
{message.materia !=="stock" && (
          <div>Cantidad: {message.itemstodo}</div>
        )}
        {message.materia ==="stock" && (
          <div>Unidades STOCK: {message.itemsstock}</div>
        )}
{message.preforma && ( 
          <div>Preforma: {message.preforma}</div>
          )}
{message.finalproduct && ( 
          <div>Garrafones: {message.finalproduct}</div>
          )}
{message.code && (
          <div>Resina: {message.code}</div>
)}
        </div>
      );
      toast(<>{messageDetails}</>, {
        autoClose: false,
        
      });
     
    };

    // Add the 'orderCreatedMessage' event listener
    socket.on('orderCreatedMessage', handleOrderCreatedMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('orderCreatedMessage', handleOrderCreatedMessage);
    };
  }, []);

  const [selectedComponent, setSelectedComponent] = useState(null);
  return (
    <>
    
      <div className="bg-gray-900 min-h-screen">
        <NavBar />
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={<AlreadyLoggedRoute element={<LoginPage />} />}
          />

          <Route path="/newqr" element={<AdminRoute element={<QrApp />} />} />

          <Route
            path="/newqr2"
            element={<AdminRoute element={<QRScanNewTest />} />}
          />

          <Route
            path="/guia"
            element={<AlmacenRoute element={<GuiaPage />} />}
          />
          <Route
            path="/guiaload"
            element={<AlmacenRoute element={<GuiaLoadPage />} />}
          />
          <Route
            path="/guiacrearpedido"
            element={<AlmacenRoute element={<GuiaCrearPedidoPage />} />}
          />
          <Route
            path="/guiapedido"
            element={<AlmacenRoute element={<GuiaPedidoPage />} />}
          />
          <Route
            path="/guiaconsulta"
            element={<AlmacenRoute element={<GuiaConsultaPage />} />}
          />
          <Route
            path="/guiareviso"
            element={<AlmacenRoute element={<GuiaRevisoPage />} />}
          />
          <Route
            path="/guialogreg"
            element={<AlmacenRoute element={<GuiaLogRegPage />} />}
          />
          <Route
            path="/guiacamara"
            element={<AlmacenRoute element={<GuiaConfigCameraPage />} />}
          />

          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />

          <Route
            path="/chat"
            element={<ProtectedRoute element={<ChatPage  />} />}
          />

          <Route
            path="/dashboardorders"
            element={<ProtectedRoute element={<DashboardOrder />} />}
          />
          <Route
            path="/dashboardpreformaorder"
            element={<ProtectedRoute element={<DasboardPreformas />} />}
          />

          <Route
            path="/dashboardstock"
            element={<ProtectedRoute element={<DashboardStockPage />} />}
          />
            <Route
            path="/dashboardautostock"
            element={<ProtectedRoute element={<DashboardAutoStockPage />} />}
          />
          <Route
            path="/dashboardstockpreforma"
            element={
              <ProtectedRoute element={<DashboardStockPagePreforma />} />
            }
          />

          <Route
            path="/dashboarddelivery"
            element={<ProtectedRoute element={<DashboardDeliveryPage />} />}
          />
          <Route
            path="/dashboarddeliverypreforma"
            element={
              <ProtectedRoute element={<DashboardDeliveryPagePreforma />} />
            }
          />

<Route
            path="/dashboardquality"
            element={<ProtectedRoute element={<CalidadYDefecto />} />}
          />

          <Route
            path="/dashboardproducts"
            element={<AlmacenRoute element={<DashboardProducts />} />}
          />

          <Route
            path="/admin"
            element={<AdminRoute element={<AdminPage />} />}
          />
          <Route
            path="/register"
            element={<AdminRoute element={<Register />} />}
          />

            <Route
            path="/customers"
            element={<AdminRoute element={<Customers />} />}
          />

          <Route
            path="/newproduct"
            element={<AdminRoute element={<NewProductPage />} />}
          />
          <Route
            path="/order"
            element={<AdminAndVendedorRoute element={<Order />} />}
          />
          <Route
            path="/model"
            element={<AdminRoute element={<NewModelPage />} />}
          />

          <Route
            path="/dashboardallmodelscomponents"
            element={
              <AdminRoute
                element={
                  <AllModelsComponent
                    selectedComponent={selectedComponent}
                    setSelectedComponent={setSelectedComponent}
                  />
                }
              />
            }
          />

          <Route
            path="/preformamodel"
            element={<AdminRoute element={<NewPreformaModelPage />} />}
          />

          <Route
            path="/dashboardusers"
            element={<AdminRoute element={<DashboardUsers />} />}
          />
          <Route
            path="/dashboardmodels"
            element={<AdminRoute element={<DashboardModels />} />}
          />
          <Route
            path="/dashboardpreforma"
            element={<AdminRoute element={<DashboardPreforma />} />}
          />
          <Route
            path="/dashboardreview"
            element={<AdminRoute element={<DashboardReview />} />}
          />
          <Route
            path="/dashboardcompleted"
            element={
              <AdminAndVendedorRoute element={<DashboardCompletedOrder />} />
            }
          />
          <Route
            path="/logreg"
            element={<AdminRoute element={<LogRegPage />} />}
          />

          <Route path="*" element={<ErrorPage></ErrorPage>} />
        </Routes>
      </div>
    
    </>
  );
}

export default App;
