import { apiWrapper } from '../../utils/apiwrapper';
import { useState, useEffect } from 'react';
import React from 'react';
import { format } from 'date-fns';
//import WarehouseIcon from '@mui/icons-material/Warehouse';
import EditModal from '../editmodal/EditModal';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {CSVLink} from "react-csv";
import SubtractKgModal from '../editmodal/SubtractKdModal';
import SearchBar from '../searchbar/searchbar';
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import { socket } from "../../utils/variousConst.js";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import {almacenheaders} from "../../utils/variousConst";
import EditAlertDeleteArticleModal from "../editmodal/EditAlertDeleteArticle";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RecyclingIcon from '@mui/icons-material/Recycling';
import QRScan from '../Product/VideoProduct';
import Alert from "@mui/material/Alert";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { Snackbar } from "@mui/material";

import RecycleModal from '../editmodal/RecycleModal';
import {date, options, mexicanTime, formatMexicanDate} from "../../utils/variousConst"
import EditConsultAlmacenModal from '../editmodal/EditConsultAlmacenModal';
import HelpIcon from '@mui/icons-material/Help';

const AlmacenComponent = ({ article }) => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [message, setMessage] = useState('')
  const errorSound = new Audio('/sounds/error.wav')

  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const [loading, setLoading] = useState(true);

  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;


  
    const fetchArticles = async () => {
      try {
        const response = await apiWrapper("almacen", "GET");
        setArticles(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching articles:", error);
      }
    };

    useEffect(() => {
      fetchArticles(); 
    },[]);

    

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (searchQuery) => {
      setSearchQuery(searchQuery);
    };
    useEffect(() => {
    
      if (searchQuery === '') {
      setFilteredArticles([]); // Clear the filtered articles list
    } else {
    const filtered = articles.filter((article) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const lowerCaseCodigo = article.code.toLowerCase();
      const lowerCaseProvedor = article.provider.toLowerCase();
      const lowerCasePais = article.country.toLowerCase();
      const lowerCaseColor = article.color.toLowerCase();
      const lowerCodeAlfa = article.codealfa.toLowerCase();
      return (
        lowerCaseCodigo.includes(lowerCaseSearchQuery) ||
        lowerCaseProvedor.includes(lowerCaseSearchQuery) ||
        lowerCasePais.includes(lowerCaseSearchQuery)) ||
        lowerCaseColor.includes(lowerCaseSearchQuery) ||
        lowerCodeAlfa.includes(lowerCaseSearchQuery);
    });
    setFilteredArticles(filtered);
  };
}, [searchQuery, articles]); 

 
const fetchArticlesCSV = async () => {
  try {
    const response = await apiWrapper("almacen", "GET");
    setArticles(response);
    setIsDownloadReady(true);
    setTimeout(() => {
      setIsDownloadReady(false)
    }, 5000);
  } catch (error) {
    console.error("Error fetching articles:", error); }
};

  const [colorUpdatedAlmacenSubtractKg, setColorUpdatedAlmacenSubtractKg ] =useState(false)
  const [colorUpdated ,setColorUpdated] = useState(false)


  const [alertDeleteArticle, setAlertDeleteArticle] = useState(null);
  const [isAlertArticleDeleteModalOpen, setIsAlertArticleDeleteModalOpen] = useState(false)

  const openAlertArticleDeleteModal = (article, index) =>{
    setAlertDeleteArticle({article, index});
    setIsAlertArticleDeleteModalOpen(true)
  }

  const closeOpenAlertArticleDeleteModal =() =>{
    setAlertDeleteArticle(null);
    setIsAlertArticleDeleteModalOpen(false)
  }

  const [editableItem, setEditableItem] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  //modal kg ratio
  const [KgEditableItem, setKgEditableItem] = useState(null)
  const [isKgModalOpen, setIsKgModalOpen] = useState(false);

  const [addConsultModal, setConsultModal] = useState(null);
  const [isConsultModalOpen,setIsConsultModalOpen ]= useState( false )


  const openConsultModal = (article, index) =>{
    setConsultModal({article, index});
    setIsConsultModalOpen(true)
  }

  const closeConsultModal =() =>{
    setConsultModal(null);
    setIsConsultModalOpen(false)
  }

  
  const [isRecycleModalOpen, setIsRecycleModalOpen] = useState(false);




  const openRecycleModal =(article, index) =>{
    setKgEditableItem({article,index});
    setIsRecycleModalOpen(true);
  };
  
  const closeRecycleModal = () => {
    setKgEditableItem(null);
    setIsRecycleModalOpen(false);
  }

const openKgModal =(article, index) =>{
  setKgEditableItem({article,index});
  setIsKgModalOpen(true);
};

const closeKgModal = () => {
  setKgEditableItem(null);
  setIsKgModalOpen(false);
}
//


  const openEditModal = (article,index) => {
    setEditableItem({article, index});
    setEditModalOpen(true);
  };
  
  const closeEditModal = () => {
    setEditableItem(null);
    setEditModalOpen(false);
  };

  const closeEditModalQr = () => {
    setEditableItem(null);
    setEditModalOpen(false);
    fetchArticles()
  };

//apikg
const handleSaveSubtract = (index, updatedArticle) => {
   // console.log(updatedArticle)
  
  // Make a PATCH request to update the article on the server
  const updateArticleOnServer = async () => {

    
    const valueToRemove=updatedArticle.kg / updatedArticle.masterkg;
//console.log(valueToRemove)
const kgToRemove=updatedArticle.kg
//console.log(kgToRemove)
//console.log(updatedArticle.mexicanTime)
    try {
     const res = await apiWrapper(`almacen/${updatedArticle._id}/subtract`, 'PATCH',{
        value: valueToRemove,
        kg: kgToRemove,
        employee: {user, 
          mexicanTime: new Date().toLocaleString("en-US", options), 
          value: valueToRemove,  kg: kgToRemove}
      });
      if(res.message) {
        if(res.message === "Updated value exceeds itemtodo. Patch denied.") {
          errorSound.play()
         // window.alert('An error occurred while updating the article. Please try again.');

         setMessage(
          <Snackbar
            open={true}
            autoHideDuration={4000}
            onClose={() => setMessage(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <Alert className="flex justify-center" severity="error">
              Intentaste sacar más kg de los almacenados. Operación no permitida.
              <div className="flex flex-col items-center">
                <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
               
                  <div>   Intentaste sacar: {kgToRemove} Kg </div>
                </div>
              Revisa, y inténtalo de nuevo
              </div >
              
            </Alert>
          </Snackbar>
        );   
          console.log('Error block reached.') 
            }}
      

      // If the PATCH request is successful, update the article in the state
      const updatedArticles = [...articles];
      updatedArticles[index] = updatedArticle;
      setArticles(updatedArticles);
      const response = await apiWrapper('almacen', 'GET');
    setArticles(response);
      closeEditModal();
    } catch(error) {
      //errorSound.play()
      
      console.error("Error fetching orders:", error);
        
      //

      // Handle error updating article
    }
  };

  updateArticleOnServer();
};
//

//recycle add
const handleSaveAdd = (index, updatedArticle) => {
  // console.log(updatedArticle)
 
 // Make a PATCH request to update the article on the server
 const updateArticleOnServer = async () => {

   
   const valueToRemove=updatedArticle.kg / updatedArticle.masterkg;
//console.log(valueToRemove)
const kgToRemove=updatedArticle.kg
//console.log(kgToRemove)
//console.log(updatedArticle.mexicanTime)
   try {
    const res = await apiWrapper(`almacen/${updatedArticle._id}/addrecycle`, 'PATCH',{
       value: valueToRemove,
       kg: kgToRemove,
       employee: {user, 
        mexicanTime: new Date().toLocaleString("en-US", options),
           value: valueToRemove,  kg: kgToRemove}
     });
     if(res.message) {
       if(res.message === "Updated value exceeds itemtodo. Patch denied.") {
         errorSound.play()
        // window.alert('An error occurred while updating the article. Please try again.');

        setMessage(
         <Snackbar
           open={true}
           autoHideDuration={4000}
           onClose={() => setMessage(null)}
           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
           style={{ top: '50%', transform: 'translateY(-50%)' }}
         >
           <Alert className="flex justify-center" severity="error">
             Intentaste sacar más kg de los almacenados. Operación no permitida.
             <div className="flex flex-col items-center">
               <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
              
                 <div>   Intentaste sacar: {kgToRemove} Kg </div>
               </div>
             Revisa, y inténtalo de nuevo
             </div >
             
           </Alert>
         </Snackbar>
       );   
         console.log('Error block reached.') 
           }}
     

     // If the PATCH request is successful, update the article in the state
     const updatedArticles = [...articles];
     updatedArticles[index] = updatedArticle;
     setArticles(updatedArticles);
     const response = await apiWrapper('almacen', 'GET');
   setArticles(response);
     closeEditModal();
   } catch(error) {
     //errorSound.play()
     
     console.error("Error fetching orders:", error);
       
     //

     // Handle error updating article
   }
 };

 updateArticleOnServer();
};
//


  const handleSave = (index, updatedArticle) => {
   // console.log(updatedArticle)
    updatedArticle.mexicanTime = mexicanTime;
  updatedArticle.user = user;
  updatedArticle.employee.push({
    mexicanTime: new Date().toLocaleString("en-US", options),
    user, code:updatedArticle.code, provider:updatedArticle.provider, 
    value:updatedArticle.value, kg:updatedArticle.kg, country:updatedArticle.country, masterkg:updatedArticle.masterkg,
  color: updatedArticle.color, lot: updatedArticle.lot, grade: updatedArticle.grade, modelname: updatedArticle.modelname, codealfa: updatedArticle.codealfa});
  
  
    // Make a PATCH request to update the article on the server
    const updateArticleOnServer = async () => {
      try {
        await apiWrapper(`almacen/${updatedArticle._id}`, 'PATCH', updatedArticle);
        // If the PATCH request is successful, update the article in the state
        const updatedArticles = [...articles];
        updatedArticles[index] = updatedArticle;
        setArticles(updatedArticles);

// Update the filtered search as well
const updatedFilteredArticles = [...filteredArticles];
updatedFilteredArticles[index] = updatedArticles;
setFilteredArticles(updatedFilteredArticles);


       // const response = await apiWrapper('almacen', 'GET');
    //  setArticles(response);
      fetchArticles();
        closeEditModal();
      } catch (error) {
        console.error('Error updating article:', error);
        // Handle error updating article
      }
    };
  
    updateArticleOnServer();
  };

 

  const handleDelete = async (articleId) => {
    try {
      // Make a DELETE request to the API to delete the article with the given ID
      await apiWrapper(`almacen/${articleId}`, "DELETE");

      // Update the articles state by removing the deleted article from the array
      setArticles(articles.filter((article) => article._id !== articleId));
    } catch (error) {
      // Handle error
      console.error("Error deleting article:", error);
    }
  };


  useEffect(() => {
    socket.on('subtractArticleDecrement', (updatedAlmacenSubtract) => {
     //console.log(updatedAlmacenSubtract)
      setColorUpdatedAlmacenSubtractKg(updatedAlmacenSubtract._id); // Set the ID of the updated order
      
  
      setTimeout(() => {
        setColorUpdatedAlmacenSubtractKg(null); // Clear the ID after 3 seconds
      
     }, 3000);
  
     setArticles((prevArticles) => {
        const updatedArticles = prevArticles.map((article) => {
          
          if(article._id ===updatedAlmacenSubtract._id) {
           
            return{...article, kg:updatedAlmacenSubtract.kg, value:updatedAlmacenSubtract.value
           
            }
            
          }
          return article;
          
        });
        //console.log(updatedArticles)
        return updatedArticles;
        
      });
    });
  
    return () => {
      socket.off('subtractArticleDecrement');
    };
  }, []);




  useEffect(() => {
    socket.on('updateArticle', (updatedEditArticle) => {
     //console.log("cheedita",updatedEditArticle.data.customer)
      setArticles((prevArticles) => {
        const updatedArticles = prevArticles.map((article) => {
          if (article._id === updatedEditArticle._id) {
            //console.log("check",updatedEditArticle.customer)
            
            return { ...article, ...updatedEditArticle.data };
            
          }
          return article;
        });
        //console.log("check2", updatedArticles)
        return updatedArticles;
      });
    });
  
    return () => {
      socket.off('updateArticle');
    };
  }, []);



  useEffect(() => {
    const handleCreateArticle = (newArticle) => {
      setArticles((prevArticles) => [...prevArticles, newArticle]);
    };
  
    // Add the 'createArticle' event listener
    socket.on('createArticle', handleCreateArticle);
  
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('createArticle', handleCreateArticle);
    };
  }, []);


  useEffect(() => {
    socket.on('deleteArticle', (deletedArticleId) => {
      setArticles((prevArticles) => {
        return prevArticles.filter((article) => article._id !== deletedArticleId);
      });
    });
  
    return () => {
      socket.off('deleteArticle');
    };
  }, []);

  useEffect(() => {
    socket.on('updateArticleIncrement',(updatedItemsscanned) => {
      //console.log(updatedItemsscanned)
      setColorUpdated(updatedItemsscanned._id); // Set the ID of the updated order
   //   console.log("heeel",updatedItemsscanned._id)
  
      setTimeout(() => {
        setColorUpdated(null); // Clear the ID after 3 seconds
      }, 3000);
  
      setArticles((prevArticles) => {
        const updatedArticles = prevArticles.map((article) => {
          if (article._id === updatedItemsscanned._id) {
           // console.log(updatedItemsscanned)
          //  console.log(article)
           // console.log(updatedItemsscanned.value)
          //  console.log(updatedItemsscanned.kg)
            return { ...article, value: updatedItemsscanned.value, kg:updatedItemsscanned.kg };
           
          }
        //  console.log(article.provider)
          return article;
             });
       //      console.log(updatedArticles)
             return updatedArticles;
             
      });
    });
  
    return () => {
      socket.off('updateArticleIncrement');
       };
  }, []);

 //spinning loading
  useEffect(() => {
    if (articles.length === 0) {
      // If there are no articles to load, hide the loading animation after 5 seconds
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 5000);
  
      return () => clearTimeout(timeoutId);
    } else {
      setLoading(true);
    }
  }, [articles]);

  return (
    
    <section className="bg-gray-900   sticky top-10 z-40    ">
    <div className="flex flex-col  px-6 py-8   ">
      <div className="bg-gray-800 rounded-lg ">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
    
        <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
          Almacén de Resina
        </h1>
        <div>{message} </div>
        <div className="flex items-center justify-center">
              <div className="bg-transparent text-white hover:text-emerald-600 flex justify-center" > <QRScan id={123}  /></div>
           
            </div>
           
           
            <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg ">
            <div
        className="absolute top-10 left-10 text-white"
        >
    
        <HelpIcon 
          sx={{ fontSize: 20,    
          }}
             onClick= {() =>
              openConsultModal(article)
            }          />
             
                  {role === "admin" && ( 
      <>
         

     <button 
        className='text-white ml-5'
        onClick={fetchArticlesCSV}>
                    {isDownloadReady ? ( 
          <CSVLink data={articles} headers={almacenheaders} filename={`ListaResina.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink> ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
       
      </>
    )}
            </div> 
                <div className="text-center">
    <SearchBar key="searchbar" onSearch={handleSearch }  />
    </div>
    
  </div>
      </div> 
      <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
            <tr>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Codigo
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Code Alfa
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Modelo
              </th>
           
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Color
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Lot
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Grade
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Piezas
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Peso (Kg)
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Provedor
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Pais
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Fecha de Entrada
              </th>
               
              {role !== "user" && (
                 <React.Fragment>
              <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                Edit
              </th>
              </React.Fragment>
)}
              {role === "admin" && (
                 <React.Fragment>
              <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                Delete
              </th>
              <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                Export
              </th>
              </React.Fragment>
)}
              <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                Quitar (Kg)
              </th>

              <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
               Reciclaje (Kg)
              </th>
             
              {/* Add more table headers as needed */}
            </tr>
        
          </thead>
          <tbody>
  {filteredArticles.map((article,index) => (
    <tr className={
      index % 2 === 0 ? "bg-amber-400 text-center" : "bg-amber-300 text-center" 
    }  key={`${article.provider}-${article.code}`}>
      <td>{article.code}</td>
      <td>{article.codealfa}</td>
      <td>{article.modelname}</td>
      
      <td className="px-6 py-4 whitespace-nowrap">{article.color}</td>
                <td className="px-6 py-4 whitespace-nowrap">{article.lot}</td>
                <td className="px-6 py-4 whitespace-nowrap">{article.grade}</td>
      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}>{Number.isInteger(article.value) ? article.value : Math.round(article.value * 100) / 100}</td> 
   <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}>{Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100}</td>
<td>{article.provider}</td>
                <td className="px-6 py-4 text-xs  whitespace-nowrap">{article.country}</td>
                <td className=" text-xs px-6 py-4 whitespace-nowrap">{formatMexicanDate(article.createdAt)}</td>
                {role !== "user" && (  
                <React.Fragment> 
                <td className=" whitespace-nowrap    ">
               
                   <EditIcon
                     onClick={() => openEditModal(article, index)} />
                </td>
                </React.Fragment>)}
                {role === "admin" && (  
                <React.Fragment> 
                <td className="px-2  whitespace-nowrap m-18  ">
                   <DeleteOutlineIcon
                           onClick={() => openAlertArticleDeleteModal(article)}
                         ></DeleteOutlineIcon>
                   </td>

                   <td className=" whitespace-nowrap    ">
                    <button onClick={fetchArticlesCSV}>
                    {isDownloadReady ? ( 
          <CSVLink data={[article, ...article.employee]} headers={almacenheaders} filename={`article_${article._id}_${article.code}_${article.provider}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink> ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
          </td>
                    </React.Fragment>)}
                   <td className=" whitespace-nowrap    ">
                   
                   <PlaylistRemoveIcon
                     onClick={() => openKgModal(article, index)}
               />
          </td>
          <td className=" whitespace-nowrap    ">
                   
                   <RecyclingIcon
                     onClick={() => openRecycleModal(article, index)}
               />
          </td>
         
       
    </tr>

  ))}
</tbody>

          <tbody className=" divide-y divide-gray-200 text-center text-white">
          {articles.length === 0 || filteredArticles.length > 0  ? (
       <tr className="text-center">
       <td colSpan="8">
     {loading ? (
       <p className=" text-white text-center">-</p>
     ) : (
       <p className="text-center  font-medium text-white">No hay datos en DB</p>
     )}
   </td>
       </tr>
  ) : (articles.slice().reverse().map((article, index) => (
              <tr className={index  % 2 === 0 ? " bg-sky-900" : " bg-sky-800"} key={article._id}>
                <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">{article.code}</td>
                <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">{article.codealfa}</td>
                <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">{article.modelname}</td>
                
                <td className="px-6 py-4 whitespace-nowrap">{article.color}</td>
                <td className="px-6 py-4 whitespace-nowrap">{article.lot}</td>
                <td className="px-6 py-4 whitespace-nowrap">{article.grade}</td>
                <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}>{Number.isInteger(article.value) ? article.value : Math.round(article.value * 100) / 100}</td>
                <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}>{Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100}</td>
                <td className="px-6 py-4 whitespace-nowrap">{article.provider}</td>
                <td className="px-6 py-4 text-xs  whitespace-nowrap">{article.country}</td>
                <td className=" text-xs px-6 py-4 whitespace-nowrap">{formatMexicanDate(article.createdAt)}</td>
                {role !== "user" && (  
                <React.Fragment>
                    <td className=" whitespace-nowrap    ">
                   
                      <EditIcon
                        onClick={() => openEditModal(article, index)}
                        
                      />
                      
                      
                    </td>
                    </React.Fragment> )}
                    {role === "admin" && (  
                <React.Fragment>
                    
                    <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertArticleDeleteModal(article)}
                            ></DeleteOutlineIcon>
                      </td>

                      <td className="px-2  whitespace-nowrap m-18">
                      <button onClick={fetchArticlesCSV}>
                    {isDownloadReady ? ( 
          <CSVLink data={[article, ...article.employee]} headers={almacenheaders} filename={`article_${article._id}_${article.code}_${article.provider}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink> ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                    
                     
                   </td>
                     </React.Fragment>)}

                    
                    <td className=" whitespace-nowrap    ">
                   
                   <PlaylistRemoveIcon
                     onClick={() => openKgModal(article, index)}
                    
                   />
                                     
                 </td>
                 <td className=" whitespace-nowrap    ">
                   
                   <RecyclingIcon
                     onClick={() => openRecycleModal(article, index)}
               />
          </td>
         
              </tr>
            ))
            )}
          </tbody>
        </table>
      </div>
      
      <div className='grid grid-cols-1 gap-4 md:hidden  '>
      <div className="flex justify-center items-center " >
      <div
        className="absolute top-10 left-10 text-white "
        >
        <HelpIcon 
          sx={{ fontSize: 20,    
          }}
             onClick= {() =>
              openConsultModal(article)
            }          />
               {role === "admin" && ( 
      <>
         

     <button 
        className='text-white ml-5'
        onClick={fetchArticlesCSV}>
                    {isDownloadReady ? ( 
          <CSVLink data={articles} headers={almacenheaders} filename={`ListaResina.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink> ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
       
      </>
    )}


            </div> 
    <SearchBar key="searchbar" onSearch={handleSearch }  />
    
  
    </div>
<div>

  {filteredArticles.map((article, index) => (
    <div  className={
      index % 2 === 0 ? "bg-amber-400 text-center" : "bg-amber-300 text-center" 
    } key={`${article.provider}-${article.code}`}>
        <div className="flex flex-col items-center justify-center space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
  
  <span className="flex p-2 justify-center items-center  text-xl font-bold">{article.code}</span>
  <span className="flex p-2 justify-center items-center  text-xl font-bold">{article.modelname}</span>

      </div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-900">Code Alfa:</span> <span> {article.codealfa}</span></div>
      
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all "> <span className="text-gray-900">Color:</span> <span> {article.color}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-900">Lot:</span> <span> {article.lot}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-900">Grade:</span> <span> {article.grade}</span></div>
      <div className={`flex items-center justify-between space-x-2 text-2xl font-bold ml-3 mr-3 mt-2 whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}><span className="text-gray-900 text-sm font-medium ">Piezas:</span> <span className="flex items-center justify-end space-x-2">
{Number.isInteger(article.value) ? article.value : Math.round(article.value * 100) / 100}
<span className="text-gray-900 text-sm font-medium ml-2">ud</span>
</span></div>
      <div className={`flex items-center justify-between space-x-2 text-2xl font-bold ml-3 mr-3 mb-4 whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}><span className="text-gray-900 text-sm font-medium ">Peso:</span> <span className="flex items-center justify-end space-x-2"> {Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100} <span className="text-gray-900 text-sm font-medium ml-2 ">Kg</span></span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-900">Proveedor:</span> <span> {article.provider}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 text-sm font-medium break-all "><span className="text-gray-900 text-sm font-medium ">Pais:</span> <span> {article.country}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 text-sm font-medium break-all "><span className="text-gray-900 text-sm font-medium ">Fecha de entrada:</span> <span> {formatMexicanDate(article.createdAt)}</span></div>
      <div className="flex flex-col justify-between ">
      {role !== "user" && (  
                <React.Fragment>
    <button
        onClick={() => openEditModal(article, index)}
        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                        >
                          <EditIcon /> Edit
                        </button>
                        </React.Fragment> )}
                        {role === "admin" && (  
                <React.Fragment>
      <button
        onClick={() => openAlertArticleDeleteModal(article)}
        className=" text-white focus:ring-4 focus:outline-none  
        font-medium rounded-lg text-sm px-1 py-0.5 text-center
        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
        >
          <DeleteOutlineIcon /> Del
        </button> 
        
        <button
        
        className=" text-white focus:ring-4 focus:outline-none  
        font-medium rounded-lg text-sm px-1 py-0.5 text-center
        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
        onClick={fetchArticlesCSV}
       >
           {isDownloadReady ? ( 
            <CSVLink data={[article, ...article.employee]} headers={almacenheaders} filename={`article_${article._id}_${article.code}_${article.provider}.csv`}>
        <FileDownloadOutlinedIcon /> Listo
      </CSVLink>) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}

        </button>
        
        </React.Fragment>)}
        
      <button
        onClick={() => openKgModal(article, index)}
        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                      bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mt-2"
                        >
                          <PlaylistRemoveIcon /> Quitar Resina
                        </button>{" "}

                        <button
        onClick={() => openRecycleModal(article, index)}
        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                      bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4 mt-2"
                        >
                          <RecyclingIcon/> Añadir Resina
                        </button>{" "}
    </div>
    </div>
    
  ))}
 
</div>
{articles.length === 0 || filteredArticles.length > 0 ? (
   loading ? (
    <div className="border rounded-lg bg-sky-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className=" text-white">-</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-sky-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)
  ) : (articles.slice().reverse().map((article, index) => (
    <div className={index % 2 === 0 ? "border rounded-lg bg-sky-900 text-white"
    : " border rounded-lg bg-sky-800 text-white"} key={article._id}>
      <div className="bg-transparent ">
      <div className="flex flex-col items-center justify-center  space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
  
  <span className="flex p-2 justify-center items-center  text-xl font-bold">{article.code}</span>
  
  <span className="flex p-2 justify-center items-center  text-lg ">{article.modelname}</span>
</div>
        
      </div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-300">Code Alfa:</span> <span> {article.codealfa}</span></div>
      
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all "> <span className="text-gray-300">Color:</span> <span> {article.color}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-300">Lot:</span> <span> {article.lot}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-300">Grade:</span> <span> {article.grade}</span></div>

      <div className={`flex items-center justify-between space-x-2 text-2xl font-bold ml-3 mr-3 mt-2 whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}><span className="text-gray-300 text-sm font-medium ">Piezas:</span> <span className="flex items-center justify-end space-x-2">
{Number.isInteger(article.value) ? article.value : Math.round(article.value * 100) / 100}
<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
</span></div>
      <div className={`flex items-center justify-between space-x-2 text-2xl font-bold ml-3 mr-3 mb-2 whitespace-nowrap ${
  colorUpdatedAlmacenSubtractKg === article._id ? 'text-red-500' : colorUpdated === article._id ? 'text-green-500' : ''
}`}><span className="text-gray-300 text-sm font-medium ">Peso:</span> <span className="flex items-center justify-end space-x-2"> {Number.isInteger(article.kg) ? article.kg : Math.round(article.kg * 100) / 100} <span className="text-gray-300 text-sm font-medium ml-2 ">Kg</span></span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all "> <span className="text-gray-300">Provedor:</span> <span> {article.provider}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 text-sm font-medium break-all "><span className="text-gray-300 text-sm font-medium ">Pais:</span> <span> {article.country}</span></div>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3 text-sm font-medium break-all "><span className="text-gray-300 text-sm font-medium ">Fecha de entrada:</span> <span>{formatMexicanDate(article.createdAt)}</span></div>
      <div className="flex flex-col justify-between ">
      {role !== "user" && (  
                <React.Fragment>
      <button
        onClick={() => openEditModal(article, index)}
        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                        >
                          <EditIcon /> Edit
                        </button>
                        </React.Fragment> )}
                        {role === "admin" && (  
                <React.Fragment>
      <button
        onClick={() => openAlertArticleDeleteModal(article)}
        className=" text-white focus:ring-4 focus:outline-none  
        font-medium rounded-lg text-sm px-1 py-0.5 text-center
        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
        >
          <DeleteOutlineIcon /> Del
        </button>


        <button
        
        className=" text-white focus:ring-4 focus:outline-none  
        font-medium rounded-lg text-sm px-1 py-0.5 text-center
        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
        onClick={fetchArticlesCSV}
        >
            {isDownloadReady ? ( 
            <CSVLink data={[article, ...article.employee]} headers={almacenheaders} filename={`article_${article._id}_${article.code}_${article.provider}.csv`}>
        <FileDownloadOutlinedIcon /> Listo
      </CSVLink>) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
        </button>
      
        </React.Fragment>)}
      <button
        onClick={() => openKgModal(article, index)}
        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                      bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3  mt-2"
                        >
                          <PlaylistRemoveIcon /> Quitar Resina
                        </button>{" "}

                        <button
        onClick={() => openRecycleModal(article, index)}
        className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                      bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4 mt-2"
                        >
                          <RecyclingIcon/> Añadir Resina
                        </button>{" "}
      </div>
    </div>
  ))
  )}
</div>
     
       
      
    </div>
  </div>
  {isEditModalOpen && (
     <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll  ">
     <div className="mt-10 flex items-center  justify-center">
  <EditModal editableItem={editableItem} onSave={handleSave} onClose={closeEditModal} onCloseQr={closeEditModalQr} mexicanTime={mexicanTime} options={options} user={user} role={role} />
  </div>
        </div>
)};
{isKgModalOpen && (
    <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
    <div className="mt-10 flex items-center  justify-center">
  <SubtractKgModal KgEditableItem={KgEditableItem} onSave={handleSaveSubtract} onClose={closeKgModal} mexicanTime={mexicanTime}  />
  </div>
        </div>
)}
{isRecycleModalOpen && (
    <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
    <div className="mt-10 flex items-center  justify-center">
  <RecycleModal KgEditableItem={KgEditableItem} onSave={handleSaveAdd} onClose={closeRecycleModal} mexicanTime={mexicanTime}  />
  </div>
        </div>
)}
  {isAlertArticleDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteArticleModal
              alertDeleteArticle={alertDeleteArticle}
              onSave={handleDelete}
              onClose={closeOpenAlertArticleDeleteModal}
            />
          </div>
        </div>
      )};
       {isConsultModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45 bg-gray-800 opacity-90">
        
          <div className="mt-10">
            <EditConsultAlmacenModal
              addConsultModal={addConsultModal}
              
              onClose={closeConsultModal}
            />
          </div>
        </div>
      )}
</section>
    
  )
}

export default AlmacenComponent