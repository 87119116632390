import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
import EditOrderModal from "../editmodal/EditOrderModal";
import React from "react";
import SearchBar from "../searchbar/searchbar";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditAlertPhaseOrderModal from "../editmodal/EditAlertPhaseOrder";
import EditAlertDeleteOrderModal from "../editmodal/EditAlertDeleteOrder";
import {CSVLink} from "react-csv";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import{ headers} from "../../utils/variousConst.js"
import{ preformaheaders } from "../../utils/variousConst.js"
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import EditOrderModalPreforma from "../editmodal/EditOrderPreformaModal";
import { socket } from "../../utils/variousConst.js";
import {date, options, mexicanTime} from "../../utils/variousConst"

const DashboardCompleted = ({ order }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  // console.log("ilruolo", role)
  
  
  
//test excel export







  //console.log("here the prop",scannedcompleted)
  const [orders, setOrders] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const [isEditOrderModalOpenPreforma, setEditOrderModalOpenPreforma] = useState(false);
  const [editableItemPreforma, setEditableItemPreforma] = useState(null);

  const [loading, setLoading] = useState(true);

  const filterOrders = (orders) =>
  orders.filter((order) => order.deliverycompleted || order.preformadeliverycompleted);

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on scannedcompleted and stockcompleted
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);
  
    // Merge the filtered updated orders with existing orders in the state
    setOrders((prevOrders) => {
      const mergedOrders = filteredUpdatedOrders.map((filteredOrder) => {
        const existingOrder = prevOrders.find((order) => order._id === filteredOrder._id);
        return existingOrder ? existingOrder : filteredOrder;
      });
      return mergedOrders;
    });
  };
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      //console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error); 
        }
  };
  useEffect(() => {
    fetchOrders();
  
    // Listen for socket event to handle updates to orders
    socket.on('getAllOrders', (updatedOrderArray) => {
      setOrders(updatedOrderArray)
      handleUpdateOrders(updatedOrderArray);
    });
  
    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off('getAllOrders');
    };
  }, []);

  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response)

      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };

  const fetchOrdersListCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      
      setOrdersList(response);

      setIsDownloadReady(true);
      setTimeout(() => {
        fetchOrders()
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };

  {/* 
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      const filteredOrders = response.filter(
        (order) =>
          order.scannedcompleted === true &&
          order.stockcompleted === true &&
          order.deliverycompleted === false
      );
      setOrders(filteredOrders);
      //setOrders(response)
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

*/}

const [searchQuery, setSearchQuery] = useState('');

const handleSearch = (searchQuery) => {
  setSearchQuery(searchQuery);
};
useEffect(() => {

  if (searchQuery === '') {
      setFilteredOrders([]); // Clear the filtered orders list
    } else {
      const filtered = orders.filter((order) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseOrderNr = order.ordernr.toLowerCase();
        const lowerCaseCustomer = order.customer.toLowerCase();
        const lowerCaseDescription = order.finalproduct.toLowerCase();
        return (
          lowerCaseOrderNr.includes(lowerCaseSearchQuery) ||
          lowerCaseCustomer.includes(lowerCaseSearchQuery) ||
          lowerCaseDescription.includes(lowerCaseSearchQuery)
        );
      });
      setFilteredOrders(filtered);
    }
  }, [searchQuery, orders]); 

  const [editableItem, setEditableItem] = useState(null);
  const [isEditOrderModalOpen, setEditOrderModalOpen] = useState(false);

  const [alertOrder, setAlertOrder] = useState(null);
  const [isAlertOrderPhaseModalOpen, setIsAlertOrderPhaseModalOpen] = useState(false)

  const [alertDeleteOrder, setAlertDeleteOrder] = useState(null);
  const [isAlertOrderDeleteModalOpen, setIsAlertOrderDeleteModalOpen] = useState(false)

  const [colorUpdated, setColorUpdated] = useState(false)

  const openEditOrderModal = (order, index) => {
    setEditableItem({ order, index });
    setEditOrderModalOpen(true);
  };

  const closeEditOrderModal = () => {
    setEditableItem(null);
    setEditOrderModalOpen(false);
  };

  const openAlertOrderPhaseModal = (order, index) =>{
    setAlertOrder({order, index});
    setIsAlertOrderPhaseModalOpen(true)
  }

  const closeOpenAlertOrderPhaseModal =() =>{
    setAlertOrder(null);
    setIsAlertOrderPhaseModalOpen(false)
  }

  const openAlertOrderDeleteModal = (order, index) =>{
    setAlertDeleteOrder({order, index});
    setIsAlertOrderDeleteModalOpen(true)
  }

  const closeOpenAlertOrderDeleteModal =() =>{
    setAlertDeleteOrder(null);
    setIsAlertOrderDeleteModalOpen(false)
  }

  const openEditOrderModalPreforma = (order, index) => {
    setEditableItemPreforma({ order, index });
    setEditOrderModalOpenPreforma(true);
  };

  const closeEditOrderModalPreforma = () => {
    setEditableItemPreforma(null);
    setEditOrderModalOpenPreforma(false);
  };

  const handleSavePreforma = (index, updatedOrder) => {
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", updatedOrder);
        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[index] = updatedOrder;
        setOrders(updatedOrders);

// Update the filtered search as well
const updatedFilteredOrders = [...filteredOrders];
updatedFilteredOrders[index] = updatedOrders;
setFilteredOrders(updatedFilteredOrders);

        //const response = await apiWrapper('order', 'GET');
        //setOrders(response);
        fetchOrders();
        closeEditOrderModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };

    updateOrderOnServer();
  };


  const handleSave = (index, updatedOrder) => {
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", updatedOrder);
        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[index] = updatedOrder;
        setOrders(updatedOrders);

// Update the filtered search as well
const updatedFilteredOrders = [...filteredOrders];
updatedFilteredOrders[index] = updatedOrders;
setFilteredOrders(updatedFilteredOrders);

        //const response = await apiWrapper('order', 'GET');
        //setOrders(response);
        fetchOrders();
        closeEditOrderModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };

    updateOrderOnServer();
  };

  const handleDelete = async (orderId) => {
    try {
      // Make a DELETE request to the API to delete the order with the given ID
      await apiWrapper(`order/${orderId}`, "DELETE");

      // Update the orders state by removing the deleted order from the array
      setOrders(orders.filter((order) => order._id !== orderId));
    } catch (error) {
      // Handle error
      console.error("Error deleting order:", error);
    }
  };



  const updateOrder = async (orderId) => {
    console.log(orderId)
    try {
      const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        deliverycompleted: true,
      });

      await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
        employeestory: { user, mexicanTime, deliverycompleted: true },
      });


      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, deliverycompleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
      fetchOrders();

    // console.log("Order updated:", updatedOrder);
    } catch (error) {
      console.error("Error updating delivery:", error);
    }
  };

  useEffect(() => {
    socket.on('updateDeliveryIncrement',(updatedOrder) => {
     // console.log(updatedOrder)
      setColorUpdated(updatedOrder._id); // Set the ID of the updated order
    // console.log("heeel",updatedOrder._id)
  
      setTimeout(() => {
        setColorUpdated(null); // Clear the ID after 3 seconds
      }, 3000);
  
      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedOrder._id) {
            return { ...order, itemsdelivery: updatedOrder.itemsdelivery };
          }
          return order;
             });
  
        return updatedOrders;
      });
    });
  
    return () => {
      socket.off('updateDeliveryIncrement');
       };
  }, []);


  //delete
  useEffect(() => {
    socket.on('deleteOrder', (deletedOrderId) => {
      setOrders((prevOrders) => {
        return prevOrders.filter((order) => order._id !== deletedOrderId);
      });
    });
  
    return () => {
      socket.off('deleteOrder');
    };
  }, []);

 //phase completed
 useEffect(() => {
  socket.on('updateOrderDefectedSign', (updatedItemsDefected) => {
    setOrders((prevOrders) => {
      // Filter out the orders with deliverycompleted set to true
      const filteredOrders = prevOrders.filter((order) => !order.deliverycompleted);
      return filteredOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSign');
  };
}, []);

useEffect(() => {
  socket.on('updateOrder', (updatedEditOrder) => {
   //console.log("cheedita",updatedEditOrder.data.customer)
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order._id === updatedEditOrder._id) {
          //console.log("check",updatedEditOrder.customer)
          
          return { ...order, ...updatedEditOrder.data };
          
        }
        return order;
      });
      //console.log("check2", updatedOrders)
      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrder');
  };
}, []);

 //spinning loading
 useEffect(() => {
  if (orders.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [orders]);

const csvData = orders.map(order => {
  const employeestory = order.employeestory
    .filter(entry => entry.deliverycompleted === true || entry.preformadeliverycompleted === true)
    .map((entry, index) => {
      return `User ${index + 1}: ${entry.user} Delivery Completed - ${entry.deliverycompleted}, Time: ${entry.mexicanTime}, Preforma Delivery Completed - ${entry.preformadeliverycompleted}`;
    })
    .join(' | ');

  return {
    ...order,
    employeestory,
  };
});
//




const [isPreformaExpanded, setIsPreformaExpanded] = useState(false);

  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
    <div className="flex flex-col  px-6 py-8   ">
      <div className="bg-gray-800 rounded-lg ">

     { role === "admin" && ( 
      <>
     <button
      className="text-white"
      onClick={fetchOrdersListCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={csvData} headers={headers} filename={`ListaPedidoconFecha.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
      </>
     )}
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
        <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
              Pedidos Completados
            </h1>
            <div className="text-white">
     
    </div>
             <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
          </div>

          <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido nr
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase ">
                    Nr Pedido de Stock
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Cliente
                    <button
                      className="px-6 py-3 text-center text-xs font-thin text-gray-400  -tracking-tight"
                      onClick={() => setIsPreformaExpanded(!isPreformaExpanded )}
                    >
                      {isPreformaExpanded ? "Ocultar Preforma" : "Mostrar Preforma"}
                    </button>
                  </th>
                  
{isPreformaExpanded && (
  <> 
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Mod Preforma
                  </th>
                 <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Preformas producidas Ud
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes Preformas producidas 
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Preformas entregada ud
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes Preformas entregada
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Preformas enviada a prod
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes Preformas enviada a prod
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Preforma original order & Cli
                   </th>

                  </>)}
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Modelo a producir
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Artículos para hacer
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paquetes Totales
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Artículos Producidos
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Artículos en Existencias
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Artículos Entregados
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paq Entregados
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paq Descartado por Calidad
                  </th>
                  {(role === "supervisor" || role === "admin") && (

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido Completado
                  </th>
                  )}
                   {role === "admin" && (
                    <React.Fragment>
                  <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Edit Prod
                  </th>
                  <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Edit Pref
                  </th>
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Delete
                  </th>
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Export Pedido Garraffones
                  </th>
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Export Pedido Preformas
                  </th>
</React.Fragment>)}
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order, index) => (
                  <tr
                    className={
                      index % 2 === 0 ? "bg-amber-400 text-center" : "bg-amber-300 text-center" 
                    }
                    key={`${order.customer}-${order.ordernr}`}
                  >
                    <td>{order.ordernr.toString()}</td>
                    <td>{order.stockordernr}</td>
                    <td>{order.customer.toString()}</td>
                    { isPreformaExpanded && (
                     <>
                     
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preforma}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformascanned}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformapackscanned}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformadelivery}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformapackdelivery}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformaaddedtoprod}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformapackaddedtoprod}
                      </td>
                      <td className={`px-6 py-4 text-xs font-medium whitespace-nowrap `}>
                        {order.preformaoriginalorder}, {order.preformaclienteorigen}
                      </td>
                      </> )}
                    <td className="px-6 py-4 whitespace-nowrap">
                        {order.finalproduct}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.pack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.totpack}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemsscanned)
                          ? order.itemsscanned
                          : Math.round(order.itemsscanned * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.itemsstock}
                      </td>

                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.itemsdelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.packdelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                      {isNaN(order.calidadpackdefected)
                   ? "" : Number.isInteger(order.calidadpackdefected) ? order.calidadpackdefected
                  : Math.round(order.calidadpackdefected * 100) / 100}
                      </td>
 {(role === "supervisor" || role === "admin") && (
 <td className="whitespace-nowrap">
  
    <React.Fragment key={`order--${Date.now()}-${Math.random()}`}>
      {Object.entries(
        order.employeestory.reduce((acc, entry) => {
          const {
            user,
            deliverycompleted,
            mexicanTime,
            preformadeliverycompleted,
          } = entry;
          if (!acc[user]) {
            acc[user] = [];
          }
          acc[user].push({
            deliverycompleted,
            mexicanTime,
            preformadeliverycompleted,
          });
          return acc;
        }, {})
      ).map(([user, entries], userIndex) =>
        entries &&
        entries.length > 0 &&
        entries.map((entry, entryIndex) => (
          <React.Fragment key={`entry-${userIndex}-${entryIndex}-${Date.now()}-${Math.random()}`}>
            {entry.deliverycompleted === true && (
              <div>
                <CheckBoxIcon className="text-green-500" />
                <span>Completado</span>
                <div className="border border-gray-500 bg-slate-800 p-4 mt-3 rounded-lg text-white">
                  <p>Garraffones Entregados</p>
                  <div className="text-xs text-white">{entry.mexicanTime}</div>
                </div>
              </div>
            )}
            {entry.preformadeliverycompleted === true && (
              <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mt-3 rounded-lg text-white">
                <p>Preformas Entregada</p>
                <div className="text-xs text-white">{entry.mexicanTime}</div>
              </div>
            )}
          </React.Fragment>
        ))
      )}
    </React.Fragment>
  
</td>
)}
                      {role === "admin" && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModal(order, index)}
                         
                        />
                         
                      </td>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModalPreforma(order, index)}
                         
                        />
                         
                      </td>

                      <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                      </td>

                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={headers} filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
      
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                      </React.Fragment>)}

                  </tr>
                ))}
              </tbody>
              <tbody className=" divide-y divide-gray-200 text-center text-white">
              {orders.length === 0 || filteredOrders.length >0 ? (
    <tr className="text-center">
    <td colSpan="8">
  {loading ? (
    <p className=" text-white text-center">-</p>
  ) : (
    <p className="text-center  font-medium text-white">No hay datos en DB</p>
  )}
  </td>
    </tr>
  ) : (orders
                  .slice()
                  .reverse()
                  .map((order, index) => (
                    <tr className={
                      index % 2 === 0 ? " bg-sky-900" : " bg-sky-800"
                      }
                      key={order._id}
                    >
                      <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                        {order.ordernr}
                      </td>
                      <td>{order.stockordernr}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.customer}
                      </td>
                      { isPreformaExpanded && (
                     <>
                     
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preforma}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformascanned}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformapackscanned}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformadelivery}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformapackdelivery}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformaaddedtoprod}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformapackaddedtoprod}
                      </td>
                       <td className={`px-6 py-4 text-xs font-medium whitespace-nowrap `}>
                        {order.preformaoriginalorder}, {order.preformaclienteorigen}
                      </td>
                      </> )}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.finalproduct}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.pack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.totpack}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemsscanned)
                          ? order.itemsscanned
                          : Math.round(order.itemsscanned * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.itemsstock}
                      </td>

                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.itemsdelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.packdelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                    {isNaN(order.calidadpackdefected)
                   ? "" : Number.isInteger(order.calidadpackdefected) ? order.calidadpackdefected
                  : Math.round(order.calidadpackdefected * 100) / 100}
                 
                      </td>
                      {(role === "supervisor" || role === "admin") && (
  <td className="whitespace-nowrap">
   
      
        <React.Fragment key={`order-`}>
          <CheckBoxIcon className="text-green-500" />
          <span>Completado</span>
          <div className="flex flex-col items-center justify-between text-sm font-medium break-all">
            {Object.entries(
              order.employeestory.reduce((acc, entry) => {
                const {
                  user,
                  deliverycompleted,
                  mexicanTime,
                  preformadeliverycompleted,
                } = entry;
                if (!acc[user]) {
                  acc[user] = [];
                }
                acc[user].push({
                  deliverycompleted,
                  mexicanTime,
                  preformadeliverycompleted,
                });
                return acc;
              }, {})
            ).map(([user, entries], userIndex) =>
              entries &&
              entries.length > 0 &&
              entries.map((entry, entryIndex) => (
                <React.Fragment key={`entry-${userIndex}-${entryIndex}`}>
                  {entry.deliverycompleted !== undefined &&
                    entry.deliverycompleted !== null && 
                    entry.deliverycompleted === true && (
                      <div className="border border-gray-500 bg-slate-800 p-4 mt-3 rounded-lg text-white">
                        <p>Garraffones Entregados</p>
                        <div className="text-xs text-white">{entry.mexicanTime}</div>
                      </div>
                    )}
                  {entry.preformadeliverycompleted !== undefined &&
                    entry.preformadeliverycompleted !== null &&
                    entry.preformadeliverycompleted === true && (
                      <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mt-3 rounded-lg text-white">
                        <p>Preformas Entregada</p>
                        <div className="text-xs text-white">{entry.mexicanTime}</div>
                      </div>
                    )}
                </React.Fragment>
              ))
            )}
          </div>
        </React.Fragment>
     
  
  </td>
)}
                      {role === "admin" && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModal(order, index)}
                         
                        />
                         
                      </td>

                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModalPreforma(order, index)}
                         
                        />
                         
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={headers} filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                      </React.Fragment>)}
                    </tr>
                  )))}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 gap-4 md:hidden">
          <div className="flex justify-center items-center" >
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>

            <div>
              {filteredOrders.map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-amber-400 px-2 py-2.5"
                      : " border rounded-lg bg-amber-300 px-2 py-2.5"
                  }
                  key={`${order.customer}-${order.ordernr}`}
                >
                   <div className="bg-transparent">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                    {order.stockordernr && ( 
                    <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900 text-sm"> Nr Pedido de Stock:{" "} </span>
                  <span>  {order.stockordernr}
                        </span>
                  </div>
                  )}
                  </div>
                  <div className="flex flex-col items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Cliente:</span> 
                  <span className="mb-2">{order.customer}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Modelo Producto:</span> 
                  <span>{order.finalproduct}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Artículos para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes de: </span>
                  <span className="text-sm">  {order.pack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes Totales: </span>
                  <span className="text-sm">  {order.totpack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Producidos:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.itemsscanned)
                      ? order.itemsscanned
                      : Math.round(order.itemsscanned * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div  className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900 text-sm"> Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.itemsstock)
                      ? order.itemsstock
                      : Math.round(order.itemsstock
                        * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Artículos en entrega: </span>
                  <span >{order.itemsdelivery}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div  className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900 text-sm"> Paquetes en entrega:{" "} </span>
                  <span>  {Number.isInteger(order.packdelivery)
                      ? order.packdelivery
                      : Math.round(order.packdelivery
                        * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div  className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900 text-sm"> Paquetes Descartados por Calidad:{" "} </span>
                  <span>  {isNaN(order.calidadpackdefected)
                   ? "" : Number.isInteger(order.calidadpackdefected) ? order.calidadpackdefected
                  : Math.round(order.calidadpackdefected * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <button
                      className="w-1/4 px-3 py-1 ml-3 text-center text-xs font-thin text-white  bg-gray-700 rounded-lg tracking-tight"
                      onClick={() => setIsPreformaExpanded(!isPreformaExpanded )}
                    >
                      {isPreformaExpanded ? "Ocultar Preforma" : "Mostrar Preforma"}
                    </button>
                    {isPreformaExpanded &&( 
                      <>
                   <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Modelo Preforma: </span>
                  <span >{order.preforma}</span>
                  </div> 
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Preformas Producidos: </span>
                  <span >{order.preformascanned}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>  
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Paquetes Preformas Producidos: </span>
                  <span >{order.preformapackscanned}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>   
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Preformas enviado a Prod : </span>
                  <span >{order.preformaaddedtoprod}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>  
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Paquetes Preformas enviado a Prod: </span>
                  <span >{order.preformapackaddedtoprod}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div> 
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Preformas Entregada: </span>
                  <span >{order.preformadelivery}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>  
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Paquetes Preformas Entregada: </span>
                  <span >{order.preformapackdelivery}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div> 
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                      <span className="text-gray-900 text-sm">     Preforma original order nr:</span>
                      <span className=" text-sm text-gray-900">   {order.preformaoriginalorder}</span>
                      </div>{" "}
                      <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                      <span className="text-gray-900 text-sm">    Preforma orignal cm:</span>
                      <span className=" text-sm text-gray-900">   {order.preformaclienteorigen}</span>
                      </div>{" "} 

                  </>)}

                  <div className="flex flex-col justify-between ">
                  {(role === "supervisor" || role === "admin") && (
                  <div
                        //   onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                            order.deliverycompleted
                              ? "bg-emerald-700 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                          }`}
                        >
                      
 
    <React.Fragment key={`order`}>
      <CheckBoxIcon className="text-green-500" />
      <span>Completado</span>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
      {Object.entries(
  order.employeestory.reduce((acc, entry) => {
    const {
      user,
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    } = entry;
    if (!acc[user]) {
      acc[user] = [];
    }
    acc[user].push({
      deliverycompleted,
      mexicanTime,
      preformadeliverycompleted,
    });
    return acc;
  }, {})
).map(([user, entries], userIndex) =>
  entries &&
  entries.length > 0 &&
  entries.map((entry, entryIndex) => (
    <React.Fragment key={`entry-${userIndex}-${entryIndex}`}>
      {entry.deliverycompleted !== undefined &&
        entry.deliverycompleted !== null && 
        entry.deliverycompleted === true && (
          <div className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Garraffones Entregados</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
      {entry.preformadeliverycompleted !== undefined &&
        entry.preformadeliverycompleted !== null &&
        entry.preformadeliverycompleted === true && (
          <div className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
            <p>Preformas Entregadas</p>
            <div className="text-xs text-white">{entry.mexicanTime}</div>
          </div>
        )}
    </React.Fragment>
  ))
)}
</div>
</React.Fragment>
 
                        </div>
                        )}
                        {role === "admin" && (
                    <React.Fragment>
                  <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit Producto
                        </button>
                        <button
                          onClick={() => openEditOrderModalPreforma(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit Preforma
                        </button>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>
                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={headers} filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
     <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download Preforma Resume</span>
      )}
     </button>
                        </React.Fragment>)}
                  </div>
                </div>
              ))}
            </div>

            {orders.length === 0 || filteredOrders.length > 0 ? (
    loading ? (
      <div className="border rounded-lg bg-sky-900">
        <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
        <div className=" text-white">-</div>
        
        </div>
      </div>
  ): (
    <div className="border rounded-lg bg-sky-900">
      <p className="text-center text-white">No hay datos en DB</p>
    </div>
  )
  ) : (
    orders
              .slice()
              .reverse()
              .map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                    ? "border rounded-lg bg-sky-900 text-white"
                    : " border rounded-lg bg-sky-800 text-white"
                  }
                  key={order._id}
                >
                    <div className="bg-transparent">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                    {order.stockordernr && ( 
                    <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300 text-sm"> Nr Pedido de Stock:{" "} </span>
                  <span>  {order.stockordernr}
                        </span>
                  </div>
                  )}
                  </div>
                  <div className="flex flex-col items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Cliente:</span> 
                  <div className="mb-2">{order.customer}</div> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Modelo Producto:</span> 
                  <span>{order.finalproduct}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Artículos para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes de: </span>
                  <span className="text-sm">  {order.pack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes Totales: </span>
                  <span className="text-sm">  {order.totpack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Producidos:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.itemsscanned)
                      ? order.itemsscanned
                      : Math.round(order.itemsscanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300 text-sm"> Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.itemsstock)
                      ? order.itemsstock
                      : Math.round(order.itemsstock
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Artículos en entrega: </span>
                  <span className="">{order.itemsdelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300 text-sm"> Paquetes en entrega:{" "} </span>
                  <span>  {Number.isInteger(order.packdelivery)
                      ? order.packdelivery
                      : Math.round(order.packdelivery
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div  className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300 text-sm"> Paquetes Descartados por Calidad:{" "} </span>
                  <span>  {isNaN(order.calidadpackdefected)
                   ? "" : Number.isInteger(order.calidadpackdefected) ? order.calidadpackdefected
                  : Math.round(order.calidadpackdefected * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <button
                      className="w-1/4 px-3 py-1 ml-3 text-center text-xs font-thin text-white  bg-gray-700 rounded-lg tracking-tight"
                      onClick={() => setIsPreformaExpanded(!isPreformaExpanded )}
                    >
                      {isPreformaExpanded ? "Ocultar Preforma" : "Mostrar Preforma"}
                    </button>
                    {isPreformaExpanded &&( 
                      <>
                   <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Modelo Preforma: </span>
                  <span className="text-lg">{order.preforma}</span>
                  </div> 
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Preformas Producidos: </span>
                  <span >{order.preformascanned}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>  
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300"> Paquetes Preformas Producidos: </span>
                  <span >{order.preformapackscanned}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300"> Preformas enviado a Prod : </span>
                  <span >{order.preformaaddedtoprod}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>  
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300"> Paquetes Preformas enviado a Prod: </span>
                  <span >{order.preformapackaddedtoprod}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>    
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300"> Preformas Entregada: </span>
                  <span >{order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>  
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300"> Paquetes Preformas Entregada: </span>
                  <span >{order.preformapackdelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div> 
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                      <span className="text-gray-300 text-sm">     Preforma original order nr:</span>
                      <span className=" text-sm">   {order.preformaoriginalorder}</span>
                      </div>{" "}
                      <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 `}>
                      <span className="text-gray-300 text-sm">    Preforma orignal cm:</span>
                      <span className=" text-sm">   {order.preformaclienteorigen}</span>
                      </div>{" "}    
                  </>)}

             
    <React.Fragment key={`order`}>
      <CheckBoxIcon className="text-green-500" />
      <span>Completado</span>
      <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
        {Object.entries(
          order.employeestory.reduce((acc, entry) => {
            const {
              user,
              deliverycompleted,
              mexicanTime,
              preformadeliverycompleted,
            } = entry;
            if (!acc[user]) {
              acc[user] = [];
            }
            acc[user].push({
              deliverycompleted,
              mexicanTime,
              preformadeliverycompleted,
            });
            return acc;
          }, {})
        ).map(([user, entries], userIndex) =>
          entries &&
          entries.length > 0 &&
          entries.map((entry, entryIndex) => (
            <React.Fragment key={`entry-${userIndex}-${entryIndex}`}>
              {entry.deliverycompleted !== undefined &&
                entry.deliverycompleted !== null && 
                entry.deliverycompleted === true && (
                  <div className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
                    <p>Garraffones Entregados</p>
                    <div className="text-xs text-white">{entry.mexicanTime}</div>
                  </div>
                )}
              {entry.preformadeliverycompleted !== undefined &&
                entry.preformadeliverycompleted !== null &&
                entry.preformadeliverycompleted === true && (
                  <div id="prefdelivery" className="border border-gray-500 bg-slate-800 p-4 mr-3 mt-3 rounded-lg text-white">
                    <p>Preformas Entregada</p>
                    <div className="text-xs text-white">{entry.mexicanTime}</div>
                  </div>
                )}
            </React.Fragment>
          ))
        )}
      </div>
    </React.Fragment>
    <div className="flex flex-col justify-between ">
    {role === "admin" && (
                    <React.Fragment>
                  <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit Producto
                        </button>
                        <button
                          onClick={() => openEditOrderModalPreforma(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit Preforma
                        </button>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>
                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={headers} filename={`order_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
     <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download Preforma Resume</span>
      )}
     </button>
                        </React.Fragment>)}
                        </div>
                </div>
                
              )))}
             
          </div>
          
        </div>
        
      </div>
      {isEditOrderModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">

        <div className="mt-10 flex items-center  justify-center">
            <EditOrderModal
              editableItem={editableItem}
              onSave={handleSave}
              onClose={closeEditOrderModal}
            />
          </div>
        </div>
      )}

{isEditOrderModalOpenPreforma && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">

        <div className="mt-10 flex items-center  justify-center">
            <EditOrderModalPreforma
              editableItem={editableItemPreforma}
              onSave={handleSavePreforma}
              onClose={closeEditOrderModalPreforma}
            />
          </div>
        </div>
      )}


         {isAlertOrderPhaseModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhaseOrderModal
              alertOrder={alertOrder}
              onSave={updateOrder}
              onClose={closeOpenAlertOrderPhaseModal}
            />
          </div>
        </div>
      )}
      {isAlertOrderDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteOrderModal
              alertDeleteOrder={alertDeleteOrder}
              onSave={handleDelete}
              onClose={closeOpenAlertOrderDeleteModal}
            />
          </div>
        </div>
      )}
      ;
    </section>
  );
};

export default DashboardCompleted;
