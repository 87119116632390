import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
import React from "react";
import EditOrderModalPreforma from "../editmodal/EditOrderPreformaModal";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {CSVLink} from "react-csv";
import{ preformaheaders } from "../../utils/variousConst.js"
import QRScanStockPreforma from "./videoStockPreforma";
import SearchBar from "../searchbar/searchbar";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditAlertPhasePreformaStockModal from "../editmodal/EditPhasePreformaStock.js";
import EditAlertDeleteOrderModal from "../editmodal/EditAlertDeleteOrder";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { socket } from "../../utils/variousConst.js";
import {date, options, mexicanTime, formatMexicanDate} from "../../utils/variousConst"


const DashboardStockPreforma = ({ order }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  // console.log("ilruolo", role)
 
  //console.log("here the prop",preformastockcompleted)
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const [loading, setLoading] = useState(true);

  const filterOrders = (orders) =>
  orders.filter((order) => order.preformascannedcompleted  && !order.preformastockcompleted && order.preforma !== "");

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on scannedcompleted and preformastockcompleted
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);
  
    // Merge the filtered updated orders with existing orders in the state
    setOrders((prevOrders) => {
      const mergedOrders = filteredUpdatedOrders.map((filteredOrder) => {
        const existingOrder = prevOrders.find((order) => order._id === filteredOrder._id);
        return existingOrder ? existingOrder : filteredOrder;
      });
      return mergedOrders;
    });
  };
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      //console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error); 
        }
  };
  useEffect(() => {
    fetchOrders();
  

   

    // Listen for socket event to handle updates to orders
    socket.on('getAllOrders', (updatedOrderArray) => {
      setOrders(updatedOrderArray)
      handleUpdateOrders(updatedOrderArray);
    });
  
    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off('getAllOrders');
    };
  }, []);
  
  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response);
      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
  
    if (searchQuery === '') {
      setFilteredOrders([]); // Clear the filtered orders list
    } else {
      const filtered = orders.filter((order) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseOrderNr = order.ordernr.toLowerCase();
        const lowerCaseCustomer = order.customer.toLowerCase();
        const lowerCaseDescription = order.preforma.toLowerCase();
        return (
          lowerCaseOrderNr.includes(lowerCaseSearchQuery) ||
          lowerCaseCustomer.includes(lowerCaseSearchQuery) ||
          lowerCaseDescription.includes(lowerCaseSearchQuery)
        );
      });
      setFilteredOrders(filtered);
    }
  }, [searchQuery, orders]); 


  const [editableItem, setEditableItem] = useState(null);
  const [isEditOrderModalOpen, setEditOrderModalOpen] = useState(false);

  const [alertOrder, setAlertOrder] = useState(null);
  const [isAlertOrderPhaseModalOpen, setIsAlertOrderPhaseModalOpen] = useState(false)

  const [isAlertOrderPhaseModalOpenCloseOrder, setIsAlertOrderPhaseModalOpenCloseOrder] = useState(false)

  const [alertDeleteOrder, setAlertDeleteOrder] = useState(null);
  const [isAlertOrderDeleteModalOpen, setIsAlertOrderDeleteModalOpen] = useState(false)

  const [colorUpdated, setColorUpdated] = useState(false)
  const [colorUpdatedRed, setColorUpdatedRed] = useState(false)

  const [colorUpdatedPreformaStocktoProd, setColorUpdatedPreformaStocktoProd] = useState(false)

  const openEditOrderModal = (order, index) => {
    setEditableItem({ order, index });
    setEditOrderModalOpen(true);
  };

  const closeEditOrderModal = () => {
    setEditableItem(null);
    setEditOrderModalOpen(false);
  };


  const openAlertOrderPhaseModal = (order, index) =>{
    setAlertOrder({order, index});
    setIsAlertOrderPhaseModalOpen(true)
  }

  const closeOpenAlertOrderPhaseModal =() =>{
    setAlertOrder(null);
    setIsAlertOrderPhaseModalOpen(false)
  }

  const openAlertOrderPhaseModalCloseOrder = (order, index) =>{
    setAlertOrder({order, index});
    setIsAlertOrderPhaseModalOpenCloseOrder(true)
  }

  const closeOpenAlertOrderPhaseModalCloseOrder =() =>{
    setAlertOrder(null);
    setIsAlertOrderPhaseModalOpenCloseOrder(false)
  }

  const openAlertOrderDeleteModal = (order, index) =>{
    setAlertDeleteOrder({order, index});
    setIsAlertOrderDeleteModalOpen(true)
  }

  const closeOpenAlertOrderDeleteModal =() =>{
    setAlertDeleteOrder(null);
    setIsAlertOrderDeleteModalOpen(false)
  }


  const handleSave = (index, updatedOrder) => {
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", updatedOrder);
        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[index] = updatedOrder;
        setOrders(updatedOrders);

// Update the filtered search as well
const updatedFilteredOrders = [...filteredOrders];
updatedFilteredOrders[index] = updatedOrders;
setFilteredOrders(updatedFilteredOrders);


        //const response = await apiWrapper('order', 'GET');
        //setOrders(response);
        fetchOrders();
        closeEditOrderModal();

 // Emit the updated order to the server through the WebSocket
 //socket.emit('updateOrder', updatedOrder); // Assuming the server listens for 'updateOrder' events

      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };

    updateOrderOnServer();
  };

  const handleDelete = async (orderId) => {
    try {
      // Make a DELETE request to the API to delete the order with the given ID
      await apiWrapper(`order/${orderId}`, "DELETE");

      // Update the orders state by removing the deleted order from the array
      setOrders(orders.filter((order) => order._id !== orderId));
    } catch (error) {
      // Handle error
      console.error("Error deleting order:", error);
    }
  };

  

  const updateOrder = async (orderId) => {
    //console.log(orderId)
    try {
      const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        preformastockcompleted: true,
      });

      await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
        employeestory: { user, 
          mexicanTime: new Date().toLocaleString("en-US", options), 
          preformastockcompleted: true },
      });

      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, preformastockcompleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
      fetchOrders();

      // console.log("Order updated:", updatedOrder);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const updateOrderClose = async (orderId) => {
    //console.log(orderId)
    try {
      const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        preformastockcompleted: true,
        preformadeliverycompleted: true,
      });

      await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
        employeestory: { user, 
          mexicanTime: new Date().toLocaleString("en-US", options),
          preformastockcompleted: true, preformadeliverycompleted: true },
      });

      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, preformastockcompleted: true, preformadeliverycompleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
      fetchOrders();

      // console.log("Order updated:", updatedOrder);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };


  useEffect(() => {
    socket.on('updateStockIncrementPreforma',(updatedItemsstock) => {
      setColorUpdated(updatedItemsstock._id); // Set the ID of the updated order
      //console.log("heeel",updatedItemsstock._id)
  
      setTimeout(() => {
        setColorUpdated(null); // Clear the ID after 3 seconds
      }, 3000);
  
      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedItemsstock._id) {
            return { ...order, preformastock: updatedItemsstock.preformastock, 
              preformapackitemsstock:updatedItemsstock.preformapackitemsstock };
          }
          return order;
             });
  
        return updatedOrders;
      });
    });
  
    return () => {
      socket.off('updateStockIncrementPreforma');
       };
  }, []);

  
  useEffect(() => {
    socket.on('subtractPreformaDecrement',(updatedItemsstock) => {
      setColorUpdatedRed(updatedItemsstock._id); // Set the ID of the updated order
    //  console.log("Received data from socket:", updatedItemsstock)
     // console.log("heeel",updatedItemsstock._id)
  
      setTimeout(() => {
        setColorUpdatedRed(null); // Clear the ID after 3 seconds
      }, 3000);
  
      setOrders((prevOrders) => {
        console.log("Previous orders:", prevOrders); // Log the previous orders for debugging
        
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedItemsstock._id) {
          //  console.log("Updating order:", order);
            return {
              ...order,
              preformastock: updatedItemsstock.preformastock,
              preformapackitemsstock: updatedItemsstock.preformapackitemsstock
            };
          }
          return order;
        });
  
      //  console.log("Updated orders:", updatedOrders); // Log the updated orders for debugging
  
        return updatedOrders;
      });
    });
  
    return () => {
      socket.off('subtractPreformaDecrement');
       };
  }, []);





  //phase completed
useEffect(() => {
  socket.on('updateOrderDefectedSign', (updatedItemsDefected) => {
    setOrders((prevOrders) => {
      // Filter out the orders with preformastockcompleted set to true
      const filteredOrders = prevOrders.filter((order) => !order.preformastockcompleted);
      return filteredOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSign');
  };
}, []);

useEffect(() => {
  socket.on('updateOrder', (updatedEditOrder) => {
   //console.log("cheedita",updatedEditOrder.data.customer)
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order._id === updatedEditOrder._id) {
          //console.log("check",updatedEditOrder.customer)
          
          return { ...order, ...updatedEditOrder.data };
          
        }
        return order;
      });
      //console.log("check2", updatedOrders)
      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrder');
  };
}, []);

useEffect(() => {
  socket.on('deleteOrder', (deletedOrderId) => {
    setOrders((prevOrders) => {
      return prevOrders.filter((order) => order._id !== deletedOrderId);
    });
  });

  return () => {
    socket.off('deleteOrder');
  };
}, []);


useEffect(() => {
  socket.on('updateOrderPreformaFromStocktoProd', (updatedItemsSubtract) => {
   // console.log("heeessl",updatedItemsDefected._id)
   setColorUpdatedPreformaStocktoProd(updatedItemsSubtract._id); // Set the ID of the updated order
   // console.log("heeel",updatedItemsDefected._id)

    setTimeout(() => {
      setColorUpdatedPreformaStocktoProd(null); // Clear the ID after 3 seconds
   }, 3000);

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        
        if(order._id ===updatedItemsSubtract._id) {
          return{...order, preformaaddedtoprod:updatedItemsSubtract.preformaaddedtoprod, 
            preformapackaddedtoprod:updatedItemsSubtract.preformapackaddedtoprod,
            

          }
        }
        return order;
      });

      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrderPreformaFromStocktoProd');
  };
}, []);

 //spinning loading
 useEffect(() => {
  if (orders.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [orders]);

  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
          <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
          PREFORMA Existencia
            </h1>
            <div className="flex items-center justify-center">
            {role !== "vendedor" && (
                      <React.Fragment>
            <div className="bg-transparent text-yellow-400 hover:text-yellow-600  flex justify-center" > <QRScanStockPreforma id={123} /> </div>
            </React.Fragment>)}
             
            </div>
           
            <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>

          </div>

          <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido nr
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Fecha
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Cliente
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Modelo Preforma
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Resina
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Color
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas para hacer
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes de
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paquetes Totales
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas Producida
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas en existencia
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paq Existencia
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Reales en existencia ud
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paq Reales Existencia
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas a producción
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paq Preformas a producción
                  </th>

                  {(role === "supervisor" || role === "admin" || role === "almacenista") && (
                    <React.Fragment>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pasar a Entrega
                  </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido completado
                  </th>
                  </React.Fragment>
                  )}
                  {role === "admin" && (
                    <React.Fragment>
                  <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Edit
                  </th>
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Delete
                  </th>
               
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Export
                  </th>
</React.Fragment>)}
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
             
              <tbody>
                {filteredOrders.map((order, index) => (
                  <tr
                    className={
                      index % 2 === 0 ? "bg-amber-400 text-center" : "bg-amber-300 text-center" 
                    }
                    key={`${order.customer}-${order.ordernr}`}
                  >
                    <td>{order.ordernr.toString()}</td>
                    <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                    {formatMexicanDate(order.createdAt)}
                      </td>
                    <td>{order.customer.toString()}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-2xl font-medium">
                        {order.preforma}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-medium">
                        {order.code}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-medium">
                        {order.preformacolor}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformapack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformatotpack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-medium">
                        {Number.isInteger(order.preformascanned)
                          ? order.preformascanned
                          : Math.round(order.preformascanned * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                        {order.preformastock}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {Number.isInteger(order.preformapackitemsstock)
                      ? order.preformapackitemsstock
                      : Math.round(order.preformapackitemsstock
                        * 100) / 100}
                      </td>
                      
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                        {order.preformastock - order.preformadelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}
                      </td>
                      
                      <td className={`px-6 py-4  whitespace-nowrap ${colorUpdatedPreformaStocktoProd === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                        {order.preformaaddedtoprod }
                        
                      </td>
                      <td className={`px-6 py-4 whitespace-nowrap ${colorUpdatedPreformaStocktoProd === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {Number.isInteger(order.preformapackaddedtoprod )
                      ? order.preformapackaddedtoprod
                      : Math.round(order.preformapackaddedtoprod
                        * 100) / 100}
                      </td>

                      {(role === "supervisor" || role === "admin" || role === "almacenista") && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                     
                        <button
                          onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={`focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                            order.preformastockcompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformastockcompleted ?  (
                                <CheckBoxIcon className="text-green-500" />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )}
                        </button>
                      
                      </td>
                    <td className=" whitespace-nowrap    ">
                     
                    <button
                      onClick={()=>openAlertOrderPhaseModalCloseOrder(order, index) }
                      className={`focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                        order.preformastockcompleted
                          ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                          : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                      }`}
                    >
                      {order.preformadeliverycompleted ?  (
                            <CheckBoxIcon className="text-green-500" />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}
                    </button>
                  
                  </td>
                  </React.Fragment>
                      )}
                      {role === "admin" && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModal(order, index)}
                         
                        />
                         
                      </td>

                      <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                      </React.Fragment>)}
                  </tr>
                ))}
              </tbody>
              
              <tbody className=" divide-y divide-gray-200 text-center text-white">
              {orders.length === 0 || filteredOrders.length > 0 ? (
       <tr className="text-center">
       <td colSpan="8">
     {loading ? (
       <p className=" text-white text-center">-</p>
     ) : (
       <p className="text-center  font-medium text-white">No hay datos en DB</p>
     )}
   </td>
       </tr>
  ) : (orders
                  .slice()
                  .reverse()
                  .map((order, index) => (
                    <tr className={
                      index % 2 === 0 ? " bg-sky-900" : " bg-sky-800"
                    }
                      key={order._id}
                    >
                      <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                        {order.ordernr}
                      </td>
                      <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                      {formatMexicanDate(order.createdAt)} 
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.customer}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-2xl font-medium">
                        {order.preforma}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap font-medium">
                        {order.code}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-medium">
                        {order.preformacolor}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformapack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformatotpack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  font-medium">
                        {Number.isInteger(order.preformascanned)
                          ? order.preformascanned
                          : Math.round(order.preformascanned * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                        {order.preformastock}
                        
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {Number.isInteger(order.preformapackitemsstock)
                      ? order.preformapackitemsstock
                      : Math.round(order.preformapackitemsstock
                        * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {order.preformastock - order.preformadelivery}
                        
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}
                      </td>
                      <td className={`px-6 py-4  whitespace-nowrap ${colorUpdatedPreformaStocktoProd === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                        {order.preformaaddedtoprod }
                        
                      </td>
                      <td className={`px-6 py-4 whitespace-nowrap ${colorUpdatedPreformaStocktoProd === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                      {Number.isInteger(order.preformapackaddedtoprod )
                      ? order.preformapackaddedtoprod
                      : Math.round(order.preformapackaddedtoprod
                        * 100) / 100}
                      </td>
                     
                     
                      {(role === "supervisor" || role === "admin"  || role === "almacenista") && (
                        <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                     
                        <button
                          onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                            order.preformastockcompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformastockcompleted ?  (
                                <CheckBoxIcon className="text-green-500" />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )}
                        </button>

                      </td>
                      <td className=" whitespace-nowrap    ">
                     
                     <button
                       onClick={()=>openAlertOrderPhaseModalCloseOrder(order, index) }
                       className={`focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                         order.preformastockcompleted
                           ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                           : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                       }`}
                     >
                       {order.preformadeliverycompleted ?  (
                             <CheckBoxIcon className="text-green-500" />
                           ) : (
                             <CheckBoxOutlineBlankIcon />
                           )}
                     </button>
                   
                   </td>
                      </React.Fragment>
                      )}
                      {role === "admin" && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModal(order, index)}
                         
                        />
                         
                      </td>

                      <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
                      </td>
                      </React.Fragment>)}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          
         
          <div className="grid grid-cols-1 gap-4 md:hidden">
         
          <div className="flex justify-center items-center" >
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>

            <div>
              {filteredOrders.map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-amber-400 px-2 py-2.5"
                      : " border rounded-lg bg-amber-300 px-2 py-2.5"
                  }
                  key={`${order.customer}-${order.ordernr}`}
                >
                  <div className="bg-transparent">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" > Fecha:</span>
                    <span className="ml-auto font-bold text-xl">{formatMexicanDate(order.createdAt)}</span>
                    </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2  text-sm font-medium  ">
                  <span className="text-gray-900"> Modelo Preforma:</span> 
                  <span> {order.preforma}</span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Resina:</span> 
                  <span> {order.code}</span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Color:</span> 
                  <span> {order.preformacolor}</span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Preformas para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes de: </span>
                  <span className="text-sm">  {order.preformapack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Preformas Producida:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes Preformas Producida:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformapackscanned)
                      ? order.preformapackscanned
                      : Math.round(order.preformapackscanned * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                  <span className="text-gray-900 text-sm"> Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.preformastock)
                      ? order.preformastock
                      : Math.round(order.preformastock
                        * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span>
                        <span className="text-gray-900 text-sm"> - Existencia real:{" "} </span>
                        <span>{order.preformastock - order.preformadelivery}</span>
                        <span className="text-gray-900 text-sm font-medium ml-2">ud</span>
                        </span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                  <span className="text-gray-900 text-sm"> Paq Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.preformapackitemsstock)
                      ? order.preformapackitemsstock
                      : Math.round(order.preformapackitemsstock
                        * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span>
                        <span className="text-gray-900 text-sm"> - Existencia real:{" "} </span>
                        <span>{Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}</span>
                        <span className="text-gray-900 text-sm font-medium ml-2">ud</span>
                        </span>
                  </div>

                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdatedRed === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900 text-sm"> Preformas a Producción:{" "} </span>
                  <span className="text-sm">  {Number.isInteger(order.preformaaddedtoprod )
                      ? order.preformaaddedtoprod
                      : Math.round(order.preformaaddedtoprod
                        * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdatedRed === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900 text-sm"> Paquetes Preformas a Producción:{" "} </span>
                  <span className="text-sm">  {Number.isInteger(order.preformapackaddedtoprod )
                      ? order.preformapackaddedtoprod
                      : Math.round(order.preformapackaddedtoprod
                        * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>

                  <div className="flex flex-col justify-between ">
                  {(role === "supervisor" || role === "admin" || role === "almacenista") && (
                   <>
                   <div className="flex justify-around">
                    <button
                           onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={` text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                            order.preformastockcompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                          }`}
                        >
                          {order.preformastockcompleted ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Completada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Pasar a Entrega</span>
                            </>
                          )}
                        </button>
                        <button
                           onClick={()=>openAlertOrderPhaseModalCloseOrder(order, index) }
                          className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                            order.preformastockcompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                          }`}
                        >
                          {order.preformadeliverycompleted ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Completada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Pedido Completado</span>
                            </>
                          )}
                        </button>
                        </div>
                  </>
                 
                  )}
                        {role === "admin" && (
                    <React.Fragment>

                  <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit
                        </button>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>
                         <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
                        </React.Fragment>)}
                  </div>
                </div>
              ))}
            </div>
                  
            {orders.length === 0 || filteredOrders.length >0 ? (
   loading ? (
    <div className="border rounded-lg bg-sky-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className="animate-spin text-white">&#9696;</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-sky-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)
  ) : (
    orders
              .slice()
              .reverse()
              .map((order, index) => (
                <div
                className={ 
                  index % 2 === 0
                    ? "border rounded-lg bg-sky-900 text-white"
                    : " border rounded-lg bg-sky-800 text-white"
                }
                  key={order._id}
                >
                  <div className="bg-transparent  ">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Fecha:</span>
                    <span className="ml-auto font-bold text-xl">{formatMexicanDate(order.createdAt)}</span>
                    </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2 text-sm font-medium  ">
                  <span className="text-gray-300"> Modelo Preforma:</span> 
                  <span> {order.preforma}</span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Resina:</span> 
                  <span> {order.code}</span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Color:</span> 
                  <span> {order.preformacolor}</span>
                    </div>
                    <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Preformas para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes de: </span>
                  <span className="text-sm">  {order.preformapack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Preformas Producida:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes Preformas Producida:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformapackscanned)
                      ? order.preformapackscanned
                      : Math.round(order.preformapackscanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                  <span className="text-gray-300 text-sm"> Existencia:{" "} </span>
                  <span>  {Number.isInteger(order.preformastock)
                      ? order.preformastock
                      : Math.round(order.preformastock
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span className="text-gray-300 text-sm"> - Existencia real:{" "} </span>
                        <span>{order.preformastock - order.preformadelivery}</span>
                        <span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        </span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : (colorUpdatedRed === order._id ? 'text-red-500' : '')}`}>
                  <span className="text-gray-300 text-sm"> Paq Existencias:{" "} </span>
                  <span>  {Number.isInteger(order.preformapackitemsstock)
                      ? order.preformapackitemsstock
                      : Math.round(order.preformapackitemsstock
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        <span className="text-gray-300 text-sm"> - Existencia real:{" "} </span>
                        <span>{Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}</span>
                        <span className="text-gray-300 text-sm font-medium ml-2">ud</span>
                        </span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdatedRed === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300 text-sm"> Preformas a Producción:{" "} </span>
                  <span className="text-sm">  {Number.isInteger(order.preformaaddedtoprod )
                      ? order.preformaaddedtoprod
                      : Math.round(order.preformaaddedtoprod
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdatedRed === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300 text-sm"> Paquetes Preformas a Producción:{" "} </span>
                  <span className="text-sm">  {Number.isInteger(order.preformapackaddedtoprod )
                      ? order.preformapackaddedtoprod
                      : Math.round(order.preformapackaddedtoprod
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>

                  <div className="flex flex-col justify-between ">
                  {(role === "supervisor" || role === "admin" || role === "almacenista") && (
                  <>
                  <div className="flex justify-around">
                   <button
                          onClick={()=>openAlertOrderPhaseModal(order, index) }
                         className={` text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                           order.preformastockcompleted
                             ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                             : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                         }`}
                       >
                         {order.preformastockcompleted ? (
                           <>
                             <CheckBoxIcon className="text-green-500" />
                             <span>Completada</span>
                           </>
                         ) : (
                           <>
                             <CheckBoxOutlineBlankIcon />
                             <span>Pasar a Entrega</span>
                           </>
                         )}
                       </button>
                       <button
                          onClick={()=>openAlertOrderPhaseModalCloseOrder(order, index) }
                         className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                           order.preformastockcompleted
                             ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                             : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                         }`}
                       >
                         {order.preformadeliverycompleted ? (
                           <>
                             <CheckBoxIcon className="text-green-500" />
                             <span>Completada</span>
                           </>
                         ) : (
                           <>
                             <CheckBoxOutlineBlankIcon />
                             <span>Pedido Completado</span>
                           </>
                         )}
                       </button>
                       </div>
                 </>
                        
                  )}
                        {role === "admin" && (
                    <React.Fragment>

                  <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit
                        </button>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>
                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
                        </React.Fragment>)}
                  </div>
                </div>
              ))
              )}
          </div>
        </div>
      </div>
      {isEditOrderModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">

        <div className="mt-10 flex items-center  justify-center">
            <EditOrderModalPreforma
              editableItem={editableItem}
              onSave={handleSave}
              onClose={closeEditOrderModal}
              user = {user}
              role = {role}
            />
          </div>
        </div>
      )}
       {isAlertOrderPhaseModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhasePreformaStockModal
              alertOrder={alertOrder}
              onSave={updateOrder}
              onClose={closeOpenAlertOrderPhaseModal}
            />
          </div>
        </div>
      )}
      {isAlertOrderPhaseModalOpenCloseOrder && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhasePreformaStockModal
              alertOrder={alertOrder}
              onSave={updateOrderClose}
              onClose={closeOpenAlertOrderPhaseModalCloseOrder}
            />
          </div>
        </div>
      )}
      {isAlertOrderDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteOrderModal
              alertDeleteOrder={alertDeleteOrder}
              onSave={handleDelete}
              onClose={closeOpenAlertOrderDeleteModal}
            />
          </div>
        </div>
      )}
      ;
    </section>
  );
};

export default DashboardStockPreforma;
