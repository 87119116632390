import { useState, useEffect  } from "react";
import { apiWrapper } from "../../utils/apiwrapper";

const AddPreformaToOrderModal = ({ addPreformaModal, onSave, onClose,mexicanTime }) => {
    const { order, customer, index }  = addPreformaModal;
    const [editedOrderNr, setEditedOrderNr] = useState(order.ordernr || "");
    const [editedCustomer, setEditedCustomer] = useState(order.customer || "");

    const [editedId, setEditedId] = useState(order._id)

const [editedpreformapackitemsstock, setEditedpreformapackitemsstock] = useState("")      // paq preforma
const [editedpreformastock, setEditedpreformastock] = useState(order.preformastock || "") //ud preforma



const handlepreformapackitemsstockChange = (h) =>{  
  setEditedpreformapackitemsstock(h.target.value);
}

const handlepreformastockChange = (i) =>{  
  setEditedpreformastock(i.target.value);
}
const [message, setMessage] = useState('');
    const [selectedPreforma, setSelectedPreforma] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    //console.log("here the prop",preformastockcompleted)
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const filterOrders = (orders) =>
  orders.filter((order) => order.preformascannedcompleted );

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on scannedcompleted and preformastockcompleted
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);
  
    // Merge the filtered updated orders with existing orders in the state
    setOrders((prevOrders) => {
      const mergedOrders = filteredUpdatedOrders.map((filteredOrder) => {
        const existingOrder = prevOrders.find((order) => order._id === filteredOrder._id);
        return existingOrder ? existingOrder : filteredOrder;
      });
      return mergedOrders;
    });
  };
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      //console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error); 
        }
  };
  useEffect(() => {
    // Fetch orders when the component mounts
    fetchOrders();
  }, []);

  useEffect(() => {
    // Find the selected order based on selectedPreforma
    const foundOrder = orders.find((order) => order._id === selectedPreforma);
    
    setSelectedOrder(foundOrder);
  }, [selectedPreforma, orders]);
    
 
    
 //   const handleKgChange = (h) =>{  
  //    setEditedKg(h.target.value);
 //   }


  
    const handleSave = (user) => {
   //console.log("data",editedpreformapackitemsstock, editedpreformastock)
      onSave(order.index, {...selectedOrder,  idpanel: order._id, cm:order.customer, ornr:order.ordernr, preformastock: editedpreformastock, preformapackitemsstock: editedpreformapackitemsstock, _id:selectedOrder._id, preformaoriginalorder:selectedOrder.ordernr, preformaclienteorigen:selectedOrder.customer, mexicanTime, allUnitPrefromaStock:selectedOrder.preformastock }, customer);
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }} >
        {/* Modal content */}
        <div
        className="sm:mt-10 rounded-lg shadow bg-gray-800  "
      >
       
       <div>
      <label className="block mb-2 mt-10  text-sm font-medium  text-gray-400 uppercase">pedido nr </label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
                             type="text" 
                             value={editedOrderNr}
                             readOnly  />

      </div>

       <div>
          <label className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase">Cliente</label>
          <input
            className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white "
            type="text"
           value={editedCustomer} // Display the customer value here
            readOnly
          />
        </div>


      <div className="mt-4">
      
      <select  className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white "
       
        value={selectedPreforma}
        onChange={(e) => {
           setSelectedPreforma(e.target.value);
          }}
      >
         <option value="">Seleccionar Preforma</option> {/* Add this line */}
         {orders
    .filter((order) => order.preformastock > 0 && order.preformadelivery <= 0) // Filter orders where preformastock > 0
    .map((order) => (
      <option key={order._id} value={order._id}>
        {order.preforma} - {order.code} - {order.preformacolor} 
      </option>
    ))}
</select>

     
        <div  className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white mt-4">
        
          
          <p>Paquetes: {selectedOrder ? Math.round((selectedOrder.preformapackitemsstock)*100)/100 : ''}</p>
          <p>Unidades: {selectedOrder ? selectedOrder.preformastock : ''}</p>
          <p>Resina: {selectedOrder ? selectedOrder.code : ''}</p>
          <p>Color: {selectedOrder ? selectedOrder.preformacolor : ''}</p>
            <div  className="text-xs text-gray-400 mt-2" >
            <p> Pedido: {selectedOrder ? selectedOrder.ordernr : ''}</p>
            <p > Cliente: {selectedOrder ? selectedOrder.customer : ''}</p>
        <p>ID: {selectedOrder ? selectedOrder._id : ''}</p>
          </div>
        </div>
      

      </div>

    

          <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">Ud Preforma para producción</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white" 
                            type="number" 
                            
                            //{editedpreformapackitemsstock} // Display the customer value here
                          // onChange={handlepreformapackitemsstockChange} 
                          onChange={handlepreformastockChange}
      />

<label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">Paq Preforma para producción</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            " type="number" 
                          // value={(null ? 0 : editedpreformapackitemsstock) * (selectedOrder ? selectedOrder.preformapack : 0)} // Display the customer value here
                           value={(null ? 0 : editedpreformastock)/ (selectedOrder ? selectedOrder.preformapack : 0)} // Display the customer value here
                           readOnly
                          // value={(preformastock / setEditedpreformapackitemsstock )}
      />
         
             
        </div>

      
        <div className="mb-6">
        <button   className={`w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center 
        ${selectedOrder && editedpreformastock > 0 ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800'
      : 'bg-gray-400 cursor-not-allowed' } mt-8`}
                       onClick={selectedOrder && editedpreformastock >= 0 ? handleSave : null}
                        disabled={!selectedOrder || editedpreformastock === ""}
                        >
                           {selectedOrder && editedpreformastock > 0 ? 'Guardar' : 'No Preforma seleccionada'}
                        </button>
                       
     
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  export default AddPreformaToOrderModal;