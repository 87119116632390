import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import HelpIcon from '@mui/icons-material/Help';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const GuiaConfigCameraPage = () => {
    const navigate = useNavigate();
  
 
      const navigateToGuia = () => {
        navigate("/guia");
      };
  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
      <button className="mr-2 text-white"
      onClick={navigateToGuia}
      ><ArrowCircleLeftOutlinedIcon
      sx={{ fontSize: 20 }} />
      </button> 
        
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                  Configuración de la Cámara <CameraAltOutlinedIcon
                        sx={{ fontSize: 20, }}/>
                  </h1> 
                  </div>
                  <div id="description" className="text-white ml-3 mr-3 mb-4">

                 


<p class="mb-4">A través del modo "Consulta" <HelpIcon sx={{ fontSize: 15, }}/>, puedes configurar la cámara de la siguiente manera:</p>
  <ul class="list-disc ml-6">
    <li>Haz clic en "Request Camera Permission" y permite que el sitio web utilice la cámara.</li>
    <li>Selecciona la cámara que deseas utilizar; si tienes varias opciones, elige la segunda, ya que debería ofrecer más detalles de cerca.</li>
    <li>Haz clic en "Start scanning".</li>
  </ul>

  <p class="mb-4">Una vez que hayas verificado que funciona correctamente, haz clic en "Apagar Cámara".</p>

  <p class="mb-4">Esta operación se realiza una sola vez, luego el dispositivo recordará los permisos otorgados a la aplicación web y la cámara estará disponible cada vez que hagas clic en el código QR de la ficha.</p>

<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1697532458/guia/configcamera.mp4")
                        }
                        >Ejemplo de configuración de cámara </button>
                  
                  </div>
                    
       </div>
          </div>
      
    </section>
    </>
  )
}

export default GuiaConfigCameraPage