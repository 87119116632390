import { useState, useEffect } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Select from 'react-select';

const EditDefectedCalidadModal = ({ defectedEditableItem, onSave, onClose }) => {
  const { order, index } = defectedEditableItem;
  const [editedOrderNr, setEditedOrderNr] = useState(order.ordernr || "");
  const [editedFinalProduct, setEditedFinalProduct] = useState(
    order.finalproduct || "");

    const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
    const [personalComment, setPersonalComment] = useState(""); // State to store the personal comment
  
    
    // const [stockOrders, setStockOrders] = useState([]);
    const [stockOrderIdKg, setStockOrderIdKg] = useState('');
    
    
    
    
    useEffect(() => {
      const stockOrderIdAssociated = order.stockorderid ? order.stockorderid : [];
      const fetchOrder = async (id) => {
        try {
          const response = await apiWrapper(`order/${id}`, "GET");
          setStockOrderIdKg(response.finalproductkg);
        } catch (error) {
          console.log(`Error: ${error}`);
        }
      };
    
      if(stockOrderIdAssociated.length > 0){
        const lastId = stockOrderIdAssociated[stockOrderIdAssociated.length - 1];
        fetchOrder(lastId);
      } else {
        fetchOrder(order._id);
      }
    }, [order]);

useEffect(() => {
    if (stockOrderIdKg) {
      setEditedFinalProductKg(stockOrderIdKg);
    }
  }, [stockOrderIdKg]);


    const options =[
      { "value": "GAR_acumulacion_de_material", "label": "GAR_Acumulacion de material" },
      { "value": "GAR_asa_fracturada", "label": "GAR_Asa fracturada" },
      { "value": "GAR_asa_zafada", "label": "GAR_Asa Zafada" },
      { "value": "GAR_deformes", "label": "GAR_Deformes" },
      { "value": "GAR_distribucion", "label": "GAR_Distribucion" },
      { "value": "GAR_mal_fechado", "label": "GAR_Mal fechado" },
      { "value": "GAR_rebabas", "label": "GAR_Rebabas" },
      { "value": "GAR_variacion_de_color", "label": "GAR_Variacion de color" },
      { "value": "GAR_aperlado", "label": "GAR_Aperlado" },
      { "value": "GAR_arranque", "label": "GAR_Arranque" },
      { "value": "GAR_estrella", "label": "GAR_Estrella" },
      { "value": "GAR_grasa_o_aceite", "label": "GAR_Grasa o aceite" },
      { "value": "GAR_impurezas", "label": "GAR_Impurezas (Contaminacion)" },
      { "value": "GAR_labio_incompleto", "label": "GAR_Labio incompleto" },
      { "value": "GAR_marca_de_agua", "label": "GAR_Marca de agua" },
      { "value": "GAR_marcas", "label": "GAR_Marcas (rayas)" },
      { "value": "GAR_material_frio", "label": "GAR_Material frio" },
      { "value": "GAR_nube", "label": "GAR_Nube (punto blanco)" },
      { "value": "GAR_opaco", "label": "GAR_Opaco" },
      { "value": "GAR_sin_aza", "label": "GAR_Sin aza" },
      { "value": "GAR_burbuja", "label": "GAR_Burbuja" },
      { "value": "GAR_crater", "label": "GAR_Crater" },
      { "value": "GAR_hilo", "label": "GAR_Hilo" },
      { "value": "GAR_perforado", "label": "GAR_Perforado" },
      { "value": "GAR_punto_de_inyeccion_desviado", "label": "GAR_Punto de inyeccion desviado" },
      { "value": "GAR_rafagas_de_pigmento", "label": "GAR_Rafagas de pigmento" },
      { "value": "GAR_reventados", "label": "GAR_Reventados" },
      { "value": "ASA_aza_fracturada", "label": "ASA_Aza fracturada" },
      { "value": "ASA_variacion_de_color", "label": "ASA_Variacion de color" },
      { "value": "ASA_aza_chueca", "label": "ASA_Aza chueca" },
      { "value": "ASA_aza_quebrada", "label": "ASA_Aza quebrada" },
      { "value": "ASA_aza_blanda", "label": "ASA_Aza blanda" },
      { "value": "ASA_aza_quemada", "label": "ASA_Aza quemada" },
      { "value": "otro", "label": "Otro" },
    ];
    options.sort((a, b) => a.label.localeCompare(b.label));
    // ...
    
 



    
     // Event handler for when the select input changes
  const handleSelectChange = (e) => {
    //console.log('handleSelectChange called');
    const selectedValue = e.target.value;
   //console.log("dd",selectedValue)
    const isOptionSelected = ["arana", "polvo", "quemada", "barredura", "purgas"].includes(selectedValue);
    setSelectedOption(selectedValue);
    setEditedDesperdicioModelTf(isOptionSelected);
  };

  // Event handler for when the personal comment input changes
  const handleCommentChange = (e) => {
    const comment = e.target.value;
    setPersonalComment(comment);
  }; 

  //model missing
  const [editedFinalProductKg, setEditedFinalProductKg] = useState(
    order.finalproductkg ? order.finalproductkg : stockOrderIdKg ? stockOrderIdKg : ""
  );
  const [editedItemsDefected, setEditedItemsDefected] = useState(
     ""
  );

  const [editedItemsUdDefected, setEditedItemsUdDefected] = useState(0);

  const [editedKgItemsDefected, setEditedKgItemsDefected] = useState(
     ""
  );

  const [editedDesperdicioModelTf, setEditedDesperdicioModelTf] = useState(false)
  const handleToggle = () => {
    setEditedDesperdicioModelTf(!editedDesperdicioModelTf);
  };

  useEffect(() => {
    setEditedItemsUdDefected(editedItemsDefected * order.pack);
  }, [editedItemsDefected, order.pack]);

  const isSaveEnabled = editedItemsDefected > 0 && selectedOption && selectedOption !== "" 
  && parseFloat(editedItemsDefected) + (order.calidadpackdefected ? parseFloat(order.calidadpackdefected) : 0 ) <= parseFloat(order.totpack);
  //mat prima missing

  const handleKgItemsDefectedChange = (e) => {
    const inputValue = e.target.value;
    const calculatedValue =
      Math.round(editedFinalProductKg * editedItemsDefected * 100) / 100;
    setEditedKgItemsDefected(calculatedValue);
  };

  const handleItemsDefectedChange = (f) => {
    const inputValue = f.target.value;
    const calculatedValue = Math.round(editedFinalProductKg * inputValue*100) /100 * order.pack;
    setEditedItemsDefected(inputValue);
    setEditedKgItemsDefected(calculatedValue);
  };

  const handleSave = () => {
    //console.log("editedDesperdicioModelTf:", editedDesperdicioModelTf);
   // console.log("selectedOption:", selectedOption);
    //console.log("personalComment:", personalComment);
    onSave(order.index, {
      ...order,
    
      calidaditemdefected:editedItemsUdDefected,
      calidadpackdefected: editedItemsDefected,
      calidaditemdefectedkg: editedKgItemsDefected,
      desperdiciomodel: {  
        desperdiciomodeltf: editedDesperdicioModelTf,
      defectedtypemodel: selectedOption,
      defectedcommentmodel: personalComment
    }
    });
    onClose();
  };
  
  return (
    <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }} >
      {/* Modal content */}
      <div
        className="sm:mt-10 rounded-lg shadow bg-gray-800  "
      >
       <label className="block mb-2 mt-10 text-sm font-medium  text-gray-400 uppercase text-center">
          Defecto de Calidad
        </label>
        <label className="block mb-2 mt-10 text-sm font-medium  text-gray-400 uppercase">
          Pedido NR
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="text"
          value={editedOrderNr}
          readOnly
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Modelo
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="text"
          value={editedFinalProduct}
          readOnly
        />

        <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">
       Paquetes defectuosos (1 = {order.pack} ud)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          onChange={handleItemsDefectedChange}
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Peso unitario (Kg)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          value={editedFinalProductKg}
          readOnly
        />

        <label className="block mb-2 mt-4 text-sm font-medium  text-gray-400 uppercase">
          Resina necesarias (Kg)
        </label>
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
          type="number"
          value={editedKgItemsDefected}
          onChange={handleKgItemsDefectedChange}
        />
      </div>


      <div className="flex justify-between">
      <Select
  className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase  p-2 w-full rounded-lg"
  value={options.find(option => option.value === selectedOption)}
  onChange={(option) => setSelectedOption(option ? option.value : '')}
  options={options}
  isClearable
  filterOption={(option, inputValue) => {
    const words = inputValue.toLowerCase().split(' ');
    return words.every(word => option.label.toLowerCase().includes(word));
  }}
  styles={{
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1A202C', // bg-gray-800
     
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#D1D5DB', // white-300
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1A202C', // bg-gray-800
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#3256a8' : provided.color, // blue-500 if selected
      backgroundColor: state.isFocused ? '#3256a8' : provided.backgroundColor, // blue-500 if focused
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D5DB', // white-300
    }),
    input: (provided) => ({
      ...provided,
      color: '#D1D5DB', // white
    }),
  }}

/>

{/* Conditionally render the checkbox */}
{selectedOption === "otro" &&  (
  <div className="flex  text-gray-300 justify-center items-center">
    <label htmlFor="otro"> Marcar si es Desperdicio:</label>
  <button onClick={handleToggle}>
    {editedDesperdicioModelTf === true ? (
      <CheckBoxIcon className="text-green-500 " />
    ) : (
      <CheckBoxOutlineBlankIcon className="border border-white" />
    )}
  </button>
  </div>
)}
      </div>
      <div>
      
      <input
  className="text-white bg-gray-700 rounded-lg mt-4 p-2 w-full "
  value={options.find(option => option.value === selectedOption)?.label || ''}
  readOnly
/>

       
        <div>
          <label
            htmlFor="comment"
            className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase"
          >
            Comentario:
          </label>
          <input
            type="text"
            id="comment"
            name="comment"
            className="text-white bg-gray-700 rounded-lg block w-full p-2.5 "
            value={personalComment}
            onChange={handleCommentChange}
          />
        </div>
      
      
      </div>


      
      <div className="mb-6">
        <button
          className={`w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center 
          ${isSaveEnabled ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800'
        : 'bg-gray-400 cursor-not-allowed' } mt-8`}
                        onClick={isSaveEnabled ? handleSave : null}
                        disabled={!isSaveEnabled}
        >
           {isSaveEnabled ? 'Guardar' : 'Eligir Paquetes'}
        </button>
        <button
          className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditDefectedCalidadModal;
