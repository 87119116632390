import React, { useState, useEffect } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
//import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const NewModel = () => {
  const [codeError, setCodeError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    code: "",
    modelname: "",
    color: "",
    colordescription: "",
    value: "1",
    masterkg: "",
    //qr,
    familia: "",
    familiadescription: "",
    subfamilia: "",
    subfamiliadescription: "",
    listacode: "",
    listadescription: "",
    capacitad: "",
    rosca: "",
    roscadescription: "",
    asacolor: "",
    asacolodescription:"",
    placa: "",
    placatipocode: "",
    placatipodescription: "",
    placacodedescription: "",
    tipomodelo: "",
    tipomodelodescription: "",
  });
  const [model, setModel] = useState("");

  const [grams, setGrams] = useState(0);

  const [asacolors, setAsacolors] = useState([]);
  const [listaSubFam, setListaSubFam] = useState([]);
  const [listaTipoModelos, setListaTipoModelos] = useState([]);
  const [color, setColor] = useState([]);
  const [listaRoscas, setListaRoscas] = useState([]);
  const [numPlacas, setNumPlacas] = useState([]);

  const [selectedAsacolorCode, setSelectedAsacolorCode] = useState("");
  const [selectedAsacolorDescription, setSelectedAsacolorDescription] =
    useState("");
  const [selectedListaSubFamCode, setSelectedListaSubFamCode] = useState("");
  const [selectedListaSubDescription, setSelectedListaSubDescription] =
    useState("");
  const [selectedTipoModelos, setSelectedTipoModelos] = useState("");
  const [selectedTipoModelosDescription, setSelectedTipoModelosDescription] =
    useState("");
  const [selectedTipoModelosCapacitad, setSelectedTipoModelosCapacitad] =
    useState("");
  const [selectedListaColores, setSelectedListaColores] = useState("");
  const [selectedListaColoresDescription, setSelectedListaColoresDescription] =
    useState("");
  const [selectedListaRoscas, setSelectedListaRoscas] = useState("");
  const [selectedPlacaCode, setSelectedPlacaCode] = useState("");
  const [selectedPlacaCodeDescription, setSelectedPlacaCodeDescription] =
    useState("");
  

  const [familiaKey, setFamiliaKey] = useState("SEL");
  const [subfamiliaKey, setSubfamiliaKey] = useState("SEL");
  const [placaTipoKey, setPlacaTipoKey] = useState("SEL");
const [allModels, setAllModels] = useState([]);
  
  //const qrCodeRef = useRef(null);

  //const [qrData, setQrData] = useState("");

  const resetAllStates = () => {
  //  selectedAsacolorCode("");
  setSelectedAsacolorDescription("");
  setSelectedListaSubFamCode("");
  setSelectedListaSubDescription("");
  setSelectedTipoModelos("");
  setSelectedTipoModelosDescription("");
  setSelectedTipoModelosCapacitad("");
  setSelectedListaColores("");
  setSelectedListaColoresDescription("");
  setSelectedListaRoscas("");
  setSelectedPlacaCode("");
  setSelectedPlacaCodeDescription("");
  setFamiliaKey("SEL");
  setSubfamiliaKey("SEL");
  setPlacaTipoKey("SEL");
    setFormData({
      code: "",
      modelname: "",
      color: "",
      colordescription: "",
      value: "1",
      masterkg: "",
      //qr,
      familia: "",
      familiadescription: "",
      subfamilia: "",
      subfamiliadescription: "",
      listacode: "",
      listadescription: "",
      capacitad: "",
      rosca: "",
      roscadescription: "",
      asacolor: "",
      asacolodescription:"",
      placa: "",
      placatipocode: "",
      placatipodescription: "",
      placacodedescription: "",
      tipomodelo: "",
      tipomodelodescription: "",
    });
  };

  const fetchAllModels = async () => {
    try {
      const response = await apiWrapper("model", "GET");
      setAllModels(response);
    //  console.log(response);
    } catch (error) {
      console.error("Error fetching allmodels:", error);
    }
  };


  useEffect(() => {
    const fetchPlacaCode = async () => {
      try {
        const response = await apiWrapper("garnumdeplacas", "GET");
        setNumPlacas(response);
    //    console.log(response);
      } catch (error) {
        console.error("Error fetching numdeplacas:", error);
      }
    };

    const fetchAsaCode = async () => {
      try {
        const response = await apiWrapper("garcolordeasanum", "GET");
        setAsacolors(response);
     //   console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching asacolor:", error);
      }
    };

    const fetchListaCode = async () => {
      try {
        const response = await apiWrapper("garlistasubfamilia", "GET");
        setListaSubFam(response);
     //   console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching listasubfamilia:", error);
      }
    };

    const fetchModeloCode = async () => {
      try {
        const response = await apiWrapper("gartipomodelos", "GET");
        setListaTipoModelos(response);
   //     console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching tipomodelos:", error);
      }
    };

    const fetchRoscas = async () => {
      try {
        const response = await apiWrapper("roscas", "GET");
        setListaRoscas(response);
   //     console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching roscas:", error);
      }
    };

    const fetchColores = async () => {
      try {
        const response = await apiWrapper("colores", "GET");
        setColor(response);
    //    console.log(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching asacolor:", error);
      }
    };
    fetchPlacaCode();
    fetchAsaCode();
    fetchListaCode();
    fetchModeloCode();
    fetchRoscas();
    fetchColores();
   
  }, []);

  const subfamiliaMapping = [
    { code: "S", description: "Soplado" },
    { code: "E", description: "Extrusion" },
    { code: "I", description: "Inyeccion" },
  ];

  const familiaMapping = [
    { code: "GAR", description: "Garrafon Asa Rosca" },
    { code: "GLP", description: "Garrafon Liso Presion" },
    { code: "GAP", description: "Garrafon Asa presion" },
  ];

  const placatipoMapping =[ 
    {code: "UP", description:"Una Placa"},
    {code: "DP", description:"Dos Placas"},
    {code: "TP", description:"Tres Placas"},
    {code: "NP", description:"No Placa"},
  ]
 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "asacolor") {
      const selectedAsacolor = asacolors.find(
        (asacolor) => asacolor.asacolorcode === value
      );
      setSelectedAsacolorCode(
        selectedAsacolor ? selectedAsacolor.asacolorcode : ""
      );
      setSelectedAsacolorDescription(
        selectedAsacolor ? selectedAsacolor.asacolordescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        
        asacolor: value === "SEL" ? "" : value,
        asacolodescription: selectedAsacolor
          ? selectedAsacolor.asacolordescription
          : "",
      }));
    }if (name === "grams") {
      setGrams(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        masterkg: value / 1000, // Convert grams to kg
      }));
    } else if (name === "listasubfam") {
      const selectedListaSubFam = listaSubFam.find(
        (listaSubFamy) => listaSubFamy.listacode === value
      );
      setSelectedListaSubFamCode(
        selectedListaSubFam ? selectedListaSubFam.listacode : ""
      );
      setSelectedListaSubDescription(
        selectedListaSubFam ? selectedListaSubFam.listadescription : ""
      );
      setFormData((prevFormData) => ({
        ...prevFormData,

        listadescription: selectedListaSubFam
          ? selectedListaSubFam.listadescription
          : "",
        listacode: value === "SEL" ? "" : value,
      }));
    } else if (name === "tipomodelo") {
      const selectedListaTipoModelos = listaTipoModelos.find(
        (modelos) => modelos.modelocode === value
      );
      setSelectedTipoModelos(
        selectedListaTipoModelos ? selectedListaTipoModelos.modelocode : ""
      );
      setSelectedTipoModelosDescription(
        selectedListaTipoModelos
          ? selectedListaTipoModelos.modelodescription
          : ""
      );
      setSelectedTipoModelosCapacitad(
        selectedListaTipoModelos ? selectedListaTipoModelos.capacitad : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        tipomodelodescription: value === "SEL" ? "" : selectedListaTipoModelos.modelodescription, 
        capacitad: value === "SEL" ? "" : selectedListaTipoModelos
          ? selectedListaTipoModelos.capacitad
          : "",
        tipomodelo: value === "SEL" ? "" : value,
      }));
    } else if (name === "color") {
      const selectedListaColores = color.find(
        (color) => color.colorcode === value
      );
      setSelectedListaColores(
        selectedListaColores ? selectedListaColores.colorcode : ""
      );
      setSelectedListaColoresDescription(
        selectedListaColores ? selectedListaColores.colordescription : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        colordescription: value === "SEL" ? "" : selectedListaColores.colordescription,
        color: value === "SEL" ? "" : value,
      }));
    } else if (name === "rosca") {
      const selectedListaRosca = listaRoscas.find(
        (rosca) => rosca.rosca === value
      );
      setSelectedListaRoscas(
        selectedListaRosca ? selectedListaRosca.rosca : ""
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        roscadescription: selectedListaRosca
          ? selectedListaRosca.rosca[0] === "R"
            ? "rosca"
            : selectedListaRosca.rosca[0] === "P"
            ? "presion"
            : selectedListaRosca.rosca[0] === "L"
            ? "liso"
            : selectedListaRosca.rosca
          : "",
        rosca: value === "SEL" ? "" : value,
      }));
    } else if (name === "placa") {
      const selectedPlaca = numPlacas.find(
        (placa) => placa.placacode === value
      );
      setSelectedPlacaCode(selectedPlaca ? selectedPlaca.placacode : "");
      setSelectedPlacaCodeDescription(
        selectedPlaca ? selectedPlaca.placacodedescription : ""
      );
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        placacodedescription: value === "SEL" ? "" : selectedPlaca.placacodedescription,
        placa: value === "SEL" ? "" : value,
      }));
    } else if (name === "placatipocode") {
      setPlacaTipoKey(value);
      const selectedPlacaTipoCode = placatipoMapping.find(
        (tipo) => tipo.code === value
      );
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        placatipocode: value === "SEL" ? "" : selectedPlacaTipoCode ? selectedPlacaTipoCode.code : "",
        placatipodescription: value === "SEL" ? "" : selectedPlacaTipoCode ? selectedPlacaTipoCode.description :'',
        placa: value === "NP" ? "000" : prevFormData.placa,
        placacodedescription: value === "NP" ? "No Placa" : prevFormData.placacodedescription,
      }));
    
      if (value === "NP") {
        setSelectedPlacaCode("000");
        setSelectedPlacaCodeDescription("No Placa");
      }
    }else if (name === "familia") {
      setFamiliaKey(value);
      const selectedFamilia = familiaMapping.find(
        (familia) => familia.code === value
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value === "SEL" ? "" : value,
        familiadescription: selectedFamilia ? selectedFamilia.description : "",
      }));
    } else if (name === "subfamilia") {
      setSubfamiliaKey(value);
      const selectedSubfamilia = subfamiliaMapping.find(
        (subfamilia) => subfamilia.code === value
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value === "SEL" ? "" : value,
        subfamiliadescription: selectedSubfamilia
          ? selectedSubfamilia.description
          : "",
      }));
    } else {
      let parsedValue = value;
      if (name === "value" || name === "kg") {
        parsedValue = Number(value);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue,
      }));
    }
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value === "SEL" ? "" : value,
      };

      // console.log(newFormData.tipomodelo, value);

      // Create codeConcatenation using the newFormData
    let codeConcatenation = `${newFormData.familia || ''}${newFormData.listacode|| ''}${newFormData.subfamilia || ''}${newFormData.capacitad || ''}${newFormData.rosca || ''}${newFormData.tipomodelo || ''}${newFormData.color|| ''}${newFormData.asacolor|| ''}${newFormData.placatipocode || ''}${newFormData.placa || ''}` ;
      
      // Update "code" field
    newFormData.code = codeConcatenation;

    //Garrafon Asa rosca 19 Lts. R-49 Morado Tepa
      let descriptionConcatenation = `${newFormData.familiadescription || ''} ${newFormData.capacitad || ''} ${newFormData.rosca || ''} ${newFormData.colordescription || ''} ${newFormData.placatipocode || ''} ${newFormData.placacodedescription || ''}`
     newFormData.modelname = descriptionConcatenation;
      return newFormData;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataWithNumberValue = {
        ...formData,
        value: Number(formData.value),
      };

      const response = await apiWrapper(
        "model",
        "POST",
        formDataWithNumberValue
      );
      fetchAllModels();
     resetAllStates()
      setModel(response._id);
      
      // to get all the data in the message
      const updatedFormData = { ...formDataWithNumberValue };

      //   console.log("Response:", response);
      if (response.msg) {
        if (response.msg === "Code already exists") {
          setCodeError(response.msg);
          setMessage(
            <Alert className="flex justify-center" severity="error">
              El código {formDataWithNumberValue.code} ya existe. Por favor
              asigne uno nuevo.
            </Alert>
          );
          setTimeout(() => {
            setMessage("");
          }, 6000);
          setTimeout(() => {
            setCodeError(false);
          }, 6000);
        }
      } else {
        setMessage(
          <Alert className="flex justify-center" severity="success">
            <AlertTitle>¡Nuevo modelo añadido!</AlertTitle>
            <div className="flex flex-col items-center">
              <div>
                <strong>Código:</strong> {updatedFormData.code}
              </div>
              <div>
                <strong>Nombre Modelo:</strong> {updatedFormData.modelname}
              </div>
          
            </div>
          </Alert>
        );

        // Clear the message after 1 second
        setTimeout(() => {
          setMessage("");
        }, 9000);
      }
    } catch (error) {
      console.error(error);

      setMessage(
        <Alert className="flex justify-center" severity="error">
          Ocurrió un error, Inténtalo de nuevo.
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
      }, 9000);
    }
  };



  const navigate = useNavigate();

  const navigateToDashboardModel = () => {
    navigate("/dashboardmodels");
  };

  return (
    <section className=" bg-gray-900   ">
     <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
     <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 
        bg-gray-800 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
              Añadir Modelo
            </h1>

            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="code"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Código
                </label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  value={formData.code}
                  onChange={handleInputChange}
                  className="
                          sm:text-sm rounded-lg  block w-full p-2.5 xl:text-xl
                          bg-gray-700 
                           placeholder-gray-400 text-white
                             "
                  placeholder="código"
                  required
                />
                {codeError && (
                  <p className="error-message text-white">{codeError}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="modelname"
                  className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Nombre Modelo
                </label>
                <textarea
                  name="modelname"
                  id="modelname"
                  value={formData.modelname}
                  onChange={handleInputChange}
                  className="
    sm:text-sm rounded-lg block w-full p-2.5
    bg-gray-700 
    placeholder-gray-400 text-white xl:text-xl
     "
                  placeholder="Nombre Modelo"
                  required
                />
              </div>

              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  xl:gap-20 grid md:gap-10 sm:gap-1 md:grid-cols-3 grid-rows-1">
              <div>

                <label
                  htmlFor="familia"
                  className="block mb-2 text-sm font-medium text-white xl:text-xl uppercase"
                >
                  Familia
                </label>

                <select
                  className={`rounded-lg  text-white p-2  w-full ${formData.familia === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="familia"
                  value={familiaKey}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {familiaMapping.map((familia) => (
                    <option key={familia.code} value={familia.code}>
                      {familia.description}
                    </option>
                  ))}
                </select>
              </div>
<div className="flex-grow">

                <label
                    htmlFor="listasubfam"
                    className="block mb-2 mt-2 text-sm font-medium text-white xl:text-xl uppercase"
                  >
                    subFamilia
                  </label>
                  <select
                   className={`rounded-lg  text-white p-2  w-full ${formData.listacode === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                    name="listasubfam"
                    value={formData.listacode}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="SEL">SEL</option>
                    {listaSubFam.map((subfamily) => (
                      <option key={subfamily._id} value={subfamily.listacode}>
                        {subfamily.listadescription}
                      </option>
                    ))}
                  </select>
</div>
              <div>

                  <label
                    htmlFor="subfamilia"
                    className="block mb-2 mt-2 text-sm font-medium  text-white xl:text-xl uppercase"
                  >
                    subfamiliatipo
                  </label>
                  <select
                   className={`rounded-lg  text-white p-2 w-full  ${formData.subfamilia === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                    name="subfamilia"
                    value={formData.subfamilia}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="SEL">SEL</option>
                    {subfamiliaMapping.map((subfamilia) => (
                      <option key={subfamilia.code} value={subfamilia.code}>
                        {subfamilia.description}
                      </option>
                    ))}
                  </select>
              </div>

                </div>
             

                
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800   grid xl:gap-20 md:gap-10 sm:gap-2 md:grid-cols-3 ">
                <div className="flex flex-col gap-2">
                  <input
                    type="text"
                    name="familia"
                    placeholder="familia código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white md:w-full   "
                    value={formData.familia}
                    onChange={() => {}}
                 //  readOnly
                   required
                  />

                  <input
                    type="text"
                    name="familiadescription"
                    placeholder="familia descripción ..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white mb-1 md:w-full  "
                    value={formData.familiadescription}
                    onChange={() => {}}
                    //readOnly
                    required
                  />
                 </div>
                <div className="flex flex-col flex-grow ">
                  <input
                    type="text"
                    name="listasubfam"
                    placeholder="subfam código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white mt-1   mb-2 md:w-full"
                    value={formData.listacode}
                    onChange={() => {}}
                  //  readOnly
                    required
                  />

                  <input
                    type="text"
                    name="listasubfam"
                    placeholder="subfam descripción..."
                   className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                    value={selectedListaSubDescription}
                    onChange={() => {}}
                  //  readOnly
                    required
                  />
                </div>
            
                <div className="flex flex-col flex-grow">
                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamiliatipo código..."
                    className="sm:text-sm rounded-lg mt-2  w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white mb-2"
                    value={formData.subfamilia}
                    onChange={() => {}}
                //    readOnly
                    required
                  />

                  <input
                    type="text"
                    name="subfamilia"
                    placeholder="subfamilia descripción..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={formData.subfamiliadescription}
                    onChange={() => {}}
                 //   readOnly
                    required
                  />
                </div>
              </div>
             
              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-1">
                 

                  <label
                    htmlFor="tipomodelo"
                    className="block mb-2 text-sm   font-medium  text-white xl:text-xl uppercase"
                  >
                    Tipo de Modelos
                  </label>
                
                  <select
                    className={`rounded-lg  p-2 w-full text-white ${formData.tipomodelo === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                   
                    name="tipomodelo"
                    value={formData.tipomodelo}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="SEL">SEL</option>
                    {listaTipoModelos.map((tipomodelos) => (
                      <option key={tipomodelos._id} value={tipomodelos.modelocode}>
                        {tipomodelos.modelodescription}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-3 ">
                  <input
                    type="text"
                    name="tipomodelo"
                    placeholder="capacitad..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={selectedTipoModelosCapacitad}
                    onChange={() => {}}
                //    readOnly
                    required
                  />

                  <input
                    type="text"
                    name="tipomodelo"
                    placeholder="tipos código..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={formData.tipomodelo}
                    onChange={() => {}}
                 //   readOnly
                    required
                  />
                    <input
                    type="text"
                    name="tipomodelodescription"
                    placeholder="descripción mod..."
                    className=" sm:text-sm rounded-lg 
block flex-grow p-2.5
bg-gray-700  placeholder-gray-400 text-white   "
                    value={selectedTipoModelosDescription}
                    onChange={() => {}}
                 //   readOnly
                    required
                  />
                </div>

         

              <div>
              <div className="w-full rounded-lg shadow  mb-4 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-2 ">
<div >
                <label
                  htmlFor="rosca"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Rosca
                </label>
                <select
                 className={`rounded-lg  w-full p-2 text-white ${formData.rosca === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="rosca"
                  value={formData.rosca}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {listaRoscas.map((rosca) => (
                    <option key={rosca._id} value={rosca.rosca}>
                      {rosca.rosca}
                    </option>
                  ))}
                </select>
                </div>
                <div>

                <label
                  htmlFor="color"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Color
                </label>
                <select
                 className={`rounded-lg w-full p-2 text-white ${formData.color === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="color"
                  value={formData.color}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {color.map((color) => (
                    <option key={color._id} value={color.colorcode}>
                      {color.colordescription}
                    </option>
                  ))}
                </select>
                </div>
               </div>
               <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-4 ">
               <input
                  type="text"
                  name="rosca"
                  id="rosca"
                  placeholder="rosca..."
                  value={selectedListaRoscas}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
              // readOnly
                  required
                />
                 <input
                  type="text"
                  name="roscadescription"
                  id="roscadescription"
                  placeholder="rosca descripción..."
                  value={formData.roscadescription} 
              //    onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
               //   readOnly
                  required
                />
                <input
                  type="text"
                  name="color"
                  id="color"
                  placeholder="color código..."
                  value={selectedListaColores}
               //   onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                  onChange={() => {}}
              //   readOnly
                  required
                />
                <input
                  type="text"
                  name="colordescription"
                  id="colordescription"
                  placeholder="blue, rojo, transparente..."
                  value={ selectedListaColoresDescription}
                //  onChange={handleInputChange}
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  onChange={() => {}}
                //  readOnly
                  required
                />
               </div>
              </div>
              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-1 ">
                <label
                  htmlFor="asacolor"
                  className="block text-sm font-medium  text-white xl:text-xl uppercase "
                >
                  Asacolor
                </label>
                <select
                className={`rounded-lg p-2 w-full text-white ${formData.asacolor === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="asacolor"
                  value={formData.asacolor}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {asacolors.map((asacolor) => (
                    <option key={asacolor._id} value={asacolor.asacolorcode}>
                      {asacolor.asacolordescription}
                    </option>
                  ))}
                </select>
                </div>
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
                  <input
                    type="text"
                    name="asacolorCode"
                    placeholder="asa código..."
                    className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                    value={selectedAsacolorCode}
                    onChange={() => {}}
                  //  readOnly
                    required
                  />

                  <input
                    type="text"
                    name="asacolorDisplay"
                    placeholder="asa color..."
                    className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white "
                    value={selectedAsacolorDescription}
                    onChange={() => {}}
                   // readOnly
                    required
                  />
                </div>
             
              
                <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
            <div>

              <label
                  htmlFor="placa"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Placa Tipo
                </label>
                <select
                 className={`w-full rounded-lg p-2 text-white ${formData.placatipocode === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="placatipocode"
                  value={placaTipoKey}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {placatipoMapping.map((placatipo) => (
                    <option key={placatipo.code} value={placatipo.code}>
                      {placatipo.code}
                    </option>
                  ))}
                </select>
            </div>
            <div>

                <label
                  htmlFor="placa"
                  className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase"
                >
                  Placa Descripción
                </label>
                <select
                 className={`w-full rounded-lg p-2 text-white ${formData.placa === '' ? 'border-2 border-pink-700 bg-gray-800' : 'border-2 border-green-700 bg-gray-800'}`}
                  name="placa"
                  value={formData.placa}
                  onChange={handleInputChange}
                  required
                >
                  <option value="SEL">SEL</option>
                  {numPlacas.map((placa) => (
                    <option key={placa._id} value={placa.placacode}>
                      {placa.placacodedescription}
                    </option>
                  ))}
                </select>
            </div>
           
               
              </div>
              <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-4 ">
              <input
                  type="text"
                  name="placatipocode"
                  placeholder="placa código..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={formData.placatipocode}
                  onChange={() => {}}
                 // readOnly
                  required
                />
                       <input
                  type="text"
                  name="placatipocode"
                  placeholder="placa tipo descripción..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={formData.placatipodescription}
                  onChange={() => {}}
                //  readOnly
                  required
                />
                <input
                  type="text"
                  name="placa"
                  placeholder="placa código..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={selectedPlacaCode}
                  onChange={() => {}}
              //    readOnly
                  required
                />

                <input
                  type="text"
                  name="placaDisplay"
                  placeholder="placa descripción..."
                  className="sm:text-sm rounded-lg w-full sm:flex-grow p-2.5 bg-gray-700  placeholder-gray-400 text-white"
                  value={selectedPlacaCodeDescription}
                  onChange={() => {}}
               //   readOnly
                  required
                />
              </div>
              <div className="w-full rounded-lg shadow  mb-4 sm:w-full xl:p-0 bg-gray-800 grid gap-2 md:grid-cols-2 ">
         
         <div>

         <label htmlFor="masterkg" className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase">Peso (GR)</label>
</div>
<div>

<label htmlFor="masterkg" className="block mb-2 text-sm font-medium  text-white xl:text-xl uppercase">Peso (KG)</label>
</div>

</div>
                    <div className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p-0 bg-gray-800  grid gap-2 md:grid-cols-2 ">
                       
                        <input type="number" name="grams" id="grams" placeholder="añadir peso en gramos" 
                        value={grams}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white
                          " required/>
                           
                        <input type="number" name="masterkg" id="masterkg" placeholder="peso en kg" 
                        value={formData.masterkg}
                        readOnly
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700  placeholder-gray-400 text-white
                          " required/>
                    </div>
              <span className="w-full rounded-lg shadow  md:mt-0 sm:w-full xl:p0 bg-gray-800  grid gap-2 md:grid-cols-2 md:justify-between xl:justify-between">
  <button
    type="submit"
    className="sm:w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800"
  >
    Guardar
  </button>
  <button
    type="dashboardmodels"
    className=" sm:w-full text-white mt-1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center   bg-sky-900 hover:bg-sky-700 focus:sky-800  xl:ml-auto md:ml-auto sm:ml-0"
    onClick={navigateToDashboardModel}
  >
    Panel de Modelos
  </button>
 
</span>
            </form>
            {/* Display the message */}
            {message && <div className="message-container">{message}</div>}
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewModel;
