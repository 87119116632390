import { apiWrapper } from "../../utils/apiwrapper";
import React, { useState, useEffect } from "react";
import EditGarCompModal from "../editmodal/EditComponentsGarModal.js";
import { format } from "date-fns";
import { formatMexicanTime } from '../../utils/variousConst.js';
import SearchBar from "../searchbar/searchbar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import EditAlertDeleteGarComp from "../editmodal/EditAlertDeleteGarComp.js";
import { socket } from "../../utils/variousConst.js";

import GarListaSubFamilia from "./AsaElements/GarListaSubFamilia.js";
import GarTipoModelos from "./AsaElements/GarTipoModelos.js";
import GarColorDeAsaNum from "./AsaElements/GarColorDeAsaNum.js";
import Roscas from "./AsaElements/Roscas.js";
import GarNumDePlacas from "./AsaElements/GarNumDePlacas.js";
import ListaColores from "./AsaElements/ListaColores.js";

import ProviderResina from "./AsaElements/ProviderResina.js";
import FamiliaResina from "./AsaElements/FamiliaResina.js";
import SubFamiliaResina from "./AsaElements/SubFamiliaResina.js";

import PrefListaSubFamilia from "./AsaElements/PrefListaSubFamilia.js";

const AllModelsComponent = ({ selectedComponent, setSelectedComponent }) => {
  //  console.log('ORIGINselectedComponent:', selectedComponent);
  //  console.log('ORIGINsetSelectedComponent is a function:', typeof setSelectedComponent === 'function');
  const [numPlacas, setNumPlacas] = useState([]);
  const [asacolors, setAsacolors] = useState([]);
  const [listaSubFam, setListaSubFam] = useState([]);
  const [listaPrefSubFam, setListaPrefSubFam] = useState([]);
  const [listaTipoModelos, setListaTipoModelos] = useState([]);
  const [listaRoscas, setListaRoscas] = useState([]);
  const [color, setColor] = useState([]);

  const [filteredModels, setFilteredModels] = useState([]);

  const [editableItem, setEditableItem] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [alertDeleteModel, setAlertDeleteModel] = useState(null);
  const [isAlertModelDeleteModalOpen, setIsAlertModelDeleteModalOpen] =
    useState(false);

  const [loading, setLoading] = useState(true);

  const openAlertModelDeleteModal = (model, index) => {
    setAlertDeleteModel({ model, index });
    setIsAlertModelDeleteModalOpen(true);
  };

  const closeOpenAlertModelDeleteModal = () => {
    setAlertDeleteModel(null);
    setIsAlertModelDeleteModalOpen(false);
  };

  const openEditModal = (model, index) => {
    setEditableItem({ model, index });
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditableItem(null);
    setEditModalOpen(false);
  };
  const [keyMapping, setKeyMapping] = useState("SEL");
  const fetchPlacaCode = async () => {
    try {
      const response = await apiWrapper("garnumdeplacas", "GET");
      setNumPlacas(response);
      //    console.log(response);
    } catch (error) {
      console.error("Error fetching numdeplacas:", error);
    }
  };

  const fetchAsaCode = async () => {
    try {
      const response = await apiWrapper("garcolordeasanum", "GET");
      setAsacolors(response);
      //   console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };

  const fetchListaCode = async () => {
    try {
      const response = await apiWrapper("garlistasubfamilia", "GET");
      setListaSubFam(response);
      //   console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching listasubfamilia:", error);
    }
  };

  const fetchPrefListaCode = async () => {
    try {
      const response = await apiWrapper("preflistasubfamilia", "GET");
      setListaPrefSubFam(response);
      //   console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching preflistasubfamilia:", error);
    }
  };


  const fetchModeloCode = async () => {
    try {
      const response = await apiWrapper("gartipomodelos", "GET");
      setListaTipoModelos(response);
      //     console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching tipomodelos:", error);
    }
  };

  const fetchRoscas = async () => {
    try {
      const response = await apiWrapper("roscas", "GET");
      setListaRoscas(response);
      //     console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching roscas:", error);
    }
  };

  const fetchColores = async () => {
    try {
      const response = await apiWrapper("colores", "GET");
      setColor(response);
      //    console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };
const [providers, setProviders] = useState([]);
  const fetchProviders = async () => {
    try {
      const response = await apiWrapper("provider", "GET");
      setProviders(response);
      //    console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };

  const [familiaResinas, setFamiliaResinas] = useState([]);
  const fetchFamiliaResina = async () => {
    try {
      const response = await apiWrapper("resinafamilia", "GET");
      setFamiliaResinas(response);
      //    console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };

  const [subfamiliaResinas, setSubFamiliaResinas] = useState([]);
  const fetchSubFamiliaResina = async () => {
    try {
      const response = await apiWrapper("resinasubfamilia", "GET");
      setSubFamiliaResinas(response);
      //    console.log(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching asacolor:", error);
    }
  };

  const stateVariables = [
    { name: "Subfamilias G", state: listaSubFam },
    { name: "Tipo de Modelos", state: listaTipoModelos },
    { name: "Asa Colores", state: asacolors },
    { name: "Personalizacion Placa", state: numPlacas },
    {name: "-", },
    { name: "Roscas", state: listaRoscas },
    { name: "Colores", state: color },
  //  {name: "Cerrar", state: []},
  {name: "--", },
  { name: "Subfamilias P", state: listaPrefSubFam },
      {name: "---", },
    {name: "Proveedores", state: providers},
    {name: "Familias Resina", state: familiaResinas},
    {name: "SubFamilias Resina", state: subfamiliaResinas},
  ];

  const selectedState = stateVariables.find(
    (variable) => variable.name === keyMapping
  )?.state;

  

  useEffect(() => {
  
    fetchPlacaCode();
    fetchAsaCode();
    fetchListaCode();
    fetchModeloCode();
    fetchRoscas();
    fetchColores();

    fetchProviders();
    fetchFamiliaResina();
    fetchSubFamiliaResina();
    fetchPrefListaCode();
  }, [keyMapping]);

  const componentsMapping = {
    "-GARAFFONES-": (
     <></>
    ),
    "Subfamilias G": (
      <GarListaSubFamilia
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "Tipo de Modelos": (
      <GarTipoModelos
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "Asa Colores": (
      <GarColorDeAsaNum
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "Personalizacion Placa": (
      <GarNumDePlacas
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "---": (
      <></>
     ),
    "-TODOS-": (
      <></>
     ),
    "Roscas": (
      <Roscas
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "Colores": (
      <ListaColores
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "-----": (
      <></>
     ),
    "-PREFORMAS-": (
      <></>
     ),
    "Subfamilias P": (
      <PrefListaSubFamilia
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "--------": (
      <></>
     ),
     "-RESINA-": (
      <></>
     ),
    "Proveedores": (
      <ProviderResina
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "Familia": (
      <FamiliaResina
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
    "SubFamilias R": (
      <SubFamiliaResina
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
      />
    ),
  };

  // const [selectedComponent, setSelectedComponent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // SEARCH BAR
  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredModels([]); // Clear the filtered models list
    } else {
      const filtered = selectedState
        ?.slice()
        .reverse()
        .filter((model) => {
          const lowerCaseSearchQuery = searchQuery.toLowerCase();
          const lowerCaseCode = (
            model?.placacode ||
            model?.asacolorcode ||
            model?.rosca ||
            model?.colorcode ||
            model?.listacode ||
            model?.modelocode ||
            model?.provider ||
            model?.familiadescription ||
            model?.subfamilia ||
            model?.preflistacode ||
            ""
          ).toLowerCase();
          const lowerCaseProvider = (
            model?.placacodedescription ||
            model?.asacolordescription ||
            model?.rosca ||
            model?.colordescription ||
            model?.listadescription ||
            model?.modelodescription ||
            model?.country ||
            model?.codealfa ||
            model?.subfamdescription ||
            model?.preflistadescription ||
            ""
          ).toLowerCase();
          const lowerCaseCapacitad = (model.capacitad || "").toLowerCase();

          //   console.log('lowerCaseCode:', lowerCaseCode);
          //  console.log('lowerCaseProvider:', lowerCaseProvider);
          //   console.log('lowerCaseSearchQuery:', lowerCaseSearchQuery);

          return (
            lowerCaseCode.includes(lowerCaseSearchQuery) ||
            lowerCaseProvider.includes(lowerCaseSearchQuery) ||
            lowerCaseCapacitad.includes(lowerCaseSearchQuery)
          );
        });
      setFilteredModels(filtered);
    }
  }, [searchQuery, selectedState]);

  //*END POINTS
  const handleSave = (index, updatedModel) => {
    //  console.log("what comes from modal:", updatedModel)
    // const code =  updatedModel?.listacode || updatedModel?.modelocode || updatedModel?.asacolorcode || updatedModel?.rosca || updatedModel?.colorcode || updatedModel?.placacode
    const updatedModelUpperCase = Object.fromEntries(
      Object.entries(updatedModel).map(([key, value]) => [
        key,
        typeof value === "string" ? value.toUpperCase() : value,
      ])
    );
    // console.log( 'code', code);
    // Make a PATCH request to update the model on the server
    const updatedModelOnServer = async () => {
      if (updatedModel.listacode !== undefined)
        try {
          await apiWrapper(
            `garlistasubfamilia/${updatedModel._id}`,
            "PATCH",
            updatedModel
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...listaSubFam];
          updatedModels[index] = updatedModel;
          setListaSubFam(updatedModels);
          const response = await apiWrapper("garlistasubfamilia", "GET");
          setListaSubFam(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating listasubfam:", error);
          //    Handle error updating model
        }
        else if (updatedModel.preflistacode !== undefined)
        try {
          await apiWrapper(
            `preflistasubfamilia/${updatedModel._id}`,
            "PATCH",
            updatedModel
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...listaPrefSubFam];
          updatedModels[index] = updatedModel;
          setListaPrefSubFam(updatedModels);
          const response = await apiWrapper("preflistasubfamilia", "GET");
          setListaPrefSubFam(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating preflistasubfam:", error);
          //    Handle error updating model
        }
      else if (updatedModelUpperCase.modelocode !== undefined)
        try {
          await apiWrapper(
            `gartipomodelos/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...listaTipoModelos];
          updatedModels[index] = updatedModel;
          setListaTipoModelos(updatedModels);
          const response = await apiWrapper("gartipomodelos", "GET");
          setListaTipoModelos(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating tipomodelos:", error);
          //    Handle error updating model
        }
      else if (updatedModelUpperCase.rosca !== undefined)
        try {
          await apiWrapper(
            `roscas/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...listaRoscas];
          updatedModels[index] = updatedModel;
          setListaRoscas(updatedModels);
          const response = await apiWrapper("roscas", "GET");
          setListaRoscas(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating rosca:", error);
          //    Handle error updating model
        }
      else if (updatedModelUpperCase.colorcode !== undefined)
        try {
          await apiWrapper(
            `colores/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...color];
          updatedModels[index] = updatedModel;
          setColor(updatedModels);
          const response = await apiWrapper("colores", "GET");
          setColor(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating color:", error);
          //    Handle error updating model
        }
      else if (updatedModel.placacode !== undefined)
        try {
          await apiWrapper(
            `garnumdeplacas/${updatedModel._id}`,
            "PATCH",
            updatedModel
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...numPlacas];
          updatedModels[index] = updatedModel;
          setNumPlacas(updatedModels);
          const response = await apiWrapper("garnumdeplacas", "GET");
          setNumPlacas(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating placas:", error);
          //    Handle error updating model
        }
        else if (updatedModelUpperCase.provider !== undefined)
        try {
          await apiWrapper(
            `provider/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...providers];
          updatedModels[index] = updatedModel;
          setProviders(updatedModels);
          const response = await apiWrapper("provider", "GET");
          setProviders(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating providers:", error);
          //    Handle error updating model
        }

        else if (updatedModelUpperCase.codefamilia !== undefined)
        try {
          await apiWrapper(
            `resinafamilia/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...familiaResinas];
          updatedModels[index] = updatedModel;
          setFamiliaResinas(updatedModels);
          const response = await apiWrapper("resinafamilia", "GET");
          setFamiliaResinas(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating famresina:", error);
          //    Handle error updating model
        }
        else if (updatedModelUpperCase.subfamilia !== undefined)
        try {
          await apiWrapper(
            `resinasubfamilia/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...subfamiliaResinas];
          updatedModels[index] = updatedModel;
          setSubFamiliaResinas(updatedModels);
          const response = await apiWrapper("resinasubfamilia", "GET");
          setSubFamiliaResinas(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating subfamresina:", error);
          //    Handle error updating model
        }

      else if (updatedModelUpperCase.asacolorcode !== undefined)
        try {
          await apiWrapper(
            `garcolordeasanum/${updatedModel._id}`,
            "PATCH",
            updatedModelUpperCase
          );
          // If the PATCH request is successful, update the model in the state
          const updatedModels = [...asacolors];
          updatedModels[index] = updatedModel;
          setAsacolors(updatedModels);
          const response = await apiWrapper("garcolordeasanum", "GET");
          setAsacolors(response);
          closeEditModal();
        } catch (error) {
          console.error("Error updating asacolor:", error);
          //    Handle error updating model
        }
    };

    updatedModelOnServer();
  };

  const handleDelete = async (model) => {
    const modelId = model._id;
    //  const code =  model?.listacode || model?.modelocode || model?.asacolorcode || model?.rosca || model?.colorcode || model?.placacode

    //  console.log('id:', modelId, 'code', code);
    if (model.listacode !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`garlistasubfamilia/${modelId}`, "DELETE");

        // Update the models state by removing the deleted model from the array
        setListaSubFam(listaSubFam.filter((model) => model._id !== modelId));
      } catch (error) {
        // Handle error
        console.error("Error deleting subfammodel:", error);
      }
      else if(model.preflistacode !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`preflistasubfamilia/${modelId}`, "DELETE");

        // Update the models state by removing the deleted model from the array
        setListaPrefSubFam(listaPrefSubFam.filter((model) => model._id !== modelId));
      } catch (error) {
        // Handle error
        console.error("Error deleting subfammodel:", error);
      }
    else if (model.modelocode !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`gartipomodelos/${modelId}`, "DELETE");
        setListaTipoModelos(
          listaTipoModelos.filter((model) => model._id !== modelId)
        );
      } catch (error) {
        console.error("Error deleting tipomodel:", error);
      }
    else if (model.asacolorcode !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`garcolordeasanum/${modelId}`, "DELETE");
        setAsacolors(asacolors.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting asamodel:", error);
      }
    else if (model.rosca !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`roscas/${modelId}`, "DELETE");
        setListaRoscas(listaRoscas.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting roscamodel:", error);
      }
    else if (model.colorcode !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`colores/${modelId}`, "DELETE");
        setColor(color.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting colormodel:", error);
      }
    else if (model.placacode !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`garnumdeplacas/${modelId}`, "DELETE");
        setNumPlacas(numPlacas.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting placamodel:", error);
      }
      else if (model.provider !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`provider/${modelId}`, "DELETE");
        setProviders(providers.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting provider:", error);
      }
      else if (model.codefamilia !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`resinafamilia/${modelId}`, "DELETE");
        setFamiliaResinas(familiaResinas.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting  familia resina:", error);
      }
      else if (model.subfamilia !== undefined)
      try {
        // Make a DELETE request to the API to delete the model with the given ID
        await apiWrapper(`resinasubfamilia/${modelId}`, "DELETE");
        setSubFamiliaResinas(subfamiliaResinas.filter((model) => model._id !== modelId));
      } catch (error) {
        console.error("Error deleting  subfamilia resina:", error);
      }
     
  };

  



  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setNumPlacas((prevModels) => [...prevModels, newModel]);
    };
    socket.on("delGarNumDePlacas", (deletedModel) => {
 //     console.log("Received delGarNumDePlacas event", deletedModel);
      setNumPlacas((prevNumPlacas) => {
        const updatedModels = prevNumPlacas.filter(
          (model) => model._id !== deletedModel._id
        );
  //      console.log("Updated models:", updatedModels);
        return updatedModels;
      });
      fetchPlacaCode();
    
    });
    socket.on('editGarNumDePlacas', (updatedEditModel) => {
      setNumPlacas((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchPlacaCode();
    });
  
    socket.on('createGarNumDePlacas', handleCreateModel);
  
    return () => {
  
      socket.off("delGarNumDePlacas");
      socket.off('editGarNumDePlacas');
      socket.off('createGarNumDePlacas', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setColor((prevModels) => [...prevModels, newModel]);
    };
    socket.on("delColores", (deletedModel) => {
 //     console.log("Received delColores event", deletedModel);
      setColor((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
 //       console.log("Updated models:", updatedModels);
        return updatedModels;
      });
      fetchColores();
    
    });
    socket.on('editColores', (updatedEditModel) => {
      setColor((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchColores();
    });
  
    socket.on('createColores', handleCreateModel);
  
    return () => {
    
   //   console.log("Cleaning up...");
      socket.off("delColores");
      socket.off('editColores');
      socket.off('createColores', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setListaRoscas((prevModels) => [...prevModels, newModel]);
    };
    socket.on("delRoscas", (deletedModel) => {
  //    console.log("Received delColores event", deletedModel);
      setListaRoscas((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
   //     console.log("Updated models:", updatedModels);
        return updatedModels;
      });
      fetchRoscas();
    
    });
    socket.on('editRoscas', (updatedEditModel) => {
      setListaRoscas((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchRoscas();
    });
  
    socket.on('createRoscas', handleCreateModel);
  
    return () => {
    
    //  console.log("Cleaning up...");
      socket.off("delRoscas");
      socket.off('editRoscas');
      socket.off('createRoscas', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setAsacolors((prevModels) => [...prevModels, newModel]);
    };
    socket.on("delGarColorDeAsaNum", (deletedModel) => {
   //   console.log("Received delColores event", deletedModel);
      setAsacolors((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
   //     console.log("Updated models:", updatedModels);
        return updatedModels;
      });
      fetchAsaCode();
    
    });
    socket.on('editGarColorDeAsaNum', (updatedEditModel) => {
      setAsacolors((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchAsaCode();
    });
  
    socket.on('createGarColorDeAsaNum', handleCreateModel);
  
    return () => {
    
  //    console.log("Cleaning up...");
      socket.off("delGarColorDeAsaNum");
      socket.off('editGarColorDeAsaNum');
      socket.off('createGarColorDeAsaNum', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setListaTipoModelos((prevModels) => [...prevModels, newModel]);
    };
    socket.on("delGarTipoModelos", (deletedModel) => {
    //  console.log("Received delColores event", deletedModel);
      setListaTipoModelos((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
    //    console.log("Updated models:", updatedModels);
        return updatedModels;
      });
      fetchModeloCode();
    
    });
    socket.on('editGarTipoModelos', (updatedEditModel) => {
      setListaTipoModelos((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchModeloCode();
    });
  
    socket.on('createGarTipoModelos', handleCreateModel);
  
    return () => {
   //   console.log("Cleaning up...");
      socket.off("delGarTipoModelos");
      socket.off('editGarTipoModelos');
      socket.off('createGarTipoModelos', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setListaSubFam((prevModels) => [...prevModels, newModel]);
    };
  
    socket.on("delGarListaSubFamilia", (deletedModel) => {
      setListaSubFam((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
        return updatedModels;
      });
      fetchListaCode();
    });
  
    socket.on('editGarListaSubFamilia', (updatedEditModel) => {
      setListaSubFam((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchListaCode();
    });
  
    socket.on('createGarListaSubFamilia', handleCreateModel);
  
    return () => {
      socket.off("delGarListaSubFamilia");
      socket.off('editGarListaSubFamilia');
      socket.off('createGarListaSubFamilia', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setListaPrefSubFam((prevModels) => [...prevModels, newModel]);
    };
  
    socket.on("delPrefListaSubFamilia", (deletedModel) => {
      setListaPrefSubFam((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
        return updatedModels;
      });
      fetchPrefListaCode();
    });
  
    socket.on('editPrefListaSubFamilia', (updatedEditModel) => {
      setListaPrefSubFam((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchPrefListaCode();
    });
  
    socket.on('createPrefListaSubFamilia', handleCreateModel);
  
    return () => {
      socket.off("delPrefListaSubFamilia");
      socket.off('editPrefListaSubFamilia');
      socket.off('createPrefListaSubFamilia', handleCreateModel);
    };
  }, []);



  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setProviders((prevModels) => [...prevModels, newModel]);
    };
  
    socket.on("providerdeleteArticle", (deletedModel) => {
      setProviders((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
        return updatedModels;
      });
      fetchProviders();
    });
  
    socket.on('providerupdateArticle', (updatedEditModel) => {
      setProviders((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchProviders();
    });
  
    socket.on('providercreateArticle', handleCreateModel);
  
    return () => {
      socket.off("providerdeleteArticle");
      socket.off('providerupdateArticle');
      socket.off('providercreateArticle', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setFamiliaResinas((prevModels) => [...prevModels, newModel]);
    };
  
    socket.on("familiadeleteArticle", (deletedModel) => {
      setFamiliaResinas((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
        return updatedModels;
      });
      fetchFamiliaResina();
    });
  
    socket.on('familiaupdateArticle', (updatedEditModel) => {
      setFamiliaResinas((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchFamiliaResina();
    });
  
    socket.on('familiacreateArticle', handleCreateModel);
  
    return () => {
      socket.off("familiadeleteArticle");
      socket.off('familiaupdateArticle');
      socket.off('familiacreateArticle', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setSubFamiliaResinas((prevModels) => [...prevModels, newModel]);
    };
  
    socket.on("subfamdeleteArticle", (deletedModel) => {
      setSubFamiliaResinas((prevModels) => {
        const updatedModels = prevModels.filter(
          (model) => model._id !== deletedModel._id
        );
        return updatedModels;
      });
      fetchSubFamiliaResina();
    });
  
    socket.on('subfamupdateArticle', (updatedEditModel) => {
      setSubFamiliaResinas((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            return { ...model, ...updatedEditModel };
          }
          return model;
        });
        return updatedModels;
      });
      fetchSubFamiliaResina();
    });
  
    socket.on('subfamcreateArticle', handleCreateModel);
  
    return () => {
      socket.off("subfamdeleteArticle");
      socket.off('subfamupdateArticle');
      socket.off('subfamcreateArticle', handleCreateModel);
    };
  }, []);

  {
    /* 

   //spinning loading
 useEffect(() => {
  if (models.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [models]);
*/
  }
  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
              Panel de Componentes de Modelos
            </h1>
            <div className="flex items-center justify-center">
              <select
                onChange={(e) =>
                  setSelectedComponent(componentsMapping[e.target.value])
                }
                className={`rounded-lg  text-white p-2 border  w-full  bg-gray-800 uppercase`}
              >
                <option value="SEL">Sel AÑADIR COMPONENTE</option>
                {Object.keys(componentsMapping).map((name) => (
                  <option value={name} key={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            {!selectedComponent && (
              <div className="flex items-center justify-center">
                <select
                  onChange={(e) => setKeyMapping(e.target.value)}
                  className={`rounded-lg  text-white p-2 border  w-full  bg-gray-800 uppercase`}
                >
                  <option value="SEL">SELECCIONAR LISTA</option>
                  {stateVariables.map((variable) => (
                    <option value={variable.name} key={variable.name}>
                      {variable.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
              {!selectedComponent && keyMapping !== "SEL" && (
                <SearchBar key="searchbar" onSearch={handleSearch} />
              )}
            </div>
          </div>
          <div>{selectedComponent}</div>
          <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            {!selectedComponent && (
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-gray-700 ">
                  <tr>
                  {keyMapping !== "Proveedores" && (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Codigo
                    </th>
                     )}
                       {keyMapping === "Proveedores" && (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Proveedor
                    </th>
                     )}
                       {keyMapping !== "Proveedores" && (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Modelo
                    </th>
                      )}
                          {keyMapping === "Proveedores" && (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      País
                    </th>
                     )}
{(keyMapping !== "Proveedores" && keyMapping !== "FamResina" && keyMapping !== "SubFamResina")&&  (
  <React.Fragment>
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Kg
                    </th>
                    </React.Fragment>
  )}
                    {(keyMapping !== "FamResina" && keyMapping !== "Proveedores") && (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Capacitad
                    </th>
                     )}
                       {keyMapping === "FamResina" && (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Codigo Alfa
                    </th>
                     )}
  
  {(keyMapping === "Proveedores") && (
  <React.Fragment>
    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
      -
    </th>
  </React.Fragment>
)}   
{(keyMapping === "Proveedores" || keyMapping === "FamResina")  &&  (
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      -
                    </th>
 )}
                    <th className="px-6 py-3 text-center text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Fecha
                    </th>

                    <th className="px-2 py-3  text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Edit
                    </th>
                    <th className="px-3 py-3 text-xs xl:text-xl font-medium text-gray-400 uppercase tracking-wider">
                      Delete
                    </th>

                    {/* Add more table headers as needed */}
                  </tr>
                </thead>
                <tbody>
                  {filteredModels.map((model, index) => (
                    <tr
                      className={
                        index % 2 === 0
                          ? "bg-amber-200 text-center"
                          : "bg-amber-300 text-center"
                      }
                      key={`${model._id}-${index}`}
                    >
                           {model.codefamilia && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.codefamilia}
                        </td>
                      )}
                            {model.subfamilia && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.subfamilia}
                        </td>
                      )}
                           {model.provider && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.provider}
                        </td>
                      )}
                            {model.country && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.country}
                        </td>
                      )}
                      {model.placacode && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.placacode}
                        </td>
                      )}
                      {model.placacodedescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.placacodedescription}
                        </td>
                      )}
                      {model.familiadescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.familiadescription}
                        </td>
                      )}
                      {model.asacolorcode && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.asacolorcode}
                        </td>
                      )}
                      {model.asacolordescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.asacolordescription}
                        </td>
                      )}

                      {model.rosca && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.rosca}
                        </td>
                      )}
                      {model.rosca && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {" "}
                          {model.rosca
                            ? model.rosca[0] === "R"
                              ? "rosca"
                              : model.rosca[0] === "P"
                              ? "presion"
                              : model.rosca[0] === "L"
                              ? "liso"
                              : model.rosca
                            : ""}
                        </td>
                      )}

                      {model.colorcode && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.colorcode}
                        </td>
                      )}
                      {model.colordescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.colordescription}
                        </td>
                      )}

                      {model.listacode && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.listacode}
                        </td>
                      )}
                      {model.listadescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.listadescription}
                        </td>
                      )}

                        {model.preflistacode && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.preflistacode}
                        </td>
                      )}
                      {model.preflistadescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.preflistadescription}
                        </td>
                      )}

                      {model.modelocode && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.modelocode}
                        </td>
                      )}
                      {model.modelodescription && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.modelodescription}
                        </td>
                      )}
 {model.codealfa && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.codealfa}
                        </td>
                      )}
                      {!model.codealfa && ( 
                      <td className="px-6 py-4  font-medium whitespace-nowrap ml-3 mr-3">
                        {isNaN(model.value)
                          ? "-"
                          : Number.isInteger(model.masterkg)
                          ? model.masterkg
                          : Math.round(model.masterkg * 100) / 100}
                      </td>
                     )}
                      <td className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        {model.capacitad ? model.capacitad : "-"}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        {formatMexicanTime(model.createdAt)}
                      </td>

                      <td className=" whitespace-nowrap    ">
                        <EditIcon onClick={() => openEditModal(model, index)} />
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                        <DeleteOutlineIcon
                          onClick={() => {
                            openAlertModelDeleteModal(model);
                          }}
                        ></DeleteOutlineIcon>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tbody className=" divide-y divide-gray-200 text-center text-white ">
                  {numPlacas.length === 0 || filteredModels.length > 0 ? (
                    <tr className="text-center">
                      <td colSpan="8">
                        {loading ? (
                          <p className=" text-white text-center">
                           -
                          </p>
                        ) : (
                          <p className="text-center  font-medium text-white">
                            No hay datos en DB
                          </p>
                        )}
                      </td>
                    </tr>
                  ) : (
                    selectedState
                      ?.slice()
                      .reverse()
                      .map((model, index) => (
                        <tr
                          className={
                            index % 2 === 0 ? " bg-sky-900" : " bg-sky-800"
                          }
                          key={model._id}
                        >
                               {model.subfamilia && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.subfamilia}
                        </td>
                      )}
                                 {model.codefamilia && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.codefamilia}
                        </td>
                      )}
                            {model.provider && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.provider}
                        </td>
                      )}
                            {model.country && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {model.country}
                        </td>
                      )}
                          {model.placacode && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.placacode}
                            </td>
                          )}
                            {model.familiadescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.familiadescription}
                            </td>
                          )}

                          {model.placacodedescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.placacodedescription}
                            </td>
                          )}

                          {model.asacolorcode && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.asacolorcode}
                            </td>
                          )}
                          {model.asacolordescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.asacolordescription}
                            </td>
                          )}

                          {model.rosca && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.rosca}
                            </td>
                          )}
                          {model.rosca && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {" "}
                              {model.rosca
                                ? model.rosca[0] === "R"
                                  ? "rosca"
                                  : model.rosca[0] === "P"
                                  ? "presion"
                                  : model.rosca[0] === "L"
                                  ? "liso"
                                  : model.rosca
                                : ""}
                            </td>
                          )}

                          {model.colorcode && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.colorcode}
                            </td>
                          )}

                         
                          {model.colordescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.colordescription}
                            </td>
                          )}

                          {model.listacode && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.listacode}
                            </td>
                          )}
                          {model.listadescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.listadescription}
                            </td>
                          )}

                            {model.preflistacode && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.preflistacode}
                            </td>
                          )}
                          {model.preflistadescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.preflistadescription}
                            </td>
                          )}

                          {model.modelocode && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.modelocode}
                            </td>
                          )}
                          {model.modelodescription && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.modelodescription}
                            </td>
                          )}
 {model.codealfa && (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {model.codealfa}
                            </td>
                          )}
                          <td className="px-6 py-4  font-medium whitespace-nowrap">
                            {isNaN(model.value)
                              ? "-"
                              : Number.isInteger(model.masterkg)
                              ? model.masterkg
                              : Math.round(model.masterkg * 100) / 100}
                          </td>
                          {!model.codealfa && (
                          <td className="px-6 py-4 whitespace-nowrap">
                            {model.capacitad ? model.capacitad : "-"}
                          </td>
                           )}
                         
                          <td className=" text-xs px-6 py-4 whitespace-nowrap">
                          {formatMexicanTime(model.createdAt)}
                          </td>
                          <td className=" whitespace-nowrap">
                            <EditIcon
                              onClick={() => {
                                //   console.log(model)
                                openEditModal(model, index);
                              }}
                            />
                          </td>
                          <td className="px-2  whitespace-nowrap m-18">
                            <DeleteOutlineIcon
                              onClick={() => {
                                openAlertModelDeleteModal(model);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            )}
          </div>

          <div className="grid grid-cols-1 gap-4 md:hidden ">
            <div className="flex justify-center items-center">
              {!selectedComponent && keyMapping !== "SEL" && (
                <SearchBar key="searchbar" onSearch={handleSearch} />
              )}
            </div>
            <div>
              {filteredModels.map((model, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-amber-200 px-2 py-2.5"
                      : " border rounded-lg bg-amber-300 px-2 py-2.5"
                  }
                  key={`${index}-${model._id}`}
                >
                  <div className=" bmodel rounded-lg flex items-center justify-center space-x-2 text-xl">
                  {model.codefamilia && (
                      <div className="px-6 py-4 whitespace-nowrap">
                        Modelo: {model.codefamilia} - {model.familiadescription}
                      </div>
                    )}
                       {model.subfamilia && (
                      <div className="px-6 py-4 whitespace-nowrap">
                        Código: {model.subfamilia}
                      </div>
                    )}
                  {model.provider && (
                      <div className="px-6 py-4 whitespace-nowrap">
                        Proveedor: {model.provider}
                      </div>
                    )}
                    {model.placacode && (
                      <div className="px-6 py-4 whitespace-nowrap">
                        Codigo: {model.placacode}
                      </div>
                    )}
                    {model.asacolorcode && (
                      <div className="px-6 py-4 whitespace-nowrap">
                        Codigo: {model.asacolorcode}
                      </div>
                    )}
                    {model.rosca && (
                      <div className="px-6 py-4 whitespace-nowrap">
                        Codigo: {model.rosca}
                      </div>
                    )}
                    {model.colorcode && (
                      <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                        Codigo: {model.colorcode}
                      </div>
                    )}
                    {model.listacode && (
                      <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                        Codigo: {model.listacode}
                      </div>
                    )}
                      {model.preflistacode && (
                      <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                        Codigo: {model.preflistacode}
                      </div>
                    )}
                    {model.modelocode && (
                      <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                        Codigo: {model.modelocode}
                      </div>
                    )}
                  </div>
                  {model.country && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      País: {model.country}
                    </div>
                  )}
                  {model.asacolordescription && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo: {model.asacolordescription}
                    </div>
                  )}
                  {model.placacodedescription && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo: {model.placacodedescription}
                    </div>
                  )}
                  {model.rosca && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo:{" "}
                      {model.rosca
                        ? model.rosca[0] === "R"
                          ? "rosca"
                          : model.rosca[0] === "P"
                          ? "presion"
                          : model.rosca[0] === "L"
                          ? "liso"
                          : model.rosca
                        : ""}
                    </div>
                  )}
                  {model.colordescription && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo: {model.colordescription}
                    </div>
                  )}
                  {model.listadescription && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo: {model.listadescription}
                    </div>
                  )}
                   {model.preflistadescription && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo: {model.preflistadescription}
                    </div>
                  )}
                  {model.modelodescription && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Modelo: {model.modelodescription}
                    </div>
                  )}
                    {model.codealfa && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Código Alfa: {model.codealfa}
                    </div>
                  )}

                  {!model.colordescription && !model.placacodedescription && !model.provider
                   && !model.codealfa && !model.subfamilia && (
                    <div className="px-6 py-4  font-medium whitespace-nowrap ml-3 mr-3">
                      KG:{" "}
                      {isNaN(model.value)
                        ? "-"
                        : Number.isInteger(model.masterkg)
                        ? model.masterkg
                        : Math.round(model.masterkg * 100) / 100}
                    </div>
                  )}
                  {model.capacitad && (
                    <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Capacitad: {model.capacitad ? model.capacitad : "-"}
                    </div>
                  )}

                  <div className="px-6 py-4 whitespace-nowrap ml-3 mr-3">
                    Fecha:  {formatMexicanTime(model.createdAt)}
                  </div>
                  <div className="flex justify-between mt-4 ml-3 mr-3">
                    <button
                      className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-2 py-2.5 text-center
                          bg-gray-700 hover:bg-sky-900 focus:ring-gray-800 "
                      onClick={() => openEditModal(model, index)}
                    >
                      <EditIcon /> Edit
                    </button>
                    <button
                      className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-700 hover:bg-red-700 focus:ring-gray-800"
                      onClick={() => openAlertModelDeleteModal(model)}
                    >
                      <DeleteOutlineIcon /> Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {numPlacas.length === 0 || filteredModels.length > 0 ? (
              loading ? (
                <div className="border rounded-lg bg-sky-900">
                  <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
                    <div className=" text-white">-</div>
                  </div>
                </div>
              ) : (
                <div className="border rounded-lg bg-sky-900">
                  <p className="text-center text-white">No hay datos en DB</p>
                </div>
              )
            ) : (
              selectedState
                ?.slice()
                .reverse()
                .map((model, index) => (
                  <div
                    className={
                      index % 2 === 0
                        ? "border rounded-lg bg-sky-900 text-white"
                        : "border rounded-lg bg-sky-800 text-white"
                    }
                    key={model._id}
                  >
                    <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
                    {model.provider && (
                        <div className="px-6 py-4 whitespace-nowrap">
                          Proveedor: {model.provider}
                        </div>
                        
                      )}
                        {model.subfamilia && (
                        <div className="px-6 py-4 whitespace-nowrap">
                          Código: {model.subfamilia}
                        </div>
                      )}
                       {model.codealfa && (
                        <div className="px-6 py-4 whitespace-nowrap">
                          Modelo: {model.codefamilia} - {model.familiadescription}
                        </div>
                      )}

                      {model.placacode && (
                        <div className="px-6 py-4 whitespace-nowrap">
                          Codigo: {model.placacode}
                        </div>
                      )}
                      {model.asacolorcode && (
                        <div className="px-6 py-4 whitespace-nowrap">
                          Codigo: {model.asacolorcode}
                        </div>
                      )}
                      {model.rosca && (
                        <div className="px-6 py-4 whitespace-nowrap">
                          Codigo: {model.rosca}
                        </div>
                      )}
                      {model.colorcode && (
                        <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                          Codigo: {model.colorcode}
                        </div>
                      )}
                      {model.listacode && (
                        <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                          Codigo: {model.listacode}
                        </div>
                      )}

                      {model.preflistacode && (
                        <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                          Codigo: {model.preflistacode}
                        </div>
                      )}

                      {model.modelocode && (
                        <div className="flex items-center justify-between space-x-2 text-2xl font-bold px-6 py-4 whitespace-nowrap">
                          Codigo: {model.modelocode}
                        </div>
                      )}
                    </div>
                    {model.provider && (
                         <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                          País: {model.country}
                        </div>
                      )}
                    {model.asacolordescription && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo: {model.asacolordescription}
                      </div>
                    )}
                    {model.placacodedescription && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo: {model.placacodedescription}
                      </div>
                    )}
                    {model.rosca && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo:{" "}
                        {model.rosca
                          ? model.rosca[0] === "R"
                            ? "rosca"
                            : model.rosca[0] === "P"
                            ? "presion"
                            : model.rosca[0] === "L"
                            ? "liso"
                            : model.rosca
                          : ""}
                      </div>
                    )}
                    {model.colordescription && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo: {model.colordescription}
                      </div>
                    )}
                    {model.listadescription && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo: {model.listadescription}
                      </div>
                    )}
                       {model.preflistadescription && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo: {model.preflistadescription}
                      </div>
                    )}
                    {model.modelodescription && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Modelo: {model.modelodescription}
                      </div>
                    )}
                      {model.codealfa && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                       Código Alfa: {model.codealfa}
                      </div>
                    )}


                    {!model.colordescription && !model.placacodedescription && !model.provider &&
                     !model.codealfa && !model.subfamilia &&(
                      <div className="px-6 py-4  font-medium whitespace-nowrap ml-3 mr-3">
                        KG:{" "}
                        {isNaN(model.value)
                          ? "-"
                          : Number.isInteger(model.masterkg)
                          ? model.masterkg
                          : Math.round(model.masterkg * 100) / 100}
                      </div>
                    )}
                    {model.capacitad && (
                      <div className="text-xl px-6 py-4 whitespace-nowrap ml-3 mr-3">
                        Capacitad: {model.capacitad ? model.capacitad : "-"}
                      </div>
                    )}

                    <div className="px-6 py-4 whitespace-nowrap ml-3 mr-3">
                      Fecha:{" "}
                      {formatMexicanTime(model.createdAt)}
                    </div>
                    <div className="flex flex-col justify-between">
                      <button
                        className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-1 py-0.5 text-center bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                        onClick={() => openEditModal(model, index)}
                      >
                        <EditIcon /> Edit
                      </button>
                      <button
                        className="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-1 py-0.5 text-center bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3"
                        onClick={() => openAlertModelDeleteModal(model)}
                      >
                        <DeleteOutlineIcon /> Delete
                      </button>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
          <div className="mt-10 flex items-center  justify-center">
            <EditGarCompModal
              editableItem={editableItem}
              onSave={handleSave}
              onClose={closeEditModal}
            />
          </div>
        </div>
      )}
      {isAlertModelDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteGarComp
              alertDeleteModel={alertDeleteModel}
              onSave={handleDelete}
              onClose={closeOpenAlertModelDeleteModal}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default AllModelsComponent;
