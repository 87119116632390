import React, { useState, useRef } from "react";
//import QrReader from "react-qr-scanner";
//import { QrReader } from 'react-qr-reader';
import { apiWrapper } from '../../utils/apiwrapper';
import Html5QrcodePlugin from "../temp/VideoProductNewQrReader";
import { tokenDecoder } from '../../utils/tokenDecoder';
import { getUserSession } from '../../utils/localStorage.utils';
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Snackbar } from "@mui/material";
import {date, options, mexicanTime} from "../../utils/variousConst"
import { successSound, errorSound } from "../../utils/variousConst";

const QRScan = ({ id, article }) => {

  const session =tokenDecoder(getUserSession())
  const user = session.username
 
 
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("No result");
  const [scanning, setScanning] = useState(false);
  const qrRef = useRef(null);

  const [articles, setArticles] = useState([]);

 


  const handleScan = async (data) => {
    const dataerr = JSON.stringify(data, null, 2)
    setResult(data);
    if (data ) {
      setMessage(`Reading....`);
    //  setScanning(false); // Stop scanning when an article is scanned
      onScanDetected()
      try {
        // Extract the data from the QR code
        const { code,
           provider, 
           //value, 
           //kg, 
           masterkg, 
           id, 
          // country
           } = extractDataFromQRCode(data);
       // console.log('extracting the QR code data:', data.text); // Log the QR code data 
    //  console.log('Article ID:', { id }); // Log the extracted ID
    const response2 = await apiWrapper(`almacen/${id}`, "GET");
const value = "";
const kg = ""; 
const country = response2.country;
        // Calculate the updated value
        const updatedValue = Number(value) + 1;
        const updatedKg = Number(kg) + Number(masterkg);
  
        //console.log('Article ID:',id );
  
        // Call the API to patch the updated value to the database
        const response =   await apiWrapper(`almacen/${id}/increment`, 'PATCH', { value: updatedValue, kg: updatedKg, 
          employee: {user,
            mexicanTime: new Date().toLocaleString("en-US", options),
             value: updatedValue, kg: updatedKg} });
  
        //console.log('QR code data submitted to the database:', data.text);
        const idscanned = id;
       // console.log(response)
      if (response.message) {
        if (response.message === "Article not Found. Not Updated") {
          //console.error(error);
          errorSound.play();
          setMessage(
            <Snackbar
            open={true}
            autoHideDuration={4000}
            onClose={() => setMessage('No result')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            <Alert className="flex justify-center" severity="error">
               Ocurrió un error durante el escaneo. ¿Es este un artículo QR?
              <div className="flex flex-col items-center">
                <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                  <pre className="whitespace-pre-wrap">
                    {JSON.stringify(data, null, 2)}
                  </pre>
                </div>
              </div>
              Inténtalo de nuevo
            </Alert>
            </Snackbar>
          );
         // setTimeout(() => {
        //    setMessage("No result");
        //  }, 5000);
          return;
        }
      }
      else  {
        const fetchArticles = async () => {
          try {
            const response = await apiWrapper("almacen", "GET");
            const filteredArticles = response.filter(
              (article) => article._id === idscanned
            );
           setArticles(filteredArticles);
        // console.log(filteredArticles)
            //setArticles(response)

            const itemsupdated = filteredArticles[0]?.value;
            const kglastupdated = filteredArticles[0]?.kg;
           // console.log(kglastupdated)
         //  console.log(itemsupdated)
        
           return {
            itemsupdated: Number.isInteger(itemsupdated) ? itemsupdated : Math.round(itemsupdated * 100) / 100,
            kglastupdated: Number.isInteger(kglastupdated) ? kglastupdated : Math.round(kglastupdated * 100) / 100 
          };
            
          } catch (error) {
            console.error("Error fetching articles:", error); 
            return { itemsupdated: null, kglastupdated: null };
          }
        };
           // Play the success sound
           successSound.play();
           const { itemsupdated, kglastupdated } = await fetchArticles();
   //console.log(itemsupdated, kglastupdated);
   
           setMessage(
            <Snackbar
            open={true}
            autoHideDuration={4000}
            onClose={() => setMessage('No result')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
             <Alert className="flex justify-center" severity="success">
               <AlertTitle>¡Escaneo completado!</AlertTitle>
               <div className="flex flex-col items-center">
                 <div>
                   <strong>Codigo:</strong> {code}
                 </div>
                 <div>
                   <strong>Provedor:</strong> {provider}
                 </div>
                 <div>
                   <strong>Pais:</strong> {country}
                 </div>
                 <div className="text-2xl">
                   <strong>Ud Añadida:</strong> {updatedValue}
                 </div>
                 <div className="text-2xl">
                   <strong>kg Añadido:</strong> {updatedKg}
                 </div>
                
                 <div className="text-1xl text-center">
                   <strong>Ud totales:</strong> {itemsupdated}
                 </div>
                 <div className="text-1xl text-center">
                   <strong>Kg Totales:</strong> {kglastupdated}
                 </div>
               </div>
             </Alert>
             </Snackbar>
           );
   
           // Clear the message after 1 second
        //   setTimeout(() => {
          //   setMessage("No result");
        //   }, 5000);
         }} 
        catch (error) {
        console.error(error);
        errorSound.play();
        setMessage(
          <Snackbar
          open={true}
          autoHideDuration={4000}
          onClose={() => setMessage('No result')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="error">
            Ocurrió un error durante el escaneo. ¿Es este un artículo QR?
            <div className="flex flex-col items-center">
              <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                <pre className="whitespace-pre-wrap">
                {dataerr}
                </pre>
              </div>
            </div>
            Inténtalo de nuevo
          </Alert>
          </Snackbar>
        );
      //  setTimeout(() => {
      //    setMessage("No result");
      //  }, 5000);
        return;
      }
    } else {
      setMessage("No result");
    }
  };

       
  



  const extractDataFromQRCode = (data) => {
    // Parse the QR code data and extract the code, provider, value, kg, masterkg , and ID
    
    const { code, provider, value, kg, masterkg, id, country  } = JSON.parse(data);

    return { code, provider, value, kg, masterkg, id, country    };
 
    // Return some default values or null if parsing fails
    
  }


  const handleError = (err) => {
   // console.error(err);
  };

  let scanTimeout;
  
  const startScanning = () => {
    setScanning(true);
   // qrRef.current?.openImageDialog(); // Open the device camera
 //close camera if no scan done in 6 seconds
// setTimeout(() => {
//  setScanning(false);
//}, 5000);

  };

  const stopScanning = () => {
    setScanning(false);
    clearTimeout(scanTimeout);
  };

  const onScanDetected = () => {
    setScanning(false);
    clearTimeout(scanTimeout);
     // Set the scanning state back to true after 1 second
    
     scanTimeout = setTimeout(() => {
     setScanning(true);
   //  console.log("passo1riattivo")
     }, 3000);
     //scanTimeOutTwo = setTimeout(() => {
     //    console.log("passo2laspengodefi")
       //  setScanning(false);
        // }, 90000);
     };

  return (
    <div >
   {scanning ? (
       <Html5QrcodePlugin
       fps={10}
       qrbox={250}
       disableFlip={false}
       showTorchButtonIfSupported={true}
       qrCodeSuccessCallback={handleScan}
      // qrCodeErrorCallback={handleError}
   />
      ) : (
       
       <p className="text-center">Listo</p>
      )}
      {message !== "No result" && (
        <div className="flex justify-center items-center h-16 bg-gray-600 text-white">
          {message}
        </div>
      )}
      {scanning ? (
        <>
          <div className="flex flex-col justify-center text-white">
            <h1 className="flex  justify-center">
              {" "}
              ...Trabajando...{" "}
            </h1>

            <button
              className="flex justify-center  text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-red-700 focus:ring-teal-800 mt-4"
              onClick={stopScanning}
            >
              Apagar la cámara
            </button>
          </div>{" "}
        </>
      ) : (
        <QrCodeScannerOutlinedIcon
          sx={{ fontSize: 60 }}
          onClick={startScanning}
        />
      )}
    </div>
  );
};

export default QRScan;