import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
import EditOrderModalPreforma from "../editmodal/EditOrderPreformaModal";
import React from "react";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {CSVLink} from "react-csv";
import{ preformaheaders} from "../../utils/variousConst.js"
import SearchBar from "../searchbar/searchbar";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditAlertPhasePreformaDeliveryModal from "../editmodal/EditPhasePreformaDelivery.js";
import EditAlertDeleteOrderModal from "../editmodal/EditAlertDeleteOrder";
import QRScanDeliveryFrontPreforma from "./VideoDeliveryFrontPreforma";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { socket } from "../../utils/variousConst.js";
import {date, options, mexicanTime, formatMexicanDate} from "../../utils/variousConst"


const DashboardDeliveryPreforma = ({ order }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  // console.log("ilruolo", role)
 
  //console.log("here the prop",scannedcompleted)
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const [loading, setLoading] = useState(true);

  const filterOrders = (orders) =>
  orders.filter((order) =>  !order.preformadeliverycompleted &&  order.preforma !== "");

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on scannedcompleted and stockcompleted
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);
  
    // Merge the filtered updated orders with existing orders in the state
    setOrders((prevOrders) => {
      const mergedOrders = filteredUpdatedOrders.map((filteredOrder) => {
        const existingOrder = prevOrders.find((order) => order._id === filteredOrder._id);
        return existingOrder ? existingOrder : filteredOrder;
      });
      return mergedOrders;
    });
  };
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      //console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error); 
        }
  };
  useEffect(() => {
    fetchOrders();
   
    // Listen for socket event to handle updates to orders
    socket.on('getAllOrders', (updatedOrderArray) => {
      setOrders(updatedOrderArray)
      handleUpdateOrders(updatedOrderArray);
    });
  
    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off('getAllOrders');
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredOrders([]); // Clear the filtered orders list
    } else {
      const filtered = orders.filter((order) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const lowerCaseOrderNr = (order.ordernr || '').toLowerCase();
        const lowerCaseCustomer = (order.customer || '').toLowerCase();
        const lowerCaseDescription = (order.finalproduct || '').toLowerCase();
        const lowerCaseDescriptionPreforma = (order.preforma || '').toLowerCase();
        return (
          lowerCaseOrderNr.includes(lowerCaseSearchQuery) ||
          lowerCaseCustomer.includes(lowerCaseSearchQuery) ||
          lowerCaseDescription.includes(lowerCaseSearchQuery) ||
          lowerCaseDescriptionPreforma.includes(lowerCaseSearchQuery)
        );
      });
      setFilteredOrders(filtered);
    }
  }, [searchQuery, orders]);


  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response);
      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };

  {/* 
  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      const filteredOrders = response.filter(
        (order) =>
          order.scannedcompleted === true &&
          order.stockcompleted === true &&
          order.preformadeliverycompleted === false
      );
      setOrders(filteredOrders);
      //setOrders(response)
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

*/}



  const [editableItem, setEditableItem] = useState(null);
  const [isEditOrderModalOpen, setEditOrderModalOpen] = useState(false);

  const [alertOrder, setAlertOrder] = useState(null);
  const [isAlertOrderPhaseModalOpen, setIsAlertOrderPhaseModalOpen] = useState(false)

  const [alertDeleteOrder, setAlertDeleteOrder] = useState(null);
  const [isAlertOrderDeleteModalOpen, setIsAlertOrderDeleteModalOpen] = useState(false)

  const [colorUpdated, setColorUpdated] = useState(false)

  const openEditOrderModal = (order, index) => {
    setEditableItem({ order, index });
    setEditOrderModalOpen(true);
  };

  const closeEditOrderModal = () => {
    setEditableItem(null);
    setEditOrderModalOpen(false);
  };

  const openAlertOrderPhaseModal = (order, index) =>{
    setAlertOrder({order, index});
    setIsAlertOrderPhaseModalOpen(true)
  }

  const closeOpenAlertOrderPhaseModal =() =>{
    setAlertOrder(null);
    setIsAlertOrderPhaseModalOpen(false)
  }

  const openAlertOrderDeleteModal = (order, index) =>{
    setAlertDeleteOrder({order, index});
    setIsAlertOrderDeleteModalOpen(true)
  }

  const closeOpenAlertOrderDeleteModal =() =>{
    setAlertDeleteOrder(null);
    setIsAlertOrderDeleteModalOpen(false)
  }



  const handleSave = (index, updatedOrder) => {
    // Make a PATCH request to update the order on the server
    const updateOrderOnServer = async () => {
      try {
        await apiWrapper(`order/${updatedOrder._id}`, "PATCH", updatedOrder);
        // If the PATCH request is successful, update the order in the state
        const updatedOrders = [...orders];
        updatedOrders[index] = updatedOrder;
        setOrders(updatedOrders);

// Update the filtered search as well
const updatedFilteredOrders = [...filteredOrders];
updatedFilteredOrders[index] = updatedOrders;
setFilteredOrders(updatedFilteredOrders);

        //const response = await apiWrapper('order', 'GET');
        //setOrders(response);
        fetchOrders();
        closeEditOrderModal();
      } catch (error) {
        console.error("Error updating order:", error);
        // Handle error updating order
      }
    };

    updateOrderOnServer();
  };

  const handleDelete = async (orderId) => {
    try {
      // Make a DELETE request to the API to delete the order with the given ID
      await apiWrapper(`order/${orderId}`, "DELETE");

      // Update the orders state by removing the deleted order from the array
      setOrders(orders.filter((order) => order._id !== orderId));
    } catch (error) {
      // Handle error
      console.error("Error deleting order:", error);
    }
  };



  const updateOrder = async (orderId, order) => {
    //console.log(orderId)
    //console.log(order)
    try {
      const updatedOrder = await apiWrapper(`order/${orderId}`, "PATCH", {
        preformadeliverycompleted: true,
      //  preformapackdelivery :order.preformapackitemsstock,
      //   preformadelivery: order.preformastock,
      });

      await apiWrapper(`order/${updatedOrder._id}/defectedsign`, "PATCH", {
        employeestory: { user,
          mexicanTime: new Date().toLocaleString("en-US", options),
          preformadeliverycompleted: true,
       //   preformapackdelivery :order.preformapackitemsstock,
       //   preformadelivery: order.preformastock,
        },
      });


      const updatedOrders = orders.map((order) => {
        if (order._id === orderId) {
          return { ...order, preformadeliverycompleted: true };
        }
        return order;
      });
      setOrders(updatedOrders);
      fetchOrders();

    // console.log("Order updated:", updatedOrder);
    } catch (error) {
      console.error("Error updating delivery:", error);
    }
  };

  useEffect(() => {
    socket.on('updateDeliveryIncrementPreforma',(updatedOrder) => {
     // console.log(updatedOrder)
      setColorUpdated(updatedOrder._id); // Set the ID of the updated order
    // console.log("heeel",updatedOrder._id)
  
      setTimeout(() => {
        setColorUpdated(null); // Clear the ID after 3 seconds
      }, 3000);
  
      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order._id === updatedOrder._id) {
            return { ...order, preformadelivery: updatedOrder.preformadelivery, preformapackdelivery:updatedOrder.preformapackdelivery };
          }
          return order;
             });
  
        return updatedOrders;
      });
    });
  
    return () => {
      socket.off('updateDeliveryIncrementPreforma');
       };
  }, []);


  //delete
  useEffect(() => {
    socket.on('deleteOrder', (deletedOrderId) => {
      setOrders((prevOrders) => {
        return prevOrders.filter((order) => order._id !== deletedOrderId);
      });
    });
  
    return () => {
      socket.off('deleteOrder');
    };
  }, []);

 //phase completed
 useEffect(() => {
  socket.on('updateOrderDefectedSign', (updatedItemsDefected) => {
    setOrders((prevOrders) => {
      // Filter out the orders with preformadeliverycompleted set to true
      const filteredOrders = prevOrders.filter((order) => !order.preformadeliverycompleted);
      return filteredOrders;
    });
  });

  return () => {
    socket.off('updateOrderDefectedSign');
  };
}, []);

useEffect(() => {
  socket.on('updateOrder', (updatedEditOrder) => {
   //console.log("cheedita",updatedEditOrder.data.customer)
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order._id === updatedEditOrder._id) {
          //console.log("check",updatedEditOrder.customer)
          
          return { ...order, ...updatedEditOrder.data };
          
        }
        return order;
      });
      //console.log("check2", updatedOrders)
      return updatedOrders;
    });
  });

  return () => {
    socket.off('updateOrder');
  };
}, []);

 //spinning loading
 useEffect(() => {
  if (orders.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [orders]);

  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
    <div className="flex flex-col  px-6 py-8   ">
      <div className="bg-gray-800 rounded-lg ">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
        <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
             PREFORMA Entregas
            </h1>
           
            <div className="flex items-center justify-evenly">
             {/*  
            <div className="bg-transparent text-white hover:text-emerald-600 flex justify-center" > <QRScanDelivery id={123}   /></div>
            */ }
              {role !== "vendedor" && (
                      <React.Fragment>
            <div className="bg-transparent text-yellow-400 hover:text-yellow-600 flex justify-center"  > <QRScanDeliveryFrontPreforma id={456}   /></div>
            </React.Fragment>)}
                </div>  
             <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>
          </div>

          <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Pedido nr
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Fecha
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Cliente
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Modelo Preforma
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas para hacer
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Paquetes de
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paquetes Totales
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas Producidos
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paq Preformas Producidos
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas en existencia
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paq Preformas en existencia
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Preformas en entrega
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Paq en entrega
                  </th>

                

                  {(role === "supervisor" || role === "admin" || role ==="almacenista") && (
                  
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Entrega completada
                  </th>
                 
                  )}
                   {role === "admin" && (
                    <React.Fragment>
                  <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Edit
                  </th>
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Delete
                  </th>
                  <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Export

                  </th>
</React.Fragment>)}
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order, index) => (
                  <tr
                    className={
                      index % 2 === 0 ? "bg-amber-400 text-center" : "bg-amber-300 text-center" 
                    }
                    key={`${order.customer}-${order.ordernr}`}
                  >
                    <td>{order.ordernr.toString()}</td>
                    <td>{formatMexicanDate(order.createdAt)}</td>
                    <td>{order.customer.toString()}</td>
                    <td className="px-6 py-4 text-2xl font-medium whitespace-nowrap">
                        {order.preforma}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformapack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformatotpack}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.preformascanned)
                          ? order.preformascanned
                          : Math.round(order.preformascanned * 100) / 100}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {order.preformapackscanned}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformastock- order.preformadelivery}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.preformadelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.preformapackdelivery}
                      </td>
                      {(role === "supervisor" || role === "admin" || role ==="almacenista") && (
                      <td className=" whitespace-nowrap    ">
                      <button
                          onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={` focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                            order.preformadeliverycompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformadeliverycompleted ?  (
                                <CheckBoxIcon className="text-green-500" />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )}
                        </button>
                      </td>)}
                      {role === "admin" && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModal(order, index)}
                         
                        />
                         
                      </td>

                      <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                       <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
      </td>
                      </React.Fragment>)}

                  </tr>
                ))}
              </tbody>
              <tbody className=" divide-y divide-gray-200 text-center text-white">
              {orders.length === 0 || filteredOrders.length >0 ? (
  <tr className="text-center">
  <td colSpan="8">
{loading ? (
  <p className="animate-spin text-white text-center">&#9696;</p>
) : (
  <p className="text-center  font-medium text-white">No hay datos en DB</p>
)}
</td>
  </tr>
  ) : (orders
                  .slice()
                  .reverse()
                  .map((order, index) => (
                    <tr className={
                      index % 2 === 0 ? " bg-sky-900" : " bg-sky-800"
                      }
                      key={order._id}
                    >
                      <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">
                        {order.ordernr}
                      </td>
                      <td>{formatMexicanDate(order.createdAt)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.customer}
                      </td>
                      <td className="px-6 py-4 text-2xl font-medium whitespace-nowrap">
                        {order.preforma}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.itemstodo)
                          ? order.itemstodo
                          : Math.round(order.itemstodo * 100) / 100}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformapack}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.preformatotpack}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {Number.isInteger(order.preformascanned)
                          ? order.preformascanned
                          : Math.round(order.preformascanned * 100) / 100}
                      </td>
                      <td className="px-6 py-4  font-medium whitespace-nowrap">
                        {order.preformapackscanned}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {order.preformastock- order.preformadelivery}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      {Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}
                      </td>

                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.preformadelivery}
                      </td>
                      <td className={`px-6 py-4 text-2xl font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                        {order.preformapackdelivery}
                      </td>
                      {(role === "supervisor" || role === "admin" || role ==="almacenista") && (
                      <td className=" whitespace-nowrap    ">
                      <button
                          onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center ${
                            order.preformadeliverycompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-transparent-600 hover:bg-emerald-400 focus:ring-emerald-800 mt-4"
                          }`}
                        >
                          {order.preformadeliverycompleted ?  (
                                <CheckBoxIcon className="text-green-500" />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )}
                        </button>
                      </td>)}
                      {role === "admin" && (
                      <React.Fragment>
                      <td className=" whitespace-nowrap    ">
                      <EditIcon
                          onClick={() => openEditOrderModal(order, index)}
                         
                        />
                         
                      </td>

                      <td className="px-2  whitespace-nowrap m-18  ">
                      <DeleteOutlineIcon
                              onClick={() => openAlertOrderDeleteModal(order)}
                            ></DeleteOutlineIcon>
                      </td>
                      <td className="px-2  whitespace-nowrap m-18  ">
                      <button onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
      </td>
                      </React.Fragment>)}
                    </tr>
                  )))}
              </tbody>
            </table>
          </div>

          <div className="grid grid-cols-1 gap-4 md:hidden">
          <div className="flex justify-center items-center" >
              <SearchBar key="searchbar" onSearch={handleSearch} />
            </div>

            <div>
              {filteredOrders.map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "border rounded-lg bg-amber-400 px-2 py-2.5"
                      : " border rounded-lg bg-amber-300 px-2 py-2.5"
                  }
                  key={`${order.customer}-${order.ordernr}`}
                >
                  <div className="bg-transparent">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-900 text-sm" > Fecha:</span>
                    <span className="ml-auto font-bold text-xl">{formatMexicanDate(order.createdAt)}</span>
                    </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2 text-sm font-medium ">
                  <span className="text-gray-900"> Modelo Preforma</span> 
                  <span>{order.preforma}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Preformas para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes de: </span>
                  <span className="text-sm">  {order.preformapack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Producidos:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900"> Paquetes Producidos:{" "}</span>
                  <span className="text-sm">  {order.preformapackscanned}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">   Existencia:{" "}</span>
                  <span className="text-sm">  {order.preformastock- order.preformadelivery}
                  <span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-900">Paquetes en Existencia:{" "}</span>
                  <span className="text-sm">  
                  {Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}
                     <span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900"> Preformas en entrega: </span>
                  <span className="text-lg">{order.preformadelivery}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-900 text-sm"> Paquetes en entrega:{" "} </span>
                  <span>  {Number.isInteger(order.preformapackdelivery)
                      ? order.preformapackdelivery
                      : Math.round(order.preformapackdelivery * 100) / 100}<span className="text-gray-900 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex flex-col justify-between ">
                  {(role === "supervisor" || role === "admin" || role ==="almacenista") && (
                  <button
                           onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                            order.preformadeliverycompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                          }`}
                        >
                          {order.preformadeliverycompleted ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Completada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Entrega completada</span>
                            </>
                          )}
                        </button>
                        )}
                        {role === "admin" && (
                    <React.Fragment>
                  <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit
                        </button>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>
                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
                        </React.Fragment>)}
                  </div>
                </div>
              ))}
            </div>

            {orders.length === 0 || filteredOrders.length > 0 ? (
  loading ? (
    <div className="border rounded-lg bg-sky-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className="animate-spin text-white">&#9696;</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-sky-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)
  ) : (
    orders
              .slice()
              .reverse()
              .map((order, index) => (
                <div
                  className={
                    index % 2 === 0
                    ? "border rounded-lg bg-sky-900 text-white"
                    : " border rounded-lg bg-sky-800 text-white"
                  }
                  key={order._id}
                >
                   <div className="bg-transparent  ">
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Pedido nr:</span>
                    <span className="ml-auto font-bold text-2xl">{order.ordernr}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between space-x-2 mb-2 ml-3 mr-3 mt-2 text-sm font-medium break-all ">
                    <span  className="text-gray-300 text-sm" > Fecha:</span>
                    <span className="ml-auto font-bold text-xl">{formatMexicanDate(order.createdAt)}</span>
                    </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Cliente:</span> 
                  <span>{order.customer}</span> 
                  </div>
                  <div className="flex items-center flex-col space-x-2 ml-3 mr-3 mb-2  text-sm font-medium  ">
                  <span className="text-gray-300"> Modelo Preforma:</span> 
                  <span>{order.preforma}</span> 
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Preformas para hacer:</span>
                  <span >  {Number.isInteger(order.itemstodo)
                      ? order.itemstodo
                      : Math.round(order.itemstodo * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes de: </span>
                  <span className="text-sm">  {order.preformapack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes Totales: </span>
                  <span className="text-sm">  {order.preformatotpack}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Producidos:{" "}</span>
                  <span className="text-sm">  {Number.isInteger(order.preformascanned)
                      ? order.preformascanned
                      : Math.round(order.preformascanned * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300"> Paquetes Producidos:{" "}</span>
                  <span className="text-sm">  {order.preformapackscanned}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">   Existencia:{" "}</span>
                  <span className="text-sm">  
                      {order.preformastock- order.preformadelivery}
                      <span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex items-center justify-between space-x-2 ml-3 mr-3  text-sm font-medium break-all ">
                  <span className="text-gray-300">Paquetes en Existencia:{" "}</span>
                  <span className="text-sm">  
                  {Number.isInteger(order.preformapackitemsstock)
  ? order.preformapackitemsstock - order.preformapackdelivery
  : Math.round((order.preformapackitemsstock - order.preformapackdelivery) * 100) / 100}
                     <span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2  ml-3 mr-3  text-sm font-medium whitespace-nowrap ${colorUpdated  === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300"> Preformas en entrega: </span>
                  <span className="text-lg">{order.preformadelivery}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className={`flex items-center justify-between space-x-2 font-medium text-lg ml-3 mr-3 ${colorUpdated === order._id ? 'text-green-500' : ''}`}>
                  <span className="text-gray-300 text-sm"> Paquetes en entrega:{" "} </span>
                  <span>  {Number.isInteger(order.preformapackdelivery)
                      ? order.preformapackdelivery
                      : Math.round(order.preformapackdelivery
                        * 100) / 100}<span className="text-gray-300 text-sm font-medium ml-2">ud</span></span>
                  </div>
                  <div className="flex flex-col justify-between ">
                  {(role === "supervisor" || role === "admin" || role ==="almacenista") && (
                  <button
                           onClick={()=>openAlertOrderPhaseModal(order, index) }
                          className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-0.5 text-center mt-4 ml-3 mr-3 ${
                            order.preformadeliverycompleted
                              ? "bg-emerald-500 hover:bg-emerald-700 focus:ring-emerald-800 mt-4"
                              : "bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 mt-1 mb-2"
                          }`}
                        >
                          {order.preformadeliverycompleted ? (
                            <>
                              <CheckBoxIcon className="text-green-500" />
                              <span>Completada</span>
                            </>
                          ) : (
                            <>
                              <CheckBoxOutlineBlankIcon />
                              <span>Entrega completada</span>
                            </>
                          )}
                        </button>
                        )}
                        {role === "admin" && (
                    <React.Fragment>
                  <button
                          onClick={() => openEditOrderModal(order, index)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-2"
                        >
                          <EditIcon /> Edit
                        </button>
                        <button
                          onClick={() => openAlertOrderDeleteModal(order)}
                          className=" text-white focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-1 py-0.5 text-center
                        bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2"
                        >
                          <DeleteOutlineIcon /> Del
                        </button>
                        <button
                         onClick={fetchOrdersCSV}
                         className=" text-white focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-1 py-0.5 text-center
                       bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-4"
                       >
                      {isDownloadReady ? ( 
                      <CSVLink data={[order, ...order.employeestory, ]} headers={preformaheaders} filename={`PREFORMAorder_${order._id}_${order.ordernr}_${order.customer}.csv`}>
        <FileDownloadOutlinedIcon /> <span>Listo</span>
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /> Download</span>
      )}
     </button>
                        </React.Fragment>)}
                  </div>
                </div>
              )))}
          </div>
        </div>
      </div>
      {isEditOrderModalOpen && (
        <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">

        <div className="mt-10 flex items-center  justify-center">
            <EditOrderModalPreforma
              editableItem={editableItem}
              onSave={handleSave}
              onClose={closeEditOrderModal}
              user = {user}
              role = {role}
            />
          </div>
        </div>
      )}
         {isAlertOrderPhaseModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertPhasePreformaDeliveryModal
              alertOrder={alertOrder}
              onSave={updateOrder}
              onClose={closeOpenAlertOrderPhaseModal}
            />
          </div>
        </div>
      )}
      {isAlertOrderDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteOrderModal
              alertDeleteOrder={alertDeleteOrder}
              onSave={handleDelete}
              onClose={closeOpenAlertOrderDeleteModal}
            />
          </div>
        </div>
      )}
      ;
    </section>
  );
};

export default DashboardDeliveryPreforma;
