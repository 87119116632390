import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect } from "react";
//import EditLoginRecordModal from "../editmodal/EditLoginRecordModal";
import { format } from "date-fns";
import { formatMexicanSoloTime, formatMexicanDate } from "../../utils/variousConst.js";
import SearchBarLogReg from "../searchbar/searchbarLoginReg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
//import EditIcon from "@mui/icons-material/Edit";
//import EditAlertDeleteLoginRecordModal from "../editmodal/EditAlertDeleteLoginRecordModal";
import { socket } from "../../utils/variousConst.js";


const LogRegDashboardComponent = () => {
  const [loginrecords, setLoginRecords] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);

  //const [editableItem, setEditableItem] = useState(null);
  //const [isEditModalOpen, setEditModalOpen] = useState(false);

  //const [alertDeleteLoginRecord, setAlertDeleteLoginRecord] = useState(null);
  //const [isAlertLoginRecordDeleteModalOpen, setIsAlertLoginRecordDeleteModalOpen] = useState(false);

    const [loading, setLoading] = useState(true);
{/*  
  const openAlertLoginRecordDeleteModal = (loginrecord, index) => {
    setAlertDeleteLoginRecord({ loginrecord, index });
    setIsAlertLoginRecordDeleteModalOpen(true);
  };

  const closeOpenAlertLoginRecordDeleteModal = () => {
    setAlertDeleteLoginRecord(null);
    setIsAlertLoginRecordDeleteModalOpen(false);
  };

  const openEditModal = (loginrecord, index) => {
    setEditableItem({ loginrecord, index });
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditableItem(null);
    setEditModalOpen(false);
  };

  const handleSave = (index, editLoginRecord) => {
    // Make a PATCH request to update the loginrecord on the server
    const updateArticleOnServer = async () => {
      try {
        await apiWrapper(`loginrecord/${editLoginRecord._id}`, "PATCH", editLoginRecord);
        // If the PATCH request is successful, update the loginrecord in the state
        const editLoginRecords = [...loginrecords];
        editLoginRecords[index] = editLoginRecord;
        setLoginRecords(editLoginRecords);
        const response = await apiWrapper("loginrecord", "GET");
        setLoginRecords(response);

        // Update the filtered roles as well
        const updatedFilteredEmails = [...filteredEmails];
        updatedFilteredEmails[index] = editLoginRecord;
        setFilteredEmails(updatedFilteredEmails);

        closeEditModal();
      } catch (error) {
        console.error("Error updating loginrecord:", error);
        // Handle error updating loginrecord
      }
    };

    updateArticleOnServer();
  };
  */}

  useEffect(() => {
    const fetchLoginRecords = async () => {
      try {
        const response = await apiWrapper("loginrecord", "GET");
        setLoginRecords(response);
       // setFilteredEmails([...response])
      //  console.log([...response])
      } catch (error) {
        // Handle error
        console.error("Error fetching loginrecords:", error);
      }
    };

    fetchLoginRecords();
  }, []);

  const dateComparisonFunction = (recordDate, 
    filterDate
    ) => {
    
       // Parse the dates to compare
    const recordDateTime = new Date(recordDate);
    const filterDateTime = new Date(filterDate);
  
    // Compare the dates
    return recordDateTime >= filterDateTime;
  };
 
  

  const filterLoginRecordsByDate = (loginrecords, selectedDate) => {
    const selectedDateStart = new Date(selectedDate);
    selectedDateStart.setHours(0, 0, 0, 0);
    const selectedDateEnd = new Date(selectedDate);
    selectedDateEnd.setHours(23, 59, 59, 999);
  
    return loginrecords.filter((record) => {
      const recordDate = new Date(record.loginTime);
      return recordDate >= selectedDateStart && recordDate <= selectedDateEnd;
    });
  };

  const filterLoginRecordsByGeolocationCorrect = (loginrecords, isGeolocationCorrect) => {
    const filteredRecords = loginrecords.filter((record) => {
     //   console.log("Record isGeolocationCorrect:", record.isGeolocationCorrect); 
     //   console.log("Filter isGeolocationCorrect:", isGeolocationCorrect);
        return record.isGeolocationCorrect === isGeolocationCorrect;
    });
  //  console.log("Filtered Records:", filteredRecords); 
    return filteredRecords;      
};

  const handleSearch = (searchQuery, filterDate, filterIsGeolocationCorrect ) => {
    if (searchQuery === "" && !filterDate && filterIsGeolocationCorrect === null
     ) {
      setFilteredEmails([]); // Clear the filtered emails list
    } else {
      let filtered = loginrecords.slice(); // Make a copy of the original loginrecords array
  
      // Apply the search query filter
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const matchesSearchQuery = (record) => {
        const lowerCaseUsername = record.username?.toLowerCase() || "";
        const lowerCaseEmail = record.email?.toLowerCase() || "";
        return (
          lowerCaseUsername.includes(lowerCaseSearchQuery) ||
          lowerCaseEmail.includes(lowerCaseSearchQuery)
        );
      };
      filtered = filtered.filter(matchesSearchQuery);
  
      // Apply the date filter if filterDate is provided
      if (filterDate) {
        const selectedDateStart = new Date(filterDate);
        selectedDateStart.setHours(0, 0, 0, 0); // Start of the selected date
        const selectedDateEnd = new Date(filterDate);
        selectedDateEnd.setHours(23, 59, 59, 999); // End of the selected date
        filtered = filterLoginRecordsByDate(filtered, selectedDateStart, selectedDateEnd);
      }
  
      // Apply the geolocation correctness filter if filterGeolocationCorrect is provided
      if (filterIsGeolocationCorrect !== null) {
       filtered = filterLoginRecordsByGeolocationCorrect(filtered, filterIsGeolocationCorrect);
      }
  
      // Set the filtered records
      setFilteredEmails(filtered);
    }
  };



  const handleDelete = async (loginrecordId) => {
  //  console.log(loginrecordId)
    try {
      // Make a DELETE request to the API to delete the loginrecord with the given ID
      await apiWrapper(`loginrecord/${loginrecordId}`, "DELETE");

      // Update the loginrecords state by removing the deleted loginrecord from the array
      setLoginRecords(loginrecords.filter((loginrecord) => loginrecord._id !== loginrecordId));
    } catch (error) {
      // Handle error
      console.error("Error deleting loginrecord:", error);
    }
  };



  const [isPasswordExpanded, setIsPasswordExpanded] = useState(false);

  //const [searchQuery, setSearchQuery] = useState('');


  
  

  useEffect(() => {
    const handleCreateLoginRecord = (newLoginRecord) => {
      setLoginRecords((prevLoginRecords) => [...prevLoginRecords, newLoginRecord]);
    };
    // Add the 'createLoginRecord' event listener
    socket.on("register", handleCreateLoginRecord);
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("register", handleCreateLoginRecord);
    };
  }, []);

  useEffect(() => {
    socket.on("deleteLoginRecord", (deletedLoginRecordId) => {
      setLoginRecords((prevLoginRecords) => {
        return prevLoginRecords.filter((loginrecord) => loginrecord._id !== deletedLoginRecordId);
      });
    });

    return () => {
      socket.off("deleteLoginRecord");
    };
  }, []);

  {/*  
  useEffect(() => {
    socket.on("editLoginRecord", (updatedEditLoginRecord) => {
      // console.log("cheedita",updatedEditLoginRecord.data.loginrecordname)
      setLoginRecords((prevLoginRecords) => {
        const updatedLoginRecords = prevLoginRecords.map((loginrecord) => {
          if (loginrecord._id === updatedEditLoginRecord._id) {
            //console.log("check",updatedEditLoginRecord.customer)

            return { ...loginrecord, ...updatedEditLoginRecord.data };
          }
          return loginrecord;
        });
        //console.log("check2", updatedLoginRecords)
        return updatedLoginRecords;
      });
    });

    return () => {
      socket.off("editLoginRecord");
    };
  }, []);
*/}

 //spinning loading
  
 useEffect(() => {
  if (loginrecords.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [loginrecords]);


  return (
    <section className="bg-gray-900  sticky top-10 z-40    ">
   <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
          <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
              Panel de Registro de Login
            </h1>
            <div className="flex   justify-around  rounded-lg ml-5 mr-5 mt-3 ">
     <SearchBarLogReg key="searchbar" onSearch={handleSearch} />
    </div>


   </div>
   <div className=" overflow-auto rounded-lg hidden md:block">
            <table className="min-w-full divide-y divide-gray-200 table-auto ">
              <thead className="bg-gray-300 ">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Usuario
                  </th>

                 
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Correo 
                  </th>

                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Fecha
                  </th>
                 
                  <th className="text-center text-xs font-medium text-gray-500 uppercase tracking-wider   ">
                    Horario
                  </th>

                  
                  <th className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Geolocation ok?
                    <br></br>
                    <button
                      className="px-6 py-3 text-center text-xs font-thin text-gray-500  -tracking-tight"
                      onClick={() => setIsPasswordExpanded(!isPasswordExpanded)}
                    >
                      {isPasswordExpanded ? "Ocultar Locaction" : "Mostrar Location"  }
                    </button>
                  </th>
                  {isPasswordExpanded && (
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider ">
                      latitude & longitude
                    </th>
                  )}
                <th className="text-center text-xs font-medium text-gray-500 uppercase tracking-wider   ">
                    Del
                  </th>
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
  
  {/* search values */}
              <tbody>
                {filteredEmails.map((loginrecord, index) => (
                  <tr
                  className={
                    index % 2 === 0 ? "bg-amber-200 text-center" : "bg-amber-300 text-center" 
                  }
                    key={`${loginrecord.loginTime}`}
                  >
                    <td>{loginrecord.username}</td>
                    
                    <td>{loginrecord.email}</td>
                   
                    <td>{formatMexicanDate((loginrecord.loginTime))}</td>
                    <td>{formatMexicanSoloTime((loginrecord.loginTime))}</td>
                    
                    <td>{loginrecord.isGeolocationCorrect ? "True" : "False"}</td>
                   
                    {isPasswordExpanded && (
                    <td className="whitespace-nowrap">
                    <a
                      href={`https://www.google.com/maps?q=${loginrecord.latitude},${loginrecord.longitude}`}
                      target="_blank"
                    >
                      {loginrecord.latitude}, {loginrecord.longitude}
                    </a>
                  </td>
                  
                    )}
                     <td>
                        <button
                        onClick={()=> handleDelete(loginrecord._id)}
                        >
                        <DeleteOutlineIcon />
                        </button>
                        </td>      
                      
                    
                  </tr>
                ))}
              </tbody>

              <tbody className=" divide-y divide-gray-200 text-center">
                {loginrecords.length === 0 || filteredEmails.length > 0 ? (
                 <tr className="text-center">
                 <td colSpan="8">
               {loading ? (
                 <p className="animate-spin text-white text-center">&#9696;</p>
               ) : (
                 <p className="text-center  font-medium text-white">No hay datos en DB</p>
               )}
               </td>
                 </tr>
                ) : (
                  loginrecords
                    .slice()
                    .reverse()
                    .map((loginrecord, index) => (
                      <tr
                        className={
                          index % 2 === 0 ? "bg-gray-400" : "bg-gray-300"
                        }
                        key={loginrecord.loginTime}
                      >
                        <td>{loginrecord.username}</td>
                    
                    <td>{loginrecord.email}</td>
                    <td>{formatMexicanDate((loginrecord.loginTime))}</td>
                    <td>{formatMexicanSoloTime((loginrecord.loginTime))}</td>
                    
                    
                    <td>{loginrecord.isGeolocationCorrect ? "True" : "False"}</td>
                   
                    {isPasswordExpanded && (
                  <td className="whitespace-nowrap">
                  <a
                    href={`https://www.google.com/maps?q=${loginrecord.latitude},${loginrecord.longitude}`}
                    target="_blank"
                  >
                    {loginrecord.latitude}, {loginrecord.longitude}
                  </a>
                </td>
                    )}
                      
                      <td>
                        <button
                        onClick={()=> handleDelete(loginrecord._id)}
                        >
                        <DeleteOutlineIcon />
                        </button>
                        </td>      
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
          <div id='containerresponsive' className="grid grid-cols-1 gap-4 md:hidden">
   <div id="responsive search">

   {filteredEmails.map((loginrecord, index) => (
                  <div
                  className={
                    index % 2 === 0 ? "bg-amber-200 text-center" : "bg-amber-300 text-center" 
                  }
                    key={`${loginrecord.loginTime}`}
   >
<div className="  border rounded-lg  space-x-2 text-sm">
                    <div className="  text-sm font-medium break-all   ">
                      Usuario:{loginrecord.username}
                    </div> 
                    <div className="  text-sm font-medium break-all   ">
                      Correo:{loginrecord.email}
                    </div> 
                    <div className="  text-sm font-medium break-all   ">
                    <span className="mr=20">  Fecha:{formatMexicanDate((loginrecord.loginTime))}<span>  ||  </span>   </span>
                    <span className="ml=20">Date: {formatMexicanSoloTime((loginrecord.loginTime))}</span>
                    </div>
                    <div>Geolocalization correcta? {loginrecord.isGeolocationCorrect ? "True" : "False"}</div>
                    <button
                      className="px-6 py-1 text-center text-xs font-thin text-gray-500  -tracking-tight"
                      onClick={() => setIsPasswordExpanded(!isPasswordExpanded)}
                    >
                      {isPasswordExpanded ? "Ocultar Locaction" : "Mostrar Location"  }
                    </button>
                    {isPasswordExpanded && (
                  <div className="whitespace-nowrap">
                  <a
                    href={`https://www.google.com/maps?q=${loginrecord.latitude},${loginrecord.longitude}`}
                    target="_blank"
                  >
                    {loginrecord.latitude}, {loginrecord.longitude}
                  </a>
                </div>
                
                    )}
                     <div className="flex justify-end mb-2">
                        <button
                        onClick={()=> handleDelete(loginrecord._id)}
                        >
                        <DeleteOutlineIcon />
                        </button>
                        </div>      
                    </div>

   </div>
   
   ))}


   </div>
{loginrecords.length === 0 || filteredEmails.length > 0 ? (
                 <div className="text-center">
                 <div >
               {loading ? (
                 <p className="animate-spin text-white text-center">&#9696;</p>
               ) : (
                 <p className="text-center  font-medium text-white">No hay datos en DB</p>
               )}
               </div>
                 </div>
                ) : (
                  loginrecords
                    .slice()
                    .reverse()
                    .map((loginrecord, index) => (
                      <div
                        className={
                          index % 2 === 0 ? "bg-gray-400 text-center" : "bg-gray-300 text-center"
                        }
                        key={loginrecord.loginTime}
                      >
                       <div className="  border rounded-lg  space-x-2 text-sm">
                        <div className="  text-sm font-medium break-all px-6 py-1  ">
                            {loginrecord.username}
                            </div>
                            <div className="  text-sm font-medium break-all   ">
                      Correo:{loginrecord.email}
                    </div> 
                    <div className="  text-sm font-medium break-all   ">
                    <span className="mr=20">  Fecha:{formatMexicanDate((loginrecord.loginTime))}<span>  ||  </span>   </span>
                    <span className="ml=20">Date: {formatMexicanSoloTime((loginrecord.loginTime))}</span>
                    </div>
                    <div>Geolocalization correcta? {loginrecord.isGeolocationCorrect ? "True" : "False"}</div>
                    <button
                      className="px-6 py-1 text-center text-xs font-thin text-gray-800  -tracking-tight"
                      onClick={() => setIsPasswordExpanded(!isPasswordExpanded)}
                    >
                      {isPasswordExpanded ? "Ocultar Locaction" : "Mostrar Location"  }
                    </button>
                    {isPasswordExpanded && (
                  <div className="whitespace-nowrap px-6 py-1">
                  <a
                    href={`https://www.google.com/maps?q=${loginrecord.latitude},${loginrecord.longitude}`}
                    target="_blank"
                  >
                    {loginrecord.latitude}, {loginrecord.longitude}
                  </a>
                </div>
                
                    )}
                     <div className="flex justify-end mb-2">
                        <button
                        onClick={()=> handleDelete(loginrecord._id)}
                        >
                        <DeleteOutlineIcon />
                        </button>
                        </div>      
                        </div>
                      </div>
                      )))}


   </div>
   
   </div></div>
   </section>
  );

};

export default LogRegDashboardComponent;
