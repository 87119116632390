import { useState, useEffect } from "react";
import { socket } from "../utils/variousConst";
//import { getSocket } from "../utils/variousConst";
import React from "react";
import { getUserSession} from "../utils/localStorage.utils";
import { tokenDecoder } from "../utils/tokenDecoder";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const Home = () => {


  const [session, setSession] = useState(getUserSession());
  const userIdToken = tokenDecoder(session);
  const role = userIdToken.role
  

  const isAdmin = role === "admin" || role === "supervisor" ;
  const almacenista = role ==="almacenista";
  const user = role === "user"
  const vendedor = role === 'vendedor'
  

  const navigate = useNavigate();

  const navigateToElAlmacenPage = () => {
    navigate("/dashboardproducts");
  };

  const navigateToDashboardOrder = () => {
    navigate("/dashboardorders");
  };

  const navigateToDashboardStock = () => {
    navigate("/dashboardstock");
  };

  const navigateToDashboardAutoStock = () => {
    navigate("/dashboardautostock");
  };

  const navigateToDashboardStockPreforma = () => {
    navigate("/dashboardstockpreforma");
  };

  const navigateToDashboardDelivery = () => {
    navigate("/dashboarddelivery");
  };

  const navigateToDashboardDeliveryPreforma = () => {
    navigate("/dashboarddeliverypreforma");
  };

  const navigateToDashboardPreformaOrder = () => {
    navigate("/dashboardpreformaorder");
  };

  const navigateToGuia = () => {
    navigate("/guia");
  };

  const navigateToNewOrder = () => {
    navigate("/order");
  };

  const navigateToDashboardCompleted = () => {
    navigate("/dashboardcompleted");
  };

  const navigateToDashboardQuality = () => {
    navigate("/dashboardquality");
  };
  
    return (
      <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                  Página de inicio
                  </h1> 
                 
                  </div>
                  {!user && !vendedor && ( 
                  <div
        className="text-gray-600 absolute top-30 left-30"
        >
        <InfoIcon 
          sx={{ fontSize: 20,    
          }}
             onClick= {
              navigateToGuia} />
            </div> 
               )}
               {vendedor && (
                <React.Fragment>
                  <button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
            onClick={navigateToNewOrder}
           
          >
            + Pedido
          </button>

          <button type="dashboardcompleted"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToDashboardCompleted}
                        >Pedidos Completados</button>

                </React.Fragment>
               )}
                  {!user && !vendedor && ( 
                    <React.Fragment>
               <button type="almacen"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToElAlmacenPage}
                        ><span className="mr-5">Almacén de Resina </span><WarehouseIcon /></button>
                        
                      
                        </React.Fragment>
                        )}

                  <button type="preformaoder"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardPreformaOrder}
                        > <span className="mr-5"> PREFORMA Pedidos</span><ReceiptIcon /> </button>

<button type="stock"
                        className="w-full text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
                         onClick={navigateToDashboardStockPreforma}
                         ><span className="mr-2">PREFORMA Existencia</span>  <WidgetsSharpIcon /></button>
             
             <button type="delivery"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardDeliveryPreforma}
                        > <span className="mr-5"> PREFORMA Entregas</span><LocalShippingIcon /> </button>
  
                  <button type="autostock"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardAutoStock}
                        ><span className="mr-7">GARRAFONES STOCK</span>  <AccountBalanceIcon /></button>


               <button type="order"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardOrder}
                        ><span className="mr-5">GARRAFONES Pedidos </span>< ReceiptIcon /></button>
              
                <>
               <button type="stock"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardStock}
                        ><span className="mr-2">GARRAFONES Existencia</span>  <WidgetsSharpIcon /></button>
               
                      <button type="delivery"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardDelivery}
                        > <span className="mr-5">GARRAFONES Entregas</span><LocalShippingIcon /> </button>

              <button type="calidad"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToDashboardQuality}
                        > <span className="mr-10">Calidad / Def Prod</span><NewReleasesIcon /> </button>
                  </>
                        
                 
              </div>
          </div>
      
    </section>
    );
  };
  
  export default Home;