import React from 'react'
import { socket } from "../../utils/variousConst.js";
//import "./Logout.css"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout';
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";

function Logout(props) {
  const session = tokenDecoder(getUserSession());
  const userId = session?.userId;
 // console.log("from navbar",userId)
    const navigate = useNavigate()

    const handleClick = ()=>{
      if (userId) {
       
        socket.emit('disconnected-users', userId);
      }
        window.localStorage.removeItem("user-session")
        navigate("/")
    }


  return (
    <div className="bg-gray-900 text-white">
        <button    className='logout bg-gray-900 text-white' onClick={() => handleClick()}  >{props.content} <LogoutIcon className='logout-icon'/></button>
    </div>
  )
}

export default Logout