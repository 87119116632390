import { useState, useEffect } from "react";
import { apiWrapper } from "../../utils/apiwrapper";
import { Alert } from "@mui/material";

const ReplaceQualityToOrderModal = ({
  editableItem,
  onSave,
  onClose,
  mexicanTime,
}) => {
  const { order, customer, index } = editableItem;
  const [editedOrderNr, setEditedOrderNr] = useState(order.ordernr || "");
  const [editedCustomer, setEditedCustomer] = useState(order.customer || "");
  const [codeOrderModel, setCodeOrderModel] = useState(order.codemodel || "");
  const [isButtonActive, setIsButtonActive] = useState(false);


  const [editedId, setEditedId] = useState(order._id);

  const [editedpackitemsstock, setEditedpackitemsstock] = useState(""); // paq
  const [editedstock, setEditedstock] = useState(order.itemsstock || ""); //ud 
  const [updateKG, setUpdateKG] = useState(0);

  const autostock = `CENTRO DE INVESTIGACION DE INGENIERIA Y ADM,, CAMINO REAL #26, SANTA MARIA IXTULCO. TLAXCALA, TLAXCALA CP. 90105 , TLAXCALA, TLAX `;

 


  const handlepackitemsstockChange = (h) => {
    const selectedOrder = orders.find((order) => order._id === selectedStock);

    const newValue = Number(h.target.value);
    const stockAvailable =
      selectedOrder &&
      selectedOrder.packitemsstock - selectedOrder.packdelivery;
    setEditedpackitemsstock(newValue);
    if (newValue > stockAvailable) {
      setMessage(
        <Alert
          className="flex justify-center"
          severity="error"
          style={{ backgroundColor: "red", color: "white" }}
        >
          Los paquetes a remplazar no pueden ser más que las unidades en stock.
          Tenemos nr<span className="text-xl ml-2">{stockAvailable}</span> paq
          disponibles y intentaste sacar
          <span className="text-xl ml-2">{newValue}</span> .
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
        setEditedpackitemsstock(0);
        setEditedstock(0);
     //   setUpdateKG(0)
      }, 5000);
    } else if (!Number.isInteger(newValue)) {
      setMessage(
        <Alert className="flex justify-center" severity="warning">
          Los paquetes tienen que ser enteros. Por favor, ajusta la cantidad
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
        setEditedpackitemsstock(0);
        setEditedstock(0);
     //   setUpdateKG(0)
      }, 3000);
    }
    else if(newValue > order.calidadpackdefected){
      
      const defected = order.calidadpackdefected
setMessage(
        <Alert
          className="flex justify-center"
          severity="error"
          style={{ backgroundColor: "red", color: "white" }}
        >
          Los paquetes a remplazar no pueden ser más que los paquetes defectuosos.
          Tenemos nr<span className="text-xl ml-2">{defected}</span> paq
          defectuosos y intentaste sacar
          <span className="text-xl ml-2">{newValue}</span> .
        </Alert>
      );
      setTimeout(() => {
        setMessage("");
        setEditedpackitemsstock(0);
        setEditedstock(0);
      //  setUpdateKG(0)
      }, 5000);
    }
  };


  const handlestockChange = (i) => {
    setEditedstock(i.target.value);
  };

  const [message, setMessage] = useState("");
  const [selectedStock, setSelectedStock] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  //console.log("here the prop",preformastockcompleted)
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const filterOrders = (orders) =>
    orders.filter(
      (order) =>
        order.customer === autostock && order.codemodel === codeOrderModel
    );

  const handleUpdateOrders = (updatedOrderArray) => {
    // Filter the updated orders based on the customer
    const filteredUpdatedOrders = filterOrders(updatedOrderArray);
    // console.log(filteredUpdatedOrders,"ddd")
    // Replace the existing orders in the state with the filtered orders
    setOrders(filteredUpdatedOrders);
  };

  const fetchOrders = async () => {
    try {
      const response = await apiWrapper("order", "GET");
      handleUpdateOrders(response); // Call the handleUpdateOrders function with the response data
      // console.log("fd", response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    // Fetch orders when the component mounts
    fetchOrders();
  }, []);

  useEffect(() => {
    // Find the selected order based on selectedStock
    const foundOrder = orders.find((order) => order._id === selectedStock);

    setSelectedOrder(foundOrder);
  }, [selectedStock, orders]);

 
  const handleSave = (user) => {
    
    onSave(
   
      {
      
        stockorderid: selectedOrder._id,
        stockordernr: selectedOrder.ordernr,
        stockorderdestinocliente: order.customer, 
        stockorderdestinoid: order._id, 
        stockorderdestinonr:order.ordernr,
        packdelivery:editedpackitemsstock,
        itemsdelivery:updateUD,
        calidaditemdefectedkg:updateKG

      },
    
    
    );
    onClose();
  };
  const updateUD = (editedpackitemsstock || 0) * (selectedOrder ? selectedOrder.pack : 0);
 
  useEffect(()=> {
    const uUpdateKG =updateUD  * (selectedOrder ? selectedOrder.finalproductkg : 0);
   
    setUpdateKG(uUpdateKG);
  },[editedpackitemsstock])

  useEffect(() => {
    const checkConditions = () => {
      return selectedOrder && 
        editedpackitemsstock > 0 && 
        editedpackitemsstock <= (selectedOrder.packitemsstock - selectedOrder.packdelivery) &&
        editedpackitemsstock <= order.calidadpackdefected;
    };
  
    setIsButtonActive(checkConditions());
  }, [selectedOrder, editedpackitemsstock, order]);

  return (
    <div
      className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto"
      style={{ position: "absolute", top: "180px", zIndex: "9999" }}
    >
      {/* Modal content */}
      <div className="sm:mt-10 rounded-lg shadow bg-gray-800  ">
      <label className="block mb-2 mt-10 text-sm font-medium text-center  text-gray-400 uppercase">
           Calidad{" "}
          </label>
        <div>
          <label className="block mb-2 mt-10  text-sm font-medium  text-gray-400 uppercase">
            pedido nr{" "}
          </label>
          <input
            className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white
                            "
            type="text"
            value={editedOrderNr}
            readOnly
          />
        </div>

        <div>
          <label className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase">
            Cliente
          </label>
          <textarea
            className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white "
            type="text"
            value={editedCustomer} // Display the customer value here
            readOnly
          />
        </div>
        <div>
          <label className="block mb-2 mt-4 text-sm font-medium text-gray-400 uppercase">
           Paquetes Descartados para Calidad
          </label>
          <input
            className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white "
            type="text"
            value={order.calidadpackdefected} // Display the customer value here
            readOnly
          />
        </div>

        <div className="mt-4">
          <select
            className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white "
            value={selectedStock}
            onChange={(e) => {
              setSelectedStock(e.target.value);
            }}
          >
            <option value="">Seleccionar Remplazo</option> {/* Add this line */}
            {filterOrders(orders)
              .filter((order) => order.itemsstock - order.itemsdelivery > 0)
              .map((order) => (
                <option key={order._id} value={order._id}>
                  Stock nr: {order.ordernr} - {order.finalproduct}
                </option>
              ))}
          </select>

          <div className="sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700  placeholder-gray-400 text-white mt-4">
            <p>
              Artículos en Stock:{" "}
              {selectedOrder
                ? selectedOrder.itemsstock - selectedOrder.itemsdelivery
                : ""}
            </p>
            <p>
              Paquetes en Stock:{" "}
              {selectedOrder
                ? selectedOrder.packitemsstock - selectedOrder.packdelivery
                : ""}
            </p>
            <p>Ud por Paquete: {selectedOrder ? selectedOrder.pack : ""}</p>
            <p>Modelo: {selectedOrder ? selectedOrder.codemodel : ""}</p>
            <p>
              Tipo Modelo:{" "}
              {selectedOrder ? selectedOrder.tipomodeldescription : ""}
            </p>
            <div className="text-xs text-gray-400 mt-2">
              <p> Pedido: {selectedOrder ? selectedOrder.ordernr : ""}</p>

              <p>ID: {selectedOrder ? selectedOrder._id : ""}</p>
            </div>
          </div>
        </div>

        {selectedOrder && (
          <>
            <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">
              Paq Remplazo
            </label>
            <input
              className="
                                    sm:text-sm rounded-lg  block w-full p-2.5
                                    bg-gray-700 
                                     placeholder-gray-400 text-white
                                      "
              type="number"
              value={editedpackitemsstock}
              onChange={handlepackitemsstockChange}
            />
            <div>{message}</div>

            <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">
              Ud de Remplazo
            </label>
            <input
              className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white"
              type="number"
              value={updateUD}
              readOnly
            />
             <label className="block mb-2  mt-4 text-sm font-medium  text-gray-400 uppercase">
              Kg tot de Remplazo
            </label>
            <input
              className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 
                           placeholder-gray-400 text-white"
              type="number"
              value={updateKG}
              readOnly
            />
          </>
        )}
      </div>

      <div className="mb-6">
      <button
  className={`w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center 
    ${isButtonActive ? "bg-teal-600 hover:bg-teal-700 focus:ring-teal-800" : "bg-gray-400 cursor-not-allowed"} mt-8`}
  onClick={isButtonActive ? handleSave : null}
  disabled={!isButtonActive}
>
  {isButtonActive ? "Guardar" : "No Stock seleccionado"}
</button>
        <button
          className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ReplaceQualityToOrderModal;
