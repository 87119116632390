import Chat from "../components/chat/Chat";

import React from 'react'

const ChatPage = () => {
  return (
    <Chat />
  )
}

export default ChatPage