import { apiWrapperobjects } from "../../utils/apiwrapperobject";
import { apiWrapper } from "../../utils/apiwrapper";
import { useState, useEffect, useRef } from "react"; // Import useRef
import EditAlertDeleteCustomersCollectionModal from "../editmodal/EditAlertDeleteCustomerCollection";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const UploadCustomers = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const fileInput = useRef(); // Create a ref for the file input
  
  const [alertDeleteModel, setAlertDeleteModel] = useState(null);
  const [isAlertModelDeleteModalOpen, setIsAlertModelDeleteModalOpen] = useState(false)
  
  const openAlertModelDeleteModal = (customers, index) =>{
    
    // console.log("from the func",customers)
     setAlertDeleteModel({customers, index});
     setIsAlertModelDeleteModalOpen(true)
   }
  const closeOpenAlertModelDeleteModal =() =>{
    setAlertDeleteModel(null);
    setIsAlertModelDeleteModalOpen(false)
  }

  const deleteCollection = async () => {
    try {
      const response = await apiWrapperobjects("customers", "DELETE");
     console.log("Response status:", response);
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };


  const submitForm = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await apiWrapperobjects("customers", "POST", formData);
      setFile(null); // Clear the file input
      fileInput.current.value = ''; // Clear the file input's value
    // Set a success message
    setMessage('Actualizando Documento en la Base de Datos, espera...');

    // Clear the message after 4 seconds
    setTimeout(() => {
      setMessage(null);
    }, 8000);
    } catch (err) {
      if (err && err.message)  {
        setMessage(`Error uploading file: ${err.message}`);
      } else {
        setMessage('Error uploading file');
       
      }
      setFile(null);
      fileInput.current.value = ''; // Clear the file input's value
   
      setTimeout(() => {
        setMessage(null);
      }, 4000);
    }
  }

  return (
    <div className="bg-gray-800 text-white-300 ">
  <form onSubmit={submitForm}>
  <div className="flex justify-center">
    <input 
      id="file-upload"
      type="file" 
      accept=".csv" 
      onChange={(e) => setFile(e.target.files[0])} 
      required 
      style={{ display: 'none' }} // Hide the default file input
      ref={fileInput} // Attach the ref to the file input
    />
    <label htmlFor="file-upload" className="rounded-lg p-2 bg-pink-600 hover:bg-pink-800 text-white cursor-pointer">
      {file ? file.name : 'Add CSV'} {/* Display the name of the selected file or 'Upload CSV' if no file is selected */}
    </label>
    
    {file && <button className="rounded-lg p-2 bg-pink-600 hover:bg-pink-800 text-white ml-4 mr-4 " type="submit">Upload CSV</button>}
    {file && <button type="button" onClick={() => { setFile(null); fileInput.current.value = ''; }} className="rounded-lg p-2 bg-blue-500 hover:bg-blue-800 text-white">Cancel</button>}
    {message && <p className="text-white mt-4 font-bold">{message}</p>}
    </div>
  </form>
  <div className="mt-5 mb-5 flex justify-center">
    <button 
    onClick={() => openAlertModelDeleteModal()}
      //onClick={deleteCollection}
      className="rounded-lg p-2 bg-gray-600 hover:bg-red-800 text-white flex items-center" type="submit"><DeleteForeverIcon />  TODA LA TABLA</button>
  </div>
  {isAlertModelDeleteModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-45">
      <div className="mt-10">
        <EditAlertDeleteCustomersCollectionModal
          alertDeleteModel={alertDeleteModel}
          onSave={deleteCollection}
          onClose={closeOpenAlertModelDeleteModal}
        />
      </div>
    </div>
  )}
</div>
  );
}

export default UploadCustomers;