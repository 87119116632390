import { apiWrapper } from '../../utils/apiwrapper';
import { useState, useEffect } from 'react';
import EditCustomerModal from '../editmodal/EditCustomerModal';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import SearchBar from '../searchbar/searchbar';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import EditAlertDeleteCustomersModal from "../editmodal/EditAlerDeleteCustomers";
import { socket } from "../../utils/variousConst.js";
import ViewModelModal from '../editmodal/ViewModelModal.js';
import {CSVLink} from "react-csv";
import{ listacustomersheaders} from "../../utils/variousConst.js"
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UploadCustomers from './UploadCustomers.js';
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";

const DashboardCustomers = ({ isDashboardActive, setIsDashboardActive }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const [editableItem, setEditableItem] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [viewItem, setViewItem] = useState(null);
  const [isViewModalOpen, setViewModalOpen] = useState(false);

  const [alertDeleteModel, setAlertDeleteModel] = useState(null);
  const [isAlertModelDeleteModalOpen, setIsAlertModelDeleteModalOpen] = useState(false)

  const [loading, setLoading] = useState(true);

  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("customers", "GET");
      setCustomers(response);
      
      //setOrders(response)
      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };


  const openAlertModelDeleteModal = (customers, index) =>{
    
   // console.log("from the func",customers)
    setAlertDeleteModel({customers, index});
    setIsAlertModelDeleteModalOpen(true)
  }

  const closeOpenAlertModelDeleteModal =() =>{
    setAlertDeleteModel(null);
    setIsAlertModelDeleteModalOpen(false)
  }

  const openEditModal = (customers,index) => {
    setEditableItem({customers, index});
    setEditModalOpen(true);
  };
  
  const closeEditModal = () => {
    setEditableItem(null);
    setEditModalOpen(false);
  };

  const openViewModal = (customers,index) => {
    setViewItem({customers, index});
    setViewModalOpen(true);
  };
  
  const closeViewModal = () => {
    setViewItem(null);
    setViewModalOpen(false);
  };

  const fetchCustomers = async () => {
    try {
      const response = await apiWrapper("customers", "GET");
      setCustomers(response);
    } catch (error) {
      // Handle error
      console.error("Error fetching customers:", error);
    }
  };
  useEffect(() => {

    fetchCustomers();
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
  
    if (searchQuery === '') {
      setFilteredCustomers([]); // Clear the filtered customers list
    } else {
    const filtered = customers.filter((customers) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const lowerCaseRazonSocial = customers.razonsocial.toLowerCase();
      const lowerCaseNombreComprador = customers.nombrecomprador.toLowerCase();
      const lowerCaseEstado = customers.estado.toLowerCase();
      const lowerCaseTelefono = customers.telefono.toLowerCase();
      const lowerCaseDireccion = customers.direccion.toLowerCase();
    //  const lowerCaseDescription = customers.description.toLowerCase();
      return (
        lowerCaseRazonSocial.includes(lowerCaseSearchQuery) ||
        lowerCaseNombreComprador.includes(lowerCaseSearchQuery) ||
        lowerCaseEstado.includes(lowerCaseSearchQuery) ||
        lowerCaseTelefono.includes(lowerCaseSearchQuery) ||
        lowerCaseDireccion.includes(lowerCaseSearchQuery)
        // ||
     //   lowerCaseDescription.includes(lowerCaseSearchQuery)

      );
    });
    setFilteredCustomers(filtered);
  };
}, [searchQuery, customers]); 



  const handleSave = (index, updatedModel) => {
    
  
    // Make a PATCH request to update the customers on the server
    const updatedModelOnServer = async () => {
      try {
        await apiWrapper(`customers/${updatedModel._id}`, 'PATCH', updatedModel);
        // If the PATCH request is successful, update the customers in the state
        const updatedCustomers = [...customers];
        updatedCustomers[index] = updatedModel;
        setCustomers(updatedCustomers);
        const response = await apiWrapper('customers', 'GET');
      setCustomers(response);

// Update the filtered search as well
const updatedFilteredCustomers = [...filteredCustomers];
updatedFilteredCustomers[index] = updatedCustomers;
setFilteredCustomers(updatedFilteredCustomers);


        closeEditModal();
      } catch (error) {
        console.error('Error updating customers:', error);
        // Handle error updating customers
      }
    };
  
    updatedModelOnServer();
  };



  const handleDelete = async ( customersId) => {
    //  console.log("delete", customersId)
   // const customersId = customers._id;
    try {
      // Make a DELETE request to the API to delete the customers with the given ID
      await apiWrapper(`customers/${customersId}`, "DELETE");

      // Update the customers state by removing the deleted customers from the array
      setCustomers(customers.filter((customer) => customer._id !== customersId));
    } catch (error) {
      // Handle error
      console.error("Error deleting customers:", error);
    }
  };

  const navigate = useNavigate();
  const navigateToNewProduct = () => {
    navigate("/customers");
  };


  useEffect(() => {
    const handleCreateModel = (newModel) => {
      fetchCustomers();
      setCustomers((prevCustomers) => [...prevCustomers, newModel]);
    };
  
    // Add the 'createModel' event listener
    socket.on('createCustomers', handleCreateModel);
  
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('createCustomers', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    socket.on('delCustomers', (deletedModelId) => {
      fetchCustomers();
      setCustomers((prevCustomers) => {
        return prevCustomers.filter((customers) => customers._id !== deletedModelId);
      });
    });
  
    return () => {
      socket.off('delCustomers');
    };
  }, []);

  useEffect(() => {
    socket.on('editCustomers', (updatedEditModel) => {
    // console.log("cheedita",updatedEditModel)
    fetchCustomers();
      setCustomers((prevCustomers) => {
        const updatedCustomers = prevCustomers.map((customers) => {
          if (customers._id === updatedEditModel._id) {
            //console.log("check",updatedEditModel.customer)
            
            return { ...customers, ...updatedEditModel.data };
            
          }
          return customers;
        });
      //  console.log("check2", updatedCustomers)
      
        return updatedCustomers;
      });
    });
  
    return () => {
      socket.off('editCustomers');
    };
  }, []);

  useEffect(() => {
    socket.on('deleteCMCollection', () => {
      fetchCustomers(); // Fetch the updated list from the server
      setCustomers([]); // Clear the local state
    });
  
    return () => {
      socket.off('deleteCMCollection');
    };
  }, []);

  useEffect(() => {
    socket.on('csvDB', (data) => {
      console.log('csvDB', data)

      setTimeout(fetchCustomers, 8000);
      
      //setCustomers([]); // Clear the local state
    });
  
    return () => {
      socket.off('csvDB');
    };
  }, []);
   //spinning loading
   {/* 
 useEffect(() => {
  if (customers.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [customers]);
*/ }

  return (
    
    <section className="bg-gray-900  sticky top-10 z-40    ">
       
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
          <button 
        className='text-white'
        onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                    <CSVLink data={customers} headers={listacustomersheaders} filename={`ListaCustomers_.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
        <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
          Panel de Clientes
        </h1>

      {role === "admin" && ( 
      
        <UploadCustomers />
        )}
        <div className='flex items-center justify-center'>
        <button
                       onClick={() => setIsDashboardActive(prevState => !prevState)}
                        className=" text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                         bg-pink-600 hover:bg-pink-700 focus:ring-pink-800 mt-8"
                      >
                        Añadir Cliente
                      </button>
                      </div>
                      <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
    <SearchBar key="searchbar" onSearch={handleSearch }  />
    
  
  </div>
      </div>
      <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
            <tr>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Razon Social
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Nombre Comprador
              </th>
              
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Estado
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Direccion
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Telefono
              </th>
             
             
              <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                Edit
              </th>
              <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                Delete
              </th>
             
              {/* Add more table headers as needed */}
            </tr>
        
          </thead>
          <tbody>
  {filteredCustomers.map((customers, index) => (
    <tr className={
      index % 2 === 0 ? "bg-amber-200 text-center" : "bg-amber-300 text-center" 
    }  key={`${customers.nombrecomprador}-${customers._id}`}>
      <td className='font-medium'>{customers.razonsocial}</td>
      {/*
      <td className='font-medium'><button onClick={() => openViewModal(customers, index)}>{customers.customersname}</button></td>
*/}
      
      <td className="px-6 py-4   whitespace-nowrap">{customers.nombrecomprador}</td>
                <td className="px-6 py-4   whitespace-nowrap">{customers.estado}</td>
                <td className="px-6 py-4   whitespace-nowrap">{customers.direccion}</td>
                <td className="px-6 py-4   whitespace-nowrap">{customers.telefono}</td>
              
                <td className=" whitespace-nowrap    ">
                <EditIcon
                     onClick={() => openEditModal(customers, index)}
                   />
                 </td>
                   <td className="px-2  whitespace-nowrap m-18  ">
                         <DeleteOutlineIcon
                           onClick={() => openAlertModelDeleteModal(customers)}
                         ></DeleteOutlineIcon>
                       </td>
    </tr>
  ))}
</tbody>

<tbody className=" divide-y divide-gray-200 text-center text-white ">
                {customers.length === 0 || filteredCustomers.length > 0 ? (
     <tr className="text-center">
     <td colSpan="8">
   {loading ? (
     <p className=" text-white text-center">-</p>
   ) : (
     <p className="text-center  font-medium text-white">No hay datos en DB</p>
   )}
   </td>
     </tr>
  ) : (customers.slice().reverse().map((customers, index) => (
              <tr className={index % 2 === 0 ? " bg-sky-900" : " bg-sky-800" } key={customers._id}>
                <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">{customers.razonsocial}</td>
                
              {/*
                <td className="px-6 py-4 whitespace-nowrap">   
                <button onClick={() => openViewModal(customers, index)}>{customers.customersname}</button>
                </td>
  */}
               
             
                <td className="px-6 py-4   whitespace-nowrap">{customers.nombrecomprador}</td>
                <td className="px-6 py-4   whitespace-nowrap">{customers.estado}</td>
                <td className="px-6 py-4   whitespace-nowrap">{customers.direccion}</td>
                <td className="px-6 py-4   whitespace-nowrap">{customers.telefono}</td>
                
                    <td className=" whitespace-nowrap    ">
                   
                      <EditIcon
                        onClick={() => openEditModal(customers, index)}
                       
                      />
                      
                      
                    </td>
                    
                    
                  
                    <td className="px-2  whitespace-nowrap m-18  ">
                            <DeleteOutlineIcon
                              onClick={() =>{
                            //    console.log(customers._id)
                                openAlertModelDeleteModal(customers)}
                            }
                            ></DeleteOutlineIcon>
                          </td>
                    
              </tr>
            )))}
          </tbody>
        </table>
      </div>
      
      <div className='grid grid-cols-1 gap-4 md:hidden '>
      <div className="flex justify-center items-center" >
    <SearchBar key="searchbar" onSearch={handleSearch }  />
    
  
    </div>
<div>

  {filteredCustomers.map((customers, index) => (
    <div  className={
      index % 2 === 0
        ? "border rounded-lg bg-amber-200 px-2 py-2.5"
        : " border rounded-lg bg-amber-300 px-2 py-2.5"
    } key={`${customers.nombrecomprador}-${customers._id}`}>
      <div className=' bcustomers rounded-lg flex items-center justify-center space-x-2 text-xl mb-2'>
      <div className="  flex items-center justify-between space-x-2 text-1xl font-bold   ">Razon Social:{customers.razonsocial}</div>
      </div>

     {/*
      <div className="text-xl font-medium break-all ml-3 mr-3    ">Modelo:<button onClick={() => openViewModal(customers, index)}>{customers.customersname}</button></div>
*/ }
      <div className="text-sm font-medium break-all  ml-3 mr-3 flex justify-between ">Nombre Comprador:<span className='mr-3'> {customers.nombrecomprador}</span></div>
      <div className="text-sm font-medium break-all  ml-3 mr-3 flex justify-between  ">Estado: <span  className='mr-3'> {customers.estado}</span></div>
      <div className="text-sm font-medium break-all ml-3 mr-3 flex justify-between  ">Direccion:<span  className='mr-3'> {customers.direccion}</span></div>
      <div className="text-sm font-medium break-all ml-3 mr-3 flex justify-between  ">Telefono:<span  className='mr-3'>  {customers.telefono}</span></div>
      <div className="flex justify-between mt-4 ml-3 mr-3 ">
      <button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-2 py-2.5 text-center
                          bg-gray-700 hover:bg-sky-900 focus:ring-gray-800 "
                          onClick={() => openEditModal(customers, index)}>
      <EditIcon /> Edit
      </button>
<button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-700 hover:bg-red-700 focus:ring-gray-800"
                          onClick={() => openAlertModelDeleteModal(customers)}>
      <DeleteOutlineIcon/> Delete
      </button>
      </div>
    
    </div>
    
  ))}
 
</div>

        
{customers.length === 0 || filteredCustomers.length > 0 ? (
  loading ? (
    <div className="border rounded-lg bg-sky-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className=" text-white">-</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-sky-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)
  ) : (
    customers.slice().reverse().map((customers, index) => (
    <div className={index % 2 === 0
      ? "border rounded-lg bg-sky-900 text-white"
      : " border rounded-lg bg-sky-800 text-white"} key={customers._id}>
      <div className="bg-transparent   rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium ">
                    <div className="  flex items-center justify-between space-x-2 text-1xl font-bold   ">Razon Social: {customers.razonsocial}</div>
        
      </div>
      
      {/*
        <div className="text-sm font-medium break-all ml-3 mr-3   ">Modelo:<button onClick={() => openViewModal(customers, index)}>{customers.customersname}</button></div>
    */}
    <div className="flex flex-col  justify-between ">

      <div className="text-sm font-medium break-all  ml-3 mr-3 flex justify-between ">Nombre Comprador:<span className='mr-3'> {customers.nombrecomprador}</span></div>
      <div className="text-sm font-medium break-all  ml-3 mr-3 flex justify-between  ">Estado: <span  className='mr-3'> {customers.estado}</span></div>
      <div className="text-sm font-medium break-all ml-3 mr-3 flex justify-between  ">Direccion:<span  className='mr-3'> {customers.direccion}</span></div>
      <div className="text-sm font-medium break-all ml-3 mr-3 flex justify-between  ">Telefono:<span  className='mr-3'>  {customers.telefono}</span></div>
    </div>
      <div className="flex flex-col justify-between ">
      <button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                          onClick={() => openEditModal(customers, index)}>
      <EditIcon /> Edit
      </button>
<button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3"
                          onClick={() => openAlertModelDeleteModal(customers)}>
      <DeleteOutlineIcon/> Delete
      </button>
      </div>
    </div>
  )))}
</div>
     
       
      
    </div>
  </div>
  {isEditModalOpen && (
      <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
      <div className="mt-10 flex items-center  justify-center">
  <EditCustomerModal editableItem={editableItem} onSave={handleSave} onClose={closeEditModal} />
  </div>
        </div>
)}
  {isViewModalOpen && (
      <div className="  fixed inset-0 flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
      <div className="mt-10 flex items-center  justify-center">
  <ViewModelModal viewItem={viewItem} onClose={closeViewModal} />
  </div>
        </div>
)}
{isAlertModelDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteCustomersModal
              alertDeleteModel={alertDeleteModel}
              onSave={handleDelete}
              onClose={closeOpenAlertModelDeleteModal}
            />
          </div>
        </div>
      )}
</section>
    
  )
}

export default DashboardCustomers