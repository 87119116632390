import { useState } from "react";

const RecycleModal = ({ KgEditableItem, onSave, onClose, mexicanTime }) => {
    const { article, index } = KgEditableItem;
    const [editedCode, setEditedCode] = useState(article.code);
    
    const [editedKg, setEditedKg] = useState(0);
    
    
    const handleKgChange = (h) =>{  
      setEditedKg(h.target.value);
    }

    
  
    const handleSave = () => {
      onSave(article.index, { ...article,  kg: editedKg, mexicanTime });
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }}>
      {/* Modal content */}
      <div className="   sm:mt-0 rounded-lg shadow bg-gray-800 text-cyan-400 uppercase font-bold text-center " > Resina Recuperable
       
      <div className="mt-20">
      <label className="block mb-2 text-sm font-medium  text-white uppercase ">Codigo</label>
          <input className="
                            text-center
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedCode} readOnly  />

      </div>

          <label className="block mb-2 text-sm font-medium  text-white mt-2 uppercase">Añadir (Kg)</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="number"  onChange={handleKgChange} />
         
         
        </div>
        <div className="">
        <button  className={`w-full text-white focus:ring-4 focus:outline-none
    font-medium rounded-lg text-sm px-5 py-2.5 text-center
    ${editedKg > 0 ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8' : 'bg-gray-400 cursor-not-allowed mt-8'}`}
                         onClick={() => editedKg  > 0  ? handleSave() : null}
                        disabled={editedKg <= 0 }
                      >
                        {editedKg > 0.1 ? "Save" : "Save"}
                      </button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  export default RecycleModal;