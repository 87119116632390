import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    onSearch(searchQuery);
    
  };

   const clearAll = () => {
    setSearchQuery(''); // Clear the search query state
    onSearch('');
     // Clear the search results by calling the onSearch function with an empty string
  };

  return (
    <>
    <div className='rounded-lg  bg-transparent ml-2'>
    <form onSubmit={handleSearchSubmit} className="flex items-center">
    <button className='rounded-lg rounded-r-none bg-white'>
        <SearchIcon  fontSize="small" type="submit" />
      </button>
      <input
        type="text"
        placeholder="Buscar..."
        value={searchQuery}
        onChange={handleSearchInputChange}
        className='rounded-lg rounded-l-none'
      />
      <button className=' bg-slate-400 font-semibold rounded-lg text-sm px-3 py-0.5 ml-3 ' type="submit">
       Buscar
      </button>
    </form>
    <div className='mt-2 '>
    {searchQuery && (
            <button className='text-slate-400 bg-slate-700 rounded-lg text-sm px-3 py-0  w-full' type="button" onClick={clearAll}>
              Clear
            </button>
          )}
      </div>
    </div>
    </>
  );
};

export default SearchBar;