import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

const EditAlertDeleteCustomersCollectionModal = ({ alertDeleteModel, onSave, onClose,  }) => {
    const [selectedModelId, setSelectedModelId] = useState(null);
   // const { customers  } = alertDeleteModel;
    

    const handleDelete = () => {
        onSave();
        onClose();
      };
   
      const handleNoClick = () => {
        setSelectedModelId(null);
        onClose(); // Close the modal
      };


  return (
    <Alert
    severity="warning"
    sx={{ backgroundColor: "rgba(255, 255, 255)" }}
  >
    <AlertTitle>¿Estás seguro?</AlertTitle>
    <div className="break-all">

    Esta acción no se puede deshacer
    Te recomendamos que hagas una copia de seguridad de tus datos antes de continuar.
    </div>
    <div className="flex justify-center mt-4">
    <Button onClick={handleNoClick}>No</Button>
      <Button onClick={handleDelete} variant="contained">
        Si
      </Button>
    </div>
  </Alert>
  )
}

export default EditAlertDeleteCustomersCollectionModal