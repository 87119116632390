import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

const EditAlertDeleteGarComp = ({ alertDeleteModel, onSave, onClose,  }) => {
    const [selectedModelId, setSelectedModelId] = useState(null);
    const { model  } = alertDeleteModel;


    const handleDelete = () => {
   
    // console.log('ready to delete:', model);
       onSave(model);
        onClose();
      };
   
      const handleNoClick = () => {
        setSelectedModelId(null);
        onClose(); // Close the modal
      };


  return (
    <Alert
    severity="warning"
    sx={{ backgroundColor: "rgba(255, 255, 255)" }}
  >
    <AlertTitle>¿Estás seguro?</AlertTitle>
    Esta acción no se puede deshacer
    <div className="flex justify-center mt-4">
    <Button onClick={handleNoClick}>No</Button>
      <Button onClick={handleDelete} variant="contained">
        Si
      </Button>
    </div>
  </Alert>
  )
}

export default EditAlertDeleteGarComp