import { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

const EditAlertPhaseOrderModalPreformaExistencia = ({ alertOrderPreformaExistencia, onSave, onClose  }) => {
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const { order  } = alertOrderPreformaExistencia;

    

    //console.log(order)

    const updateOrder = () => {
        onSave(order._id);
        onClose();
      };
   
      const handleNoClick = () => {
        setSelectedOrderId(null);
        onClose(); // Close the modal
      };


  return (
    <Alert
    severity="warning"
    sx={{ backgroundColor: "rgba(255, 255, 255)" }}
  >
    <AlertTitle>¿Hey, Estás seguro?</AlertTitle>
    Esta acción no se puede deshacer
    <div className="mt-4">Paquetes Pedido:{order.preformatotpack} </div>
    <div>Paquetes Producidos:{order.preformapackscanned} </div>
    <div>Quedan:<span className="font-bold">{order.preformatotpack - order.preformapackscanned}</span>  </div>
    <div className="flex justify-center mt-4">
    <Button onClick={handleNoClick}>No</Button>
      <Button onClick={updateOrder}
       variant="contained"
       disabled={order.preformatotpack - order.preformapackscanned !== 0}
       >
        Si
      </Button>
    </div>
  </Alert>
  )
}

export default EditAlertPhaseOrderModalPreformaExistencia