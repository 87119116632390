import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import HelpIcon from '@mui/icons-material/Help';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import { getUserSession} from "../utils/localStorage.utils";
import { tokenDecoder } from "../utils/tokenDecoder";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';


const GuiaPage = () => {

    const session = tokenDecoder(getUserSession());
    const user = session.username;
    const role = session.role;
    
  
    

  
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

  const navigate = useNavigate();
  
 

  const navigateToGuiaLoad = () => {
    navigate("/guiaload");
  };
 
  const navigateToGuiaConsulta = () => {
    navigate("/guiaconsulta");
  };
 
  const navigateToGuiaCamera = () => {
    navigate("/guiacamara");
  };
  

  const navigateToGuiaCrearPedido = () => {
    navigate("/guiacrearpedido");
  };

  const navigateToGuiaPedido = () => {
    navigate("/guiapedido");
  };

  const navigateToReviso = () => {
    navigate("/guiareviso");
  };

 
  const navigateToDashboardReview = () => {
    navigate("/dashboardreview");
  };

  const navigateToLogReg = () => {
    navigate("/guialogreg");
  };

  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                  Guía de instrucciones
                  </h1> 
                  </div>
                  <div>
                  {role === "admin" && ( 
                  <div id="dropdown" >
                  <button
        type="button"
        className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-8"
        onClick={toggleDropdown}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="options-menu"
      >   {isOpen ? (
        <>
          <KeyboardBackspaceIcon className="mr-3" />
          Volver al panel principal
        </>
      ) : (
        <>
          <AddCircleIcon className="mr-3" />
         Crear
        </>
      )}
      </button>
      {isOpen && (
        <div
          className="w-full flex flex-col justify-center  rounded-lg shadow-lg bg-transparent"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
         {/* Dropdown options here */}
        

         <button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToGuiaCrearPedido}
           
          >
            + Pedido
          </button>
          <button type="product"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696763129/guia/add_resina.mp4"
                        )
                      }
                        >+ Resina</button>

<button type="model"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696763876/guia/add_modelo.mp4"
                        )
                      }
                        >+ Modelo</button>

<button type="modelpreforma"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696763755/guia/add_preforma.mp4"
                        )
                      }
                        >+ Modelo Preforma</button>

<button type="newuser"className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696760873/guia/crear_usuario.mp4"
                        )
                      }
                        >+ Usuario</button>
          
        </div>
      )}
    </div>
                  )}

</div>
{!isOpen && (
<div>
{role === "admin" && ( 
    <>
<button type="dashboardreview"
                       className="w-full text-gray-800  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToReviso}
                        >Revisar Pedidos / Pedidos Completados / Excel</button>
    



<button type="dashboardmodels"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={() =>
                            window.open(
                              "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696794130/guia/panel_model.mp4"
                            )
                          }
                        >Modelos</button>

<button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
                       onClick={() =>
                        window.open(
                          "https://res.cloudinary.com/dytsb4ocs/video/upload/v1696794129/guia/panel_preforma.mp4"
                        )
                      }
           
          >
           Modelos Preforma
          </button>

<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={() =>
                            window.open("https://res.cloudinary.com/dytsb4ocs/video/upload/v1696760874/guia/panel_usuario.mp4")
                        }
                        >Usuarios</button>

<button type="logdashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToLogReg}
                        >Registro de Log</button>

                   </>     )}

                   {role !== "user" && ( 
                    <>
                    <React.Fragment>
               <button type="almacen"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick= {
                        navigateToGuiaLoad }
                        ><span className="mr-5">Almacén </span><WarehouseIcon /></button>
                        
                        <button type="almacen"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick= {
                        navigateToGuiaPedido }
                        ><span className="mr-5">Como manejar Pedidos</span>
                        <ReceiptIcon />
                        <span className="mr-2 ml-2"><WidgetsSharpIcon /></span>
                        <LocalShippingIcon />
                        </button>

                        <button type="consulta"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick= {
                        navigateToGuiaConsulta }
                        ><span className="mr-5">Modo Consulta</span>
                        <HelpIcon 
                        sx={{ fontSize: 20, }}/>
                        </button>

                        <button type="cameraconsulta"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                       onClick= {
                        navigateToGuiaCamera }
                        ><span className="mr-5">Configurar Cámara</span>
                        <CameraAltOutlinedIcon
                        sx={{ fontSize: 20, }}/>
                        </button>

                        </React.Fragment>
                       

          
              
                <>
              
                  </></> )}
                        </div>)}
       </div>
          </div>
      <div>
    

      </div>
    </section>
    </>
  )
}

export default GuiaPage