import React, { useState, useRef } from "react";
//import QrReader from "react-qr-scanner";
//import { QrReader } from 'react-qr-reader';
import { apiWrapper } from '../../utils/apiwrapper';
import Html5QrcodePlugin from "../temp/VideoProductNewQrReader";
import { tokenDecoder } from '../../utils/tokenDecoder';
import { getUserSession } from '../../utils/localStorage.utils';
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Snackbar } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import {date, options, mexicanTime} from "../../utils/variousConst"
import { successSound, errorSound } from "../../utils/variousConst";

const QRScanArticleConsult = ({ id, article }) => {

  const session =tokenDecoder(getUserSession())
  const user = session.username
 
 
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("No result");
  const [scanning, setScanning] = useState(true);
  const qrRef = useRef(null);

  const [articles, setArticles] = useState([]);




  const handleScan = async (data) => {
    const dataerr = JSON.stringify(data, null, 2)
    setResult(data);
    if (data ) {
      setMessage(`Reading....`);
      setScanning(false); // Stop scanning when an article is scanned
  
      try {
        // Extract the data from the QR code
        const { code,
           provider, 
           //value, 
           //kg, 
           masterkg, 
           id, 
          // country
           } = extractDataFromQRCode(data);
       // console.log('extracting the QR code data:', data.text); // Log the QR code data 
    //  console.log('Article ID:', { id }); // Log the extracted ID
    const response2 = await apiWrapper(`almacen/${id}`, "GET");
const value = response2.value;
const kg = response2.kg; 
const country = response2.country;
const color = response2.color;
const lot = response2.lot;
const grade = response2.grade      
  
    
        //console.log('QR code data submitted to the database:', data.text);
        const idscanned = id;
       // console.log(response)
   
        {
      
           // Play the success sound //////////////////////////////////////////////////////////////////////////
           successSound.play();
          
   //console.log(itemsupdated, kglastupdated);
   
           setMessage(
            <Snackbar
            open={true}
            autoHideDuration={14000}
            onClose={() => setMessage('No result')}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
             <Alert className="flex justify-center" severity="info">
               <AlertTitle>¡Escaneo de CONSULTA completado!</AlertTitle>
               <div className="flex flex-col items-center">
                {code ? ( 
                   <>
                 <div>
                   <strong>Codigo:</strong> {code}
                 </div>
                
                 <div>
                   <strong>Provedor:</strong> {provider}
                 </div>
                 <div>
                   <strong>Pais:</strong> {country}
                 </div>
                 <div>
                   <strong>Color:</strong> {color}
                 </div>
                 <div>
                   <strong>Lot:</strong> {lot}
                 </div>
                 <div>
                   <strong>Grade:</strong> {grade}
                 </div>
                 <div className="text-2xl">
                   <strong>Existencia Ud :</strong> {value}
                 </div>
                 <div className="text-2xl">
                   <strong>Existencia kg :</strong> {kg}
                 </div>
                </>
                 ): <div className="text-red-800 font-bold text-center">
                  <p>No es un RESINA QR. Compruéba  <HelpIcon
          sx={{ fontSize: 20,  }}  /> en la página de pedidos.</p>
                  </div>}
                
               </div>
             </Alert>
             </Snackbar>
           );
   
           // Clear the message after 1 second
        //   setTimeout(() => {
          //   setMessage("No result");
        //   }, 5000);
         }} 
        catch (error) {
        console.error(error);
        errorSound.play();
        setMessage(
          <Snackbar
          open={true}
          autoHideDuration={10000}
          onClose={() => setMessage('No result')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="error">
            Ocurrió un error durante el escaneo. ¿Es este un artículo QR?
            <div className="flex flex-col items-center">
              <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                <pre className="whitespace-pre-wrap">
                {dataerr}
                </pre>
              </div>
            </div>
            Inténtalo de nuevo
          </Alert>
          </Snackbar>
        );
      //  setTimeout(() => {
      //    setMessage("No result");
      //  }, 5000);
        return;
      }
    } else {
      setMessage("No result");
    }
  };

       
  



  const extractDataFromQRCode = (data) => {
    // Parse the QR code data and extract the code, provider, value, kg, masterkg , and ID
    
    const { code, provider, value, kg, masterkg, id, country  } = JSON.parse(data);

    return { code, provider, value, kg, masterkg, id, country    };
 
    // Return some default values or null if parsing fails
    
  }


  const handleError = (err) => {
   // console.error(err);
  };


  const startScanning = () => {
    setScanning(true);
   // qrRef.current?.openImageDialog(); // Open the device camera
 //close camera if no scan done in 6 seconds
// setTimeout(() => {
//  setScanning(false);
//}, 5000);

  };

  const stopScanning = () => {
    setScanning(false);
  };

  return (
    <div className="bg-gray-800 rounded-lg border-none ">
   {scanning ? (
       <Html5QrcodePlugin
       fps={10}
       qrbox={250}
       disableFlip={false}
       showTorchButtonIfSupported={true}
       qrCodeSuccessCallback={handleScan}
      // qrCodeErrorCallback={handleError}
   />
      ) : (
       
       null
      )}
      {message !== "No result" && (
        <div className="flex justify-center items-center h-16 bg-gray-600 text-white">
          {message}
        </div>
      )}
      {scanning ? (
        <>
          <div className="flex flex-col justify-center text-white">
            <h1 className="flex  justify-center items-center mt-2 text-xs font-semibold">
              {" "}
              Modo CONSULTA{" "}
            </h1>
{/* 
            <button
              className="flex justify-center  text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-red-700 focus:ring-gray-600 mt-4 mb-1"
              onClick={stopScanning}
            >
              Apagar la cámara de uso CONSULTA
            </button>
      */}
          </div>{" "}
        </>
      ) : (
        
        <HelpIcon
          sx={{ fontSize: 20,
          
        }}
          onClick={startScanning}
         
        />
        
      )}
    </div>
  );
};

export default QRScanArticleConsult;