import { useState, useEffect } from "react";

const EditGarCompModal = ({ editableItem, onSave, onClose }) => {
  const { model, index } = editableItem;

  const [editedSubFamilia, setEditedSubFamilia] = useState(model.subfamilia);
 // const [editedSubFamDescription, setEditedSubFamDescription] = useState(model.subfamdescription);

  const [editedFamResinaCode, setEditedFamResinaCode] = useState(model.codefamilia);
  const [editedResinaFamDescription, setEditedResinaFamDescription] = useState(model.familiadescription);
  const [editedResinaFamCodeAlfa, setEditedResinaFamCodeAlfa] = useState(model.codealfa);


  const [editedProvider, setEditedProvider] = useState(model.provider);
  const [editedCountry, setEditedCountry] = useState(model.country);

  const [editedListaCode, setEditedListaCode] = useState(model.listacode);
  const [editedListaDescription, setEditedListaDescription] = useState(
    model.listadescription
  );

  const [editedListaPrefCode, setEditedListaPrefCode] = useState(model.preflistacode);
  const [editedListaPrefDescription, setEditedListaPrefDescription] = useState(
    model.preflistadescription
  );


  const [editedModeloCode, setEditedModeloCode] = useState(model.modelocode);
  const [editedModeloDescription, setEditedModeloDescription] = useState(
    model.modelodescription
  );
  const [editedCapacitad, setEditedCapacitad] = useState(
    model?.capacitad || ""
  );

  const [editedAsaColorCode, setEditedAsaColorCode] = useState(
    model?.asacolorcode || ""
  );
  const [editedAsaColorDescription, setEditedAsaColorDescription] = useState(
    model?.asacolordescription || ""
  );
  const [editedMasterKg, setEditedMasterKg] = useState(model?.masterkg || "");

  const [editedRosca, setEditedRosca] = useState(model.rosca);

  const [editedColorCode, setEditedColorCode] = useState(model.colorcode);
  const [editedColorDescription, setEditedColorDescription] = useState(
    model.colordescription
  );

  const [editedPlacaCode, setEditedPlacaCode] = useState(model.placacode);
  const [editedPlacaCodeDescription, setEditedPlacaCodeDescription] = useState(
    model.placacodedescription
  );

  const [message, setMessage] = useState("");

  const handleSubFamChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedSubFamilia(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  //const handleSubFamDescriptionChange = (event) => {
   
 //     setEditedSubFamDescription(event.target.value);
  
 // };


  const handleFamResinaCodeAlfaChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedResinaFamCodeAlfa(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleFamResinaDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedResinaFamDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleFamResinaCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedFamResinaCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleCountryChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedCountry(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };
 
  const handleProviderChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedProvider(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleListaCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedListaCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleListaPrefCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedListaPrefCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };


  const handleModeloCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedModeloCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleAsaColorCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedAsaColorCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleRoscaChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedRosca(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handlePlacaCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedPlacaCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleColorCodeChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedColorCode(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleListaDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedListaDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleListaPrefDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedListaPrefDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };



  const handleModeloDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedModeloDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleAsaColorDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedAsaColorDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handlePlacaCodeDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedPlacaCodeDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleColorDescriptionChange = (event) => {
    if (event.target.value.length > 0) {
      setEditedColorDescription(event.target.value);
      setMessage("");
    } else {
      setMessage("el campo no puede estar vacio");
    }
  };

  const handleMasterKgChange = (event) => {
    setEditedMasterKg(event.target.value);
  };

  const handleCapacitadChange = (event) => {
    setEditedCapacitad(event.target.value);
    if (event.target.value.length < 1) {
      setMessage("el campo no puede estar vacio");
    } else {
      setMessage("");
    }
  };

  const handleSave = () => {
    onSave(model.index, {
      ...model,
      listacode: editedListaCode,
      listadescription: editedListaDescription,
      preflistacode: editedListaPrefCode,
      preflistadescription: editedListaPrefDescription,
      modelocode: editedModeloCode,
      modelodescription: editedModeloDescription,
      capacitad: editedCapacitad,
      asacolorcode: editedAsaColorCode,
      asacolordescription: editedAsaColorDescription,
      masterkg: editedMasterKg,
      rosca: editedRosca,
      colorcode: editedColorCode,
      colordescription: editedColorDescription,
      placacode: editedPlacaCode,
      placacodedescription: editedPlacaCodeDescription,

      provider: editedProvider,
      country: editedCountry,

      codefamilia: editedFamResinaCode,
      familiadescription: editedResinaFamDescription,
      codealfa: editedResinaFamCodeAlfa,

      subfamilia: editedSubFamilia,
    //  subfamdescription: editedSubFamDescription,
    });
    onClose();
  };

  return (
    <div
      className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto"
      style={{ position: "absolute", top: "180px", zIndex: "9999" }}
    >
      {/* Modal content */}
      <div className="   sm:mt-0 rounded-lg shadow bg-gray-800">
        {!editedProvider && (
          <label className="block  mb-2 text-sm font-medium  text-white">
            Código:
          </label>
        )}
        {editedProvider && (
          <label className="block  mb-2 text-sm font-medium  text-white">
            Proveedor:
          </label>
        )}
        <input
          className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"
          type="text"
          minLength={1}
          required
          value={
            editedListaCode ||
            editedListaPrefCode ||
            editedModeloCode ||
            editedAsaColorCode ||
            editedRosca ||
            editedPlacaCode ||
            editedColorCode ||
            editedProvider ||
            editedFamResinaCode ||
            editedSubFamilia 
          }
          onChange={(event) => {
            if (editedListaCode) {
              handleListaCodeChange(event);
            } else if  (editedListaPrefCode) {
              handleListaPrefCodeChange(event);
            } else if (editedModeloCode) {
              handleModeloCodeChange(event);
            } else if (editedAsaColorCode) {
              handleAsaColorCodeChange(event);
            } else if (editedRosca) {
              handleRoscaChange(event);
            } else if (editedPlacaCode) {
              handlePlacaCodeChange(event);
            } else if (editedColorCode) {
              handleColorCodeChange(event);
            } else if (editedProvider) {
              handleProviderChange(event);
            } else if (editedFamResinaCode) {
              handleFamResinaCodeChange(event);
            } else if (editedSubFamilia) {
              handleSubFamChange(event);
            }

          }}
        />
        {message && <p className="text-red-500 text-base">{message}</p>}
        {!editedRosca && !editedSubFamilia && (
          <>
           { !editedCountry && ( 
           <label className="block mt-2 mb-2 text-sm font-medium  text-white">
              Modelo:
            </label>
)}
       { editedCountry && ( 
           <label className="block mt-2 mb-2 text-sm font-medium  text-white">
              País:
            </label>
)}
            <textarea
              className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"
              type="text"
              minLength={1}
              value={
                editedListaDescription ||
                editedListaPrefDescription ||
                editedModeloDescription ||
                editedAsaColorDescription ||
                editedPlacaCodeDescription ||
                editedColorDescription ||
                editedCountry ||
                editedResinaFamDescription
           //     || editedSubFamDescription
              }
              onChange={(e) => {
                if (editedListaDescription) {
                  handleListaDescriptionChange(e);
                } else if(editedListaPrefDescription) {
                  handleListaPrefDescriptionChange(e);
                }  else if (editedModeloDescription) {
                  handleModeloDescriptionChange(e);
                } else if (editedAsaColorDescription) {
                  handleAsaColorDescriptionChange(e);
                } else if (editedPlacaCodeDescription) {
                  handlePlacaCodeDescriptionChange(e);
                } else if (editedColorDescription) {
                  handleColorDescriptionChange(e);
                } else if (editedCountry) {
                  handleCountryChange(e)
              } else if (editedResinaFamDescription) {
                handleFamResinaDescriptionChange(e)
            }
         //   else if (editedSubFamDescription) {
         //     handleSubFamDescriptionChange(e)
       //   }

              }}
            />
          </>
        )}

        {editedAsaColorCode &&  (
          <>
         
           <label className="block mb-2 text-sm font-medium  text-white">
              Master-Kg:{" "}
            </label>
           
           
            <input
              className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"
              type="number"
              value={editedMasterKg}
              onChange={handleMasterKgChange}
            />
          </>
        )}

{editedResinaFamCodeAlfa &&(
  <>  
           <label className="block mt-2 mb-2 text-sm font-medium  text-white">
             Codigo Alfa:{" "}
            </label>
            <input
              className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"
              type="text"
              value={editedResinaFamCodeAlfa}
              onChange={handleFamResinaCodeAlfaChange}
            />
          </>
            )}

        {editedModeloCode && (
          <>
            <label className="block mb-2 text-sm font-medium  text-white">
              Capacitad
            </label>
            <input
              className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"
              type="text"
              value={editedCapacitad}
              onChange={handleCapacitadChange}
            />
          </>
        )}
      </div>
      <div className="">
        <button
          className={`w-full text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-8 
             ${
               message !== ""
                 ? "bg-zinc-400 cursor-not-allowed"
                 : "bg-teal-600 hover:bg-teal-700 focus:ring-teal-800"
             }`}
          onClick={handleSave}
          disabled={message !== ""}
        >
          Gardar
        </button>
        <button
          className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8"
          onClick={onClose}
        >
          Descartar
        </button>
      </div>
    </div>
  );
};

export default EditGarCompModal;
