import { useState } from 'react';
import ListaCustomers from "../components/customers/NewCustomer"
import DashboardCustomers from "../components/customers/DashboardCustomers"

const Customers = () => {
    const [isDashboardActive, setIsDashboardActive] = useState(true);

    const toggleActiveComponent = () => {
      setIsDashboardActive(!isDashboardActive);
    };
  return (
    <>
    {isDashboardActive ? <DashboardCustomers isDashboardActive={isDashboardActive} setIsDashboardActive={setIsDashboardActive} /> : <ListaCustomers isDashboardActive={isDashboardActive} setIsDashboardActive={setIsDashboardActive} />}
    
  { /*
     <button className='text-white
      font-medium rounded-lg text-sm px-5 py-2.5 text-center
      bg-pink-600 hover:bg-pink-700 focus:ring-pink-800
      
      ' onClick={toggleActiveComponent}>
        {isDashboardActive ? 'Añadir Cliente' : 'Panel Clientes'}
      </button>
  */}

    </>
  )
}

export default Customers