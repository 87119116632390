import { apiWrapper } from '../../utils/apiwrapper';
import { useState, useEffect } from 'react';
import EditModelModal from '../editmodal/EditModelModal';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import SearchBar from '../searchbar/searchbar';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import EditAlertDeleteModelModal from "../editmodal/EditAlertDeleteModel";
import { socket } from "../../utils/variousConst.js";
import ViewModelModal from '../editmodal/ViewModelModal.js';
import {CSVLink} from "react-csv";
import{ listamodelosgaraffonesheaders} from "../../utils/variousConst.js"
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {date, options, mexicanTime, formatMexicanDate} from "../../utils/variousConst";

const ModelComponent = () => {
  

  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);

  const [editableItem, setEditableItem] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [viewItem, setViewItem] = useState(null);
  const [isViewModalOpen, setViewModalOpen] = useState(false);

  const [alertDeleteModel, setAlertDeleteModel] = useState(null);
  const [isAlertModelDeleteModalOpen, setIsAlertModelDeleteModalOpen] = useState(false)

  const [loading, setLoading] = useState(true);

  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const fetchOrdersCSV = async () => {
    try {
      const response = await apiWrapper("model", "GET");
      setModels(response);
      
      //setOrders(response)
      setIsDownloadReady(true);
      setTimeout(() => {
        setIsDownloadReady(false)
      }, 5000);
    } catch (error) {
      console.error("Error fetching orders:", error); }
  };


  const openAlertModelDeleteModal = (model, index) =>{
    setAlertDeleteModel({model, index});
    setIsAlertModelDeleteModalOpen(true)
  }

  const closeOpenAlertModelDeleteModal =() =>{
    setAlertDeleteModel(null);
    setIsAlertModelDeleteModalOpen(false)
  }

  const openEditModal = (model,index) => {
    setEditableItem({model, index});
    setEditModalOpen(true);
  };
  
  const closeEditModal = () => {
    setEditableItem(null);
    setEditModalOpen(false);
  };

  const openViewModal = (model,index) => {
    setViewItem({model, index});
    setViewModalOpen(true);
  };
  
  const closeViewModal = () => {
    setViewItem(null);
    setViewModalOpen(false);
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await apiWrapper("model", "GET");
        setModels(response);
      } catch (error) {
        // Handle error
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };
  useEffect(() => {
  
    if (searchQuery === '') {
      setFilteredModels([]); // Clear the filtered models list
    } else {
    const filtered = models.filter((model) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const lowerCaseCode = model.code.toLowerCase();
      const lowerCaseProvider = model.modelname.toLowerCase();
      const lowerCaseColor = model.color.toLowerCase();
    //  const lowerCaseDescription = model.description.toLowerCase();
      return (
        lowerCaseCode.includes(lowerCaseSearchQuery) ||
        lowerCaseProvider.includes(lowerCaseSearchQuery) ||
        lowerCaseColor.includes(lowerCaseSearchQuery)
        // ||
     //   lowerCaseDescription.includes(lowerCaseSearchQuery)

      );
    });
    setFilteredModels(filtered);
  };
}, [searchQuery, models]); 



  const handleSave = (index, updatedModel) => {
    
  
    // Make a PATCH request to update the model on the server
    const updatedModelOnServer = async () => {
      try {
        await apiWrapper(`model/${updatedModel._id}`, 'PATCH', updatedModel);
        // If the PATCH request is successful, update the model in the state
        const updatedModels = [...models];
        updatedModels[index] = updatedModel;
        setModels(updatedModels);
        const response = await apiWrapper('model', 'GET');
      setModels(response);

// Update the filtered search as well
const updatedFilteredModels = [...filteredModels];
updatedFilteredModels[index] = updatedModels;
setFilteredModels(updatedFilteredModels);


        closeEditModal();
      } catch (error) {
        console.error('Error updating model:', error);
        // Handle error updating model
      }
    };
  
    updatedModelOnServer();
  };



  const handleDelete = async (modelId) => {
    try {
      // Make a DELETE request to the API to delete the model with the given ID
      await apiWrapper(`model/${modelId}`, "DELETE");

      // Update the models state by removing the deleted model from the array
      setModels(models.filter((model) => model._id !== modelId));
    } catch (error) {
      // Handle error
      console.error("Error deleting model:", error);
    }
  };

  const navigate = useNavigate();
  const navigateToNewProduct = () => {
    navigate("/model");
  };


  useEffect(() => {
    const handleCreateModel = (newModel) => {
      setModels((prevModels) => [...prevModels, newModel]);
    };
  
    // Add the 'createModel' event listener
    socket.on('createModel', handleCreateModel);
  
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('createModel', handleCreateModel);
    };
  }, []);

  useEffect(() => {
    socket.on('deleteModel', (deletedModelId) => {
      setModels((prevModels) => {
        return prevModels.filter((model) => model._id !== deletedModelId);
      });
    });
  
    return () => {
      socket.off('deleteModel');
    };
  }, []);

  useEffect(() => {
    socket.on('updateModel', (updatedEditModel) => {
     //console.log("cheedita",updatedEditModel.data.customer)
      setModels((prevModels) => {
        const updatedModels = prevModels.map((model) => {
          if (model._id === updatedEditModel._id) {
            //console.log("check",updatedEditModel.customer)
            
            return { ...model, ...updatedEditModel.data };
            
          }
          return model;
        });
        //console.log("check2", updatedModels)
        return updatedModels;
      });
    });
  
    return () => {
      socket.off('updateModel');
    };
  }, []);

   //spinning loading
   {/* 
 useEffect(() => {
  if (models.length === 0) {
    // If there are no articles to load, hide the loading animation after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  } else {
    setLoading(true);
  }
}, [models]);
*/ }

  return (
    
    <section className="bg-gray-900  sticky top-10 z-40    ">
       
      <div className="flex flex-col  px-6 py-8   ">
        <div className="bg-gray-800 rounded-lg ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8  ">
          <button 
        className='text-white'
        onClick={fetchOrdersCSV}> 
                          {isDownloadReady ? ( 
                    <CSVLink data={models} headers={listamodelosgaraffonesheaders} filename={`ListaModelosGARAFFONES_.csv`}>
        <FileDownloadOutlinedIcon />
      </CSVLink>  ) : (
        <span><FileDownloadOffIcon /></span>
      )}
      </button>
        <h1 className="text-xl font-bold text-center leading-tight tracking-tight md:text-2xl text-white">
          Panel de Modelos
        </h1>
        <div className='flex items-center justify-center'>
        <button
                        onClick={navigateToNewProduct}
                        className=" text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                         bg-pink-600 hover:bg-pink-700 focus:ring-pink-800 mt-8"
                      >
                        Añadir Modelo
                      </button>
                      </div>
                      <div className="hidden md:flex justify-center items-center overflow-auto rounded-lg">
    <SearchBar key="searchbar" onSearch={handleSearch }  />
    
  
  </div>
      </div>
      <div className=" overflow-x-auto rounded-lg hidden md:block max-h-96">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-700 ">
            <tr>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Codigo
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Modelo
              </th>
              
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Kg
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                SubFamilia
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Tipo SubFam
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                Fecha
              </th>
             
              <th className="px-2 py-3  text-xs font-medium text-gray-400 uppercase tracking-wider">
                Edit
              </th>
              <th className="px-3 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider">
                Delete
              </th>
             
              {/* Add more table headers as needed */}
            </tr>
        
          </thead>
          <tbody>
  {filteredModels.map((model, index) => (
    <tr className={
      index % 2 === 0 ? "bg-amber-200 text-center" : "bg-amber-300 text-center" 
    }  key={`${model.code}-${model.modelname}`}>
      <td className='font-medium'>{model.code}</td>
      <td className='font-medium'><button onClick={() => openViewModal(model, index)}>{model.modelname}</button></td>
      <td className="px-6 py-4  font-medium whitespace-nowrap">{Number.isInteger(model.masterkg) ? model.masterkg : Math.round(model.masterkg * 100) / 100}</td>
      
      <td className="px-6 py-4   whitespace-nowrap">{model.listadescription}</td>
                <td className="px-6 py-4   whitespace-nowrap">{model.subfamiliadescription}</td>
                <td className=" text-xs px-6 py-4 whitespace-nowrap">{formatMexicanDate(model.createdAt)}</td>
                <td className=" whitespace-nowrap    ">
                <EditIcon
                     onClick={() => openEditModal(model, index)}
                   />
                 </td>
                   <td className="px-2  whitespace-nowrap m-18  ">
                         <DeleteOutlineIcon
                           onClick={() => openAlertModelDeleteModal(model)}
                         ></DeleteOutlineIcon>
                       </td>
    </tr>
  ))}
</tbody>

<tbody className=" divide-y divide-gray-200 text-center text-white ">
                {models.length === 0 || filteredModels.length > 0 ? (
     <tr className="text-center">
     <td colSpan="8">
   {loading ? (
     <p className=" text-white text-center">&#9696;</p>
   ) : (
     <p className="text-center  font-medium text-white">No hay datos en DB</p>
   )}
   </td>
     </tr>
  ) : (models.slice().reverse().map((model, index) => (
              <tr className={index % 2 === 0 ? " bg-sky-900" : " bg-sky-800" } key={model._id}>
                <td className=" px-6 py-4 text-sm font-medium  whitespace-nowrap">{model.code}</td>
                <td className="px-6 py-4 whitespace-nowrap">   
                <button onClick={() => openViewModal(model, index)}>{model.modelname}</button>
                </td>
               
                <td className="px-6 py-4  font-medium whitespace-nowrap">{Number.isInteger(model.masterkg) ? model.masterkg : Math.round(model.masterkg * 100) / 100}</td>
                <td className="px-6 py-4   whitespace-nowrap">{model.listadescription}</td>
                <td className="px-6 py-4   whitespace-nowrap">{model.subfamiliadescription}</td>
                <td className=" text-xs px-6 py-4 whitespace-nowrap">{formatMexicanDate(model.createdAt)}</td>
                
                    <td className=" whitespace-nowrap    ">
                   
                      <EditIcon
                        onClick={() => openEditModal(model, index)}
                       
                      />
                      
                      
                    </td>
                    
                    
                  
                    <td className="px-2  whitespace-nowrap m-18  ">
                            <DeleteOutlineIcon
                              onClick={() => openAlertModelDeleteModal(model)}
                            ></DeleteOutlineIcon>
                          </td>
                    
              </tr>
            )))}
          </tbody>
        </table>
      </div>
      
      <div className='grid grid-cols-1 gap-4 md:hidden '>
      <div className="flex justify-center items-center" >
    <SearchBar key="searchbar" onSearch={handleSearch }  />
    
  
    </div>
<div>

  {filteredModels.map((model, index) => (
    <div  className={
      index % 2 === 0
        ? "border rounded-lg bg-amber-200 px-2 py-2.5"
        : " border rounded-lg bg-amber-300 px-2 py-2.5"
    } key={`${model.code}-${model.modelname}`}>
      <div className=' bmodel rounded-lg flex flex-col items-center justify-center space-x-2 text-xl mb-2'>
      <div className="  flex items-center justify-between space-x-2 text-1xl font-bold ml-3 mr-3  ">Codigo:</div>
      <div className="  flex items-center justify-between space-x-2 text-1xl font-bold ml-3 mr-3  ">{model.code}</div>
      </div>
      <div className="text-xl font-medium break-all ml-3 mr-3    ">Modelo:<button onClick={() => openViewModal(model, index)}>{model.modelname}</button></div>
      <div className="text-sm font-medium break-all  ml-3 mr-3   ">SubFamilia:{model.listadescription}</div>
      <div className="text-sm font-medium break-all  ml-3 mr-3   ">Tipo SubFam:{model.subfamiliadescription}</div>
      <div className="text-sm font-medium break-all ml-3 mr-3   ">Kg:{Number.isInteger(model.masterkg) ? model.masterkg : Math.round(model.masterkg * 100) / 100}</div>
      <div className="flex justify-between mt-4 ml-3 mr-3 ">
      <button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-2 py-2.5 text-center
                          bg-gray-700 hover:bg-sky-900 focus:ring-gray-800 "
                          onClick={() => openEditModal(model, index)}>
      <EditIcon /> Edit
      </button>
<button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-700 hover:bg-red-700 focus:ring-gray-800"
                          onClick={() => openAlertModelDeleteModal(model)}>
      <DeleteOutlineIcon/> Delete
      </button>
      </div>
    
    </div>
    
  ))}
 
</div>

        
{models.length === 0 || filteredModels.length > 0 ? (
  loading ? (
    <div className="border rounded-lg bg-sky-900">
      <div className="bg-transparent rounded-lg flex items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium">
      <div className="animate-spin text-white">&#9696;</div>
      
      </div>
    </div>
): (
  <div className="border rounded-lg bg-sky-900">
    <p className="text-center text-white">No hay datos en DB</p>
  </div>
)
  ) : (
    models.slice().reverse().map((model, index) => (
    <div className={index % 2 === 0
      ? "border rounded-lg bg-sky-900 text-white"
      : " border rounded-lg bg-sky-800 text-white"} key={model._id}>
      <div className="bg-transparent   rounded-lg flex flex-col items-center justify-center space-x-2 mb-4 mt-2 text-xl font-medium ">
                    <div className="  flex items-center justify-between space-x-2 text-1xl font-bold ml-3 mr-3   ">Codigo:</div>
                    <div className="  flex items-center justify-between space-x-2 text-1xl font-bold ml-3 mr-3   ">{model.code}</div>
      </div>
      <div className="text-sm font-medium break-all ml-3 mr-3   ">Modelo:<button onClick={() => openViewModal(model, index)}>{model.modelname}</button></div>
      <div className="text-sm font-medium break-all ml-3 mr-3   ">SubFamilia: {model.listadescription}</div>
      <div className="text-sm font-medium break-all ml-3 mr-3   ">Tipo Subfam: {model.subfamiliadescription}</div>
     
      <div className="text-sm font-medium break-all ml-3 mr-3   ">Kg: {Number.isInteger(model.masterkg) ? model.masterkg : Math.round(model.masterkg * 100) / 100}</div>
      <div className="text-sm font-medium break-all ml-3 mr-3   ">Fecha: {formatMexicanDate(model.createdAt)}</div>
      <div className="flex flex-col justify-between ">
      <button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-2 mt-4"
                          onClick={() => openEditModal(model, index)}>
      <EditIcon /> Edit
      </button>
<button className=" text-white focus:ring-4 focus:outline-none  
                          font-medium rounded-lg text-sm px-1 py-0.5 text-center
                          bg-gray-500 hover:bg-gray-700 focus:ring-gray-800 ml-3 mr-3 mb-3"
                          onClick={() => openAlertModelDeleteModal(model)}>
      <DeleteOutlineIcon/> Delete
      </button>
      </div>
    </div>
  )))}
</div>
     
       
      
    </div>
  </div>
  {isEditModalOpen && (
      <div className="  fixed inset-0  flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
      <div className="mt-10 flex items-center  justify-center">
  <EditModelModal editableItem={editableItem} onSave={handleSave} onClose={closeEditModal} />
  </div>
        </div>
)}
  {isViewModalOpen && (
      <div className="  fixed inset-0 flex items-center  justify-center z-45  overflow-auto overflow-y-scroll">
      <div className="mt-10 flex items-center  justify-center">
  <ViewModelModal viewItem={viewItem} onClose={closeViewModal} />
  </div>
        </div>
)}
{isAlertModelDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-45">
          <div className="mt-10">
            <EditAlertDeleteModelModal
              alertDeleteModel={alertDeleteModel}
              onSave={handleDelete}
              onClose={closeOpenAlertModelDeleteModal}
            />
          </div>
        </div>
      )}
</section>
    
  )
}

export default ModelComponent