import { useState } from "react";

const EditUserModal = ({ editableItem, onSave, onClose }) => {
    const { user, index } = editableItem;
    const [editedUsername, setEditedUsername] = useState(user.username);
    const [editedPassword, setEditedPassword] = useState(user.password);
    const [editedEmail, setEditedEmail] = useState(user.email);
   
    const [editedRole, setEditedRole] = useState(user.role);
    
    const [editedSite, setEditedSite] = useState(user.site);
  
    const handleSiteChange = (hh) => {
      setEditedSite(hh.target.value);
    };


    const handleUsernameChange = (e) => {
      setEditedUsername(e.target.value);
    };
    const handlePasswordChange =(f) => { 
      setEditedPassword(f.target.value);
    }
    const handleEmailChange = (g) => { 
      setEditedEmail(g.target.value);
    }
    

    const handleRoleChange = (i) =>{  
        setEditedRole(i.target.value);
      }
    
  
    const handleSave = () => {
      onSave(user.index, { ...user, username: editedUsername, password: editedPassword, email:editedEmail,  role: editedRole, site: editedSite});
      onClose();
    };
  
    return (
      <div className="bg-gray-800 bg-opacity-90  flex flex-col   px-6 h-auto  mx-auto" style={{ position: 'absolute', top:'180px',  zIndex: '9999' }}>
      {/* Modal content */}
      <div className="   sm:mt-0 rounded-lg shadow bg-gray-800 " >
          <label className="block mb-2 text-sm font-medium  text-white mt-2">Usuario:</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedUsername} onChange={handleUsernameChange} />
          <label className="block mb-2 text-sm font-medium  text-white mt-2">Contraseña:</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedPassword} onChange={handlePasswordChange} />
          <label className="block mb-2 text-sm font-medium  text-white mt-2">Correo:</label>
          <input  className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500"type="text" value={editedEmail} onChange={handleEmailChange} />
         
          <label className="block mb-2 text-sm font-medium  text-white mt-2">Role</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedRole} onChange={handleRoleChange} />
              
           {/* 
               <label className="block mb-2 text-sm font-medium  text-white mt-2">Site</label>
          <input className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-blue-600
                           placeholder-gray-400 text-white
                            focus:ring-blue-500 focus:border-blue-500" type="text" value={editedSite} onChange={handleSiteChange} />
          */ }
        </div>
        <div className="">
        <button  className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-teal-600 hover:bg-teal-700 focus:ring-teal-800 mt-8" onClick={handleSave}>Guardar</button>
        <button className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-zinc-400 hover:bg-zinc-700 focus:ring-zinc-800 mt-8 mb-8" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  export default EditUserModal;