import React from 'react'
import { socket } from "../../utils/variousConst.js";
import { apiWrapper } from '../../utils/apiwrapper';
import "./Chat.css";
import { useState, useEffect, useRef } from "react";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import Conversation from './Conversation';
import ChatBox from './ChatBox';

const Chat = () => {

  
  const [chats, setChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [onlinePeople, setOnlinePeople] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  
  const [currentChat, setCurrentChat] = useState(null);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null)
  

  const session = tokenDecoder(getUserSession());
  const user = session.username;
  const role = session.role;
  const userId = session.userId

  const getUserData = async () => {
    try {
      const response = await apiWrapper(`user/${userId}`, "GET");
      setOnlineUsers(response);
      console.log("user data id", response)
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await apiWrapper(`user/`, "GET");
      const allUsers = response.map(user => ({ id: user._id, username: user.username }));
      setAllUsers(allUsers);
      console.log("all users", allUsers)
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };


  useEffect(() => {
    getAllUsers() 
    getUserData();
  }, []);






  const getChats = async () => {
    try {
     // console.log("userId", userId);
      const response = await apiWrapper(`chat/${userId}`, "GET");
          const  data = response;
          console.log("chat data from chat", data)
        setChats(data);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };
  useEffect(() => {
    getChats();
  }, [user]);

  useEffect(() => {
    // Emit 'new-user-add' event with user's ID when user logs in
    if (user) {
      socket.emit('new-user-add', userId, user);
    }
  
    socket.on('get-users', (users) => {
      console.log("whoisonline", users)
      const allUsers = users.filter((user) => user.isLoggedIn);
      const otherUsers = allUsers.filter((user) => user.userId !== userId);
      setOnlineUsers(user);
      setOnlinePeople(otherUsers)
      getUserData();
    });
  
    // Emit 'disconnected-users' event with user's ID when component unmounts
    return () => {
      if (user) {
        socket.emit('disconnected-users', userId);
      }
      socket.off('get-users');
    };
  }, [user]);




 // console.log("this is the userid and username",userId, user);

 const startChat = async (receiverId) => {
  try {
    // Check if a chat with the other user already exists
    const existingChat = chats.find(chat => chat.users.includes(receiverId));

    if (!existingChat) {
      // If no existing chat, start a new chat
      const response = await apiWrapper('chat', 'POST', { senderId: userId, receiverId });
      setCurrentChat(response.chat);
      // Update chats state with the new chat
      setChats(prevChats => [...prevChats, response.chat]);
    } else {
      // If an existing chat is found, set it as the current chat
      setCurrentChat(existingChat);
    }
  } catch (error) {
    console.error('Error starting chat:', error);
  }
};


  // receive msg from socket
  useEffect(() => { 
    socket.on('receive-message', (data)=>{
      setReceivedMessage(data);
      console.log("getting message sockett", data)
    //  setNewMessages([])
 
    });
  }, []);

  const checkOnlineStatus = (chat) => {
    const chatMember = chat.members.find((member) => member !== userId);
    const online = onlinePeople.find((user) => user.userId === chatMember);
    return online ? true : false;
  };




    return (
        <div className="Chat">
          {/* Left Side */}
          <div className="Left-side-chat">
  
             <div className="Chat-container">
          <h2>Historial de Chats</h2>
          <div className="Chat-list">
            {chats.map((chat) => (
              <div
                onClick={() => {
                  setCurrentChat(chat);
                }}
              >
            
               <Conversation
                  data={chat}
                  currentUser={userId}
                  user= {user}
                  
             online={checkOnlineStatus(chat)}
                />
             
              </div>
            ))}
          </div>
          <div>
    {/* Other parts of your component */}
 
<h2>OnLine Users</h2>
    <ul>
  {onlinePeople.map(user => {
    // Check if a chat with the other user already exists
    const existingChat = chats.find(chat => Array.isArray(chat.members) && chat.members.includes(user.userId));
    
    // If no existing chat, return the JSX elements
    if (!existingChat) {
      return (
        <>
          <li key={user.userId}>{user.username} is online</li>
          <button onClick={() => startChat(user.userId)}>start a chat</button>
        </>
      );
    }

    // If an existing chat is found, return null (or you could return some other JSX element)
    return null;
  })}
</ul>
<h2>OffLine Users</h2>
<ul>
  {allUsers.map((user, index) => {
     if (user.id === userId) {
      return null;
    }
    // Check if a chat with the other user already exists
    const existingChat = chats.find(chat => Array.isArray(chat.members) && chat.members.includes(user.id));
    // Check if the user is offline
    const isOffline = !onlinePeople.find(onlineUser => onlineUser.userId === user.id);

    // If the user is offline and no existing chat, return the JSX elements
    if (isOffline && !existingChat) {
      return (
        <React.Fragment key={index}>
          <li>{user.username} is offline</li>
          <button onClick={() => startChat(user.userId)}>start a chat</button>
        </React.Fragment>
      );
    }

    // If the user is online or an existing chat is found, return null (or you could return some other JSX element)
    return null;
  })}
</ul>
  </div>
        </div>
      </div>
    
          {/* Right Side */}
    
          <div  className="Right-side-chat">
            <div style={{ width: "20rem", alignSelf: "flex-end" }}>
          {/*
              <NavIcons />
                */ }
            </div>
          
            <ChatBox
              chat={currentChat}
              currentUser={userId}
              setSendMessage={setSendMessage}
              receivedMessage={receivedMessage}
            
            />
            
          </div>
        </div>
      );
    };

export default Chat