//import FormAndQr from '../components/temp/formandqr/FormAndQr';
//import VideoQRCodeScanner from '../components/temp/video2/Video';
//import PatchIncrementQRCodeScanner from '../components/temp/qrsubmit/PatchIncrementQrCodeScanSubmit';

import NewOrder from "../components/order/OrderCreate"



const Order = () => {
  return (
    <>
    <div className='bg-white'>
      
    <NewOrder />
   </div>
  </>
  )
}

export default Order