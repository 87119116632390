import {useState} from 'react'
import { apiWrapper } from '../utils/apiwrapper';
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const site = sessionStorage.getItem('site');

const Register = () => {

  const [userError, setUserError] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const[message, setMessage]=useState("")
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        role: "",
        site: "",
      });

      const [userId, setUserId] = useState("");

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        let parsedValue = value;
    
        if (name === "value") {
          parsedValue = Number(value);
        }
    
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: parsedValue,
        }));
      };
    
      const registerUser = async (event) => {
        event.preventDefault();
        try {
         // If no role is selected, set it to 'user' before sending the request
    const finalFormData = formData.role ? formData : { ...formData, role: 'user' };
    
          const response = await apiWrapper("user/register", "POST", finalFormData);
          setFormData({ username: "", email: "", password: "",  role:"", site:"" });
          setUserId(response._id);
        //  console.log("Response:", response);
         if (response.msg) {

          

          if (response.msg === "El nombre de usuario ya existe") {
            setUserError(response.msg);
            setMessage(
              <Alert className="flex justify-center" severity="error">
                El nombre de usuario "{finalFormData.username}"  ya existe
              </Alert>
            );
            setTimeout(() => {
              setMessage("");
            }, 5000);
            setTimeout(() => {
              setUserError(false);
              
            }, 6000);
         }
         else if (response.msg === "El correo ya existe") {
          setEmailError("");
          setMessage(
            <Alert className="flex justify-center" severity="error">
              El correo "{finalFormData.email}" ya existe en el DB
            </Alert>
          );
          setEmailError(response.msg);
      setShowErrorMessage(true);
      setTimeout(() => {
        setMessage('')
        setEmailError(false);
        setShowErrorMessage(false);
      }, 6000);
      
        }

      }
      else {

          setMessage(
            <Alert className="flex justify-center " severity="success">
              <AlertTitle>Nuevo {finalFormData.role} añadido!</AlertTitle>
            
            </Alert>
          );
      
          // Clear the message after 1 second
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }}

       catch (error) {
          console.error(error);
          
          setMessage(
            <Alert className="flex justify-center" severity="error">
              An error occurred, 
              
              Please try again.
            </Alert>
          );
          setTimeout(() => {
            setMessage("");
          }, 5000);
        }
      } 

      const navigate = useNavigate();

      const navigateToDashboardUsers = () => {
        navigate("/dashboardusers");
      };

  return (
    <section className=" bg-gray-900  ">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
        
        <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
        bg-gray-800 border-pink-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                    Crear una cuenta
                </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={registerUser}>
                <div>
                        <label htmlFor="username"
                         className="block mb-2 text-sm font-medium  text-white">Usuario</label>
                        <input type="text" name="username" id="username" 
                        value={formData.username}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-red-600
                           placeholder-gray-400 text-white
                            focus:ring-red-500 focus:border-red-500" 
                            placeholder="máximo 13 caracteres" 
                            maxLength="13"
                            required/>
                            {userError && <p className="error-message text-white">{userError}</p>}
                    </div>
                   
                    <div>
                        <label htmlFor="email"
                         className="block mb-2 text-sm font-medium  text-white">Correo</label>
                        <input type="email" name="email" id="email" 
                        value={formData.email}
                        onChange={handleInputChange}
                        className="
                          sm:text-sm rounded-lg  block w-full p-2.5
                          bg-gray-700 border-red-600
                           placeholder-gray-400 text-white
                            focus:ring-red-500 focus:border-red-500" 
                            placeholder="nombre@xxxx.com" required/>
                             {emailError && <p className="error-message text-white">{emailError}</p>}
                    </div>
                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium  text-white">Contraseña </label>
                        <input type="password" name="password" id="password" placeholder="••••••••" 
                        value={formData.password}
                        onChange={handleInputChange}
                        className=" sm:text-sm rounded-lg 
                        block w-full p-2.5
                         bg-gray-700 border-red-600 placeholder-gray-400 text-white
                          focus:ring-red-500 focus:border-red-500" 
                          minLength={6}
                          required/>
                          {passwordError && <p className="error-message text-white">{passwordError}</p>}
                    </div>

                    <div className="mb-4">
  <label htmlFor="role" className="block mb-2 text-sm font-medium text-white">
    Role
  </label>
  <select
    name="role"
    id="role"
    value={formData.role}
    onChange={handleInputChange}
    className="block w-full p-2.5 bg-gray-700 border-red-600 placeholder-gray-400 text-white focus:ring-red-500 focus:border-red-500 rounded-lg"
  >
    <option value="user">User</option>
    <option value="admin">Admin</option>
    <option value="almacenista">Almacenista</option>
    <option value="supervisor">Supervisor</option>
    <option value="vendedor">Vendedor</option>
    
  </select>
</div>
<div className="mb-4">
  <label htmlFor="site" className="block mb-2 text-sm font-medium text-white">
    Sitio
  </label>
  <select
    name="site"
    id="site"
    value={formData.site}
    onChange={handleInputChange}
    
    className="block w-full p-2.5 bg-gray-700 border-red-600 placeholder-gray-400 text-white focus:ring-red-500 focus:border-red-500 rounded-lg"
  >
  <option className='text-red-400' value="">elegir ubicacion de trabajo</option>
    
    
    {site === "tlaxcala" && <option value="tlaxcala">TLX</option>}
    {site === "cdmx" && <option value="cdmx">CDMX</option>}
  
    
  </select>
</div>            
                    <button type="submit"
                     className="w-full text-white  focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 
                     text-center bg-red-600 hover:bg-red-700 focus:ring-red-800">Registrar</button>
                    
                </form>
                {showErrorMessage && <p className="error-message text-white">Error: verifique sus credenciales de usuario.</p>}
                {message && (
        <div className="message-container">
          {message}
        </div>
      )}
                <div>
                    <button type="dashboardproducts"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-green-600 hover:bg-green-700 focus:ring-green-800 mt-8"
                        onClick={navigateToDashboardUsers}
                        >Panel Usuarios</button>
                    </div>
            </div>
            
        </div>
       
    </div>
  </section>
  )
}

export default Register