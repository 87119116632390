import React, { useState, useRef, useEffect } from "react";
//import QrReader from "react-qr-scanner";
import {date, options, mexicanTime} from "../../utils/variousConst"
import { apiWrapper } from "../../utils/apiwrapper";
import Html5QrcodePlugin from "../temp/VideoProductNewQrReader";
import { tokenDecoder } from "../../utils/tokenDecoder";
import { getUserSession } from "../../utils/localStorage.utils";
import { successSound, errorSound } from "../../utils/variousConst";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { SwitchAccessShortcutAddOutlined } from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';

const QRScanOrderConsult = ({ id, order }) => {
  const session = tokenDecoder(getUserSession());
  const user = session.username;
  // console.log("ciccio", user)
  
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("No result");
  const [scanning, setScanning] = useState(true);
  const qrRef = useRef(null);

  const [orders, setOrders] = useState([]);



  const handleScan = async (data) => {
    const dataerr = JSON.stringify(data, null, 2)
    setResult(data);

    if (data ) {
      setMessage(`Reading....`);
      setScanning(false); // Stop scanning when an article is scanned

      try {
        // Extract the data from the QR code
        const {
          ordernr,
          customer,
        //  finalproduct,
        //  itemstodo,
         // itemsscanned,
         // pack,
          id,
        } = extractDataFromQRCode(data);
        //  console.log('extracting the QR code data:', data.text); // Log the QR code data
        // console.log('Article ID:', { id }); // Log the extracted ID
        const response2 = await apiWrapper(`order/${id}`, "GET");
        const pack = response2.pack;
        const finalproductcolor = response2.finalproductcolor
        const itemstodo = response2.itemstodo;
        const finalproduct = response2.finalproduct;
        const preforma = response2.preforma;
        const preformacolor = response2.preformacolor;
        const preformapack = response2.preformapack;
        // Calculate the updated value
        //const updatedValue = Number(itemsscanned) + Number(pack);

        // const updatedKg = Number(kg) + Number(masterkg);

        //  console.log('Article ID:', id);

     

        //console.log('QR code data submitted to the database:', data.text);
        const idscanned = id;

       

{ 
  
       
        // Play the success sound
        successSound.play();
        
        //console.log(itemsupdated)

        setMessage(
          <Snackbar
          open={true}
          autoHideDuration={14000}
          onClose={() => setMessage('No result')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="info">
            <AlertTitle>¡Escaneo de CONSULTA completado!</AlertTitle>
            <div className="flex flex-col items-center">
            {(finalproduct || preforma) && ( 
                <> 
              <div>
                <strong>Pedido:</strong> {ordernr}
              </div>
              <div>
                <strong>Cliente:</strong> {customer}
              </div>
              </>
              )}
              { finalproduct && (
                <>  
              <div>
                <strong>Modelo Producto:</strong> {finalproduct}
              </div>
              <div>
                <strong>Color:</strong> {finalproductcolor}
              </div>
              
              
              <div >
                <strong>Paquetes Ud:</strong><span>{pack}</span> 
                </div>
                </>
              )}
              {(finalproduct || preforma) && ( 
              <div>
                <strong>Artículos para hacer:</strong> {itemstodo}
              </div>
              )}
              {preforma && (
                <> 
                <div>
                <strong>Modelo Preforma:</strong> {preforma} 
              </div>
              <div>
                <strong>Preforma Color:</strong> {preformacolor} 
              </div>
              <div >
                <strong>Paquetes Preformas Ud:</strong><span>{preformapack}</span> 
                </div> 
                </>
                )}
          {(!preforma && !finalproduct) && (
            <div className="text-red-800 font-bold text-center">
            <p>No es un PEDIDO QR. Compruéba  <HelpIcon
    sx={{ fontSize: 20,  }} /> en la página de almacén.</p>
            </div>

          )}
            </div>
          </Alert>
          </Snackbar>
        );

        // Clear the message after 1 second
       // setTimeout(() => {
      //    setMessage("No result");
      //  }, 5000);
    }} catch (error) {
        console.error(error);
        errorSound.play();
        setMessage(
          <Snackbar
          open={true}
          autoHideDuration={10000}
          onClose={() => setMessage('No result')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
          <Alert className="flex justify-center" severity="error">
            Ocurrió un error durante el escaneo. ¿Es este un pedido QR?
            <div className="flex flex-col items-center">
              <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                <pre className="whitespace-pre-wrap">
                  {dataerr}
                </pre>
              </div>
            </div>
            Inténtalo de nuevo
          </Alert>
          </Snackbar>,
        );
        //setTimeout(() => {
      //    setMessage("No result");
      //  }, 5000);
      }
    } else {
      setMessage("No result");
    }
    // console.log(data);
  };

  const extractDataFromQRCode = (qrCodeData) => {
    // Parse the QR code data and extract the code, provider, value, kg, masterkg , and ID
    const {
      ordernr,
      customer,
      finalproduct,
      itemstodo,
      itemsscanned,
      pack,
      id,
    } = JSON.parse(qrCodeData);

    return {
      ordernr,
      customer,
      finalproduct,
      itemstodo,
      itemsscanned,
      pack,
      id,
    };
  };

  const handleError = (err) => {
    console.error(err);
  };

  const startScanning = () => {
    // Open the device camera
    setScanning(true);

    //qrRef.current?.openImageDialog();

    //close camera if no scan done in 6 seconds
  //  setTimeout(() => {
  //    setScanning(false);
  //  }, 5000);
  //
};

  const stopScanning = () => {
    setScanning(false);
  };

  return (
    <div>
          {scanning ? (
       <Html5QrcodePlugin
       fps={10}
       qrbox={250}
       disableFlip={false}
       showTorchButtonIfSupported={true}
       qrCodeSuccessCallback={handleScan}
      // qrCodeErrorCallback={handleError}
   />
      ) : (
       null
      )}
      {message !== "No result" && (
        <div className="flex justify-center items-center h-16 bg-gray-600 text-white">
          {message}
        </div>
      )}
      {scanning ? (
        <>
          <div className="flex flex-col justify-center text-white">
            <h1 className="flex  justify-center">
              {" "}
              Modo CONSULTA{" "}
            </h1>

        {/* 
            <button
              className="flex justify-center  text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-red-700 focus:ring-teal-800 mt-4"
              onClick={stopScanning}
            >
               Apagar la cámara de uso CONSULTA
            </button>
      */}
        
          </div>{" "}
        </>
      ) : (
        <HelpIcon
          sx={{ fontSize: 20,    
        }}
          onClick={startScanning}
        />
      )}
    </div>
  );
};

export default QRScanOrderConsult;
