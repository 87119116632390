import React, { useState, useRef } from "react";
//import QrReader from "react-qr-scanner";
//import { QrReader } from 'react-qr-reader';
import { apiWrapper } from '../../utils/apiwrapper';
import Html5QrcodePlugin from "../temp/VideoProductNewQrReader";
import { tokenDecoder } from '../../utils/tokenDecoder';
import { getUserSession } from '../../utils/localStorage.utils';
import { successSound, errorSound } from "../../utils/variousConst";
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import {date, options, mexicanTime} from "../../utils/variousConst"
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Snackbar } from "@mui/material";
//import Button from "@mui/material/Button";


const QRScanStock = ({ id,order }) => {

  const session =tokenDecoder(getUserSession())
  const user = session.username
 // console.log("ciccio", user)
//console.log(mexicanTime);

  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("No result");
  const [scanning, setScanning] = useState(false);
  const qrRef = useRef(null);

  const [orders, setOrders] =useState([])
 
  

  



  const handleScan = async (data) => {
    const dataerr = JSON.stringify(data, null, 2)
    setResult(data);
    if (data ) {
      setMessage(`Reading....`);
     // setScanning(false); // Stop scanning when an article is scanned
     onScanDetected()
      try {
        // Extract the data from the QR code
        const { ordernr, customer,
          // finalproduct, 
         // itemstodo, 
        //  itemsstock, 
        //  pack, 
          id } = extractDataFromQRCode(data);
      //  console.log('extracting the QR code data:', data.text); // Log the QR code data
     // console.log('Article ID:', { id }); // Log the extracted ID
     const response2 = await apiWrapper(`order/${id}`, "GET");
     const pack = response2.pack;
    // const itemsscanned = response2.itemsscanned
     const itemstodo = response2.itemstodo;
     const itemsstock = "";
     const finalproduct = response2.finalproduct;
        // Calculate the updated value
        const updatedValue = Number(itemsstock) + Number(pack);
       // const updatedKg = Number(kg) + Number(masterkg);
  
      //  console.log('Article ID:', id);
  
        // Call the API to patch the updated value to the database
      const response =  await apiWrapper(`order/${id}/stockincrement`, 'PATCH', { itemsstock: updatedValue, 
        employeestory: {user,
          mexicanTime: new Date().toLocaleString("en-US", options),
          } });
  
        //console.log('QR code data submitted to the database:', data.text);

        const idscanned = id
        if (response.message) {
          if (response.message === "Stock not Found. Not Updated") {
            errorSound.play()
            setMessage(
              <Snackbar
              open={true}
              autoHideDuration={3000}
              onClose={() => setMessage('No result')}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              style={{ top: '50%', transform: 'translateY(-50%)' }}
            >
              <Alert className="flex justify-center" severity="error">
                Ocurrió un error durante el escaneo.
                    ¿Es este un QR de Existencias?
                <div className="flex flex-col items-center">
                <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                <pre className="whitespace-pre-wrap">{JSON.stringify(data, null, 2)}</pre>
                </div>
                </div>
                Please try again.
              </Alert>
              </Snackbar >
            );
          //  setTimeout(() => {
              
         //   setMessage('No result');
         // }, 5000);
          }
          else {
            if (response.message === "Updated value exceeds itemtodo. Patch denied.") {
              errorSound.play()
              setMessage(
                <Snackbar
                open={true}
                autoHideDuration={4000}
                onClose={() => setMessage('No result')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                Llegaste al tope de la orden de producción. No se permiten más escaneos en esta fase
                  <div className="flex flex-col items-center">
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 font-bold p-4 my-2">
                  <div> Art Para hacer: {itemstodo} </div>
                  
                    </div>
                  </div>
                  
                </Alert>
                </Snackbar>
              );
            }
            if (response.message === "Updated value exceeds produced items. Patch denied.") {
              errorSound.play()
              setMessage(
                <Snackbar
                open={true}
                autoHideDuration={6000}
                onClose={() => setMessage('No result')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <Alert className="flex justify-center" severity="error">
                Estás intentando poner en Existencia más Articulos de los actualmente producidos 
                 
                  
                </Alert>
                </Snackbar>
              );
            }
          }
        }

        else { 
        const fetchOrders = async () => {
          try {
            const response = await apiWrapper("order", "GET");
            const filteredOrders = response.filter(
              (order) => order._id === idscanned
            );
            setOrders(filteredOrders);
            //setOrders(response)
        
           
        const itemsupdated =filteredOrders[0]?.itemsstock
        //console.log(itemsupdated)
        const packStockupdated = filteredOrders[0]?.packitemsstock;
        return {itemsupdated, packStockupdated }
          } catch (error) {
            console.error("Error fetching orders:", error);
          }
        };
        // Play the success sound
        successSound.play();
        const {itemsupdated, packStockupdated } = await fetchOrders();
        //console.log(itemsupdated)
          
                setMessage(
                  <Snackbar
                  open={true}
                  autoHideDuration={3000}
                  onClose={() => setMessage('No result')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  style={{ top: '50%', transform: 'translateY(-50%)' }}
                >
                  
                  <Alert className="flex justify-center" severity="success">
                  <AlertTitle>¡Escaneo completado!</AlertTitle>
                  <div className="flex flex-col items-center">
                    <div>
                      Pedido: {ordernr} <span>Cliente: {customer}</span>
                    </div>
                     <div>
                     Artículos para hacer: {itemstodo}
                    </div>
                    <div >
                      <strong>Añadidos:</strong><span className="text-2xl"> {updatedValue}</span> 
                    </div>
                    <div >
                      <strong>Total:</strong><span className="text-2xl"> {itemsupdated}</span> 
                    </div>
                    <div>
                <strong>Paquetes:</strong><span className="text-2xl"> {packStockupdated}</span> 
              </div>
                  </div>
                </Alert>
                </Snackbar>
              );
                  
                  
                // Clear the message after 1 second
              //  setTimeout(() => {
                //  setMessage('No result');
              //  }, 2000);
              }} catch (error) {
                errorSound.play()
                console.error(error);
                setMessage(
                  <Snackbar
                  open={true}
                  autoHideDuration={3000}
                  onClose={() => setMessage('No result')}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  style={{ top: '50%', transform: 'translateY(-50%)' }}
                >
                  <Alert className="flex justify-center" severity="error">
                    Ocurrió un error durante el escaneo.
                    ¿Es este un QR de Existencias?
                    <div className="flex flex-col items-center">
                    <div className="max-w-50 overflow-hidden break-all border border-gray-300 p-4 my-2">
                    <pre className="whitespace-pre-wrap">{dataerr}</pre>
                    </div>
                    </div>
                    Inténtalo de nuevo
                  </Alert>
                  </Snackbar>
                );
           //     setTimeout(() => {
                  
             //   setMessage('No result');
          //    }, 5000);
              }
            } else {
              setMessage( "No result");
            }
           // console.log(data);
          };

  const extractDataFromQRCode = (qrCodeData) => {
    // Parse the QR code data and extract the code, provider, value, kg, masterkg , and ID
    const { ordernr, customer, finalproduct, itemstodo, itemsstock, pack, id  } = JSON.parse(qrCodeData);

    return { ordernr, customer, finalproduct, itemstodo, itemsstock, pack, id  };
  };

  const handleError = (err) => {
    console.error(err);
  };

  let scanTimeout;
  //let scanTimeOutTwo;

  const startScanning = () => {
    setScanning(true);
    //qrRef.current?.openImageDialog(); // Open the device camera

    //close camera if no scan done in 6 seconds
    //setTimeout(() => {
     // setScanning(false);
     // }, 12000);
  };

  const stopScanning = () => {
    setScanning(false);
    clearTimeout(scanTimeout);
  };


  const onScanDetected = () => {
    setScanning(false);
    clearTimeout(scanTimeout);
     // Set the scanning state back to true after 1 second
    
     scanTimeout = setTimeout(() => {
     setScanning(true);
   //  console.log("passo1riattivo")
     }, 3000);
     //scanTimeOutTwo = setTimeout(() => {
     //    console.log("passo2laspengodefi")
       //  setScanning(false);
        // }, 90000);
     };

  return (
    <div >
         {scanning ? (
       <Html5QrcodePlugin
       fps={10}
       qrbox={250}
       disableFlip={false}
       showTorchButtonIfSupported={true}
       qrCodeSuccessCallback={handleScan}
      // qrCodeErrorCallback={handleError}
   />
      ) : (
        <p className="text-center">Listo</p>
      )}
       {message !== 'No result' && (
        <div className="flex justify-center items-center h-16 bg-gray-600 text-white">
          {message}
        </div>
      )}
      {scanning ? (
        <>             
        <div className="flex flex-col justify-center text-white">
            <h1 className="flex  justify-center"> ...Trabajando... </h1>
            
        <button  className="flex justify-center  text-white  focus:ring-4 focus:outline-none  
                        font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-gray-600 hover:bg-red-700 focus:ring-teal-800 mt-4"  onClick={stopScanning}>
     
     Apagar la cámara</button>
          </div>   </>
      ) : (
        <QrCodeScannerOutlinedIcon  sx={{ fontSize: 60 }} onClick={startScanning} />
        
      )}
    </div>
  );
};

export default QRScanStock;