import { useNavigate } from "react-router-dom";
import { socket } from "../utils/variousConst";
//import { getSocket } from "../utils/variousConst";
import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';

const AdminPage = () => {
 

    const [isOpen, setIsOpen] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

  const navigate = useNavigate();

  const navigateToRegister = () => {
    navigate("/register");
  };

  const navigateToNewProduct = () => {
    navigate("/newproduct");
  };

  const navigateToGuia = () => {
    navigate("/guia");
  };
  
  const navigateToLogReg = () => {
    navigate("/logreg");
  };


  //const navigateToDashboardProduct = () => {
  //  navigate("/dashboardproducts");
  //};

  const navigateToDashboardUsers = () => {
    navigate("/dashboardusers");
  };

  const navigateToDashboardCustomers = () => {
    navigate("/customers");
  };

  const navigateToNewOrder = () => {
    navigate("/order");
  };

  const navigateToModelPreforma = () => {
    navigate("/dashboardpreforma");
  };

  const navigateToNewModel = () => {
    navigate("/model");
  };

  const navigateToNewComponentModel = () => {
    navigate("/dashboardallmodelscomponents");
  };
  

  const navigateToNewModelPreforma = () => {
    navigate("/preformamodel");
  };

  const navigateToDashboardModel = () => {
    navigate("/dashboardmodels");
  };

 // const navigateToDashboardStock = () => {
 //   navigate("/dashboardstock");
 // };

 // const navigateToDashboardDelivery = () => {
 //   navigate("/dashboarddelivery");
 // };

  const navigateToDashboardReview = () => {
    navigate("/dashboardreview");
  };

  const navigateToDashboardCompleted = () => {
    navigate("/dashboardcompleted");
  };

  return (
    <>
    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  ">
      <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 
          bg-gray-800 border-none">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
                  <h1 className="text-xl font-bold text-center leading-tight tracking-tight  md:text-2xl text-white">
                  Panel de administración
                  </h1> 
                  </div>
                  <div
        className="text-gray-600 absolute top-30 left-30"
        >
        <InfoIcon 
          sx={{ fontSize: 20,    
          }}
             onClick= {
              navigateToGuia} />
            </div> 
                  <div>
                  <div id="dropdown" >
                  <button
        type="button"
        className="w-full text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-600 hover:bg-gray-700 focus:ring-gray-800 mt-8"
        onClick={toggleDropdown}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-controls="options-menu"
      >   {isOpen ? (
        <>
          <KeyboardBackspaceIcon className="mr-3" />
          Volver al panel principal
        </>
      ) : (
        <>
          <AddCircleIcon className="mr-3" />
         Crear
        </>
      )}
      </button>
      {isOpen && (
        <div
          className="w-full flex flex-col justify-center  rounded-lg shadow-lg bg-transparent"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
         {/* Dropdown options here */}
        

         <button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
            onClick={navigateToNewOrder}
           
          >
            + Pedido
          </button>
          <button type="product"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToNewProduct}
                        >+ Resina</button>

<button type="components"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToNewComponentModel}
                        > <SettingsIcon /> Componentes </button>
<button type="model"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToNewModel}
                        >+ Modelo Garrafones</button>

<button type="modelpreforma"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToNewModelPreforma}
                        >+ Modelo Preforma</button>

<button type="newuser"className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-sky-900 hover:bg-sky-700 focus:sky-orange-800 mt-8"
                        onClick={navigateToRegister}
                        >+ Usuario</button>
          
        </div>
      )}
    </div>

</div>
{!isOpen && (
<div>

<button type="dashboardreview"
                       className="w-full text-gray-800  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToDashboardReview}
                        >Revisar Pedidos</button>
    
    <button type="dashboardcompleted"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToDashboardCompleted}
                        >Pedidos Completados</button>



<button type="dashboardmodels"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToDashboardModel}
                        >Modelos Garrafones</button>

<button
            type="button"
            className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                       bg-yellow-900 hover:bg-yellow-700 focus:sky-orange-800 mt-8"
            onClick={navigateToModelPreforma}
           
          >
           Modelos Preforma
          </button>

<button type="dashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToDashboardUsers}
                        >Usuarios</button>

<button type="customers"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToDashboardCustomers}
                        >Clientes</button>

<button type="logdashboard"
                       className="w-full text-white  focus:ring-4 focus:outline-none  
                       font-medium rounded-lg text-sm px-5 py-2.5 text-center
                        bg-emerald-800 hover:bg-emerald-700 focus:ring-emerald-800 mt-8"
                        onClick={navigateToLogReg}
                        >Registro de Log</button>

                        </div>)}
       </div>
          </div>
      
    </section>
    </>
  )
}

export default AdminPage