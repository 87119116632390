import DasboardOrder from "../components/order/dasboardOrder"

const DasboardOrders = () => {
    
  return (
    <div className="text-gray-100">
            <DasboardOrder />
         </div>
  )
}

export default DasboardOrders