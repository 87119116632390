// import jwtDecode from "jwt-decode";
// const jwtSecretKey = process.env.JWT_SECRET_KEY;
// export const tokenDecoder = () => {
//   const userSession = jwtDecode(window.localStorage.getItem('user-session'));
//   const userId = userSession.user_id;
//   const username = userSession.username; // Add this line to get the username
//   return { userId, username }; // Return an object with userId and username
// };

// import jwtDecode from "jwt-decode";

// const jwtSecretKey = process.env.JWT_SECRET_KEY;

// export const tokenDecoder = (token) => {
//   try {
//     const decodedToken = jwtDecode(token, jwtSecretKey);
//     return decodedToken;
//   } catch (error) {
//     console.error("Invalid token:", error);
//     return null;
//   }
// };

import jwtDecode from "jwt-decode";

const jwtSecretKey = process.env.JWT_SECRET_KEY;

export const tokenDecoder = () => {
  try {
    const token = window.localStorage.getItem("user-session");
    if (!token) {
      return null;
    }
    const decodedToken = jwtDecode(token, jwtSecretKey);
    const { user_id, username, role } = decodedToken;
    return { userId:user_id, username, role };
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};